import { AgChartsReact } from 'ag-charts-react';
import React, { useState } from 'react';
import { ChildEmotionalCheckIn } from '../../typings/api-types';
import { AgChartOptions, time } from 'ag-charts-community';
import _ from 'lodash';
import {
  ECI_MAX_PER_QUESTION,
  ECI_MIN_PER_QUESTION,
} from '../../emotionalHealthCheckin/ChildEmotionalCheckInPage/ChildEmotionalCheckInPage';

type ChildMentalEmotionalCheckInChartProps = {
  emotionalCheckIns: ChildEmotionalCheckIn[];
};

type JsDate = {
  js_date: any;
};

const ChildMentalEmotionalCheckInChart = (props: ChildMentalEmotionalCheckInChartProps) => {
  const checkInsWithJsDates = props.emotionalCheckIns.map((checkIn) => {
    const clonedCheck = _.clone(checkIn) as ChildEmotionalCheckIn & JsDate;
    clonedCheck.js_date = new Date(checkIn.date);
    return clonedCheck;
  });
  // We do have data for longer than this but it's probably not relevant
  let graphStartDate = new Date(2020, 1, 1);
  const [options] = useState<AgChartOptions>({
    autoSize: true,
    data: checkInsWithJsDates,
    navigator: {
      enabled: true,
      height: 20,
      margin: 5,
      // This will take some fiddling over time to keep around 6 mo. displayed
      min: 0.9,
      max: 1,
    },
    theme: {
      overrides: {
        line: {
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    series: [
      {
        yName: 'Average',
        type: 'line',
        xKey: 'js_date',
        yKey: 'score_average',
        stroke: '#000000',
        strokeWidth: 3,
        marker: {
          stroke: '#000000',
          fill: '#000000',
        },
        highlightStyle: {
          series: {
            dimOpacity: 0.05,
          },
        },
        tooltip: {
          format: 'hi',
        },
      },
      {
        yName: 'Sad/Happy',
        type: 'line',
        xKey: 'js_date',
        yKey: 'happy_score',
        strokeOpacity: 0.3,
        // tooltip
      },
      {
        yName: 'Anxious/Calm',
        type: 'line',
        xKey: 'js_date',
        yKey: 'calm_score',
        strokeOpacity: 0.3,
        // tooltip,
      },
      {
        yName: 'Angry/Peaceful',
        type: 'line',
        xKey: 'js_date',
        yKey: 'peaceful_score',
        strokeOpacity: 0.3,
        // tooltip,
      },
      {
        yName: 'Ashamed/Accepted',
        type: 'line',
        xKey: 'js_date',
        yKey: 'accepted_score',
        strokeOpacity: 0.3,
        // tooltip,
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'time',
        min: graphStartDate,
        tick: {
          interval: time.month.every(1),
        },
        title: {
          text: 'Date',
        },
        label: {
          autoRotate: true,
          // https://www.ag-grid.com/javascript-charts/axes-labels/#time-formatting
          format: '%b %Y',
        },
      },
      {
        position: 'left',
        type: 'number',
        min: ECI_MIN_PER_QUESTION,
        max: ECI_MAX_PER_QUESTION,
        title: {
          text: 'Score (min ' + ECI_MIN_PER_QUESTION + ', max ' + ECI_MAX_PER_QUESTION + ')',
        },
        label: {
          autoRotate: true,
        },
      },
    ],
  });
  return <AgChartsReact options={options} />;
};

export default ChildMentalEmotionalCheckInChart;
