import * as React from 'react';
import { ISetFilterCellRendererParams } from 'ag-grid-community';
import { getIconFromAlertGroup } from '../AlertIconsCell/AlertIconsCell';
import { ALERT_EVENT_TYPES } from '../../child/ChildRosterPage/ChildRosterPage';

function AlertIconsFilterOption(params: ISetFilterCellRendererParams) {
  const eventTypeCode = params.value;
  let icon;
  let label;
  if (eventTypeCode === '(Select All)') {
    icon = <span />;
    label = '(Select All)';
  } else if (eventTypeCode === null || eventTypeCode === undefined) {
    icon = <span />;
    label = '(Blanks)';
  } else {
    icon = getIconFromAlertGroup(eventTypeCode);
    const foundType = ALERT_EVENT_TYPES.find((eventType) => eventTypeCode === eventType.code);
    label = foundType?.label;
  }
  return (
    <span>
      {icon} {label}
    </span>
  );
}

export default AlertIconsFilterOption;
