import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';
import { replaceNewlinesWithBreaks } from '../../helpers/formattingHelper';

interface DefaultTooltipProps {
  id: string;
  tooltipText: string;
  children: React.ReactElement;
  placement?: Placement;
}

/* DefaultTooltip component (react-bootstrap Tooltip/Popover) caused scrollbars to appear sometimes
    overflow-x: hidden; on body on index.css fixed it,
    but it breaks every page that has horizontal scrollbar.
    Currently I don't have any page-level horizontal scrollbars so that is ok
    https://stackoverflow.com/a/19308332/13815107 */
const DefaultTooltip = (props: DefaultTooltipProps) => {
  const placement = props.placement ? props.placement : 'bottom';
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 250 }}
      overlay={(overlayProps) => (
        // White popover matches our UI more than the black tooltip
        // <Tooltip id={props.id} {...overlayProps}>{replaceNewlinesWithBreaks(props.tooltipText)}</Tooltip>
        <Popover id={props.id} {...overlayProps}>
          <Popover.Body
            style={{
              fontSize: 12,
            }}
          >
            {replaceNewlinesWithBreaks(props.tooltipText)}
          </Popover.Body>
        </Popover>
      )}
    >
      {props.children}
    </OverlayTrigger>
  );
};

function getCellId(params: ICellRendererParams) {
  return 'row-' + params.rowIndex + '-' + params.colDef!.field;
}

export default DefaultTooltip;
export { getCellId };
export type { DefaultTooltipProps };
