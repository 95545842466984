import React from 'react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import ChildWrat5ScoreCell from '../ChildWrat5ScoreCell/ChildWrat5ScoreCell';
import { ChildWratResult } from '../../typings/api-types';
import { deleteChildWratResult, useGetChildWratResults } from '../../api/wrat_result_api';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchChildWrat5ResultPageProps = {};

const getColumns = (onDelete: (objectToDelete: ChildWratResult) => Promise<any>): ColDef<ChildWratResult>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      width: 190,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 190,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('wratResult.SCORE'),
      cellRenderer: ChildWrat5ScoreCell,
      width: 360,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-wrat-result',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchChildWrat5ResultPage = (props: SearchChildWrat5ResultPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'wrat-result'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_WRAT_RESULT}
      translatePrefix={'wratResult.'}
      useGetListApiHook={useGetChildWratResults}
      deleteApiFunc={deleteChildWratResult}
      createForChildPageUrl={'/child-wrat-result/create/new'}
      createForHomePageUrl={'/home-wrat-result/create/new'}
    />
  );
};

export default SearchChildWrat5ResultPage;
