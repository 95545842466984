import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { patchChildRscaResult, postChildRscaResult, useGetChildRscaResultDetail } from '../../api/rsca_result_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildRscaResultPatch, ChildRscaResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  getHomeIdFromChildId,
  setHomeOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import RiskLevelDisplay from '../../beckResult/ChildBeckResultPage/RiskLevelDisplay';

type ChildRscaResultProps = {};

const DOMAIN_URL = 'child-rsca-result';
const DOMAIN = Domain.CHILD_RSCA_RESULT;
const CHILD_TAB = 'mental';

const CHILD_RSCA_RESULT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child: Yup.number().required('Required'),

  mas_raw_score: Yup.number().min(0).max(100).required('Required'),
  rel_raw_score: Yup.number().min(0).max(100).required('Required'),
  rea_raw_score: Yup.number().min(0).max(100).required('Required'),

  // Sense of Mastery
  mas_t_score: Yup.number().min(0).max(100).required('Required'),
  // Sense of Relatedness
  rel_t_score: Yup.number().min(0).max(100).required('Required'),
  // Emotional Reactivity
  rea_t_score: Yup.number().min(0).max(100).required('Required'),

  // (mas_*t*_score + rel_*t*_score) / 2
  res_raw_score: Yup.number().min(0).max(100).required('Required'),
  // Resource Index
  res_t_score: Yup.number().min(0).max(100).required('Required'),

  // rea_*t*_score - res_*t*_score
  vul_raw_score: Yup.number().min(0).max(100).required('Required'),
  // Vulnerability Index
  vul_t_score: Yup.number().min(0).max(100).required('Required'),

  // Files - Sent in separate request
  original_scan: Yup.mixed().required('Required'),
});

function getBlankChildResult(): ChildRscaResultPost {
  return {
    home: undefined,
    date: '',

    child: undefined,

    mas_raw_score: undefined,
    rel_raw_score: undefined,
    rea_raw_score: undefined,

    // Sense of Mastery
    mas_t_score: undefined,
    // Sense of Relatedness
    rel_t_score: undefined,
    // Emotional Reactivity
    rea_t_score: undefined,

    // (mas_*t*_score + rel_*t*_score) / 2
    res_raw_score: undefined,
    // Resource Index
    res_t_score: undefined,

    // rea_*t*_score - res_*t*_score
    vul_raw_score: undefined,
    // Vulnerability Index
    vul_t_score: undefined,
  };
}

const ChildRscaResultPage = (props: ChildRscaResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildRscaResultDetail(
    id,
    undefined,
    undefined,
    mode !== Mode.CREATE && hasNumberId,
  );

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let rscaResult: ChildRscaResultPost | ChildRscaResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    rscaResult = getBlankChildResult();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      rscaResult.home = homeId;
      rscaResult.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildRscaResultPost, { setSubmitting }: any) => {
      try {
        await postChildRscaResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    rscaResult = data as ChildRscaResultPatch;

    onSubmit = async (dataToSubmit: ChildRscaResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildRscaResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(rscaResult);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
  };

  return (
    <Formik initialValues={rscaResult} validationSchema={CHILD_RSCA_RESULT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildRscaResultPost | ChildRscaResultPatch>) => {
        const shouldDisplay = mode === Mode.VIEW;
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('rscaResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={8}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />

                  <br />
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'mas_raw_score'}
                        label={t('rscaResult.MAS_RAW_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'rel_raw_score'}
                        label={t('rscaResult.REL_RAW_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'rea_raw_score'}
                        label={t('rscaResult.REA_RAW_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'mas_t_score'}
                        label={t('rscaResult.MAS_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.mas_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'rel_t_score'}
                        label={t('rscaResult.REL_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.rel_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'rea_t_score'}
                        label={t('rscaResult.REA_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.rea_risk_level} />
                  </Row>
                  <br />

                  <p>RES Raw is (MAS T Score + RES T Score) / 2</p>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'res_raw_score'}
                        label={t('rscaResult.RES_RAW_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'res_t_score'}
                        label={t('rscaResult.RES_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.res_risk_level} />
                  </Row>
                  <br />

                  <p>VUL Raw is (MAS T Score + RES T Score) / 2</p>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'vul_raw_score'}
                        label={t('rscaResult.VUL_RAW_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'vul_t_score'}
                        label={t('rscaResult.VUL_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={8}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.vul_risk_level} />
                  </Row>

                  <br />
                  <FormikSinglePatchFileUpload
                    name={'original_scan'}
                    label={t('rscaResult.ORIGINAL_SCAN')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-rsca-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildRscaResultPage;
