import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import { Col, Container, Nav, Row, Spinner } from 'react-bootstrap';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { useGetHomeDetail } from '../../api/home_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import WithDomainPermission from '../../shared/components/WithDomainPermission/WithDomainPermission';
import HomeTransition from '../HomeTransition/HomeTransition';
import HomeSponsor from '../HomeSponsor/HomeSponsor';
import HomeMental from '../HomeMental/HomeMental';
import HomePhysical from '../HomePhysical/HomePhysical';
import HomeOverview from '../HomeOverview/HomeOverview';
import HomeEducation from '../HomeEducation/HomeEducation';
import { Mode } from '../../shared/helpers/modeHelper';

type HomePageProps = {};

const DOMAIN = Domain.HOME;

export default function HomePage(props: HomePageProps) {
  let { id: idString } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }

  if (!hasNumberId) {
    throw Error('Bad id: ' + idString);
  }

  const { isLoadingPermissions, permissionMap } = usePermissions();

  const { data, isLoading, error } = useGetHomeDetail(id, hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(Mode.VIEW, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const currentTabFromUrl = location.pathname.split('/')[3];

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (!data) {
    return <div>Could not find data for {id}</div>;
  } else if (error) {
    return <div>Error loading {id}</div>;
  }

  const home = data;

  return (
    <Container className="flex-grow-1 d-flex flex-column">
      <Row>
        <Col>
          <h1 className="text-center font-weight-bold">{home.name}</h1>
        </Col>
      </Row>
      <div className="border p-3 mb-3 flex-grow-1">
        <Nav variant="tabs" activeKey={currentTabFromUrl}>
          <Nav.Item>
            <Nav.Link eventKey="overview" as={Link} to={'overview/view'}>
              {t('home.tabs.OVERVIEW_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="physical"
              as={Link}
              to={'physical/view'}
              disabled={!permissionMap.pagePermissions.canViewPhysicalTab}
            >
              {t('home.tabs.PHYSICAL_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="mental"
              as={Link}
              to={'mental/view'}
              disabled={!permissionMap.pagePermissions.canViewMentalTab}
            >
              {t('home.tabs.MENTAL_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="education"
              as={Link}
              to={'education/view'}
              disabled={!permissionMap.pagePermissions.canViewEducationTab}
            >
              {t('home.tabs.EDUCATION_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="transition"
              as={Link}
              to={'transition/view'}
              disabled={!permissionMap.pagePermissions.canViewTransitionTab}
            >
              {t('home.tabs.TRANSITION_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="sponsorship"
              as={Link}
              to={'sponsorship/view'}
              disabled={!permissionMap.pagePermissions.canViewSponsorshipTab}
            >
              {t('home.tabs.SPONSOR_TAB')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route path="overview/:mode" element={<HomeOverview home={home} />} />
          {id && (
            <Route
              path="physical/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.PHYSICAL_TAB}>
                  <HomePhysical home={home} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="mental/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.MENTAL_TAB}>
                  <HomeMental home={home} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="education/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.EDUCATION_TAB}>
                  <HomeEducation home={home} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="transition/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.TRANSITION_TAB}>
                  <HomeTransition home={home} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="sponsorship/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.SPONSOR_TAB}>
                  <HomeSponsor home={home} />
                </WithDomainPermission>
              }
            />
          )}
          {id && <Route path="*" element={<Navigate to="overview/view" />} />}
        </Routes>
      </div>
    </Container>
  );
}
