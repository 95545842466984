import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ChildBeckResult } from '../../typings/api-types';
import styles from './BeckResultScoreCell.module.scss';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { useTranslation } from 'react-i18next';
import { RiskLevel } from '../ChildBeckResultPage/RiskLevelDisplay';

type BeckResultScoreCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildBeckResult;
  };

function getRiskNames(risks: any[]) {
  const riskNames = risks.map((risk: any) => {
    return risk.name;
  });
  return riskNames.join(', ');
}

export const BeckResultScoreCell = (props: BeckResultScoreCellProps) => {
  const { t } = useTranslation();
  const riskLevels = [
    { name: 'BAI (Anxiety)', level: props.data.bai_risk_level },
    { name: 'BANI (Anger)', level: props.data.bani_risk_level },
    { name: 'BDBI (Disruptive Behavior)', level: props.data.bdbi_risk_level },
    { name: 'BDI (Depression)', level: props.data.bdi_risk_level },
    { name: 'BSCI (Self-concept)', level: props.data.bsci_risk_level },
  ];

  const lowRisks = riskLevels.filter((risk) => risk.level === RiskLevel.LOW);
  const normalRisks = riskLevels.filter((risk) => risk.level === RiskLevel.NORMAL);
  const concerningRisks = riskLevels.filter((risk) => risk.level === RiskLevel.CONCERNING);
  const highRisks = riskLevels.filter((risk) => risk.level === RiskLevel.HIGH);

  const numLow = lowRisks.length;
  const numNormal = normalRisks.length;
  const numConcerning = concerningRisks.length;
  const numHigh = highRisks.length;
  return (
    <span>
      {props.data.has_risk && (
        <span className={'pe-2'}>
          <DefaultTooltip id={'has-risk'} tooltipText={t('beckResult.HAS_RISK_TOOLTIP')}>
            <span className={props.data.has_risk ? 'text-danger' : ''}>
              <FontAwesomeIcon icon={'triangle-exclamation'} /> {t('beckResult.HAS_RISK')}
            </span>
          </DefaultTooltip>
        </span>
      )}
      <span>
        (
        {numLow > 0 && (
          <DefaultTooltip
            id={'low-risk'}
            tooltipText={t('beckResult.LOW_RISK_TOOLTIP') + '\n' + getRiskNames(lowRisks)}
          >
            <span className={styles.lowRisk}>
              <FontAwesomeIcon icon={'arrow-down'} /> {numLow}
            </span>
          </DefaultTooltip>
        )}
        {numNormal > 0 && (
          <span>
            {numLow > 0 && '/'}
            <DefaultTooltip
              id={'normal-risk'}
              tooltipText={t('beckResult.NORMAL_RISK_TOOLTIP') + '\n' + getRiskNames(normalRisks)}
            >
              <span className={styles.normalRisk}>
                <FontAwesomeIcon icon={'minus'} /> {numNormal}
              </span>
            </DefaultTooltip>
          </span>
        )}
        {numConcerning > 0 && (
          <span>
            {(numNormal > 0 || (numLow > 0 && numNormal === 0)) && '/'}
            <DefaultTooltip
              id={'concerning-risk'}
              tooltipText={t('beckResult.CONCERNING_RISK_TOOLTIP') + '\n' + getRiskNames(concerningRisks)}
            >
              <span className={styles.concerningRisk}>
                <FontAwesomeIcon icon={'arrow-up'} /> {numConcerning}
              </span>
            </DefaultTooltip>
          </span>
        )}
        {numHigh > 0 && (
          <span>
            {(numConcerning > 0 ||
              (numNormal > 0 && numConcerning === 0) ||
              (numLow > 0 && numNormal === 0 && numConcerning === 0)) &&
              '/'}
            <DefaultTooltip
              id={'high-risk'}
              tooltipText={t('beckResult.HIGH_RISK_TOOLTIP') + '\n' + getRiskNames(highRisks)}
            >
              <span className={styles.highRisk}>
                <FontAwesomeIcon icon={'angles-up'} /> {numHigh}
              </span>
            </DefaultTooltip>
          </span>
        )}
        )
      </span>
    </span>
  );
};

export default BeckResultScoreCell;
export type { BeckResultScoreCellProps };
