import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { LinkContainer } from 'react-router-bootstrap';
import React, { ChangeEvent, useCallback, useContext, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { ObjectWithId } from '../../../typings/shared-types';
import { Domain } from '../../../api/permissions_api';
import FeedbackMessagesContext from '../../contexts/FeedbackMessagesContext';
import { createFeedbackMessageKey, FeedbackMessage } from '../FeedbackMessages/FeedbackMessages';
import { getDefaultColDef } from '../../grid/gridComponentHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryObserverResult } from 'react-query';
import { Column, ColumnEvent, FirstDataRenderedEvent, GridReadyEvent, RowClassParams } from 'ag-grid-community';
import {
  getGridPreferencesFromLocalStorage,
  saveGridPreferencesToLocalStorage,
} from '../../../api/grid_preferences_api';
import { debounce } from 'lodash';

// uiColumnMoved - drag reorder
// uiColumnDragged - drag resize
// autosizeColumns - double click line excel auto-resize or hamburger -> rows -> autosize columns
// contextMenu - hamburger -> rows -> pin this column OR hamburger -> rows -> reset columns
// toolPanelUi - hamburger -> columns -> check or uncheck column
// columnMenu - hamburger -> columns -> check all / uncheck all
const HUMAN_TRIGGERED_SOURCES = [
  'uiColumnMoved',
  'uiColumnDragged',
  'autosizeColumns',
  'contextMenu',
  'toolPanelUi',
  'columnMenu',
];

type GenericSearchPageProps<SearchedDataType> = {
  getColumns: (onDelete: (objectToDelete: SearchedDataType) => Promise<any>) => ColDef<SearchedDataType>[];
  permissionDomain: Domain;
  translatePrefix: string;

  useGetListApiHook: (params?: any, enabled?: boolean, gridApi?: any) => QueryObserverResult<SearchedDataType[]>;
  deleteApiFunc?: (id: number) => Promise<any>;
  customFilterRow?: (grid: AgGridReact | null) => JSX.Element;
  customOnFirstDataRendered?: (event: FirstDataRenderedEvent) => void;

  createForChildPageUrl?: string;
  createForHomePageUrl?: string;
  createByItselfUrl?: string;

  // TODO refactor to get rid of these one-offs
  childButtonVariant?: string;
  homeButtonVariant?: string;

  // TODO refactor to get rid of this one-off
  rowHeight?: number;
  getRowClass?: (params: RowClassParams) => string;

  // Used for admin pages under construction
  disableCreateByItself?: boolean;

  // If this is truthy, use the username and key to look up preferences
  // Preferences are Ag-grid settings like order of columns, size, which columns are visible, etc
  savedPreferencesKey?: string;
  // Don't load data for every column, only ones that are needed
  // Must be used in conjunction with QueryFieldsMixin on Django
  partialDataMode?: boolean;
  partialDataAlwaysLoadFields?: string[];
};

// TODO this has too many parameters but I don't know how to fix it right now
function genericOnDelete(
  objectToDelete: ObjectWithId,
  deleteApiFunction: (id: number) => Promise<any>,
  permissionDomain: Domain,
  translationPrefix: string,
  gridRef: React.RefObject<AgGridReact>,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
  refetch: () => Promise<any>,
) {
  const t = i18n.t;
  if (!objectToDelete.id) {
    throw Error('No id for object');
  }

  return deleteApiFunction(objectToDelete.id).then((response) => {
    if (gridRef.current?.api) {
      gridRef.current?.api.showLoadingOverlay();
    }
    addFeedbackMessage({
      key: createFeedbackMessageKey(permissionDomain, 'delete', objectToDelete.id),
      status: 'success',
      messageBody: <span>{t(translationPrefix + 'DELETED_SUCCESSFULLY')}</span>,
    });
    return refetch().then(() => {
      if (gridRef.current?.api) {
        // This is only needed when the data is the exact same, which is usually a bug with the delete
        // Perhaps deleteApiFunction is wrong?
        // gridRef.current?.api.hideOverlay();
      }
    });
  });
}

function wasTriggeredByHuman(event: ColumnEvent) {
  // List of everything:
  // "sizeColumnsToFit" | "autosizeColumns" | "autosizeColumnHeaderHeight" | "alignedGridChanged" |
  // "filterChanged" | "filterDestroyed" | "gridOptionsChanged" | "gridInitializing" |
  // "toolPanelDragAndDrop" | "toolPanelUi" | "uiColumnMoved" | "uiColumnResized" | "uiColumnDragged" |
  // "uiColumnExpanded" | "uiColumnSorted" | "contextMenu" | "columnMenu" | "rowModelUpdated" |
  // "rowDataUpdated" | "api" | "flex" | "pivotChart";

  // Confirmed non-human:
  // "api" | "flex" | "gridOptionsChanged"

  return HUMAN_TRIGGERED_SOURCES.includes(event.source);
}

function getFieldsFromColumns(displayedColumns: Column[], alwaysLoadFields?: string[]) {
  const explicitFields = displayedColumns
    .map((displayedColumn) => displayedColumn.getColDef().field)
    .filter((element) => {
      return element !== undefined;
    });

  // How do we handle fields that are used in cellRenderers, etc? (implicit)
  //   for now, just use partialDataAlwaysLoadFields
  let fields;
  if (alwaysLoadFields) {
    fields = alwaysLoadFields.concat(explicitFields as string[]);
  } else {
    fields = explicitFields;
  }
  let fieldsString = fields.join(',');
  return fieldsString;
}

const GenericSearchPage = <T extends ObjectWithId>(props: GenericSearchPageProps<T>) => {
  // Hook Set-up
  const gridRef = useRef<AgGridReact>(null);

  const [params, setParams] = React.useState<any>();
  let isReady = true;
  if (props.partialDataMode) {
    isReady = !!params;
  }

  const { data, error, refetch } = props.useGetListApiHook(params, isReady, gridRef?.current?.api);
  const { t } = useTranslation();
  const onFilterTextBoxChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    gridRef.current!.api.setQuickFilter(event.target.value);
  }, []);

  // This is a quick way to debounce, but if this doesn't work,
  //   don't debounce the function itself at all, debounce the input variables with useDebounce
  // I think this has a performance problem as well, as every render calls debounce() to wrap the function
  //   this newly wrapped function gets thrown away by useCallback, so it still works correctly
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveGridPreferencesToLocalStorage = useCallback(debounce(saveGridPreferencesToLocalStorage, 300), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetApiParams = useCallback(
    debounce((displayedColumns: Column[]) => {
      if (displayedColumns?.length && displayedColumns.length > 0) {
        setParams({
          fields: getFieldsFromColumns(displayedColumns, props.partialDataAlwaysLoadFields),
        });
      }
    }, 400),
    [props.partialDataAlwaysLoadFields],
  );

  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);

  // https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/#column-group-state
  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (props.savedPreferencesKey) {
        const columnApi = gridRef.current?.columnApi;
        // if (isPreferencesFetching) {
        //   throw Error('Race condition: Data loaded before preferences were ready!!');
        // } else
        const preferences = getGridPreferencesFromLocalStorage(props.savedPreferencesKey);
        console.log(gridRef.current?.api.getFilterInstance('home_name')?.isFilterActive());
        if (!columnApi) {
          throw Error('Data loaded before column api is ready!!');
        } else {
          const columnState = preferences?.columnState;
          if (columnState) {
            columnApi.applyColumnState({
              state: columnState,
              applyOrder: true,
            });
          }
        }
        console.log(gridRef.current?.api.getFilterInstance('home_name')?.isFilterActive());

        // Refresh for Partial Data Mode
        if (props.partialDataMode) {
          const displayedColumns = columnApi.getAllDisplayedColumns();
          debouncedSetApiParams(displayedColumns);
        }
      }
    },
    [debouncedSetApiParams, props],
  );

  const onSaveGridColumnState = useCallback(
    (event: ColumnEvent) => {
      if (props.savedPreferencesKey && wasTriggeredByHuman(event)) {
        const columnApi = gridRef.current?.columnApi;
        if (!columnApi) {
          return;
        }
        let columnState = columnApi.getColumnState();

        debouncedSaveGridPreferencesToLocalStorage(props.savedPreferencesKey, {
          key: props.savedPreferencesKey,
          columnState: columnState,
        });

        // Refresh for Partial Data Mode
        if (props.partialDataMode) {
          const displayedColumns = columnApi.getAllDisplayedColumns();
          debouncedSetApiParams(displayedColumns);
        }
      }
    },
    [
      debouncedSaveGridPreferencesToLocalStorage,
      debouncedSetApiParams,
      props.partialDataMode,
      props.savedPreferencesKey,
    ],
  );

  let deleteApiFunc: (id: number) => Promise<any>;
  if (props.deleteApiFunc) {
    deleteApiFunc = props.deleteApiFunc;
  } else {
    // TODO get rid of this dummy onDelete
    deleteApiFunc = (idToDelete: number) => Promise.resolve();
  }

  const onDelete = useCallback(
    (objectToDelete: T) => {
      return genericOnDelete(
        objectToDelete,

        deleteApiFunc,
        props.permissionDomain,
        props.translatePrefix,
        gridRef,

        addFeedbackMessage,
        refetch,
      );
    },
    [addFeedbackMessage, deleteApiFunc, props.permissionDomain, props.translatePrefix, refetch],
  );

  // If you want to wrap this in a useState you need to have a
  // useEffect to update onDelete and a useCallback hook around onDelete
  let [columnDefs] = React.useState(props.getColumns(onDelete));

  const [defaultColDef] = useState(getDefaultColDef());

  // Use data
  if (error) return <p>Error!</p>;
  let rowData: T[] | undefined = undefined;
  if (data) {
    rowData = data;
  }

  const defaultChildButtonVariant = props.createForHomePageUrl ? 'outline-primary' : 'primary';

  const defaultFilterRow = (
    <Row className="justify-content-md-center">
      <Col md={4}>
        <Form.Control
          type="text"
          placeholder={t(props.translatePrefix + 'SEARCH_PLACEHOLDER') || ''}
          onChange={onFilterTextBoxChanged}
        />
      </Col>
    </Row>
  );

  // Return component
  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h1 className="text-center font-weight-bold">{t(props.translatePrefix + 'SEARCH_TITLE')}</h1>
        </Col>
      </Row>
      {props.customFilterRow ? props.customFilterRow(gridRef.current) : defaultFilterRow}
      <Row className="flex-grow-1 pb-1">
        <Col className="shadow-sm border d-flex">
          <div
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={onGridReady.bind(this)}
              onRowDataUpdated={
                props.customOnFirstDataRendered ? props.customOnFirstDataRendered.bind(this) : undefined
              }
              onColumnVisible={onSaveGridColumnState.bind(this)}
              onColumnPinned={onSaveGridColumnState.bind(this)}
              onColumnResized={onSaveGridColumnState.bind(this)}
              onColumnMoved={onSaveGridColumnState.bind(this)}
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowId={(params) => params.data.id}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowHeight={props.rowHeight}
              getRowClass={props.getRowClass}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {props.disableCreateByItself && (
          <Col className="d-flex flex-column align-items-end">
            <Alert variant={'warning'}>{t(props.translatePrefix + 'CANT_ADD_WARNING')}</Alert>
          </Col>
        )}
        <Col className="d-flex flex-column align-items-end">
          {props.createForChildPageUrl && (
            <LinkContainer to={props.createForChildPageUrl}>
              <Button variant={props.childButtonVariant ? props.childButtonVariant : defaultChildButtonVariant}>
                {' '}
                + <FontAwesomeIcon icon={'child'} /> {t(props.translatePrefix + 'ADD_CHILD_BUTTON')}
              </Button>
            </LinkContainer>
          )}
          {props.createForHomePageUrl && (
            <LinkContainer to={props.createForHomePageUrl}>
              <Button variant={props.homeButtonVariant ? props.homeButtonVariant : 'primary'}>
                {' '}
                + <FontAwesomeIcon icon={'home'} /> {t(props.translatePrefix + 'ADD_HOME_BUTTON')}
              </Button>
            </LinkContainer>
          )}
          {props.createByItselfUrl && (
            <LinkContainer to={props.createByItselfUrl}>
              {/* As of 2023-08-10 ALL createByItselfUrl's should be disabled, they are the admin page stuff
                we can enable them as we build them, then eventually deprecate the disableAdd property*/}
              <Button disabled={props.disableCreateByItself} variant={'primary'}>
                {' '}
                + {t(props.translatePrefix + 'ADD_BUTTON')}
              </Button>
            </LinkContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default GenericSearchPage;
export { genericOnDelete };
export type { GenericSearchPageProps };
