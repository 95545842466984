import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postHomeEmotionalCheckIn } from '../../api/emotional_check_in_api';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FieldArray, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildAtHomeEmotionalCheckInPost, HomeEmotionalCheckInPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { GenericOption } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildrenInHome } from '../../api/child_api';
import { BOOLEAN_OPTIONS, yupRequiredIfTruthy } from '../../shared/helpers/formHelper';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { SimpleChildSummary } from '../../typings/api-types';
import i18n from 'i18next';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';

type HomeEmotionalCheckInProps = {};

const DOMAIN = Domain.CHILD_EMOTIONAL_CHECK_IN;

let required_1_to_4_if_truthy = (values: any[], schema: any) => {
  if (values[0]) {
    return schema.min(1).max(4).required('Required');
  } else {
    return schema.optional();
  }
};
const HOME_EMOTIONAL_CHECK_IN_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  children: Yup.array()
    .of(
      Yup.object().shape({
        // Auto-populated and can't be modified
        child: Yup.number().required('Required'),

        is_present: Yup.boolean().required('Required'),

        happy_score: Yup.number().when('is_present', required_1_to_4_if_truthy),
        calm_score: Yup.number().when('is_present', required_1_to_4_if_truthy),
        peaceful_score: Yup.number().when('is_present', required_1_to_4_if_truthy),
        accepted_score: Yup.number().when('is_present', required_1_to_4_if_truthy),

        checked_want_to_hurt_someone: Yup.boolean().when('is_present', yupRequiredIfTruthy),
        checked_need_help: Yup.boolean().when('is_present', yupRequiredIfTruthy),
      }),
    )
    .required('Required'),
});

function getBlankHomeCheckInNoChildren(): HomeEmotionalCheckInPost {
  return {
    home: undefined,
    date: '',
    // Null for no children loaded yet, empty array for no children in home
    children: null,
  };
}

function setInitialChildrenAtHomeCheckIn(formikProps: any, children: SimpleChildSummary[]): void {
  const childrenAtAppointment = children.map((child) => {
    return {
      child: child.id,
      is_present: undefined,
    } as ChildAtHomeEmotionalCheckInPost;
  });
  formikProps.setFieldValue('children', childrenAtAppointment);
}

function getChildrenAtCheckInGrid(formikProps: any, childOptions: GenericOption[]) {
  const t = i18n.t;
  return (
    <FieldArray
      name={'children'}
      render={(arrayHelpers) => {
        const children = formikProps.values.children;
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('common.CHILD')}</th>
                <th>{t('emotionalCheckIn.IS_PRESENT')}</th>

                <th>{t('emotionalCheckIn.HAPPY_SCORE')}</th>
                <th>{t('emotionalCheckIn.CALM_SCORE')}</th>
                <th>{t('emotionalCheckIn.PEACEFUL_SCORE')}</th>
                <th>{t('emotionalCheckIn.ACCEPTED_SCORE')}</th>

                <th>{t('emotionalCheckIn.WANT_TO_HURT_SOMEONE')}</th>
                <th>{t('emotionalCheckIn.NEED_HELP')}</th>
              </tr>
            </thead>
            <tbody>
              {children && children.length > 0 ? (
                children.map((childOfHomeAppointment: ChildAtHomeEmotionalCheckInPost, index: number) => (
                  <tr key={index}>
                    <td>
                      <FormikSelect name={`children[${index}].child`} options={childOptions} isReadOnly={true} />
                    </td>
                    <td>
                      <FormikSelect name={`children[${index}].is_present`} options={BOOLEAN_OPTIONS} />
                    </td>
                    <td>
                      <FormikTextInput name={`children[${index}].happy_score`} formikProps={formikProps} />
                    </td>
                    <td>
                      <FormikTextInput name={`children[${index}].calm_score`} formikProps={formikProps} />
                    </td>
                    <td>
                      <FormikTextInput name={`children[${index}].peaceful_score`} formikProps={formikProps} />
                    </td>
                    <td>
                      <FormikTextInput name={`children[${index}].accepted_score`} formikProps={formikProps} />
                    </td>
                    <td>
                      <FormikSelect
                        name={`children[${index}].checked_want_to_hurt_someone`}
                        options={BOOLEAN_OPTIONS}
                      />
                    </td>
                    <td>
                      <FormikSelect name={`children[${index}].checked_need_help`} options={BOOLEAN_OPTIONS} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No children in this home</td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      }}
    />
  );
}

/*
  As of 2023-05-12 this is CREATE ONLY, they will be saved as Child Doctor Appointments
 */
const HomeEmotionalCheckInPage = (props: HomeEmotionalCheckInProps) => {
  let { mode: modeString } = useParams();
  let [selectedHome, setSelectedHome] = useState(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: homeData, isLoading: isHomesLoading } = useGetHomes();
  const childrenInHomeResponse = useGetChildrenInHome(selectedHome);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: childData, isIdle: isChildrenIdle, isFetching: isChildrenFetching } = childrenInHomeResponse;
  const mode = parseMode(modeString);
  if (mode !== Mode.CREATE) {
    return <div>Please view or edit individually created Child Doctor Appointments</div>;
  }

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  if (isHomesLoading) {
    return <Spinner />;
  }

  const homeAppointment = getBlankHomeCheckInNoChildren();
  const onSubmit = async (data: HomeEmotionalCheckInPost, { setSubmitting }: any) => {
    await postHomeEmotionalCheckIn(data);
    // TODO error handling?
    setSubmitting(false);
    navigate('../child-mental-check-in/search/');
  };

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../child-mental-check-in/search');
  }

  const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  return (
    <Formik
      initialValues={homeAppointment}
      validationSchema={HOME_EMOTIONAL_CHECK_IN_FORM_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeEmotionalCheckInPost>) => {
        function onHomeChange(newHome: any) {
          setSelectedHome(newHome.value);
          formikProps.setFieldValue('children', null);
        }

        if (!formikProps.values.children && childData) {
          // This seems like a bad place to set this state
          setInitialChildrenAtHomeCheckIn(formikProps, childData);
        }
        return (
          <FormikForm>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('emotionalCheckIn.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  {isChildrenIdle && <div>Please pick a home</div>}
                  {isChildrenFetching && <Spinner />}
                  {formikProps.values.home &&
                    !isChildrenIdle &&
                    !isChildrenFetching &&
                    getChildrenAtCheckInGrid(formikProps, childOptions)}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                  <div className="d-flex justify-content-end">
                    <Button
                      className="mx-2 my-2"
                      variant="secondary"
                      onClick={onCancel.bind(null, formikProps.resetForm)}
                    >
                      {t('common.CANCEL')}
                    </Button>
                    <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                      {mode === Mode.CREATE ? t('common.CREATE') : t('common.SAVE_CHANGES')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default HomeEmotionalCheckInPage;
