import styles from './CustomErrorMessage.module.scss';

type CustomErrorMessageProps = {
  errorMessage: string;
};

// Error message not showing up? Try initializing your fields to null instead of undefined or vice-versa
const CustomErrorMessage = (props: CustomErrorMessageProps) => {
  if (typeof props.errorMessage !== 'object') {
    return <div className={styles.errorText}>{props.errorMessage}</div>;
  } else {
    // If the error message is an object, it is a field-level error and this CustomErrorMessage
    //  is for top-level tests. Ignore it and let the field-level CustomErrorMessage components display it
    return <span />;
  }
};

export default CustomErrorMessage;
