import * as React from 'react';
import { ChildWratResult } from '../../typings/api-types';

type ChildSeeScoreCellProps =
//ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildWratResult;
  };
export const ChildSeeScoreCell = (props: ChildSeeScoreCellProps) => {
  return (
    <span className={props.data.has_risk ? 'text-danger' : ''}>
      <span className={'pe-2'}>Click view to see scores</span>
    </span>
  );
};

export default ChildSeeScoreCell;
export type { ChildSeeScoreCellProps };
