import { ObjectWithId } from '../../../typings/shared-types';
import GenericSearchPage, { GenericSearchPageProps } from '../GenericSearchPage/GenericSearchPage';
import WithDomainPermission from '../WithDomainPermission/WithDomainPermission';

const GenericProtectedSearchPage = <T extends ObjectWithId>(props: GenericSearchPageProps<T>) => {
  return (
    <WithDomainPermission domainToCheck={props.permissionDomain}>
      <GenericSearchPage {...props} />
    </WithDomainPermission>
  );
};

export default GenericProtectedSearchPage;
