import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Skill } from '../typings/api-types';
import { SkillPatch, SkillPost } from '../typings/types-to-send';

const SKILL_REST_URL = '/skill';

const useGetSkills = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getSkills', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<Skill[]>(SKILL_REST_URL, requestConfig);
      return response?.data;
    },
    {
      enabled,
    },
  );
};

const useGetSkillDetail = (id: number | undefined, params?: any, onSuccess?: any, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getSkills', requestConfig, id],
    () => {
      return axios.get<Skill>(SKILL_REST_URL + '/' + id, requestConfig);
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const patchSkill = (id: number | undefined, data: SkillPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<SkillPatch>(SKILL_REST_URL + '/' + id + '/', data);
};

const postSkill = (data: SkillPost) => {
  return axios.post<SkillPost, AxiosResponse<Skill>>(SKILL_REST_URL + '/', data);
};

function createSkillOptions(skillData: undefined | Skill[]) {
  if (!skillData) {
    return [];
  } else {
    return skillData.map((skill) => {
      return {
        label: skill.name,
        value: skill.id,
      };
    });
  }
}

// No deleting for now

export { useGetSkills, useGetSkillDetail, patchSkill, postSkill, createSkillOptions };
