import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styles from './DueChildTrainingSkillIcon.module.scss';

type DueChildTrainingSkillIconProps = {
  className?: string;
  numberDue: number;
};
export const DueChildTrainingSkillIcon = (props: DueChildTrainingSkillIconProps) => {
  const { t } = useTranslation();
  const className = props.className || '';
  return (
    <DefaultTooltip id={'due-skill-tooltip'} tooltipText={t('skill.DUE_CHILD_TRAINING_SKILL_TOOLTIP')}>
      <span className={className + ' fa-layers fa-fw'}>
        <FontAwesomeIcon icon={'person-chalkboard'} transform={'right-4'} />
        <FontAwesomeIcon style={{ color: 'white' }} icon={'circle'} transform={'shrink-6 left-6 down-4'} />
        <FontAwesomeIcon icon={icon({ name: 'clock', style: 'regular' })} transform={'shrink-6 left-6 down-4'} />
        <span className={styles.notificationCount}>{props.numberDue}</span>
      </span>
    </DefaultTooltip>
  );
};

export default DueChildTrainingSkillIcon;
export type { DueChildTrainingSkillIconProps };
