import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteChildSeeResult,
  patchChildSeeResult,
  postChildSeeResult,
  useGetChildSeeResultDetail,
} from '../../api/see_result_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildSeeResultPatch, ChildSeeResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  getHomeIdFromChildId,
  setHomeOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type ChildSeeResultProps = {};

const DOMAIN_URL = 'child-see-result';
const DOMAIN = Domain.CHILD_SEE_RESULT;
const CHILD_TAB = 'education';

const SUBJECT_OPTIONS = [
  {
    label: 'Science',
    value: 'SCIENCE',
  },
  {
    label: 'Humanities',
    value: 'HUMANITIES',
  },
  {
    label: 'Commerce',
    value: 'COMMERCE',
  },
];

const CHILD_SEE_RESULT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child: Yup.number().required('Required'),

  score: Yup.number().min(0).max(4).required('Required'),
  english_score: Yup.number().min(0).max(4).required('Required'),
  nepali_score: Yup.number().min(0).max(4).required('Required'),
  math_score: Yup.number().min(0).max(4).required('Required'),
  science_score: Yup.number().min(0).max(4).required('Required'),

  subject: Yup.string().optional(),

  // Files - Sent in separate request
  original_scan: Yup.mixed().required('Required'),
});

function getBlankChildResult(): ChildSeeResultPost {
  return {
    home: undefined,
    date: '',

    child: undefined,

    score: undefined,

    english_score: undefined,
    nepali_score: undefined,
    math_score: undefined,
    science_score: undefined,

    subject: '',

    // Files - Sent in separate request
    original_scan: undefined,
  };
}

const ChildSeeResultPage = (props: ChildSeeResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildSeeResultDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childResult: ChildSeeResultPost | ChildSeeResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childResult = getBlankChildResult();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      childResult.home = homeId;
      childResult.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildSeeResultPost, { setSubmitting }: any) => {
      try {
        await postChildSeeResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    childResult = data as ChildSeeResultPatch;

    onSubmit = async (dataToSubmit: ChildSeeResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildSeeResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childResult);
    navigateAfterCancel();
  }
  const onDelete = () => {
    if (id) {
      return deleteChildSeeResult(id);
    } else {
      return Promise.resolve();
    }
  };

  const afterDelete = () => {
    if (id) {
      navigateAfterCancel();
    }
  };

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
  };

  return (
    <Formik initialValues={childResult} validationSchema={CHILD_SEE_RESULT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildSeeResultPost | ChildSeeResultPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('seeResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />

                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'score'}
                    label={t('seeResult.SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'english_score'}
                    label={t('seeResult.ENGLISH_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'nepali_score'}
                    label={t('seeResult.NEPALI_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'math_score'}
                    label={t('seeResult.MATH_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'science_score'}
                    label={t('seeResult.SCIENCE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikSelect
                    name={'subject'}
                    label={t('seeResult.SUBJECT')}
                    options={SUBJECT_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    defaultValue={''}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikSinglePatchFileUpload
                    name={'original_scan'}
                    label={t('seeResult.ORIGINAL_SCAN')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-see-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                  onDelete={onDelete}
                  afterDelete={afterDelete}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildSeeResultPage;
