import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ChildRscaResult } from '../../typings/api-types';
import styles from './RscaResultScoreCell.module.scss';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { useTranslation } from 'react-i18next';
import { RiskLevel } from '../../beckResult/ChildBeckResultPage/RiskLevelDisplay';

type RscaResultScoreCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildRscaResult;
  };

function getRiskNames(risks: any[]) {
  const riskNames = risks.map((risk: any) => {
    return risk.name;
  });
  return riskNames.join(', ');
}

export const RscaResultScoreCell = (props: RscaResultScoreCellProps) => {
  const { t } = useTranslation();
  const riskLevels = [
    { name: 'MAS (Sense of Mastery)', level: props.data.mas_risk_level },
    { name: 'REL (Sense of Relatedness)', level: props.data.rel_risk_level },
    { name: 'REA (Emotional Reactivity)', level: props.data.rea_risk_level },
    { name: 'RES (Resource Index)', level: props.data.res_risk_level },
    { name: 'VUL (Vulnerability Index)', level: props.data.vul_risk_level },
  ];

  const lowRisks = riskLevels.filter((risk) => risk.level === RiskLevel.LOW);
  const normalRisks = riskLevels.filter((risk) => risk.level === RiskLevel.NORMAL);
  const concerningRisks = riskLevels.filter((risk) => risk.level === RiskLevel.CONCERNING);
  const highRisks = riskLevels.filter((risk) => risk.level === RiskLevel.HIGH);

  const numLow = lowRisks.length;
  const numNormal = normalRisks.length;
  const numConcerning = concerningRisks.length;
  const numHigh = highRisks.length;
  return (
    <span>
      {props.data.has_risk && (
        <span className={'pe-2'}>
          <DefaultTooltip id={'has-risk'} tooltipText={t('rscaResult.HAS_RISK_TOOLTIP')}>
            <span className={props.data.has_risk ? 'text-danger' : ''}>
              <FontAwesomeIcon icon={'triangle-exclamation'} /> {t('rscaResult.HAS_RISK')}
            </span>
          </DefaultTooltip>
        </span>
      )}
      <span>
        (
        {numLow > 0 && (
          <DefaultTooltip
            id={'low-risk'}
            tooltipText={t('rscaResult.LOW_RISK_TOOLTIP') + '\n' + getRiskNames(lowRisks)}
          >
            <span className={styles.lowRisk}>
              <FontAwesomeIcon icon={'arrow-down'} /> {numLow}
            </span>
          </DefaultTooltip>
        )}
        {numNormal > 0 && (
          <span>
            {numLow > 0 && '/'}
            <DefaultTooltip
              id={'normal-risk'}
              tooltipText={t('rscaResult.NORMAL_RISK_TOOLTIP') + '\n' + getRiskNames(normalRisks)}
            >
              <span className={styles.normalRisk}>
                <FontAwesomeIcon icon={'minus'} /> {numNormal}
              </span>
            </DefaultTooltip>
          </span>
        )}
        {numConcerning > 0 && (
          <span>
            {(numNormal > 0 || (numLow > 0 && numNormal === 0)) && '/'}
            <DefaultTooltip
              id={'concerning-risk'}
              tooltipText={t('rscaResult.CONCERNING_RISK_TOOLTIP') + '\n' + getRiskNames(concerningRisks)}
            >
              <span className={styles.concerningRisk}>
                <FontAwesomeIcon icon={'arrow-up'} /> {numConcerning}
              </span>
            </DefaultTooltip>
          </span>
        )}
        {numHigh > 0 && (
          <span>
            {(numConcerning > 0 ||
              (numNormal > 0 && numConcerning === 0) ||
              (numLow > 0 && numNormal === 0 && numConcerning === 0)) &&
              '/'}
            <DefaultTooltip
              id={'high-risk'}
              tooltipText={t('rscaResult.HIGH_RISK_TOOLTIP') + '\n' + getRiskNames(highRisks)}
            >
              <span className={styles.highRisk}>
                <FontAwesomeIcon icon={'angles-up'} /> {numHigh}
              </span>
            </DefaultTooltip>
          </span>
        )}
        )
      </span>
    </span>
  );
};

export default RscaResultScoreCell;
export type { RscaResultScoreCellProps };
