import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteChildRiasecResult,
  patchChildRiasecResult,
  postChildRiasecResult,
  useGetChildRiasecResultDetail,
} from '../../api/riasec_result_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildRiasecResultPatch, ChildRiasecResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import { useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type ChildRiasecResultProps = {};

const DOMAIN_URL = 'child-riasec-result';
const DOMAIN = Domain.CHILD_RIASEC_RESULT;
const CHILD_TAB = 'transition';

const CHILD_RIASEC_RESULT_FORM_SCHEMA = Yup.object().shape({
  date: Yup.date().required('Required'),
  child: Yup.number().required('Required'),

  realistic_profile_score: Yup.number().min(0).max(15).required('Required'),
  investigative_profile_score: Yup.number().min(0).max(15).required('Required'),
  artistic_profile_score: Yup.number().min(0).max(15).required('Required'),
  social_profile_score: Yup.number().min(0).max(15).required('Required'),
  enterprising_profile_score: Yup.number().min(0).max(15).required('Required'),
  conventional_profile_score: Yup.number().min(0).max(15).required('Required'),

  realistic_personality_score: Yup.number().min(0).max(15).required('Required'),
  investigative_personality_score: Yup.number().min(0).max(15).required('Required'),
  artistic_personality_score: Yup.number().min(0).max(15).required('Required'),
  social_personality_score: Yup.number().min(0).max(15).required('Required'),
  enterprising_personality_score: Yup.number().min(0).max(15).required('Required'),
  conventional_personality_score: Yup.number().min(0).max(15).required('Required'),
});

function getBlankChildResult(): ChildRiasecResultPost {
  return {
    date: '',
    child: undefined,

    realistic_profile_score: undefined,
    investigative_profile_score: undefined,
    artistic_profile_score: undefined,
    social_profile_score: undefined,
    enterprising_profile_score: undefined,
    conventional_profile_score: undefined,

    realistic_personality_score: undefined,
    investigative_personality_score: undefined,
    artistic_personality_score: undefined,
    social_personality_score: undefined,
    enterprising_personality_score: undefined,
    conventional_personality_score: undefined,
  };
}

const ChildRiasecResultPage = (props: ChildRiasecResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildRiasecResultDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childResult: ChildRiasecResultPost | ChildRiasecResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childResult = getBlankChildResult();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      childResult.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildRiasecResultPost, { setSubmitting }: any) => {
      try {
        await postChildRiasecResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    childResult = data as ChildRiasecResultPatch;

    onSubmit = async (dataToSubmit: ChildRiasecResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildRiasecResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childResult);
    navigateAfterCancel();
  }
  const onDelete = () => {
    if (id) {
      return deleteChildRiasecResult(id);
    } else {
      return Promise.resolve();
    }
  };

  const afterDelete = () => {
    if (id) {
      navigateAfterCancel();
    }
  };

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  return (
    <Formik initialValues={childResult} validationSchema={CHILD_RIASEC_RESULT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildRiasecResultPost | ChildRiasecResultPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('riasecResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  <h3>{t('riasecResult.PROFILE_SCORES_HEADER')}</h3>
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'realistic_profile_score'}
                    label={t('riasecResult.REALISTIC_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'investigative_profile_score'}
                    label={t('riasecResult.INVESTIGATIVE_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'artistic_profile_score'}
                    label={t('riasecResult.ARTISTIC_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'social_profile_score'}
                    label={t('riasecResult.SOCIAL_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'enterprising_profile_score'}
                    label={t('riasecResult.ENTERPRISING_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'conventional_profile_score'}
                    label={t('riasecResult.CONVENTIONAL_PROFILE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  <h3>{t('riasecResult.PERSONALITY_SCORES_HEADER')}</h3>
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'realistic_personality_score'}
                    label={t('riasecResult.REALISTIC_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'investigative_personality_score'}
                    label={t('riasecResult.INVESTIGATIVE_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'artistic_personality_score'}
                    label={t('riasecResult.ARTISTIC_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'social_personality_score'}
                    label={t('riasecResult.SOCIAL_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'enterprising_personality_score'}
                    label={t('riasecResult.ENTERPRISING_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'conventional_personality_score'}
                    label={t('riasecResult.CONVENTIONAL_PERSONALITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-riasec-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                  onDelete={onDelete}
                  afterDelete={afterDelete}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildRiasecResultPage;
