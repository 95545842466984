import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { ChildAlert } from '../typings/api-types';

const CHILD_ALERT_REST_URL = '/child-alert';

const useGetChildAlerts = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildAlerts', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildAlert[]>(CHILD_ALERT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

export { useGetChildAlerts };
