import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteGuidanceSession,
  patchGuidanceSession,
  postGuidanceSession,
  useGetGuidanceSessionDetail,
} from '../../api/guidance_session_api';
import { Col, Container, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field, FieldInputProps, FieldProps, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { GuidanceSessionPatch, GuidanceSessionPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, {
  createDoesOptionMatchFieldFn,
  GenericOption,
} from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import { useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';
import {
  createGuidanceActivityOptions,
  postGuidanceActivity,
  useGetGuidanceActivities,
} from '../../api/guidance_activity_api';
import CreatableSelect from 'react-select/creatable';
import CustomErrorMessage from '../../shared/formik/CustomErrorMessage/CustomErrorMessage';

type GuidanceSessionProps = {};

const DOMAIN_URL = 'guidance-session';
const DOMAIN = Domain.GUIDANCE_SESSION;
const CHILD_TAB = 'transition';

const GUIDANCE_SESSION_FORM_SCHEMA = Yup.object().shape({
  child: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  activity: Yup.number().required('Required'),

  link: Yup.string()
    .matches(/^http[s]?:\/\/.*/, 'Must be a link starting with http')
    .optional(),
  notes: Yup.string().optional(),
  // Files - Sent in separate request
  document: Yup.mixed().optional(),
});

function getBlankGuidanceSession(): GuidanceSessionPost {
  return {
    child: undefined,
    date: '',

    activity: undefined,

    link: '',
    notes: '',
    // Files - Sent in separate request
    file: undefined,
  };
}

const GuidanceSessionPage = (props: GuidanceSessionProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const { data: activityData, refetch: refetchActivities } = useGetGuidanceActivities();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetGuidanceSessionDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let guidanceSession: GuidanceSessionPost | GuidanceSessionPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    guidanceSession = getBlankGuidanceSession();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      guidanceSession.child = childId;
    }
    onSubmit = async (dataToSubmit: GuidanceSessionPost, { setSubmitting }: any) => {
      try {
        await postGuidanceSession(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    guidanceSession = data as GuidanceSessionPatch;

    onSubmit = async (dataToSubmit: GuidanceSessionPatch, { setSubmitting }: any) => {
      try {
        await patchGuidanceSession(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(guidanceSession);
    navigateAfterCancel();
  }

  const childOptions = createChildOptions(childData);
  const activityOptions = createGuidanceActivityOptions(activityData);

  const onDelete = () => {
    if (id) {
      return deleteGuidanceSession(id);
    } else {
      return Promise.resolve();
    }
  };

  const afterDelete = () => {
    if (id) {
      navigateAfterCancel();
    }
  };

  const guidanceActivityParamName = 'activity';
  const guidanceActivityParamLabel = t('guidanceSession.ACTIVITY');
  const guidanceActivityParamIsReadOnly = mode === Mode.VIEW;
  const guidanceActivityParamLabelWidth = 4;
  const onCreateGuidanceActivity = async (
    inputValue: string,
    formikForm: FormikProps<any>,
    formikField: FieldInputProps<any>,
  ) => {
    const guidanceActivity = {
      name: inputValue,
    };
    const newActivityResponse = await postGuidanceActivity(guidanceActivity);
    await refetchActivities();
    formikForm.setFieldValue(formikField.name, newActivityResponse.data.id);
  };

  return (
    <Formik initialValues={guidanceSession} validationSchema={GUIDANCE_SESSION_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<GuidanceSessionPost | GuidanceSessionPatch>) => {
        // TODO this should really be an enhancement to FormikSelect
        //  but it is a little unwieldy to add it because FormikSelect supports isMulti
        let guidanceActivityCreatableSelect = (
          <div>
            {/* This is a formik component so formik does validation and manages state */}
            <Field name={guidanceActivityParamName}>
              {(fieldProps: FieldProps) => {
                const onCreate = async (inputValue: string) => {
                  return onCreateGuidanceActivity(inputValue, form, field);
                };

                const { field, form } = fieldProps;
                const handleChange = (option: any) => {
                  let value;
                  if (option === undefined || option === null) {
                    // Purposely send null so the backend clears the value (django PATCH ignores undefineds by default)
                    value = null;
                  } else {
                    value = (option as GenericOption).value;
                  }
                  form.setFieldValue(field.name, value);
                };
                const doesOptionMatchField = createDoesOptionMatchFieldFn(field);
                const getValue = () => {
                  return activityOptions.find(doesOptionMatchField);
                };

                let reactSelectComponent;
                if (guidanceActivityParamIsReadOnly) {
                  if (!getValue()) {
                    reactSelectComponent = <div />;
                  } else {
                    reactSelectComponent = <Col className="d-flex align-items-center">{getValue()?.label}</Col>;
                  }
                } else {
                  reactSelectComponent = (
                    <Col>
                      <CreatableSelect
                        placeholder={'Select or start typing to create'}
                        onCreateOption={onCreate}
                        name={field.name}
                        value={getValue()}
                        onChange={handleChange}
                        options={activityOptions}
                        isClearable={true}
                      />
                      {/* Not getting errors messages?
                        Try adding 'fieldName: undefined,' to initialStateOfEditableScheduleFields

                        Still not working and in AG grid? Try setting row height to 50
                      */}
                      <ErrorMessage name={guidanceActivityParamName}>
                        {(errorMessage) => {
                          return <CustomErrorMessage errorMessage={errorMessage} />;
                        }}
                      </ErrorMessage>
                    </Col>
                  );
                }
                return (
                  <FormGroup as={Row}>
                    {guidanceActivityParamLabel && (
                      // This is a react-bootstrap component match label look-and-feel
                      <FormLabel column={true} sm={guidanceActivityParamLabelWidth}>
                        {guidanceActivityParamLabel}
                      </FormLabel>
                    )}
                    {reactSelectComponent}
                  </FormGroup>
                );
              }}
            </Field>
          </div>
        );

        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('guidanceSession.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  {guidanceActivityCreatableSelect}
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'link'}
                    label={t('guidanceSession.LINK')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikSinglePatchFileUpload
                    name={'file'}
                    label={t('guidanceSession.FILE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'notes'}
                    label={t('guidanceSession.NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../guidance-session/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onDelete={onDelete}
                  afterDelete={afterDelete}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default GuidanceSessionPage;
