function isLocalhost(): boolean {
  return (
    window.location.hostname.startsWith('localhost') ||
    window.location.hostname?.startsWith('127.0.0.1') ||
    window.location.hostname?.startsWith('0.0.0.0')
  );
}

function isStaging(): boolean {
  return window.location.hostname?.startsWith('staging');
}

function isProduction(): boolean {
  return window.location.hostname?.startsWith('children');
}

function getOpsManBaseUrl(): string {
  let opsManBaseUrl = 'http://localhost:3000';
  if (isStaging()) {
    opsManBaseUrl = 'https://staging.operations.lovejustice.ngo';
  } else if (isProduction()) {
    opsManBaseUrl = 'https://operations.lovejustice.ngo';
  }
  return opsManBaseUrl;
}

export { isLocalhost, isStaging, isProduction, getOpsManBaseUrl };
