import * as React from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Home } from '../../typings/api-types';
import styles from '../../home/HomeTransition/HomeTransition.module.scss';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useGetHomeTransition } from '../../api/home_api';
import HomeSkillsList from '../HomeSkillsList/HomeSkillsList';
import DueChildLifeSkillIcon from '../../skill/DueChildLifeSkillIcon/DueChildLifeSkillIcon';

type HomeTransitionProps = {
  home: Home;
};
export const HomeTransition = (props: HomeTransitionProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetHomeTransition(props.home.id!);

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return <div>Error loading {props.home.id}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.home.id}</div>;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label column>{t('home.transition.INCOMPLETE_LIFE_SKILLS')}</Form.Label>
            {data && data.home_life_skills_due_count > 0 && (
              <DueChildLifeSkillIcon className={styles.dueTrainingIcon} numberDue={data.home_life_skills_due_count} />
            )}
          </Form.Group>
          <HomeSkillsList
            emptyListLabel={t('home.transition.NO_REQUIRED_SKILLS')}
            skills={data.required_home_life_skills}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label column>{t('home.transition.COMPLETED_LIFE_SKILLS')}</Form.Label>
          </Form.Group>
          <HomeSkillsList
            emptyListLabel={t('home.transition.NO_COMPLETED_SKILLS')}
            skills={data.completed_home_life_skills}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/home-child-training/create/new',
              search: '?homeId=' + props.home.id,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'home'} /> {t('childTraining.ADD_HOME_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeTransition;
export type { HomeTransitionProps };
