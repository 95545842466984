import Select, { components, MultiValue } from 'react-select';
import { Spinner } from 'react-bootstrap';
import { AccountOption, createAccountOptions, useGetAccountsWithChildlightRoles } from '../../../api/account_api';
import { getReactSelectStylesForAgGridCell } from '../../helpers/reactSelectStyleHelpers';
import SelectLimitedChipsContainer from '../SelectLimitedChipsContainer/SelectLimitedChipsContainer';

type AccountsMultiSelectProps = {
  selectedAccountIds: number[];
  onChange: (selectedAccounts: MultiValue<AccountOption>) => void;
};

const MultiValueDifferentChipLabel = (props: any) => {
  return <components.MultiValue {...props}>{props.data.chipLabel}</components.MultiValue>;
};

const AccountsMultiSelect = (props: AccountsMultiSelectProps) => {
  const { data: accountData, isLoading: isAccountsLoading } = useGetAccountsWithChildlightRoles();

  if (isAccountsLoading) {
    return <Spinner />;
  }

  const accountOptions = createAccountOptions(accountData);

  const selectedAccountOptions = accountOptions.filter((accountOption) => {
    return accountOption.value !== undefined && props.selectedAccountIds.indexOf(accountOption.value) > -1;
  });

  return (
    <Select
      value={selectedAccountOptions}
      options={accountOptions}
      isMulti={true}
      styles={getReactSelectStylesForAgGridCell()}
      onChange={props.onChange}
      // Allows to break out of Ag Grids
      menuPortalTarget={document.body}
      components={{ ValueContainer: SelectLimitedChipsContainer, MultiValue: MultiValueDifferentChipLabel }}
    />
  );
};

export default AccountsMultiSelect;
