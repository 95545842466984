import React from 'react';
import { Navbar, Spinner } from 'react-bootstrap';
import './Headerbar.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ChildrenSearchButton from '../ChildrenSearchButton/ChildrenSearchButton';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import Profile from '../Profile/Profile';
import AdminDropdown from '../AdminDropdown/AdminDropdown';
import { Mode } from '../../shared/helpers/modeHelper';
import DataEntryDropdown from '../DataEntryDropdown/DataEntryDropdown';
import { isLocalhost, isProduction, isStaging } from '../../shared/helpers/environmentHelper';
import ReportsDropdown from '../ReportsDropdown/ReportsDropdown';

const Headerbar = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const { isLoadingPermissions, permissionMap } = usePermissions();

  let environmentClass;
  if (isLocalhost()) {
    environmentClass = 'localHeaderbar';
  } else if (isStaging()) {
    environmentClass = 'stagingHeaderbar';
  } else if (isProduction()) {
    environmentClass = 'prodHeaderbar';
  } else {
    environmentClass = 'unknownEnvHeaderbar';
  }
  return (
    <div>
      <Navbar bg="dark" variant="dark" className={'Main-Navbar ' + environmentClass}>
        <Navbar.Brand>
          <Link className="text m-2 App-link" to={'/'}>
            <Navbar.Text className="text m-2">{t('APP_NAME')}</Navbar.Text>
          </Link>
        </Navbar.Brand>

        {isAuthenticated && (
          <Navbar.Collapse className="justify-content-end">
            {isLoadingPermissions && <Spinner variant={'primary'} />}
            {permissionMap && hasPagePermission(Mode.VIEW, getDomainPermission(permissionMap, Domain.CHILD)) && (
              <ChildrenSearchButton />
            )}
            {permissionMap && <DataEntryDropdown permissionMap={permissionMap} />}
            {permissionMap && <ReportsDropdown permissionMap={permissionMap} />}
          </Navbar.Collapse>
        )}
        <Navbar.Collapse className="justify-content-end">
          {permissionMap && <AdminDropdown permissionMap={permissionMap} />}
          {/* Login button inside profile if not logged in */}
          <Profile />
          {/* Comment back in if we need more languages */}
          {/* <LanguageDropdown />*/}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Headerbar;
