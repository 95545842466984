import { DateTime } from 'luxon';
import { FAMILY_HOMES_HQ_TIMEZONE } from '../../api/constants';

// Assumes everything in childlight is in Nepal time for now.
// If you want more complex handling, see this same function in Operations Management
function compareDatesForAgGridFilter(filterLocalDateAtMidnight: Date, cellValue: string) {
  const datetimeStringInUtc = cellValue;
  if (!datetimeStringInUtc) {
    return -1;
  }

  const cellZonedDatetime = DateTime.fromISO(datetimeStringInUtc, { zone: FAMILY_HOMES_HQ_TIMEZONE });
  // Assuming this is timezone replacement with same clock time, not same timezone conversion with same moment
  const inputZonedDatetime = DateTime.fromJSDate(filterLocalDateAtMidnight, { zone: FAMILY_HOMES_HQ_TIMEZONE });

  const cellStartOfDay = cellZonedDatetime.startOf('day');
  const inputStartOfDay = inputZonedDatetime.startOf('day');

  if (cellStartOfDay.toISO() === inputStartOfDay.toISO()) {
    return 0;
  }
  if (cellStartOfDay.toISO() < inputStartOfDay.toISO()) {
    return -1;
  }
  if (cellStartOfDay.toISO() > inputStartOfDay.toISO()) {
    return 1;
  }
}

function isoDateToCommonFormat(isoDateString?: string | null) {
  if (isoDateString) {
    const luxonDateTime = DateTime.fromFormat(isoDateString, 'yyyy-MM-dd');
    return luxonDateTime.toFormat('d MMM yyyy');
  } else {
    // Preserve undefined vs null
    return isoDateString;
  }
}

function isoDateAndTimeToCommonFormat(isoDateString?: string | null) {
  if (isoDateString) {
    const luxonDateTime = DateTime.fromISO(isoDateString);
    return luxonDateTime.toFormat('d MMM yyyy');
  } else {
    // Preserve undefined vs null
    return isoDateString;
  }
}

function getTodayIsoDate() {
  return DateTime.now().toISODate();
}

function isMoreThanXMonthsOld(isoDateString: string, months: number) {
  let monthsOld = howManyMonthsOld(isoDateString);
  return monthsOld > months;
}

function isLessThanXMonthsOld(isoDateString: string, months: number) {
  let monthsOld = howManyMonthsOld(isoDateString);
  return monthsOld < months;
}

function howManyMonthsOld(isoDateString: string) {
  const dateTime = DateTime.fromFormat(isoDateString, 'yyyy-MM-dd');
  if (dateTime.invalidExplanation) {
    throw Error(dateTime.invalidExplanation);
  }
  const monthsDiff = dateTime.diffNow('months');
  return monthsDiff.months * -1;
}

const DEFAULT_DATE_FILTER_PARAMS = {
  defaultOption: 'inRange',
  inRangeInclusive: true,
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue);
  },
};

export {
  DEFAULT_DATE_FILTER_PARAMS,
  isoDateToCommonFormat,
  getTodayIsoDate,
  isoDateAndTimeToCommonFormat,
  isMoreThanXMonthsOld,
  isLessThanXMonthsOld,
  compareDatesForAgGridFilter,
};
