import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';

type HomeGridActionProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: any;
};

const HomeGridActions = (props: HomeGridActionProps) => {
  return (
    <LinkContainer to={'/home/' + props.data.id + '/overview/view'}>
      <Button variant="outline-primary" size="sm">
        <FontAwesomeIcon icon={'eye'} />
      </Button>
    </LinkContainer>
  );
};

export default HomeGridActions;
