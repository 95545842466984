import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ChildForRoster } from '../../typings/api-types';

interface IconDashboardTooltipProps {
  // A human child
  child: ChildForRoster;
  // The React components inside the tag, NOT human children
  children: React.ReactElement;
}

/* DefaultTooltip component (react-bootstrap Tooltip/Popover) caused scrollbars to appear sometimes
    overflow-x: hidden; on body on index.css fixed it,
    but it breaks every page that has horizontal scrollbar.
    Currently I don't have any page-level horizontal scrollbars so that is ok
    https://stackoverflow.com/a/19308332/13815107 */
const IconDashboardTooltip = (props: IconDashboardTooltipProps) => {
  const placement = 'top';
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 250 }}
      overlay={(overlayProps) => (
        // White popover matches our UI more than the black tooltip
        // <Tooltip id={props.id} {...overlayProps}>{replaceNewlinesWithBreaks(props.tooltipText)}</Tooltip>
        <Popover id={props.child.public_id + '-icon-dashboard-tooltip'} {...overlayProps}>
          <Popover.Body
            style={{
              fontSize: 12,
            }}
          >
            <h3>{props.child.name}</h3>
            <div>
              <span>Id: </span>
              <span>{props.child.public_id}</span>
            </div>
            <div>
              <span>Age: </span>
              <span>{props.child.age}</span>
            </div>
          </Popover.Body>
        </Popover>
      )}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default IconDashboardTooltip;
export type { IconDashboardTooltipProps };
