import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import {
  patchHomeSkillTraining,
  postHomeSkillTraining,
  useGetHomeSkillTrainingDetail,
} from '../../api/skill_training_api';
import { getChildrenInHome, useGetChildDetail } from '../../api/child_api';
import { SimpleChildSummary } from '../../typings/api-types';
import { useParams } from 'react-router-dom';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { ChildSkillTrainingPatch, ChildSkillTrainingPost, HomeSkillTrainingPatch } from '../../typings/types-to-send';
import i18n from 'i18next';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS, useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import { GetRowIdParams } from 'ag-grid-community';
import { isAggregate, suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';
import { createSkillOptions, useGetSkills } from '../../api/skill_api';
import { createAccountOptions, useGetAccounts } from '../../api/account_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import styles from './HomeSkillTrainingPage.module.scss';

type HomeSkillTrainingPageProps = {
  project?: string;
};

// use projectPage instead, unless we want to split this into two pages
// const DOMAIN_URL = 'home-skill-training';
const DOMAIN = Domain.HOME_SKILL_TRAINING;

const HOME_SKILL_TRAINING_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  skill_trained: Yup.number().required('Required'),
  trainer: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child_skill_trainings: Yup.array()
    .of(
      Yup.object().shape({
        // Auto-populated and can't be modified
        child: Yup.number().required('Required'),

        child_was_present: Yup.boolean().required('Required'),
      }),
    )
    .required('Required'),
});

function getInitialChildrenAtHomeSkillTrainings(
  children: SimpleChildSummary[],
  project: string,
): ChildSkillTrainingPost[] {
  const childrenAtAppointment = children.map((child) => {
    let childWasPresent = undefined;
    let not_applicable;
    if (project === 'CHILD_TRAININGS') {
      const is_eligible = child.is_eligible_for_child_trainings;
      not_applicable = !child.is_in_home || !is_eligible;
    } else if (project === 'LIFE_SKILLS') {
      const is_eligible = child.is_eligible_for_life_skills;
      const old_enough = child.current_class && child.current_class >= 9;
      not_applicable = !child.is_in_home || !is_eligible || !old_enough;
    } else {
      throw Error('No project page');
    }
    if (not_applicable) {
      // Default ineligible to not present
      childWasPresent = false;
    }

    return {
      child: child.id,

      child_name: child.name,
      child_gender: child.gender,

      child_was_present: childWasPresent,

      // UI Only
      _not_applicable: not_applicable,
    } as ChildSkillTrainingPost;
  });
  return childrenAtAppointment;
}

const getColumns = (mode: Mode): ColDef[] => {
  const t = i18n.t;
  return [
    { headerName: t('common.CHILD'), field: 'child_name', flex: 2 },
    { headerName: t('common.GENDER'), field: 'child_gender', flex: 1 },
    {
      headerName: t('common.PRESENT'),
      field: 'child_was_present',
      flex: 2,
      cellRenderer: (params: any) => {
        if (mode === Mode.VIEW || isAggregate(params)) {
          if (params.value === undefined) {
            return '';
          } else if (typeof params.value == 'boolean') {
            return params.value ? '✅' : '❌';
          } else if (isAggregate(params)) {
            return `${t('common.TOTAL')}: ${params.value}%`;
          } else {
            return params.value;
          }
        } else {
          return (
            <FormikSelect
              name={`child_skill_trainings[${params.rowIndex}].child_was_present`}
              options={BOOLEAN_OPTIONS}
              isReadOnly={false}
              isInAgGrid={true}
            />
          );
        }
      },
    },
  ] as ColDef[];
};

function getBlankHomeSkillTrainingNoChildren(): HomeSkillTrainingPatch {
  return {
    home: undefined,
    date: '',
    // Undefined for no child_skill_trainings loaded yet, empty array for no child_skill_trainings in home
    child_skill_trainings: undefined,
  };
}

export default function HomeSkillTrainingPage(props: HomeSkillTrainingPageProps) {
  let { mode: modeString, id: idString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  const mode = parseMode(modeString);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error } = useGetHomeSkillTrainingDetail(id);

  const columnDefs = getColumns(mode);
  let project = props.project;
  if (id && !project && data) {
    // no project, coming from HomeSkill link probably
    project = data.project;
  }

  const [serverError, setServerError] = useState(false);
  const [isChildrenLoading, setIsChildrenLoading] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: homeData, isLoading: isHomesLoading } = useGetHomes();
  const { data: skillData } = useGetSkills({ project: project }, !!project);
  const { data: accountData } = useGetAccounts();

  let projectPage: string;
  let projectTitle: string;
  let child_tab: string;
  if (project === 'CHILD_TRAININGS') {
    projectPage = 'home-child-training';
    projectTitle = 'Child Trainings';
    child_tab = 'education';
  } else if (project === 'LIFE_SKILLS') {
    projectPage = 'home-life-skill';
    projectTitle = 'Life Skills';
    child_tab = 'transition';
  } else {
    // no project, coming from HomeSkill link probably
    projectPage = 'unknown-home-skill';
    projectTitle = 'Home Skill';
    // TODO this won't work! They are coming from the Home Skill link and this doesn't make sense
    child_tab = 'transition';
  }

  const onBack = useOnPageBack(projectPage, child_tab);
  const navigateToSuccessPage = useNavigateToSuccessPage(projectPage, child_tab);
  const navigateAfterCancel = useNavigateAfterCancel(projectPage, child_tab);

  const { data: childDetailData, isLoading: isChildDetailLoading } = useGetChildDetail(childId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  const homeOptions = createHomeOptions(homeData);
  const trainerOptions = createAccountOptions(accountData);
  const skillOptions = createSkillOptions(skillData);

  let homeSkillTraining: HomeSkillTrainingPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    if (childId && isChildDetailLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (childId && !childDetailData) {
      return <div>Could not find data for child {childId}</div>;
    } else if (!project) {
      return <div>No project, please contact LJI IT</div>;
    }
    homeSkillTraining = getBlankHomeSkillTrainingNoChildren();
    if (childDetailData) {
      const homeId = Number(childDetailData.data.home);
      homeSkillTraining.home = homeId;
    }
    onSubmit = async (data: HomeSkillTrainingPatch, { setSubmitting }: any) => {
      try {
        await postHomeSkillTraining(data);
        // TODO error handling?
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (serverError) {
    return <div> Something went wrong, contact LJI IT </div>;
  } else if (hasNumberId) {
    if (isLoading || isHomesLoading || !project) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    }

    homeSkillTraining = data as HomeSkillTrainingPatch;

    onSubmit = async (data: HomeSkillTrainingPatch, { setSubmitting }: any) => {
      try {
        await patchHomeSkillTraining(data.id, data);
        // TODO error handling?
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(homeSkillTraining);
    navigateAfterCancel();
  }

  return (
    <Formik
      // Without enableReinitialize if you save edits and then edit + cancel + edit,
      // your old edits get wiped out
      enableReinitialize
      initialValues={homeSkillTraining}
      validationSchema={HOME_SKILL_TRAINING_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeSkillTrainingPatch>) => {
        function onHomeChange(newHome: any) {
          if (!isChildrenLoading) {
            setIsChildrenLoading(true);
            formikProps.setFieldValue('child_skill_trainings', undefined);
            getChildrenInHome(newHome.value).then((childData: SimpleChildSummary[]) => {
              formikProps.setFieldValue(
                'child_skill_trainings',
                getInitialChildrenAtHomeSkillTrainings(childData, project!),
              );
              setIsChildrenLoading(false);
            });
          }
        }

        // TODO I would rather load children right after page load
        //  instead of abusing states inside the formik loop
        if (formikProps.values.home && formikProps.values.child_skill_trainings === undefined && !isChildrenLoading) {
          setIsChildrenLoading(true);
          const homeId = Number(formikProps.values.home);
          getChildrenInHome(homeId).then((childData: SimpleChildSummary[]) => {
            formikProps.setFieldValue(
              'child_skill_trainings',
              getInitialChildrenAtHomeSkillTrainings(childData, project!),
            );
            setIsChildrenLoading(false);
          });
        }
        // Without this, when a cell changes the whole row component is deleted and remade
        // That causes inputs to lose focus, because the html input underneath was deleted and recreated
        // It also probably degrades performance
        const getRowId = (params: GetRowIdParams<ChildSkillTrainingPatch>) => {
          if (params.data.id) {
            return '' + params.data.id;
          } else {
            return '' + params.data.child;
          }
        };

        return (
          <FormikForm className={'d-flex flex-grow-1'}>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">
                    {t('skillTraining.DETAIL_TITLE') + ' - ' + projectTitle}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col sm={11}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'skill_trained'}
                    label={t('skillTraining.SKILL_TRAINED')}
                    options={skillOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'trainer'}
                    label={t('skillTraining.TRAINER')}
                    options={trainerOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={1}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../' + projectPage + '/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
              </Row>
              <Row className="flex-grow-1 pb-1">
                {!formikProps.values.home && <div>Please pick a home</div>}
                {formikProps.values.home && (
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <AgGridReact
                      getRowId={getRowId}
                      rowData={formikProps.values.child_skill_trainings}
                      defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                      rowHeight={50}
                      columnDefs={columnDefs}
                      gridOptions={{
                        suppressContextMenu: true,
                      }}
                      getRowClass={(params) => {
                        if (params.data?._not_applicable) {
                          return styles.notApplicable;
                        }
                        return '';
                      }}
                      // groupIncludeFooter={true}
                      // groupIncludeTotalFooter={true}
                      rowSelection={'single'}
                      animateRows={true}
                    />
                  </div>
                )}
              </Row>
              <DefaultPageActionButtons
                mode={mode}
                formikProps={formikProps}
                onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                onBack={onBack}
              />
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
