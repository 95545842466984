import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildImpactResult } from '../typings/api-types';
import { ChildImpactResultPatch, ChildImpactResultPost } from '../typings/types-to-send';

const CHILD_IMPACT_RESULT_REST_URL = '/child-impact-result';

// For the impact SURVEY for children's futures
const useGetChildImpactResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetChildImpactResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildImpactResult[]>(CHILD_IMPACT_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildImpactResultDetail = (id: number | undefined, enabled: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetChildImpactResultDetail', requestConfig, id],
    async () => {
      const response = await axios.get<ChildImpactResult>(CHILD_IMPACT_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    { enabled: enabled },
  );
};

const patchChildImpactResult = (id: number | undefined, data: ChildImpactResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<ChildImpactResultPatch>(CHILD_IMPACT_RESULT_REST_URL + '/' + id + '/', data);
};

const postChildImpactResult = (data: ChildImpactResultPost) => {
  return axios.post<ChildImpactResultPost, AxiosResponse<ChildImpactResult>>(CHILD_IMPACT_RESULT_REST_URL + '/', data);
};

const deleteChildImpactResult = (id: number) => {
  return axios.delete(CHILD_IMPACT_RESULT_REST_URL + '/' + id + '/');
};

export {
  useGetChildImpactResults,
  useGetChildImpactResultDetail,
  patchChildImpactResult,
  postChildImpactResult,
  deleteChildImpactResult,
};
