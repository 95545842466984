import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useGetSkills } from '../../api/skill_api';
import { Skill } from '../../typings/api-types';
import { ColDef } from 'ag-grid-enterprise';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { Mode } from '../../shared/helpers/modeHelper';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { getDefaultColDef } from '../../shared/grid/gridComponentHelpers';
import { LinkContainer } from 'react-router-bootstrap';

type SearchSkillInProjectProps = {
  projectTitle: string;
  projectCode: string;
};

const getColumns = (
  // Should be type TFunction, but TFunction says it can return null, which breaks ColDefs with t() calls in them,
  // but in reality I don't think it ever returns null
  t: (key: string) => string,
): ColDef<Skill>[] => {
  return [
    {
      field: 'name',
      headerName: t('skill.NAME'),
      flex: 1,
    },
    {
      field: 'suggested_minimum_age',
      headerName: t('skill.SUGGESTED_MINIMUM_AGE'),
      width: 220,
    },
    {
      field: 'suggested_maximum_age',
      headerName: t('skill.SUGGESTED_MAXIMUM_AGE'),
      width: 220,
    },
    {
      field: 'retraining_frequency',
      headerName: t('skill.RETRAINING_FREQUENCY'),
      width: 220,
    },
    // {
    //   headerName: t('common.ACTIONS'),
    //   width: 150,
    //   cellRenderer: GridActions,
    //   cellRendererParams: {
    //     pageUrl: '/skill',
    //     onDelete: (id: number) => alert('TODO delete ' + id),
    //   },
    //  // Can't disable column
    //   suppressColumnsToolPanel: true,
    // },
  ];
};

const SearchSkillInProject = (props: SearchSkillInProjectProps) => {
  const useGetSkillsFilteredByProjectCode = () => {
    return useGetSkills({ project: props.projectCode });
  };
  const translatePrefix = 'skill.';
  const createByItselfUrl = '/skill/create/new';
  const permissionDomain = Domain.SKILL;
  const disableCreateByItself = true;
  const searchTitle = props.projectTitle;

  // Initially copied from Generic Search Page, but with changes
  // Use diff tool to see changes
  const gridRef = useRef<AgGridReact>(null);
  const { data, error } = useGetSkillsFilteredByProjectCode();
  const { t } = useTranslation();
  const onFilterTextBoxChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    gridRef.current!.api.setQuickFilter(event.target.value);
  }, []);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = Mode.VIEW;

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, permissionDomain))) {
    return <NoPermissionPage />;
  }

  // Use data
  if (error) return <p>Error!</p>;
  let rowData: Skill[] | undefined = undefined;
  if (data) {
    rowData = data;
  }

  // If you want to wrap this in a useState you need to have a
  // useEffect to update onDelete and a useCallback hook around onDelete
  let columnDefs = getColumns(t);

  // Return component
  return (
    <>
      <Row>
        <Col>
          <h3 className="text-center font-weight-bold">{searchTitle}</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder={t(translatePrefix + 'SEARCH_PLACEHOLDER') || ''}
            onChange={onFilterTextBoxChanged}
          />
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-1">
        <Col className="shadow-sm border d-flex">
          <div
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowId={(params) => params.data.id}
              defaultColDef={getDefaultColDef()}
              animateRows={true}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          {disableCreateByItself && <Alert variant={'warning'}>{t(translatePrefix + 'CANT_ADD_WARNING')}</Alert>}
          {createByItselfUrl && (
            <LinkContainer to={createByItselfUrl}>
              {/* As of 2023-08-10 ALL createByItselfUrl's should be disabled, they are the admin page stuff
                we can enable them as we build them, then eventually deprecate the disableAdd property*/}
              <Button disabled={disableCreateByItself} variant={'primary'}>
                {' '}
                + {t(translatePrefix + 'ADD_BUTTON')}
              </Button>
            </LinkContainer>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SearchSkillInProject;
