import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type BooleanCellProps = ICellRendererParams;
export const BooleanCell = (props: BooleanCellProps) => {
  return (
    <span>
      {props.value === true && <FontAwesomeIcon icon={'check-square'} />}
      {props.value === false && <FontAwesomeIcon icon={['far', 'square']} />}
      {props.value !== true && props.value !== false && <FontAwesomeIcon icon={'circle-question'} />}
    </span>
  );
};

export default BooleanCell;
export type { BooleanCellProps };
