import * as React from 'react';
import { GuidanceActivity } from '../../typings/api-types';
import styles from '../RequiredActivitiesList/RequiredActivitiesList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type RequiredActivitiesListProps = {
  activities: GuidanceActivity[];
};
export const RequiredActivitiesList = (props: RequiredActivitiesListProps) => {
  return (
    <div>
      {props.activities.map((activity) => {
        return (
          <div key={activity.id} className={styles.requiredActivity}>
            <FontAwesomeIcon icon={'xmark'} /> {activity.name}{' '}
          </div>
        );
      })}
    </div>
  );
};

export default RequiredActivitiesList;
export type { RequiredActivitiesListProps };
