import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Country } from '../typings/api-types';

const COUNTRY_REST_URL = '/country';

function createCountryOptions(countryData: undefined | AxiosResponse<Country[]>) {
  if (!countryData || !countryData.data) {
    return [];
  } else {
    return countryData.data.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  }
}

const useGetCountries = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  // TODO investigate this rule
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetCountryList', requestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      return axios.get<Country[]>(COUNTRY_REST_URL, requestConfig);
    },
    {
      enabled,
    },
  );
};

export { useGetCountries, createCountryOptions };
