import React, { useState } from 'react';
import './App.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Auth0Provider } from '@auth0/auth0-react';
import initializei18n from './initializei18n';
import { LicenseManager } from 'ag-grid-enterprise';
import Headerbar from './header/Headerbar/Headerbar';
import { Outlet } from 'react-router-dom';
import CustomQueryClientProvider from './auth/CustomQueryClientProvider/CustomQueryClientProvider';
import FeedbackMessages, { FeedbackMessage } from './shared/components/FeedbackMessages/FeedbackMessages';
import FeedbackMessagesContext from './shared/contexts/FeedbackMessagesContext';

// Add all solid icons to font-awesome (If not performant, can import only ones that are used
// Also the free regular ones, but not all of them are free
library.add(fas, far);

// Not working? Try importing this manager another way
// https://www.ag-grid.com/react-data-grid/licensing/
// process.env is replaced at build time by react based on .env
// NOTE: Someone might be able to see this in the running app, but this keeps it somewhat secret
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY!);

initializei18n().then(() => () => {
  // noop, successfully initialized translations
});

function App() {
  const [feedbackMessages, setFeedbackMessages] = useState<FeedbackMessage[]>([]);

  const addFeedbackMessage = (feedbackMessage: FeedbackMessage) => {
    const newMessages = feedbackMessages.concat(feedbackMessage);
    setFeedbackMessages(newMessages);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE_ID!,
        // All the auth0 scopes that the app needs should be listed here.
        // Scopes are all the permissions that ALL users could want (from auth0) in the application.
        // This returns with the scopes that the CURRENT user has access to.
        // We do NOT use Auth0 for our own app's Role Based Access Control, but we use a similar approach in django
        // We can use the django-admin UI to make groups and add users to them
        // If we did want to use Auth0 RBAC instead of in django, full explanation is below
        // https://auth0.com/docs/configure/apis/scopes/api-scopes#example-an-api-called-by-a-first-party-application
        scope: 'openid profile email phone',
      }}
      cacheLocation="localstorage"
    >
      <CustomQueryClientProvider>
        <FeedbackMessagesContext.Provider value={{ feedbackMessages, addFeedbackMessage }}>
          <div className="App">
            <Headerbar />
            {/*Outlet is where router children will be rendered*/}
            <Outlet />
            <FeedbackMessages
              messages={feedbackMessages}
              onFeedbackMessageClose={(messageToRemove: FeedbackMessage) => {
                // Is there a better way to remove it then 'not removing' all the others?
                const remainingMessages = feedbackMessages.filter((existingMessage) => {
                  return existingMessage.key !== messageToRemove.key;
                });
                setFeedbackMessages(remainingMessages);
              }}
            />
          </div>
        </FeedbackMessagesContext.Provider>
      </CustomQueryClientProvider>
    </Auth0Provider>
  );
}

export default App;
