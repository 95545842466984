import React from 'react';
import { FormikSetFunction, OnChangeValueSingle } from '../formik/FormikSelect/FormikSelect';
import { SimpleChildSummary } from '../../typings/api-types';
import { useLocation } from 'react-router-dom';

const BOOLEAN_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

function isFileObject(file: any) {
  if (!file) {
    console.debug('No file found, skipping');
    return false;
  }
  if (typeof file === 'string') {
    console.debug('Got string in updateFiles, assuming url to file already on server, skipping.');
    return false;
  }
  if (!file.name) {
    console.warn('No file name to updateFiles with');
    return false;
  }

  return true;
}

function jsonWithAttachmentsToFormData(data: any) {
  const formData = new FormData();
  for (const fieldName in data) {
    // @ts-ignore
    if (data[fieldName]) {
      // @ts-ignore
      formData.append(fieldName, data[fieldName]);
    }
  }
  return formData;
}

function yupRequiredIfTruthy(fieldsToLookAt: any[], schema: any) {
  if (fieldsToLookAt.length !== 1) {
    throw Error('yupRequiredIfTruthy should only be used for one field');
  }
  const booleanToCheck = fieldsToLookAt[0];
  if (booleanToCheck) {
    return schema.required('Required');
  } else {
    return schema.nullable().optional();
  }
}

function createOptionsFromMap(optionsMap: any) {
  const keyCodes = Object.keys(optionsMap);
  const options = keyCodes.map((keyCode) => {
    const typedKeyCode = keyCode as keyof typeof optionsMap;
    return (
      <option key={keyCode} value={keyCode}>
        {optionsMap[typedKeyCode]}
      </option>
    );
  });
  return options;
}

function getHomeIdFromChildId(
  childId: number | string | null | undefined,
  childData: SimpleChildSummary[] | undefined,
): number {
  const child = getChildObjectFromChildId(childId, childData);
  if (!child.home_id) {
    throw Error('Child doesnt have home ' + childId);
  } else {
    return child.home_id;
  }
}

function getChildObjectFromChildId(
  childId: number | string | null | undefined,
  childData: SimpleChildSummary[] | undefined,
): SimpleChildSummary {
  if (!childId) {
    throw Error('Please pass in child id');
  }
  if (!childData) {
    throw Error('Please pass in child data');
  }
  const child = childData.find((child: SimpleChildSummary) => {
    return child.id === Number(childId);
  });
  if (!child || !child.id) {
    throw Error('Problem loading child ' + childId);
  } else {
    return child;
  }
}

const setHomeOnChildChange = (
  newChild: OnChangeValueSingle<number>,
  children: SimpleChildSummary[] | undefined,
  formikSetFieldValue: FormikSetFunction,
  formikFieldToSet: string = 'home',
) => {
  let homeId;
  if (newChild === undefined || newChild === null) {
    homeId = null;
  } else {
    homeId = getHomeIdFromChildId(newChild?.value, children);
  }
  formikSetFieldValue(formikFieldToSet, homeId);
};

const setSchoolOnChildChange = (
  newChild: OnChangeValueSingle<number>,
  children: SimpleChildSummary[] | undefined,
  formikSetFieldValue: FormikSetFunction,
) => {
  let school = null;
  let school_name = null;
  let gradingScaleType = null;
  if (newChild && children) {
    const child = children.find((child: SimpleChildSummary) => {
      return child.id === newChild.value;
    });
    if (child) {
      school = child.current_school_id;
      school_name = child.current_school_name;
      gradingScaleType = child.current_school_grading_type;
    }
  }
  formikSetFieldValue('school', school);
  formikSetFieldValue('school_name', school_name);
  formikSetFieldValue('grading_scale_type', gradingScaleType);
};

const useGetChildIdFromSearchParams = () => {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  let childId: number | null = null;
  if (queryParams) {
    const childIdString = queryParams.get('childId');
    if (childIdString) {
      childId = Number(childIdString);
    }
  }
  return childId;
};

export {
  createOptionsFromMap,
  BOOLEAN_OPTIONS,
  isFileObject,
  jsonWithAttachmentsToFormData,
  yupRequiredIfTruthy,
  setHomeOnChildChange,
  setSchoolOnChildChange,
  getHomeIdFromChildId,
  getChildObjectFromChildId,
  useGetChildIdFromSearchParams,
};
