import { Button, Toast } from 'react-bootstrap';
import styles from './FeedbackMessages.module.scss';

type FeedbackMessage = {
  key: string;
  status: string;
  messageBody: JSX.Element;
  onUndo?: () => void;
};

type FeedbackMessagesProps = {
  messages: FeedbackMessage[];
  onFeedbackMessageClose: (feedbackMessage: FeedbackMessage) => void;
};

function getStyleColors(status: string) {
  if (status === 'success') {
    // same colors as bootstrap alert success
    return {
      color: '#155724',
      backgroundColor: '#d4edda',
      borderColor: '#c3e6cb',
    };
  } else {
    // same colors as bootstrap alert danger
    return {
      color: '#721c24',
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
    };
  }
}

function createFeedbackMessageKey(objectType: string, action: string, id?: number) {
  return objectType + '-' + action + '-' + id;
}

const FeedbackMessages = (props: FeedbackMessagesProps) => {
  return (
    <div className={styles.feedbackMessagesWrapper}>
      {props.messages.map((message: FeedbackMessage) => {
        const styleColors = getStyleColors(message.status);
        return (
          <Toast
            id={'feedbackPopup'}
            key={message.key}
            className={styles.feedbackToast}
            style={styleColors}
            onClose={() => props.onFeedbackMessageClose(message)}
            delay={5000}
            autohide
          >
            <Toast.Body>
              <span className="d-flex flex-row">
                {message.messageBody}
                {/* ml-auto -> https://getbootstrap.com/docs/4.0/utilities/flex/ */}
                {message.onUndo && (
                  <Button
                    className="ml-auto p-0"
                    variant="link"
                    id="undoButton"
                    // exclamation point to clear typescript error because it can't actually be undefined here (see && above)
                    onClick={() => {
                      message.onUndo!();
                    }}
                  >
                    UNDO
                  </Button>
                )}
              </span>
            </Toast.Body>
          </Toast>
        );
      })}
    </div>
  );
};

export default FeedbackMessages;
export type { FeedbackMessage };
export { createFeedbackMessageKey };
