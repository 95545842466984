// Looking for a new codenameToFind? Check the auth_permission DB table.
import { isMoreThanXMonthsOld } from './dateHelper';
import { ObjectWithAuditFields } from '../../typings/shared-types';
import { Permission } from '../../typings/api-types';

function hasPermission(usersPermissions: Permission[], codenameToFind: string) {
  const foundPermission = usersPermissions.find((pagePermission) => {
    return pagePermission.codename === codenameToFind;
  });
  // !!: If we find an object, return boolean true
  return !!foundPermission;
}

function getIsDisabledAfter1MonthFunction(shouldOverride: boolean) {
  if (shouldOverride) {
    return (data: ObjectWithAuditFields) => false;
  } else {
    return (data: ObjectWithAuditFields) => {
      if (!data.creation_date_time) {
        return true;
      }
      return isMoreThanXMonthsOld(data.creation_date_time.slice(0, 10), 1);
    };
  }
}

// TODO how should I get the currentUserId?
//   Make a global that is available everywhere?
//   Should I do that with permissions, too?
// function isDisabledIfCreatedByOthers(props: IsDisabledProps) {
//   if (props.hasOverride) {
//     return false;
//   } else {
//     return props.data.creation_user !== props.currentUserId;
//   }
// }

export { hasPermission, getIsDisabledAfter1MonthFunction };
