import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { patchChildWratResult, postChildWratResult, useGetChildWratResultDetail } from '../../api/wrat_result_api';
import { Col, Container, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildWratResultPatch, ChildWratResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  getChildObjectFromChildId,
  getHomeIdFromChildId,
  setHomeOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { CLASS_LEVEL_OPTIONS } from '../../shared/helpers/classLevelHelper';

type ChildWratResultProps = {};

const DOMAIN_URL = 'child-wrat-result';
const DOMAIN = Domain.CHILD_WRAT_RESULT;
const CHILD_TAB = 'education';

const CHILD_WRAT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child: Yup.number().required('Required'),

  class_at_time: Yup.number().nullable(),

  word_reading_raw_score: Yup.number().nullable().optional(),
  word_reading_percentage_score: Yup.number().min(0).max(100).required('Required'),
  word_reading_grade_level: Yup.number().min(0).max(20).required('Required'),

  spelling_raw_score: Yup.number().nullable().optional(),
  spelling_percentage_score: Yup.number().min(0).max(100).required('Required'),
  spelling_grade_level: Yup.number().min(0).max(20).required('Required'),

  math_computation_raw_score: Yup.number().nullable().optional(),
  math_computation_percentage_score: Yup.number().min(0).max(100).required('Required'),
  math_computation_grade_level: Yup.number().min(0).max(20).required('Required'),

  sentence_comprehension_raw_score: Yup.number().nullable().optional(),
  sentence_comprehension_percentage_score: Yup.number().min(0).max(100).required('Required'),
  sentence_comprehension_grade_level: Yup.number().min(0).max(20).required('Required'),

  reading_composite_raw_score: Yup.number().nullable().optional(),
  reading_composite_percentage_score: Yup.number().min(0).max(100).required('Required'),
  //  No reading composite percentage score
});

function getBlankChildCheckIn(): ChildWratResultPost {
  return {
    home: undefined,
    date: '',

    child: undefined,

    class_at_time: null,

    word_reading_raw_score: undefined,
    word_reading_percentage_score: undefined,
    word_reading_grade_level: undefined,

    spelling_raw_score: undefined,
    spelling_percentage_score: undefined,
    spelling_grade_level: undefined,

    math_computation_raw_score: undefined,
    math_computation_percentage_score: undefined,
    math_computation_grade_level: undefined,

    sentence_comprehension_raw_score: undefined,
    sentence_comprehension_percentage_score: undefined,
    sentence_comprehension_grade_level: undefined,

    reading_composite_raw_score: undefined,
    reading_composite_percentage_score: undefined,
    //  No reading composite percentage score
  };
}

const ChildWratResultPage = (props: ChildWratResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildWratResultDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childResult: ChildWratResultPost | ChildWratResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childResult = getBlankChildCheckIn();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const child = getChildObjectFromChildId(childId, childData);
      const homeId = getHomeIdFromChildId(childId, childData);
      childResult.home = homeId;
      childResult.child = childId;
      childResult.class_at_time = child.current_class;
    }
    onSubmit = async (dataToSubmit: ChildWratResultPost, { setSubmitting }: any) => {
      try {
        await postChildWratResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    childResult = data as ChildWratResultPatch;

    onSubmit = async (dataToSubmit: ChildWratResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildWratResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childResult);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
    const child = getChildObjectFromChildId(newChild?.value, childData);
    formikSetFieldValue('class_at_time', child.current_class);
  };

  return (
    <Formik initialValues={childResult} validationSchema={CHILD_WRAT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildWratResultPost | ChildWratResultPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('wratResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name="class_at_time"
                    label={t('wratResult.CLASS_AT_TIME')}
                    options={CLASS_LEVEL_OPTIONS}
                    isReadOnly={mode === 'view'}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  {mode === 'view' && childResult.age_at_time !== undefined && (
                    <FormGroup as={Row}>
                      <FormLabel column sm={4}>
                        {t('toniResult.AGE_AT_TIME')}
                      </FormLabel>
                      <Col>{childResult.age_at_time}</Col>
                    </FormGroup>
                  )}

                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'word_reading_raw_score'}
                    label={t('wratResult.WORD_READING_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'word_reading_percentage_score'}
                    label={t('wratResult.WORD_READING_PERCENTAGE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'word_reading_grade_level'}
                    label={t('wratResult.WORD_READING_GRADE_LEVEL')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />

                  <FormikTextInput
                    formikProps={formikProps}
                    name={'spelling_raw_score'}
                    label={t('wratResult.SPELLING_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'spelling_percentage_score'}
                    label={t('wratResult.SPELLING_PERCENTAGE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'spelling_grade_level'}
                    label={t('wratResult.SPELLING_GRADE_LEVEL')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />

                  <FormikTextInput
                    formikProps={formikProps}
                    name={'math_computation_raw_score'}
                    label={t('wratResult.MATH_COMPUTATION_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'math_computation_percentage_score'}
                    label={t('wratResult.MATH_COMPUTATION_PERCENTAGE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'math_computation_grade_level'}
                    label={t('wratResult.MATH_COMPUTATION_GRADE_LEVEL')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />

                  <FormikTextInput
                    formikProps={formikProps}
                    name={'sentence_comprehension_raw_score'}
                    label={t('wratResult.SENTENCE_COMPREHENSION_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'sentence_comprehension_percentage_score'}
                    label={t('wratResult.SENTENCE_COMPREHENSION_PERCENTAGE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'sentence_comprehension_grade_level'}
                    label={t('wratResult.SENTENCE_COMPREHENSION_GRADE_LEVEL')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />

                  <FormikTextInput
                    formikProps={formikProps}
                    name={'reading_composite_raw_score'}
                    label={t('wratResult.READING_COMPOSITE_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'reading_composite_percentage_score'}
                    label={t('wratResult.READING_COMPOSITE_PERCENTAGE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  {/* No reading composite grade level */}
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-wrat-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildWratResultPage;
