import * as React from 'react';
import { deleteChildPhoto, patchChildPhoto, postChildPhoto, useGetChildPhotos } from '../../api/photo_api';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import PhotoAlbum from 'react-photo-album';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Share from 'yet-another-react-lightbox/plugins/share';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import CustomLightboxButton from '../CustomLightboxButton/CustomLightboxButton';
import { useModifyModal } from '../../shared/modals/ModifyModal/ModifyModal';
import ModifyChildPhotoModal from '../ModifyChildPhotoModal/ModifyChildPhotoModal';
import { ChildPhoto } from '../../typings/api-types';
import { ChildPhotoPatch } from '../../typings/types-to-send';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { Mode } from '../../shared/helpers/modeHelper';
import CommonDeleteModal, { useDeleteModal } from '../../shared/modals/CommonDeleteModal/CommonDeleteModal';
import { ObjectWithId } from '../../typings/shared-types';

type ChildPhotoPageProps = {};

const DOMAIN = Domain.CHILD_PHOTO;

export const ChildPhotoPage = (props: ChildPhotoPageProps) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const childId = Number(id!);

  const { data, isLoading, error, refetch } = useGetChildPhotos(childId);
  const [indexOfOpenPhoto, setIndexOfOpenPhoto] = React.useState(-1);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { modifyModalState, openModifyModal, closeModifyModal } = useModifyModal<ChildPhoto>();
  const { closeDeleteModal, objectOpenToDelete, openDeleteModal } = useDeleteModal();

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return (
      <div>
        {t('common.ERROR_LOADING')} {childId}
      </div>
    );
  } else if (!data) {
    return (
      <div>
        {t('common.NO_DATA')} {childId}
      </div>
    );
  }

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(Mode.EDIT, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  const photosForAlbum = data.map((childPhoto) => {
    return {
      src: childPhoto.photo,
      width: childPhoto.width,
      height: childPhoto.height,
      share: childPhoto.photo,
      description: isoDateToCommonFormat(childPhoto.date),
      domainObject: childPhoto,
    };
  });
  const photosWithDimensions = photosForAlbum.filter((childPhoto) => childPhoto.width && childPhoto.height);
  const photosWithoutDimensions = photosForAlbum.filter((childPhoto) => !childPhoto.width || !childPhoto.height);
  if (photosWithoutDimensions) {
    console.error('photos without dimensions', photosWithoutDimensions);
  }

  const getPhotoFromIndex = (index: number, src: string | undefined) => {
    if (index < 0 || !src) {
      throw Error('Something went wrong with deleting child photo');
    }
    const foundPhoto = photosWithDimensions[index];
    if (foundPhoto.src !== src) {
      throw Error("Sources don't match for editing child photo");
    }
    return foundPhoto;
  };

  const onCreateClick = () => {
    // Lightbox shouldn't be open
    openModifyModal(null);
  };
  const onEditClick = (index: number, src: string | undefined) => {
    const foundPhoto = getPhotoFromIndex(index, src);
    closeLightbox();
    openModifyModal(foundPhoto.domainObject);
  };
  const onDeleteClick = (index: number, src: string | undefined) => {
    const foundPhoto = getPhotoFromIndex(index, src);
    closeLightbox();
    openDeleteModal(foundPhoto.domainObject);
  };

  const closeLightbox = () => {
    return setIndexOfOpenPhoto(-1);
  };

  const onDeleteOk = (childPhoto: ObjectWithId) => {
    return deleteChildPhoto(childPhoto.id!).then(() => {
      closeDeleteModal();
      // addFeedbackMessage({
      //   key: createFeedbackMessageKey('triggerRule', 'delete', idToDelete),
      //   status: 'success',
      //   messageBody: <span>Trigger rule deleted successfully.</span>,
      // });
      refetch();
    });
  };

  const onOk = (objectToModify: ChildPhotoPatch, idToModify: number | undefined) => {
    if (idToModify) {
      return patchChildPhoto(idToModify, objectToModify).then(() => {
        closeModifyModal();
        return refetch();
      });
    } else {
      return postChildPhoto(objectToModify).then(() => {
        closeModifyModal();
        return refetch();
      });
    }
  };

  // Modified version of this example
  // https://yet-another-react-lightbox.com/examples/gallery
  return (
    <Container>
      <h1 className="text-center font-weight-bold">{t('child.photo.PAGE_TITLE')}</h1>
      <Row>
        <Col>
          <LinkContainer to={'/child/' + childId + '/overview/view'}>
            <Button variant="secondary">{t('common.BACK')}</Button>
          </LinkContainer>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button onClick={onCreateClick}>+ {t('child.photo.ADD_BUTTON')}</Button>
        </Col>
      </Row>
      <br />
      <PhotoAlbum
        layout="rows"
        photos={photosWithDimensions}
        onClick={({ index: current }) => setIndexOfOpenPhoto(current)}
      />
      <Lightbox
        toolbar={{
          buttons: [
            <CustomLightboxButton onClick={onEditClick} faIcon={'pencil'} />,
            <CustomLightboxButton onClick={onDeleteClick} faIcon={'trash-can'} buttonStyle={{ color: '#ee2346' }} />,
            'close',
          ],
        }}
        index={indexOfOpenPhoto}
        slides={photosWithDimensions}
        open={indexOfOpenPhoto >= 0}
        close={closeLightbox}
        plugins={[Download, Fullscreen, Captions, Share]}
        captions={{ showToggle: true, descriptionTextAlign: 'center' }}
      />
      <CommonDeleteModal
        objectOpenToDelete={objectOpenToDelete}
        onCancel={() => closeDeleteModal()}
        onDelete={onDeleteOk}
      />
      <ModifyChildPhotoModal state={modifyModalState} onOk={onOk} onCancel={closeModifyModal} childId={childId} />
    </Container>
  );
};

export default ChildPhotoPage;
export type { ChildPhotoPageProps };
