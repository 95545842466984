import React from 'react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { deleteHomeBmiCheck, useGetHomeBmiCheck } from '../../api/bmi_check_api';
import { Domain } from '../../api/permissions_api';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { HomeBmiCheckSummary } from '../../typings/api-types';
import GridActions from '../../shared/grid/GridActions/GridActions';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type HomeBmiGridProps = {};

function getColumnDefs(onDelete: (objectToDelete: HomeBmiCheckSummary) => Promise<any>): ColDef<HomeBmiCheckSummary>[] {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    { headerName: t('common.HOME_NAME'), field: 'home_name', flex: 2 },
    {
      headerName: t('common.DATE'),
      valueFormatter: (params) => {
        return isoDateToCommonFormat(params.value);
      },
      field: 'date',
      flex: 2,
    },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/home-bmi-check',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

export default function SearchHomeBmiCheckPage(props: HomeBmiGridProps) {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'bmi-check'}
      getColumns={getColumnDefs}
      permissionDomain={Domain.HOME_BMI}
      translatePrefix={'bmiCheck.'}
      useGetListApiHook={useGetHomeBmiCheck}
      deleteApiFunc={deleteHomeBmiCheck}
      createForHomePageUrl={'/home-bmi-check/create/new'}
    />
  );
}
