import { ListGroup, ListGroupItem } from 'react-bootstrap';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import React from 'react';
import { ChildSkill } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';

type ChildSkillsListProps = {
  skills?: ChildSkill[];
  emptyListLabel: string;
};

const ChildSkillsList = (props: ChildSkillsListProps) => {
  const { t } = useTranslation();
  return (
    <ListGroup>
      {props.skills &&
        props.skills.length > 0 &&
        props.skills.map((skill, index) => (
          <ListGroupItem key={index + '-child-training'} className={skill.is_due ? 'text-danger' : undefined}>
            {skill.skill.name}{' '}
            {skill.skill.retraining_frequency && (
              <DefaultTooltip
                id={index + '-retraining-tooltip'}
                tooltipText={t('child.education.IS_RETRAINED_TOOLTIP') + ' ' + skill.skill.retraining_frequency}
              >
                <FontAwesomeIcon icon={'arrows-rotate'} />
              </DefaultTooltip>
            )}
            {skill.next_due_date && (
              <span style={{ fontSize: 12, fontStyle: 'italic' }}>
                {' '}
                (due {isoDateToCommonFormat(skill.next_due_date)})
              </span>
            )}
            {skill.last_date_completed && (
              <span style={{ fontSize: 12, fontStyle: 'italic' }}>
                {' '}
                (completed {isoDateToCommonFormat(skill.last_date_completed)})
              </span>
            )}
          </ListGroupItem>
        ))}
      {(!props.skills || props.skills.length === 0) && <p style={{ fontStyle: 'italic' }}>{props.emptyListLabel}</p>}
    </ListGroup>
  );
};

export default ChildSkillsList;
