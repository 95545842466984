import * as React from 'react';
import { useGetChildrenForRoster } from '../../api/child_api';
import { Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SITUATION_STATUS_OPTIONS } from '../../child/ChildOverview/ChildOverview';
import IconDashboardTooltip from '../IconDashboardTooltip/IconDashboardTooltip';
import { Link } from 'react-router-dom';

type IconDashboardPageProps = {};
export const IconDashboardPage = (props: IconDashboardPageProps) => {
  const { data } = useGetChildrenForRoster();

  let situationStatusGroupsBeingCreated: {
    homes: { name: string; children: any[] }[];
    situation_status: string;
    label: string;
  }[];
  if (data) {
    situationStatusGroupsBeingCreated = data.reduce((situationStatusGroupsBeingCreated, child) => {
      let situationStatusGroup = situationStatusGroupsBeingCreated.find(
        (group) => group.situation_status === child.situation_status,
      );
      if (!situationStatusGroup) {
        const foundOption = SITUATION_STATUS_OPTIONS.find((option) => option.value === child.situation_status);
        let label = child.situation_status as string;
        if (foundOption) {
          label = foundOption.label;
        }
        let newSituationStatusGroup = {
          situation_status: child.situation_status,
          homes: [] as { name: string; children: any[] }[],
          label: label,
        };
        if (situationStatusGroupsBeingCreated.length > 0) {
          const existingHomes = situationStatusGroupsBeingCreated[0].homes.map((home) => {
            return {
              name: home.name,
              children: [],
            };
          });
          newSituationStatusGroup.homes = existingHomes;
        }
        situationStatusGroupsBeingCreated.push(newSituationStatusGroup);
        situationStatusGroup = newSituationStatusGroup;
      }

      // Drill into situation status group
      const homeGroups = situationStatusGroup.homes;
      let homeGroup = homeGroups.find((home) => home.name === child.home_name);
      if (!homeGroup) {
        // Add this home to ALL situation status groups!!
        // Why? So the order of columns is preserved when we loop later
        situationStatusGroupsBeingCreated.forEach((situationStatusGroup) => {
          let newHomeGroup = { name: child.home_name, children: [] };
          situationStatusGroup.homes.push(newHomeGroup);
        });
        homeGroup = homeGroups.find((home) => home.name === child.home_name);
        if (!homeGroup) {
          throw new Error('Programming bug, should have found this home group');
        }
      }
      homeGroup.children.push(child);
      return situationStatusGroupsBeingCreated;
    }, [] as { homes: { name: string; children: any[] }[]; situation_status: string; label: string }[]);
  } else {
    situationStatusGroupsBeingCreated = [];
  }

  if (!situationStatusGroupsBeingCreated || situationStatusGroupsBeingCreated.length === 0) {
    return <Spinner />;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th />
          {situationStatusGroupsBeingCreated[0].homes.map((home) => {
            return <th>{home.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {situationStatusGroupsBeingCreated.map((statusGroup) => {
          return (
            <tr>
              <td>{statusGroup.label}</td>
              {statusGroup.homes.map((home) => {
                return (
                  <td>
                    {home.children.map((child) => {
                      return (
                        <IconDashboardTooltip child={child}>
                          <Link to={'/child/' + child.id + '/overview/view'}>
                            <FontAwesomeIcon style={{ color: 'grey' }} icon={'user'} />
                          </Link>
                        </IconDashboardTooltip>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default IconDashboardPage;
export type { IconDashboardPageProps };
