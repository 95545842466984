import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildToniResult } from '../typings/api-types';
import { ChildToniResultPatch, ChildToniResultPost, HomeToniResultPost } from '../typings/types-to-send';

const TONI_RESULT_REST_URL = '/child-toni-result';

const useGetChildToniResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildToniResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildToniResult[]>(TONI_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildToniResultDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckins', requestConfig, id],
    async () => {
      const response = await axios.get<ChildToniResult>(TONI_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchChildToniResult = (id: number | undefined, data: ChildToniResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }

  return axios.patch<ChildToniResultPatch, AxiosResponse<ChildToniResult>>(TONI_RESULT_REST_URL + '/' + id + '/', data);
};

const postChildToniResult = (data: ChildToniResultPost) => {
  return axios.post<ChildToniResultPost, AxiosResponse<ChildToniResult>>(TONI_RESULT_REST_URL + '/', data);
};

// THERE IS NO HOME ENDPOINT!
// Lazy way, just loop through every child and send to the backend
// TODO do this more efficiently with less calls
const postHomeToniResult = (dataWithFiles: HomeToniResultPost) => {
  const children = dataWithFiles.child_results;
  if (children) {
    const promises = children
      .filter((child) => child.child_was_present)
      .map((childResult) => {
        const childCheckIn: ChildToniResultPost = {
          date: dataWithFiles.date,
          home: dataWithFiles.home,
          child: childResult.child,
          class_at_time: childResult.class_at_time,

          raw_score: childResult.raw_score,
          index_score: childResult.index_score,
          percentage_score: childResult.percentage_score,
          description: childResult.description,
        };
        return postChildToniResult(childCheckIn);
      });
    return Promise.all(promises).then(() => console.log('All toni results in promises finished'));
  } else {
    return Promise.resolve();
  }
};

const deleteChildToniResult = (id: number) => {
  return axios.delete(TONI_RESULT_REST_URL + '/' + id + '/');
};

export {
  useGetChildToniResults,
  useGetChildToniResultDetail,
  patchChildToniResult,
  postChildToniResult,
  postHomeToniResult,
  deleteChildToniResult,
};
