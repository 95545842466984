type GridPreferences = {
  key: string;
  columnState: any;
};

const GRID_PREFERENCE_SUFFIX = '-grid-preferences';
// const GRID_PREFERENCE_REST_URL = '/grid-preference';

// const useGetGridPreferencesForUser = (key?: string) => {
//   const requestConfig = {
//     params: {},
//   } as AxiosRequestConfig;
//
//   // TODO investigate this rule
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   return useQuery(
//     ['useGetGridPreferenceForUser', requestConfig],
//     async () => {
//       // If the grid isn't ready, this won't work,
//       //  but ag grid defaults to showing loading overlay initially anyway
//       // if (gridApi) {
//       //   gridApi.showLoadingOverlay();
//       // }
//       const response = await axios.get<GridPreference>(GRID_PREFERENCE_REST_URL + '/' + key, requestConfig);
//       return response.data;
//     },
//     {
//       enabled: !!key,
//     },
//   );
// };

// const debouncedSaveGridPreferencesForUser = (key?: string, gridPreferences: GridPreferences)
// TODO but use useDebounce as see in https://stackoverflow.com/a/76031705/13815107

function getGridPreferencesFromLocalStorage(key: string) {
  const stringPreferences = localStorage.getItem(key + GRID_PREFERENCE_SUFFIX);
  if (!stringPreferences) {
    return null;
  } else {
    // console.log('Loading grid preferences', key + GRID_PREFERENCE_SUFFIX, JSON.parse(stringPreferences));
    return JSON.parse(stringPreferences) as GridPreferences;
  }
}

function saveGridPreferencesToLocalStorage(key: string, gridPreferences: GridPreferences) {
  // console.log('Saving grid preferences', key + GRID_PREFERENCE_SUFFIX, JSON.stringify(gridPreferences));
  localStorage.setItem(key + GRID_PREFERENCE_SUFFIX, JSON.stringify(gridPreferences));
}

export { getGridPreferencesFromLocalStorage, saveGridPreferencesToLocalStorage };
