import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Home, HomeOverviewEducation, HomeOverviewTransition, School } from '../typings/api-types';
import { HomePatch, HomePost } from '../typings/types-to-send';
import { GenericOption } from '../shared/formik/FormikSelect/FormikSelect';

const HOME_REST_URL = '/home';
const HOME_EDUCATION_REST_URL = '/home-overview-education';
const HOME_TRANSITION_REST_URL = 'home-overview-transition';

function createHomeOptions(homeData: undefined | Home[]): GenericOption<number>[] {
  if (!homeData) {
    return [];
  } else {
    return homeData.map((home) => {
      if (!home.id) {
        throw Error('Home with no id found');
      }
      return {
        label: home.name,
        value: home.id,
      };
    });
  }
}

const useGetHomes = (enabled?: boolean) => {
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  // TODO investigate this rule
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetHomeList', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<Home[]>(HOME_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetHomeDetail = (id: number | undefined, enabled?: boolean) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetHomeDetail', requestConfig, id],
    async () => {
      const response = await axios.get<Home>(HOME_REST_URL + '/' + id + '/', requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const postHome = (school: HomePost) => {
  return axios.post<HomePost, AxiosResponse<School>>(HOME_REST_URL + '/', school);
};

const patchHome = (id: number | undefined, data: HomePatch) => {
  return axios.patch<HomePatch, AxiosResponse<School>>(HOME_REST_URL + '/' + id + '/', data);
};

const useGetHomeEducation = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetHomeEducation', requestConfig, id], async () => {
    const response = await axios.get<HomeOverviewEducation>(HOME_EDUCATION_REST_URL + '/' + id + '/', requestConfig);
    return response.data;
  });
};

const useGetHomeTransition = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetHomeTransition', requestConfig, id], async () => {
    const response = await axios.get<HomeOverviewTransition>(HOME_TRANSITION_REST_URL + '/' + id + '/', requestConfig);
    return response.data;
  });
};

// No delete for now

export {
  useGetHomes,
  createHomeOptions,
  useGetHomeDetail,
  postHome,
  patchHome,
  useGetHomeEducation,
  useGetHomeTransition,
};
