import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createIcon, IconButton, useLightboxState } from 'yet-another-react-lightbox';
import { useTranslation } from 'react-i18next';
import styles from './CustomLightboxButton.module.scss';
import { IconName } from '@fortawesome/fontawesome-svg-core';

type CustomLightboxButtonProps = {
  onClick: (index: number, src: string | undefined) => void;
  faIcon: IconName;
  buttonStyle?: any;
};

function CustomLightboxButton(props: CustomLightboxButtonProps) {
  const { currentSlide, currentIndex } = useLightboxState();
  const faIcon = createIcon(props.faIcon, <FontAwesomeIcon icon={props.faIcon} />);
  const { t } = useTranslation();
  return (
    <IconButton
      className={styles.resizeFontAwesomeIcon}
      style={props.buttonStyle}
      label={t('child.photo.editButton')}
      icon={faIcon}
      onClick={() => {
        props.onClick(currentIndex, currentSlide?.src);
      }}
    />
  );
}

export default CustomLightboxButton;
