import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { Vaccine } from '../typings/api-types';

const VACCINE_REST_URL = '/vaccine';

const useGetVaccines = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getVaccines', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<Vaccine[]>(VACCINE_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

function createVaccineOptions(vaccineData: undefined | Vaccine[]) {
  if (!vaccineData) {
    return [];
  } else {
    return vaccineData.map((vaccine) => {
      return {
        label: vaccine.name,
        value: vaccine.id,
      };
    });
  }
}

export { useGetVaccines, createVaccineOptions };
