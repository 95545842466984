import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteChildImpactResult,
  patchChildImpactResult,
  postChildImpactResult,
  useGetChildImpactResultDetail,
} from '../../api/impact_result_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildImpactResultPatch, ChildImpactResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import { useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type ChildImpactResultProps = {};

const DOMAIN_URL = 'child-impact-result';
const DOMAIN = Domain.CHILD_IMPACT_RESULT;
const CHILD_TAB = 'transition';

const CHILD_IMPACT_RESULT_FORM_SCHEMA = Yup.object().shape({
  date: Yup.date().required('Required'),
  child: Yup.number().required('Required'),

  ambition_score: Yup.number().min(0).max(3).required('Required'),
  helping_score: Yup.number().min(0).max(3).required('Required'),
  independence_score: Yup.number().min(0).max(3).required('Required'),
  diligence_score: Yup.number().min(0).max(3).required('Required'),
  security_score: Yup.number().min(0).max(3).required('Required'),
  excitement_score: Yup.number().min(0).max(3).required('Required'),
  leadership_score: Yup.number().min(0).max(3).required('Required'),
  justice_score: Yup.number().min(0).max(3).required('Required'),
  children_score: Yup.number().min(0).max(3).required('Required'),
  ministry_score: Yup.number().min(0).max(3).required('Required'),
});

function getBlankChildResult(): ChildImpactResultPost {
  return {
    date: '',
    child: undefined,

    ambition_score: undefined,
    helping_score: undefined,
    independence_score: undefined,
    diligence_score: undefined,
    security_score: undefined,
    excitement_score: undefined,
    leadership_score: undefined,
    justice_score: undefined,
    children_score: undefined,
    ministry_score: undefined,
  };
}

const ChildImpactResultPage = (props: ChildImpactResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildImpactResultDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childResult: ChildImpactResultPost | ChildImpactResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childResult = getBlankChildResult();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      childResult.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildImpactResultPost, { setSubmitting }: any) => {
      try {
        await postChildImpactResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    childResult = data as ChildImpactResultPatch;

    onSubmit = async (dataToSubmit: ChildImpactResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildImpactResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childResult);
    navigateAfterCancel();
  }
  const onDelete = () => {
    if (id) {
      return deleteChildImpactResult(id);
    } else {
      return Promise.resolve();
    }
  };

  const afterDelete = () => {
    if (id) {
      navigateAfterCancel();
    }
  };

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  return (
    <Formik initialValues={childResult} validationSchema={CHILD_IMPACT_RESULT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildImpactResultPost | ChildImpactResultPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('impactResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'ambition_score'}
                    label={t('impactResult.AMBITION_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'helping_score'}
                    label={t('impactResult.HELPING_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'independence_score'}
                    label={t('impactResult.INDEPENDENCE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'diligence_score'}
                    label={t('impactResult.DILIGENCE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'security_score'}
                    label={t('impactResult.SECURITY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'excitement_score'}
                    label={t('impactResult.EXCITEMENT_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'leadership_score'}
                    label={t('impactResult.LEADERSHIP_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'justice_score'}
                    label={t('impactResult.JUSTICE_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'children_score'}
                    label={t('impactResult.CHILDREN_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'ministry_score'}
                    label={t('impactResult.MINISTRY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-impact-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                  onDelete={onDelete}
                  afterDelete={afterDelete}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildImpactResultPage;
