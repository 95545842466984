import * as React from 'react';
import { ChildWratResult } from '../../typings/api-types';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';

type ChildWrat5ScoreCellProps =
//ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildWratResult;
  };
export const ChildWrat5ScoreCell = (props: ChildWrat5ScoreCellProps) => {
  return (
    <span className={props.data.has_risk ? 'text-danger' : ''}>
      <span className={'pe-2'}>
        RC: {roundTo2Decimals(props.data.reading_composite_percentage_score)} / SC:{' '}
        {roundTo2Decimals(props.data.sentence_comprehension_percentage_score)} / MC:{' '}
        {roundTo2Decimals(props.data.math_computation_percentage_score)} / S%:{' '}
        {roundTo2Decimals(props.data.spelling_percentage_score)} / WR%:{' '}
        {roundTo2Decimals(props.data.word_reading_percentage_score)}
      </span>
    </span>
  );
};

export default ChildWrat5ScoreCell;
export type { ChildWrat5ScoreCellProps };
