import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildBmiCheck, HomeBmiCheckDetail, HomeBmiCheckSummary } from '../typings/api-types';
import { HomeBmiCheckPatch, HomeBmiCheckPost } from '../typings/types-to-send';

const HOME_BMI_CHECK_REST_URL = '/home-bmi-check';
const CHILD_BMI_CHECK_URL = '/child-bmi-check';

const useGetHomeBmiCheck = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetHomeBmiCheck', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<HomeBmiCheckSummary[]>(HOME_BMI_CHECK_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildBmiCheck = (params?: any, enabled?: boolean, onSuccess?: any) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetChildBmiCheck', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildBmiCheck[]>(CHILD_BMI_CHECK_URL, requestConfig);
      return response.data;
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const useGetHomeBmiCheckDetail = (id: number | undefined, enabled?: boolean, onSuccess?: any) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetHomeBmiCheckDetail', requestConfig, id],
    async () => {
      const response = await axios.get<HomeBmiCheckDetail>(HOME_BMI_CHECK_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const getLatestHomeBmiCheckDetail = (homeId: string) => {
  const requestConfig = {
    params: {
      home: homeId,
    },
  } as AxiosRequestConfig;
  return axios.get<HomeBmiCheckDetail>(HOME_BMI_CHECK_REST_URL + '/latest', requestConfig).then(
    (response) => response.data,
    () => {
      return null;
    },
  );
};

const patchHomeBmiCheck = (id: number | undefined, data: HomeBmiCheckPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<HomeBmiCheckPatch, AxiosResponse<HomeBmiCheckSummary>>(
    HOME_BMI_CHECK_REST_URL + '/' + id + '/',
    data,
  );
};

const postHomeBmiCheck = (data: HomeBmiCheckPost) => {
  return axios.post<HomeBmiCheckPost, AxiosResponse<HomeBmiCheckSummary>>(HOME_BMI_CHECK_REST_URL + '/', data);
};

const deleteHomeBmiCheck = (id: number) => {
  return axios.delete(HOME_BMI_CHECK_REST_URL + '/' + id + '/');
};

export {
  useGetHomeBmiCheck,
  useGetChildBmiCheck,
  deleteHomeBmiCheck,
  useGetHomeBmiCheckDetail,
  getLatestHomeBmiCheckDetail,
  patchHomeBmiCheck,
  postHomeBmiCheck,
};
