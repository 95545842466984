// Based on Profile in guide https://auth0.com/docs/quickstart/spa/react/01-login
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../LoginButton/LoginButton';
import styles from './Profile.module.scss';
import { Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner />;
  }

  if (isAuthenticated && user) {
    const imageElement = <img className={styles.profileImage} src={user.picture} alt={user.name} />;

    return (
      // align="end"
      <Dropdown align={'end'}>
        <Dropdown.Toggle variant='secondary' className={styles.profileDropdown}>
          {imageElement}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.ItemText>{user.name}</Dropdown.ItemText>
          <Dropdown.ItemText>{user.email}</Dropdown.ItemText>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => axios.get('/auth0/send-me-password-reset-email')}>
            {t('PASSWORD_RESET')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            {t('LOG_OUT')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return <LoginButton />;
  }
};

export default Profile;
