import React from 'react';
import { ChildGradeReport } from '../../typings/api-types';
import { deleteChildGradeReport, useGetChildGradeReports } from '../../api/grade_report_api';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import ReadOnlyChildGradeCell from '../ReadOnlyChildGradeCell/ReadOnlyChildGradeCell';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type GradeReportSearchProps = {};

// Cell height doubled (2 cells) + Css grid gap property
const ROW_HEIGHT = 27 + 27 + 5;

const getColumns = (onDelete: (objectToDelete: ChildGradeReport) => Promise<any>): ColDef<ChildGradeReport>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      flex: 1,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 90,
    },
    {
      field: 'school_name',
      headerName: t('gradeReport.SCHOOL_NAME'),
      width: 200,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 90,
    },
    {
      headerName: t('gradeReport.CHILD_GRADE'),
      cellRenderer: ReadOnlyChildGradeCell,
      width: 250,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-grade-report',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchGradeReportPage = (props: GradeReportSearchProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'grade-report'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_GRADE_REPORT}
      translatePrefix={'gradeReport.'}
      useGetListApiHook={useGetChildGradeReports}
      deleteApiFunc={deleteChildGradeReport}
      createForChildPageUrl={'/child-grade-report/create/new'}
      createForHomePageUrl={'/home-grade-report/create/new'}
      rowHeight={ROW_HEIGHT}
    />
  );
};

export default SearchGradeReportPage;
