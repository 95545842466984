import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { GenericOption } from '../../shared/formik/FormikSelect/FormikSelect';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-enterprise';

type ChildRosterFilterRowProps = {
  filterHomeIds: number[] | null;
  setFilterHomeIds: (ids: number[] | null) => void;
  grid: AgGridReact | null;
  isInProgramValue: boolean | null | undefined;
  onInProgramChanged: (newOption: GenericOption<boolean> | null, gridApi: GridApi | undefined) => void;
};
export const ChildRosterFilterRow = (props: ChildRosterFilterRowProps) => {
  const { t } = useTranslation();
  const { data: homeData } = useGetHomes();

  const foundOption = BOOLEAN_OPTIONS.find((option) => option.value === props.isInProgramValue);

  const homeOptions = createHomeOptions(homeData);

  let defaultHomeOptions = null;
  if (props.filterHomeIds && homeData) {
    defaultHomeOptions = homeOptions.filter((option) => props.filterHomeIds!.includes(option.value));
  }

  const gridApi = props.grid?.api;

  function onHomeChange(newHomeOptions: MultiValue<GenericOption<number>> | null) {
    if (!gridApi) {
      return;
    }

    if (newHomeOptions === null) {
      gridApi.destroyFilter('home_name');
      props.setFilterHomeIds(null);
    } else {
      gridApi.getFilterInstance('home_name', (filterInstance) => {
        if (filterInstance === null) {
          throw Error('No filter instance for home_name');
        }

        const newHomeNames = newHomeOptions.map((option) => option.label);
        const newHomeIds = newHomeOptions.map((option) => option.value);
        let filterNames = null;
        if (newHomeNames.length > 0) {
          filterNames = newHomeNames;
        }

        filterInstance.setModel({
          filterType: 'set',
          values: filterNames,
        });

        // Tell grid to run filter operation again
        gridApi!.onFilterChanged();
        props.setFilterHomeIds(newHomeIds);
      });
    }
  }

  function onInProgramChanged(newOption: GenericOption<boolean> | null) {
    props.onInProgramChanged(newOption, gridApi);
  }

  const onFilterTextBoxChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      gridApi!.setQuickFilter(event.target.value);
    },
    [gridApi],
  );

  const hasNoDefault = props.filterHomeIds === null;
  const defaultFinishedLoading = defaultHomeOptions !== null;

  return (
    <Row className="justify-content-md-center">
      <Col md={4}>
        {(hasNoDefault || defaultFinishedLoading) && (
          <Select
            defaultValue={defaultHomeOptions}
            // value={getValue()}
            onChange={onHomeChange}
            options={homeOptions}
            isMulti={true}
            isClearable={true}
            placeholder={t('common.SELECT_A_HOME')}
          />
        )}
      </Col>
      <Col md={4}>
        <Form.Control
          type="text"
          placeholder={t('child.search.SEARCH_PLACEHOLDER') || ''}
          onChange={onFilterTextBoxChanged}
        />
      </Col>
      <Col md={4}>
        <Row>
          <Col className={'text-end'}>
            <FormLabel>{t('child.search.IS_IN_PROGRAM_FILTER') || ''}</FormLabel>
          </Col>
          <Col>
            <Select value={foundOption} onChange={onInProgramChanged} options={BOOLEAN_OPTIONS} isClearable={true} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ChildRosterFilterRow;
export type { ChildRosterFilterRowProps };
