import { ListGroup, ListGroupItem } from 'react-bootstrap';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import React from 'react';
import { ChildSkill, HomeSkill } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type HomeSkillsListProps = {
  skills?: HomeSkill[];
  emptyListLabel: string;
};

function get_missed_names(childSkillsNeeded?: ChildSkill[]) {
  if(!childSkillsNeeded){
    return '';
  }
  const childSkillStrings = childSkillsNeeded.map((childSkill) => {
    return childSkill.child_name + ': ' + isoDateToCommonFormat(childSkill.next_due_date)
  })
  return childSkillStrings.join('\n');
}

const HomeSkillsList = (props: HomeSkillsListProps) => {
  const { t } = useTranslation();
  return (
    <ListGroup>
      {props.skills &&
        props.skills.length > 0 &&
        props.skills.map((skill, index) => (
          <ListGroupItem key={index + '-home-training'} className={skill.is_due ? 'text-danger' : undefined}>
            {skill.skill.name}{' '}
            {skill.skill.retraining_frequency && (
              <DefaultTooltip
                id={index + '-retraining-tooltip'}
                tooltipText={t('home.education.IS_RETRAINED_TOOLTIP') + ' ' + skill.skill.retraining_frequency}
              >
                <FontAwesomeIcon icon={'arrows-rotate'} />
              </DefaultTooltip>
            )}
            {skill.earliest_due_date && skill.is_completed && skill.is_child_missed && (
              <DefaultTooltip
                id={'child-is-missed-home-training'}
                tooltipText={t('home.education.IS_CHILD_MISSED') + '\n' + get_missed_names(skill.child_skills_needed)}>
                <FontAwesomeIcon className="text-danger" icon={'triangle-exclamation'} />
              </DefaultTooltip>
            )}
            {skill.overall_due_date && !skill.is_completed && skill.child_skills_needed && (
              <DefaultTooltip
                id={'children-need-home-training'}
                tooltipText={t('home.education.WHO_NEEDS') + '\n' + get_missed_names(skill.child_skills_needed)}>
                <FontAwesomeIcon className="text-danger" icon={'child'} />
              </DefaultTooltip>
            )}
            {skill.overall_due_date && !skill.is_completed && (
              <span style={{ fontSize: 12, fontStyle: 'italic' }}>
                {' '}
                (due {isoDateToCommonFormat(skill.overall_due_date)})
              </span>
            )}
            {skill.latest_date_completed && (
              <span style={{ fontSize: 12, fontStyle: 'italic' }}>
                {' '}
                {skill.latest_training && (
                  <Link to={'/unknown-home-skill-training/view/' + skill.latest_training}>
                    (completed {isoDateToCommonFormat(skill.latest_date_completed)})
                  </Link>
                )}
                {!skill.latest_training && (
                  <span>(completed {isoDateToCommonFormat(skill.latest_date_completed)})</span>
                )}
              </span>
            )}
          </ListGroupItem>
        ))}
      {(!props.skills || props.skills.length === 0) && <p style={{ fontStyle: 'italic' }}>{props.emptyListLabel}</p>}
    </ListGroup>
  );
};

export default HomeSkillsList;
