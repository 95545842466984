import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';
import App from './App';
import ErrorPage from './shared/pages/ErrorPage/ErrorPage';
import ChildRosterPage from './child/ChildRosterPage/ChildRosterPage';
import ChildIntakePage from './child/ChildIntakePage/ChildIntakePage';
import ChildPage from './child/ChildPage/ChildPage';
import SearchDoctorAppointmentPage from './doctorAppointment/SearchDoctorAppointmentPage/SearchDoctorAppointmentPage';
import ChildDoctorAppointmentPage from './doctorAppointment/ChildDoctorAppointmentPage/ChildDoctorAppointmentPage';
import WelcomePage from './shared/pages/WelcomePage/WelcomePage';
import HomeDoctorAppointmentPage from './doctorAppointment/HomeDoctorAppointmentPage/HomeDoctorAppointmentPage';
import SearchEmotionalCheckInPage from './emotionalHealthCheckin/SearchEmotionalCheckInPage/SearchEmotionalCheckInPage';
import ChildEmotionalCheckInPage from './emotionalHealthCheckin/ChildEmotionalCheckInPage/ChildEmotionalCheckInPage';
import SearchBeckResultPage from './beckResult/SearchBeckResultPage/SearchBeckResultPage';
import ChildBeckResultPage from './beckResult/ChildBeckResultPage/ChildBeckResultPage';
import HomeEmotionalCheckInPage from './emotionalHealthCheckin/HomeEmotionalCheckInPage/HomeEmotionalCheckInPage';
import SearchChildWrat5ResultPage from './wrat5/SearchChildWrat5ResultPage/SearchChildWrat5ResultPage';
import HomeWrat5ResultPage from './wrat5/HomeWrat5ResultPage/HomeWrat5ResultPage';
import ChildRscaResultPage from './rscaResult/ChildRscaResultPage/ChildRscaResultPage';
import SearchRscaResultPage from './rscaResult/SearchRscaResultPage/SearchRscaResultPage';
import ChildWrat5ResultPage from './wrat5/ChildWrat5ResultPage/ChildWrat5ResultPage';
import ChildToniResultPage from './toni/ChildToniResultPage/ChildToniResultPage';
import HomeToniResultPage from './toni/HomeToniResultPage/HomeToniResultPage';
import SearchChildToniResultPage from './toni/SearchChildToniResultPage/SearchChildToniResultPage';
import HomeBmiCheckPage from './bmi/HomeBmiCheckPage/HomeBmiCheckPage';
import SearchHomeBmiCheckPage from './bmi/SearchHomeBmiCheckPage/SearchHomeBmiCheckPage';
import SearchSchoolPage from './school/SearchSchoolPage/SearchSchoolPage';
import SchoolPage from './school/SchoolPage/SchoolPage';
import SearchGradeReportPage from './gradeReport/SearchGradeReportPage/SearchGradeReportPage';
import ChildGradeReportPage from './gradeReport/ChildGradeReportPage/ChildGradeReportPage';
import HomeGradeReportPage from './gradeReport/HomeGradeReportPage/HomeGradeReportPage';
import SkillProjectPage from './skill/SkillProjectPage/SkillProjectPage';
import HomeSkillTrainingPage from './skill/HomeSkillTrainingPage/HomeSkillTrainingPage';
import SearchChildSeeResultPage from './see/SearchChildSeeResultPage/SearchChildSeeResultPage';
import ChildSeeResultPage from './see/ChildSeeResultPage/ChildSeeResultPage';
import ChildPhotoPage from './photo/ChildPhotoPage/ChildPhotoPage';
import SearchVaccinationPage from './vaccine/SearchVaccinationPage/SearchVaccinationPage';
import ChildVaccinationPage from './vaccine/ChildVaccinationPage/ChildVaccinationPage';
import SearchHomePage from './home/SearchHomePage/SearchHomePage';
import HomePage from './home/HomePage/HomePage';
import SearchSponsorshipPage from './sponsorship/SearchSponsorshipPage/SearchSponsorshipPage';
import IconDashboardPage from './iconDashboard/IconDashboardPage/IconDashboardPage';
import SearchChildAlertsPage from './alert/SearchChildAlertsPage/SearchChildAlertsPage';
import SearchEventTypesPage from './event_types/SearchEventTypesPage/SearchEventTypesPage';
import SearchHomeChildTrainingPage from './skill/SearchHomeChildTrainingPage/SearchHomeChildTrainingPage';
import SearchHomeLifeSkillPage from './skill/SearchHomeLifeSkillPage/SearchHomeLifeSkillPage';
import GuidanceSessionPage from './guidanceSession/GuidanceSessionPage/GuidanceSessionPage';
import ExtracurricularPage from './extracurricular/ExtracurricularPage/ExtracurricularPage';
import ChildImpactAssessmentPage from './impactAssessment/ChildImpactAssessmentPage/ChildImpactAssessmentPage';
import ChildRiasecResultPage from './riasec/ChildRiasecResultPage/ChildRiasecResultPage';
import TransitionProjectPage from './transitionProject/TransitionProjectPage/TransitionProjectPage';
import MonthlyImpactPage from './monthlyImpact/MonthlyImpactPage/MonthlyImpactPage';
import SponsorshipPage from './sponsorship/SponsorshipPage/SponsorshipPage';

const Router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Navigate to={'child/roster'} />,
      },
      {
        path: 'welcome',
        element: <WelcomePage />,
      },
      {
        path: 'home-bmi-check/search',
        element: <SearchHomeBmiCheckPage />,
      },
      {
        path: 'home-bmi-check/:mode/:id',
        element: <HomeBmiCheckPage />,
      },
      {
        path: 'child/roster',
        element: <ChildRosterPage />,
      },
      {
        path: 'child/intake',
        element: <ChildIntakePage />,
      },
      {
        path: 'child/:id/*',
        element: <ChildPage />,
      },
      {
        path: 'child/:id/photos',
        element: <ChildPhotoPage />,
      },
      {
        path: 'child-doctor-appointment/search',
        element: <SearchDoctorAppointmentPage />,
      },
      {
        path: 'child-doctor-appointment/:mode/:id',
        element: <ChildDoctorAppointmentPage />,
      },
      {
        path: 'home-doctor-appointment/:mode/:id',
        element: <HomeDoctorAppointmentPage />,
      },
      {
        path: 'vaccination/search',
        element: <SearchVaccinationPage />,
      },
      {
        path: 'vaccination/:mode/:id',
        element: <ChildVaccinationPage />,
      },
      {
        path: 'child-mental-check-in/search',
        element: <SearchEmotionalCheckInPage />,
      },
      {
        path: 'child-mental-check-in/:mode/:id',
        element: <ChildEmotionalCheckInPage />,
      },
      {
        path: 'home-mental-check-in/:mode/:id',
        element: <HomeEmotionalCheckInPage />,
      },
      {
        path: 'child-beck-result/search',
        element: <SearchBeckResultPage />,
      },
      {
        path: 'child-beck-result/:mode/:id',
        element: <ChildBeckResultPage />,
      },
      {
        path: 'child-rsca-result/search',
        element: <SearchRscaResultPage />,
      },
      {
        path: 'child-rsca-result/:mode/:id',
        element: <ChildRscaResultPage />,
      },
      {
        path: 'child-wrat-result/search',
        element: <SearchChildWrat5ResultPage />,
      },
      {
        path: 'child-wrat-result/:mode/:id',
        element: <ChildWrat5ResultPage />,
      },
      {
        path: 'home-wrat-result/:mode/:id',
        element: <HomeWrat5ResultPage />,
      },
      {
        path: 'child-toni-result/search',
        element: <SearchChildToniResultPage />,
      },
      {
        path: 'child-toni-result/:mode/:id',
        element: <ChildToniResultPage />,
      },
      {
        path: 'home-toni-result/:mode/:id',
        element: <HomeToniResultPage />,
      },
      {
        path: 'child-see-result/search',
        element: <SearchChildSeeResultPage />,
      },
      {
        path: 'child-see-result/:mode/:id',
        element: <ChildSeeResultPage />,
      },
      // {
      //   path: 'home-see-result/:mode/:id',
      //   element: <HomeSeeResultPage />,
      // },
      {
        path: 'sponsorship/search',
        element: <SearchSponsorshipPage />,
      },
      {
        path: 'sponsorship/:mode/:id',
        element: <SponsorshipPage />,
      },
      {
        path: 'child-grade-report/search',
        element: <SearchGradeReportPage />,
      },
      {
        path: 'child-grade-report/:mode/:id',
        element: <ChildGradeReportPage />,
      },
      {
        path: 'home-grade-report/:mode/:id',
        element: <HomeGradeReportPage />,
      },
      {
        path: 'home-child-training/search',
        element: <SearchHomeChildTrainingPage />,
      },
      {
        path: 'home-child-training/:mode/:id',
        element: <HomeSkillTrainingPage project={'CHILD_TRAININGS'} />,
      },
      {
        path: 'home-life-skill/search',
        element: <SearchHomeLifeSkillPage />,
      },
      {
        path: 'home-life-skill/:mode/:id',
        element: <HomeSkillTrainingPage project={'LIFE_SKILLS'} />,
      },
      {
        path: 'unknown-home-skill-training/:mode/:id',
        element: <HomeSkillTrainingPage />,
      },
      {
        path: 'guidance-session/:mode/:id',
        element: <GuidanceSessionPage />,
      },
      {
        path: 'extracurricular/:mode/:id',
        element: <ExtracurricularPage />,
      },
      {
        path: 'transition-project/:mode/:id',
        element: <TransitionProjectPage />,
      },
      {
        path: 'child-riasec-result/:mode/:id',
        element: <ChildRiasecResultPage />,
      },
      {
        path: 'child-impact-result/:mode/:id',
        element: <ChildImpactAssessmentPage />,
      },

      {
        path: 'home/search',
        element: <SearchHomePage />,
      },
      {
        path: 'home/:id/*',
        element: <HomePage />,
      },
      {
        path: 'school/search',
        element: <SearchSchoolPage />,
      },
      {
        path: 'school/:mode/:id',
        element: <SchoolPage />,
      },
      {
        path: 'skill/search',
        element: <SkillProjectPage />,
      },
      {
        path: 'icon-dashboard',
        element: <IconDashboardPage />,
      },
      {
        path: 'monthly-impact',
        element: <MonthlyImpactPage />,
      },
      {
        path: 'child-alert/search',
        element: <SearchChildAlertsPage />,
      },
      {
        path: 'event-types/search',
        element: <SearchEventTypesPage />,
      },
    ],
  },
]);

export default Router;
