import React from 'react';
import { Domain } from '../../api/permissions_api';
import { useGetSponsorships } from '../../api/sponsorship_api';
import { isoDateAndTimeToCommonFormat } from '../../shared/helpers/dateHelper';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { ObjectWithId } from '../../typings/shared-types';
import ChildSponsorshipChildCell from '../ChildSponsorshipChildCell/ChildSponsorshipChildCell';
import { ChildSponsorship, Sponsorship } from '../../typings/api-types';
import styles from './SearchSponsorshipPage.module.scss';
import i18n from 'i18next';
import GridActions from '../../shared/grid/GridActions/GridActions';

type SearchSponsorshipPageProps = {};

function getSponsorshipChildrenNames(data: Sponsorship) {
  const children = data.children;
  if (children && children.length > 0) {
    const names = children.map((childSponsorship: ChildSponsorship) => {
      return childSponsorship.child_summary?.name + ' (' + childSponsorship.child_summary?.public_id + ') - ' + childSponsorship.child_summary?.home_name;
    });
    return names.join(',\n');
  } else {
    return '';
  }
}

function getColumnDefs(onDelete: (objectToDelete: ObjectWithId) => Promise<any>): ColDef[] {
  const t = i18n.t;
  return [
    {
      headerName: t('sponsorship.CHILDREN'),
      cellRenderer: ChildSponsorshipChildCell,
      valueGetter: (params) => {
        return getSponsorshipChildrenNames(params.data);
      },
      flex: 2,
    },
    {
      field: 'sponsor_name',
      headerName: t('sponsorship.SPONSOR_NAME'),
      flex: 1,
    },
    {
      field: 'sponsor_email',
      headerName: t('sponsorship.SPONSOR_EMAIL'),
      flex: 1,
    },
    // {
    //   field: 'amount_in_dollars',
    //   headerName: t('sponsorship.AMOUNT_IN_DOLLARS'),
    //   valueFormatter: (params) => {
    //     return '$ ' + params.value;
    //   },
    //   width: 90,
    // },
    {
      field: 'frequency',
      headerName: t('sponsorship.FREQUENCY'),
      width: 110,
    },
    {
      field: 'start_date',
      headerName: t('sponsorship.START_DATE'),
      valueFormatter: (params: any) => {
        return isoDateAndTimeToCommonFormat(params.value) || '';
      },
      width: 110,
    },
    {
      field: 'status',
      headerName: t('sponsorship.STATUS'),
      cellStyle: (params) => {
        const style = {
          textTransform: 'capitalize',
          color: '',
        };
        if (params.value === 'active') {
          style.color = 'green';
        }
        return style;
      },
      width: 90,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/sponsorship',
        disableDelete: true
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
}

const SearchSponsorshipPage = (props: SearchSponsorshipPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'sponsorship'}
      getColumns={getColumnDefs}
      permissionDomain={Domain.SPONSOR_TAB}
      translatePrefix={'sponsorship.'}
      useGetListApiHook={useGetSponsorships}
      getRowClass={(params) => {
        if (params.data.status === 'active') {
          return '';
        } else {
          return styles.cancelled;
        }
      }}
      createForChildPageUrl={'/sponsorship/create/new'}
    />
  );
};

export default SearchSponsorshipPage;
export { getSponsorshipChildrenNames };
