import { useTranslation } from 'react-i18next';
import { patchChild, useGetChildOverviewMental } from '../../api/child_api';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import React, { useContext, useRef, useState } from 'react';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { AgGridReact } from 'ag-grid-react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmotionalCheckInScoreCell from '../../emotionalHealthCheckin/EmotionalCheckInScoreCell/EmotionalCheckInScoreCell';
import ChildMentalEmotionalCheckInChart from '../ChildMentalEmotionalCheckInChart/ChildMentalEmotionalCheckInChart';
import BeckResultScoreCell from '../../beckResult/BeckResultScoreCell/BeckResultScoreCell';
import RscaResultScoreCell from '../../rscaResult/RscaResultScoreCell/RscaResultScoreCell';
import { LinkContainer } from 'react-router-bootstrap';
import EmotionalCheckInSummary from '../../emotionalHealthCheckin/EmotionalCheckInSummary/EmotionalCheckInSummary';
import { deleteChildEmotionalCheckIn } from '../../api/emotional_check_in_api';
import { deleteChildBeckResult } from '../../api/beck_result_api';
import { deleteChildRscaResult } from '../../api/rsca_result_api';
import { ChildBeckResult, ChildDetail, ChildEmotionalCheckIn, ChildRscaResult } from '../../typings/api-types';
import { Domain } from '../../api/permissions_api';
import { genericOnDelete } from '../../shared/components/GenericSearchPage/GenericSearchPage';
import FeedbackMessagesContext from '../../shared/contexts/FeedbackMessagesContext';
import { getDefaultColDef, suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';
import { ChildDetailPatch } from '../../typings/types-to-send';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import i18n from 'i18next';

type ChildMentalProps = {
  child: ChildDetail;
  refetchChild: () => void;
};

function getEmotionalHealthCheckInColumnDefs(
  onDelete: (objectToDelete: ChildEmotionalCheckIn) => Promise<any>,
  childId: number,
) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params) => {
        return isoDateToCommonFormat(params.value);
      },
      width: 190,
    },
    {
      headerName: t('emotionalCheckIn.SCORE_AVERAGE'),
      cellRenderer: EmotionalCheckInScoreCell,
      width: 190,
    },
    {
      field: 'happy_score',
      headerName: t('emotionalCheckIn.HAPPY_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'calm_score',
      headerName: t('emotionalCheckIn.CALM_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'peaceful_score',
      headerName: t('emotionalCheckIn.PEACEFUL_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'accepted_score',
      headerName: t('emotionalCheckIn.ACCEPTED_SCORE_ABBR'),
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-mental-check-in',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

function getBeckResultColumnDefs(onDelete: (objectToDelete: ChildBeckResult) => Promise<any>, childId: number) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('beckResult.SCORE'),
      cellRenderer: BeckResultScoreCell,
      width: 190,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-beck-result',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

function getRscaResultColumnDefs(onDelete: (objectToDelete: ChildRscaResult) => Promise<any>, childId: number) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('rscaResult.SCORE'),
      cellRenderer: RscaResultScoreCell,
      width: 190,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-rsca-result',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

const CHILD_MENTAL_FORM_SCHEMA = Yup.object().shape({});

const ChildMental = (props: ChildMentalProps) => {
  const { t } = useTranslation();
  let { mode } = useParams();
  const navigate = useNavigate();
  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);
  const { data, isLoading, error, refetch } = useGetChildOverviewMental(props.child.id);
  const [serverError, setServerError] = useState(false);

  const emotionalCheckInGridRef = useRef<AgGridReact>(null);
  const emotionalCheckInDomain = Domain.CHILD_EMOTIONAL_CHECK_IN;
  const emotionalCheckInPrefix = 'emotionalCheckIn.';
  const onDeleteEmotionalCheckIn = (objectToDelete: ChildEmotionalCheckIn) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildEmotionalCheckIn,
      emotionalCheckInDomain,
      emotionalCheckInPrefix,
      emotionalCheckInGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../mental/view');
  }

  async function onSubmit(dataWithFiles: ChildDetailPatch, { setSubmitting }: any) {
    if (props.child && props.child.id) {
      try {
        await patchChild(props.child.id, dataWithFiles);
        await props.refetchChild();
        setSubmitting(false);
        navigate('../mental/view');
      } catch (error) {
        setServerError(true);
      }
    } else {
      // LOG SOMETHING?
    }
  }

  const beckGridRef = useRef<AgGridReact>(null);
  const beckResultDomain = Domain.CHILD_BECK_RESULT;
  const beckResultTranslationPrefix = 'beckResult.';
  const onDeleteBeckResult = (objectToDelete: ChildBeckResult) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildBeckResult,
      beckResultDomain,
      beckResultTranslationPrefix,
      beckGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  const rscaGridRef = useRef<AgGridReact>(null);
  const rscaCheckInDomain = Domain.CHILD_RSCA_RESULT;
  const rscaTranslatePrefix = 'rscaResult.';
  const onDeleteRscaResult = (objectToDelete: ChildRscaResult) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildRscaResult,
      rscaCheckInDomain,
      rscaTranslatePrefix,
      rscaGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error || serverError) {
    return <div>Error loading {props.child.id}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.child.id}</div>;
  }

  if (!props.child.id) {
    return <div>Error with child id</div>;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Formik
        // Without enableReinitialize if you save edits and then edit + cancel + edit,
        // your old edits get wiped out
        enableReinitialize
        initialValues={props.child}
        validationSchema={CHILD_MENTAL_FORM_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<any>) => {
          return (
            <FormikForm>
              <Row>
                <Col sm={{ span: 2, order: 1 }} xs={2}>
                  {mode === 'view' && (
                    <Link to="../mental/edit">
                      <Button variant={'outline-primary'}>
                        <FontAwesomeIcon icon={'pencil'} />
                      </Button>
                    </Link>
                  )}
                  {mode === 'edit' && (
                    <Button variant={'outline-secondary'} onClick={onCancel.bind(null, formikProps.resetForm)}>
                      <FontAwesomeIcon icon={'close'} />
                    </Button>
                  )}
                </Col>
                <Col sm={{ span: 10, order: 0 }}>
                  <FormikSelect
                    label={t('child.mental.HAS_COUNSELING_PLAN')}
                    name="has_counseling_plan"
                    options={BOOLEAN_OPTIONS}
                    showSideBySide={true}
                    labelWidth={4}
                    isReadOnly={mode === 'view'}
                  />
                  <FormikSelect
                    label={t('child.mental.IS_IN_COUNSELING')}
                    name="is_in_counseling"
                    options={BOOLEAN_OPTIONS}
                    showSideBySide={true}
                    labelWidth={4}
                    isReadOnly={mode === 'view'}
                  />
                </Col>
              </Row>
              {mode === 'edit' && (
                <Row>
                  <Col lg={10}>
                    {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                    <div className="d-flex justify-content-end">
                      <Button
                        className="mx-2 my-2"
                        variant="secondary"
                        onClick={onCancel.bind(null, formikProps.resetForm)}
                      >
                        {t('common.CANCEL')}
                      </Button>
                      <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                        {t('common.SAVE_CHANGES')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </FormikForm>
          );
        }}
      </Formik>
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.mental.LAST_EMOTIONAL_CHECK_IN')}
        </Form.Label>
        <EmotionalCheckInSummary emotionalCheckIn={data.last_emotional_check_in} />
      </Form.Group>
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.mental.EMOTIONAL_CHECK_INS')}
        </Form.Label>
      </Form.Group>
      <div
        className="ag-theme-balham"
        style={{
          height: '400px',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={emotionalCheckInGridRef}
          columnDefs={getEmotionalHealthCheckInColumnDefs(onDeleteEmotionalCheckIn, props.child.id)}
          rowData={data.emotional_check_ins}
          getRowId={(params) => params.data.id}
          defaultColDef={getDefaultColDef()}
          components={{
            actionLinksRenderer: GridActions,
            emotionalCheckInScoreRenderer: EmotionalCheckInScoreCell,
          }}
          animateRows={true}
        />
      </div>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/child-mental-check-in/create/new',
              search: '?childId=' + props.child.id,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'child'} /> {t('emotionalCheckIn.ADD_CHILD_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.mental.EMOTIONAL_CHECK_IN_CHART_TITLE')}
        </Form.Label>
      </Form.Group>
      {data.emotional_check_ins && (
        <div
          style={{
            height: '350px',
            width: '75%',
          }}
        >
          <ChildMentalEmotionalCheckInChart emotionalCheckIns={data.emotional_check_ins} />
        </div>
      )}
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.mental.BECK_RESULTS')}
        </Form.Label>
      </Form.Group>
      <div
        className="ag-theme-balham"
        style={{
          height: '200px',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={beckGridRef}
          columnDefs={getBeckResultColumnDefs(onDeleteBeckResult, props.child.id)}
          rowData={data.beck_results}
          getRowId={(params) => params.data.id}
          defaultColDef={getDefaultColDef()}
          animateRows={true}
        />
      </div>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/child-beck-result/create/new',
              search: '?childId=' + props.child.id,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'child'} /> {t('beckResult.ADD_CHILD_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.mental.RSCA_RESULTS')}
        </Form.Label>
      </Form.Group>
      <div
        className="ag-theme-balham"
        style={{
          height: '200px',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={rscaGridRef}
          columnDefs={getRscaResultColumnDefs(onDeleteRscaResult, props.child.id)}
          rowData={data.rsca_results}
          getRowId={(params) => params.data.id}
          defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
          animateRows={true}
        />
      </div>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/child-rsca-result/create/new',
              search: '?childId=' + props.child.id,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'child'} /> {t('rscaResult.ADD_CHILD_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default ChildMental;
