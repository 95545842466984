import i18n from 'i18next';
import React from 'react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import { deleteChildRscaResult, useGetChildRscaResults } from '../../api/rsca_result_api';
import { ChildRscaResult } from '../../typings/api-types';
import RscaResultScoreCell from '../RscaResultScoreCell/RscaResultScoreCell';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';

type SearchRscaPageProps = {};

const getColumns = (onDelete: (objectToDelete: ChildRscaResult) => Promise<any>) => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      flex: 1,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 190,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('rscaResult.SCORE'),
      cellRenderer: RscaResultScoreCell,
      width: 190,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-rsca-result',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchRscaResultPage = (props: SearchRscaPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'rsca-result'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_RSCA_RESULT}
      translatePrefix={'rscaResult.'}
      useGetListApiHook={useGetChildRscaResults}
      deleteApiFunc={deleteChildRscaResult}
      createForChildPageUrl={'/child-rsca-result/create/new'}
    />
  );
};

export default SearchRscaResultPage;
