import React from 'react';
import { Domain } from '../../api/permissions_api';
import { deleteChildEmotionalCheckIn, useGetChildEmotionalCheckIns } from '../../api/emotional_check_in_api';
import { ChildEmotionalCheckIn } from '../../typings/api-types';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import GridActions from '../../shared/grid/GridActions/GridActions';
import EmotionalCheckInScoreCell from '../EmotionalCheckInScoreCell/EmotionalCheckInScoreCell';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchEmotionalCheckInPageProps = {};

const getColumns = (
  onDelete: (objectToDelete: ChildEmotionalCheckIn) => Promise<any>,
): ColDef<ChildEmotionalCheckIn>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      flex: 1,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 190,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('emotionalCheckIn.SCORE_AVERAGE'),
      // Field is needed when exporting to excel, on web it doesn't matter
      field: 'score_average',
      cellRenderer: EmotionalCheckInScoreCell,
      width: 190,
    },
    {
      field: 'happy_score',
      headerName: t('emotionalCheckIn.HAPPY_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'calm_score',
      headerName: t('emotionalCheckIn.CALM_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'peaceful_score',
      headerName: t('emotionalCheckIn.PEACEFUL_SCORE_ABBR'),
      flex: 1,
    },
    {
      field: 'accepted_score',
      headerName: t('emotionalCheckIn.ACCEPTED_SCORE_ABBR'),
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-mental-check-in',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchEmotionalCheckInPage = (props: SearchEmotionalCheckInPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'mental-check-in'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_EMOTIONAL_CHECK_IN}
      translatePrefix={'emotionalCheckIn.'}
      useGetListApiHook={useGetChildEmotionalCheckIns}
      deleteApiFunc={deleteChildEmotionalCheckIn}
      createForChildPageUrl={'/child-mental-check-in/create/new'}
      createForHomePageUrl={'/home-mental-check-in/create/new'}
      childButtonVariant={'primary'}
      homeButtonVariant={'outline-primary'}
    />
  );
};

export default SearchEmotionalCheckInPage;
