import { ChildBmiCheck } from '../../typings/api-types';
import { isLessThanXMonthsOld, isMoreThanXMonthsOld, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import React from 'react';
import styles from './ChildPhysicalNutritionSummary.module.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';

type ChildPhysicalNutritionSummaryProps = {
  zscore_level: string;
  last_bmi_check?: ChildBmiCheck;
  zscore_6mo_trend: string;
  age?: number;
};

const NUMBER_OF_MONTHS_OLD_BMI = 4;
const NUMBER_OF_MONTHS_RECENT_BMI = 2;

const ChildPhysicalNutritionSummary = (props: ChildPhysicalNutritionSummaryProps) => {
  const { t } = useTranslation();

  if (props.age && props.age > 19) {
    return <span>{t('child.physical.ZSCORE_UNTRACKED_TOO_OLD')}</span>;
  }

  let zscoreLevelElement = undefined;
  if (props.zscore_level) {
    let coloringClass = '';
    if (props.zscore_level.includes('UNDERWEIGHT')) {
      coloringClass = styles.bad;
    }

    // Lower-case transformed into title case with styles
    // https://stackoverflow.com/a/3054478/13815107
    zscoreLevelElement = (
      <DefaultTooltip
        id={'zscore-level-tooltip'}
        tooltipText={
          t('bmiCheck.ZSCORE') +
          ': ' +
          roundTo2Decimals(props.last_bmi_check?.zscore) +
          '\n' +
          t('bmiCheck.BMI') +
          ': ' +
          roundTo2Decimals(props.last_bmi_check?.bmi)
        }
      >
        <span className={styles.zscoreLevel + ' ' + coloringClass}>
          {props.zscore_level.toLowerCase().replace(/_/g, ' ')}
        </span>
      </DefaultTooltip>
    );
  } else {
    zscoreLevelElement = <span>{t('child.physical.ZSCORE_LEVEL_UNKNOWN')}</span>;
  }

  let lastBmiDateElement = undefined;
  if (props.last_bmi_check?.date) {
    let coloringClass = '';
    if (isMoreThanXMonthsOld(props.last_bmi_check.date, NUMBER_OF_MONTHS_OLD_BMI)) {
      coloringClass = styles.bad;
    }
    if (isLessThanXMonthsOld(props.last_bmi_check.date, NUMBER_OF_MONTHS_RECENT_BMI)) {
      coloringClass = styles.good;
    }
    lastBmiDateElement = (
      <span className={styles.asOfDate}>
        {t('common.AS_OF')} <span className={coloringClass}>{isoDateToCommonFormat(props.last_bmi_check.date)}</span>
      </span>
    );
  }

  let trendElement = undefined;
  if (props.zscore_6mo_trend) {
    let coloringClass = '';
    let icon = 'dash' as IconProp;
    if (props.zscore_6mo_trend === 'DOWN') {
      coloringClass = styles.bad;
      icon = 'arrow-down';
    } else if (props.zscore_6mo_trend === 'UP') {
      coloringClass = styles.good;
      icon = 'arrow-up';
    }
    trendElement = (
      <span className={coloringClass}>
        (<FontAwesomeIcon icon={icon} /> {t('child.physical.ZSCORE_TREND_LABEL')})
      </span>
    );
  } else {
    trendElement = <span>({t('child.physical.ZSCORE_TREND_UNKNOWN')})</span>;
  }

  return (
    <span>
      {zscoreLevelElement} {lastBmiDateElement} {trendElement}
    </span>
  );
};

export default ChildPhysicalNutritionSummary;
