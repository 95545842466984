import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';

import { ColDef, GetRowIdParams, IAggFuncParams, ICellRendererParams } from 'ag-grid-community';
import styles from './HomeBmiCheckPage.module.scss';
import {
  getLatestHomeBmiCheckDetail,
  patchHomeBmiCheck,
  postHomeBmiCheck,
  useGetHomeBmiCheckDetail,
} from '../../api/bmi_check_api';
import { getChildrenInHome, useGetChildDetail } from '../../api/child_api';
import { HomeBmiCheckDetail, SimpleChildSummary } from '../../typings/api-types';
import { useLocation, useParams } from 'react-router-dom';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { ChildBmiCheckPatch, ChildBmiCheckPost, HomeBmiCheckPatch } from '../../typings/types-to-send';
import i18n from 'i18next';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import * as Yup from 'yup';
import { TestContext } from 'yup';
import { BOOLEAN_OPTIONS, yupRequiredIfTruthy } from '../../shared/helpers/formHelper';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';
import { isAggregate, suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import _ from 'lodash';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type ChildBmiGridProps = {};

type ChildSummaryWithLatestBmiFields = SimpleChildSummary & {
  latest_height_in_cm?: number | null;
  latest_check_date?: string;
};

const DOMAIN_URL = 'home-bmi-check';
const DOMAIN = Domain.HOME_BMI;
const CHILD_TAB = 'physical';

function heightValidationTest(value: number | undefined, testContext: TestContext) {
  if (!testContext.from) {
    throw Error;
  }
  const childCheck = testContext.from[0].value as ChildBmiCheckPatch;
  const homeCheck = testContext.from[1].value as HomeBmiCheckPatch;
  if (!childCheck.child_was_present) {
    return true;
  } else if (!value || !homeCheck.date) {
    return false;
  } else {
    let isNewest = childCheck._latest_check_date < homeCheck.date;
    if (childCheck._latest_height_in_cm && isNewest) {
      return value >= childCheck._latest_height_in_cm;
    } else {
      return value >= 20;
    }
  }
}

const HOME_BMI_CHECK_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child_bmi_checks: Yup.array()
    .of(
      Yup.object().shape({
        // Auto-populated and can't be modified
        child: Yup.number().required('Required'),

        child_was_present: Yup.boolean().required('Required'),

        height_in_cm: Yup.number()
          .max(300, 'Must be less than 300 cm')
          .when('child_was_present', yupRequiredIfTruthy)
          .test('meetsMinHeight', 'Must be over 20cm and taller than last measure.', heightValidationTest),
        weight_in_kgs: Yup.number()
          .min(1, 'Must be at least 1 kg')
          .max(130, 'Must be less than 130 kg (or message Brad)')
          .when('child_was_present', yupRequiredIfTruthy),
      }),
    )
    .required('Required'),
});

function hasNowOutgrownBmi(child: SimpleChildSummary) {
  return child.age >= 19 || child.situation_status !== 'IN_HOME';
}

function getInitialChildrenAtHomeChecks(children: ChildSummaryWithLatestBmiFields[]): ChildBmiCheckPost[] {
  const childrenAtAppointment = children.map((child) => {
    // Only on create, we want this to be editable later even if kids are older
    let childWasPresent = undefined;
    let hasOutgrownBmi = hasNowOutgrownBmi(child);
    if (hasOutgrownBmi) {
      childWasPresent = false;
    }

    return {
      child: child.id,

      child_name: child.name,
      child_gender: child.gender,

      age_at_time: undefined,

      child_was_present: childWasPresent,

      height_in_cm: undefined,
      weight_in_kgs: undefined,

      // UI only
      _has_now_outgrown_bmi: hasOutgrownBmi,
      _latest_height_in_cm: child.latest_height_in_cm,
      _latest_check_date: child.latest_check_date,
    } as ChildBmiCheckPost;
  });
  return childrenAtAppointment;
}

const getColumns = (mode: Mode): ColDef[] => {
  const t = i18n.t;
  let presentPercentageFunction = (data: IAggFuncParams) => {
    const present = data.values;
    const percentPresent = (100 * present.filter((p) => p === true).length) / present.length;
    const roundedPercent = roundTo2Decimals(percentPresent);
    return roundedPercent;
  };

  let bmiAverageFunction = (data: IAggFuncParams) => {
    if (data.values && data.values.length > 0) {
      const zscores = data.values;
      const average = zscores.reduce((a, b) => a + b) / zscores.length;
      const roundedAverage = roundTo2Decimals(average);
      return roundedAverage;
    }
  };
  let zScoreAverageFunction = (data: IAggFuncParams) => {
    if (data.values && data.values.length > 0) {
      const zscores = data.values;
      const average = zscores.reduce((a, b) => a + b) / zscores.length;
      const roundedAverage = roundTo2Decimals(average);
      return roundedAverage;
    }
  };
  return [
    { headerName: t('common.CHILD'), field: 'child_name', flex: 2 },
    { headerName: t('common.GENDER'), field: 'child_gender', flex: 1 },
    {
      headerName: t('common.PRESENT'),
      field: 'child_was_present',
      flex: 2,
      cellRenderer: (params: any) => {
        if (mode === Mode.CREATE && params.data?._has_now_outgrown_bmi) {
          return 'Not Tracked';
        } else if (mode === Mode.VIEW || isAggregate(params)) {
          if (params.value === undefined) {
            return '';
          } else if (typeof params.value == 'boolean') {
            return params.value ? '✅' : '❌';
          } else if (isAggregate(params)) {
            return `${t('common.TOTAL')}: ${params.value}%`;
          } else {
            return params.value;
          }
        } else {
          return (
            <FormikSelect
              name={`child_bmi_checks[${params.rowIndex}].child_was_present`}
              options={BOOLEAN_OPTIONS}
              isReadOnly={false}
              isInAgGrid={true}
            />
          );
        }
      },
      aggFunc: mode === Mode.VIEW ? presentPercentageFunction : false,
    },
    { headerName: t('bmiCheck.AGE_AT_TIME'), field: 'age_at_time', flex: 1 },
    {
      headerName: t('bmiCheck.LATEST_HEIGHT'),
      field: '_latest_height_in_cm',
      cellRenderer: (params: ICellRendererParams<ChildBmiCheckPatch>) => {
        return (
          <span>
            {params.value}{' '}
            {params.data?._latest_check_date && (
              <DefaultTooltip
                id={'latest-height-date'}
                tooltipText={
                  'Latest Check: ' +
                  isoDateToCommonFormat(params.data._latest_check_date) +
                  '\n' +
                  'If entering a check newer than ' +
                  isoDateToCommonFormat(params.data._latest_check_date) +
                  ", the child's height must be equal or higher. " +
                  'If entering a check older than ' +
                  isoDateToCommonFormat(params.data._latest_check_date) +
                  ', or editing a check, this is ignored.'
                }
              >
                <FontAwesomeIcon icon={icon({ name: 'circle-question', style: 'regular' })} />
              </DefaultTooltip>
            )}
          </span>
        );
      },
      width: 120,
    },
    {
      headerName: t('bmiCheck.HEIGHT'),
      field: 'height_in_cm',
      valueFormatter: (params: any) => {
        return roundTo2Decimals(params.value);
      },
      cellRenderer: (params: any) => {
        if (isAggregate(params)) {
          // No aggregate for now
          return '';
        } else if (params.data?.child_was_present === false) {
          return '';
        } else if (mode === Mode.VIEW) {
          const roundedValue = roundTo2Decimals(params.value);
          if (roundedValue !== undefined) {
            return roundedValue.toString();
          } else {
            return roundedValue;
          }
        } else {
          return <FormikTextInput name={`child_bmi_checks[${params.rowIndex}].height_in_cm`} isInAgGrid={true} />;
        }
      },
      aggFunc: 'avg',
      width: 120,
    },
    {
      headerName: t('bmiCheck.WEIGHT'),
      field: 'weight_in_kgs',
      valueFormatter: (params: any) => {
        return roundTo2Decimals(params.value);
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (isAggregate(params)) {
          // No aggregate for now
          return '';
        } else if (params.data?.child_was_present === false) {
          return '';
        } else if (mode === Mode.VIEW) {
          const roundedValue = roundTo2Decimals(params.value);
          if (roundedValue !== undefined) {
            return roundedValue.toString();
          } else {
            return roundedValue;
          }
        } else {
          return <FormikTextInput name={`child_bmi_checks[${params.rowIndex}].weight_in_kgs`} isInAgGrid={true} />;
        }
      },
      aggFunc: 'avg',
      width: 120,
    },
    {
      headerName: t('bmiCheck.BMI'),
      field: 'bmi',
      flex: 2,
      valueFormatter: (params: any) => {
        if (mode === Mode.EDIT) {
          return 'Save to see results';
        } else if (isAggregate(params) && params.value) {
          return `${t('common.AVERAGE')} ${t('bmiCheck.BMI')}: ${params.value}`;
        } else {
          return roundTo2Decimals(params.value);
        }
      },
      aggFunc: mode === Mode.VIEW ? bmiAverageFunction : undefined,
    },
    {
      headerName: t('bmiCheck.ZSCORE'),
      field: 'zscore',
      flex: 2,
      valueFormatter: (params: any) => {
        if (mode === Mode.EDIT) {
          return 'Save to see results';
        } else if (isAggregate(params) && params.value) {
          return `${t('common.AVERAGE')} ${t('bmiCheck.ZSCORE')}: ${params.value}`;
        } else {
          return roundTo2Decimals(params.value);
        }
      },
      aggFunc: mode === Mode.VIEW ? zScoreAverageFunction : undefined,
    },
  ] as ColDef[];
};

function getBlankHomeBmiCheckNoChildren(): HomeBmiCheckPatch {
  return {
    home: undefined,
    date: '',
    // Undefined for no child_bmi_checks loaded yet, empty array for no child_bmi_checks in home
    child_bmi_checks: undefined,
  };
}

export default function HomeBmiCheckPage(props: ChildBmiGridProps) {
  let { mode: modeString, id: idString } = useParams();
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const { t } = useTranslation();
  const mode = parseMode(modeString);
  const columnDefs = getColumns(mode);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }

  const [isChildrenLoading, setIsChildrenLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: homeData, isLoading: isHomesLoading } = useGetHomes();

  const { data, isFetching, error } = useGetHomeBmiCheckDetail(id, hasNumberId);

  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  let childId: string | null = null;
  if (queryParams) {
    childId = queryParams.get('childId');
  }
  const { data: childDetailData, isLoading: isChildDetailLoading } = useGetChildDetail(childId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  const homeOptions = createHomeOptions(homeData);

  let homeBmiCheck: HomeBmiCheckPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    if (childId && isChildDetailLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (childId && !childDetailData) {
      return <div>Could not find data for child {childId}</div>;
    }
    homeBmiCheck = getBlankHomeBmiCheckNoChildren();
    if (childDetailData) {
      homeBmiCheck.home = Number(childDetailData.data.home);
    }
    onSubmit = async (data: HomeBmiCheckPatch, { setSubmitting }: any) => {
      try {
        // TODO where should I put code like this?
        if (data.child_bmi_checks) {
          data.child_bmi_checks.forEach((childBmiCheck) => {
            // Backend will handle this
            delete childBmiCheck.home_bmi_check;
          });
        }
        await postHomeBmiCheck(data);
        // TODO error handling?
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (serverError) {
    return <div> Something went wrong, contact LJI IT </div>;
  } else if (hasNumberId) {
    if (isFetching || isHomesLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    }

    homeBmiCheck = data as HomeBmiCheckPatch;

    onSubmit = async (data: HomeBmiCheckPatch, { setSubmitting }: any) => {
      try {
        // TODO where should I put code like this?
        if (data.child_bmi_checks) {
          data.child_bmi_checks.forEach((childBmiCheck) => {
            // Backend will handle this
            delete childBmiCheck.home_bmi_check;
          });
        }
        await patchHomeBmiCheck(data.id, data);
        // TODO error handling?
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(homeBmiCheck);
    navigateAfterCancel();
  }

  return (
    <Formik
      initialValues={homeBmiCheck}
      validationSchema={HOME_BMI_CHECK_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeBmiCheckPatch>) => {
        function onHomeChange(newHome: any) {
          if (!isChildrenLoading) {
            setIsChildrenLoading(true);
            formikProps.setFieldValue('child_bmi_checks', undefined);
            const homeId = newHome.value;
            getChildrenInHome(homeId).then((childData: SimpleChildSummary[]) => {
              // TODO this could be faster if we called these in parallel and did promise.all()
              getLatestHomeBmiCheckDetail(homeId).then((homeBmi: HomeBmiCheckDetail | null) => {
                let childrenWithLatestBmi;
                if (!homeBmi) {
                  childrenWithLatestBmi = childData;
                } else {
                  childrenWithLatestBmi = childData.map((childSummary) => {
                    const childSummaryWithLatestBmi = _.cloneDeep(childSummary) as ChildSummaryWithLatestBmiFields;
                    const foundLatestCheck = homeBmi.child_bmi_checks.find(
                      (childCheck) => childCheck.child === childSummary.id,
                    );
                    childSummaryWithLatestBmi.latest_height_in_cm = foundLatestCheck?.height_in_cm;
                    childSummaryWithLatestBmi.latest_check_date = foundLatestCheck?.date;
                    return childSummaryWithLatestBmi;
                  });
                }
                formikProps.setFieldValue('child_bmi_checks', getInitialChildrenAtHomeChecks(childrenWithLatestBmi));
                setIsChildrenLoading(false);
              });
            });
          }
        }

        // TODO I would rather load children right after page load
        //  instead of abusing states inside the formik loop
        if (formikProps.values.home && formikProps.values.child_bmi_checks === undefined && !isChildrenLoading) {
          setIsChildrenLoading(true);
          const homeId = Number(formikProps.values.home);
          getChildrenInHome(homeId).then((childData: SimpleChildSummary[]) => {
            formikProps.setFieldValue('child_bmi_checks', getInitialChildrenAtHomeChecks(childData));
            setIsChildrenLoading(false);
          });
        }

        // Without this, when a cell changes the whole row component is deleted and remade
        // That causes inputs to lose focus, because the html input underneath was deleted and recreated
        // It also probably degrades performance
        const getRowId = (params: GetRowIdParams<ChildBmiCheckPatch>) => {
          if (params.data.id) {
            return '' + params.data.id;
          } else {
            return '' + params.data.child;
          }
        };

        return (
          <FormikForm className={'d-flex flex-grow-1'}>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('bmiCheck.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={11}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={1}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../home-bmi-check/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
              </Row>
              <Row className="flex-grow-1 pb-1">
                {!formikProps.values.home && <div>Please pick a home</div>}
                {formikProps.values.home && (
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <AgGridReact
                      getRowId={getRowId}
                      rowData={formikProps.values.child_bmi_checks}
                      defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                      rowHeight={50}
                      columnDefs={columnDefs}
                      getRowClass={(params) => {
                        if (params.data?._has_now_outgrown_bmi) {
                          return styles.outgrown;
                        }
                        return '';
                      }}
                      gridOptions={{
                        suppressContextMenu: true,
                      }}
                      groupIncludeFooter={true}
                      groupIncludeTotalFooter={true}
                      rowSelection={'single'}
                      animateRows={true}
                    />
                  </div>
                )}
              </Row>
              <DefaultPageActionButtons
                mode={mode}
                formikProps={formikProps}
                onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                onBack={onBack}
              />
              {/*<DisplayFormikState formikProps={formikProps} />*/}
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
    // <Container fluid className="d-flex flex-column flex-grow-1">
    //   <Row>
    //     <Col>
    //       <h1 className="text-center font-weight-bold">{t('bmi.HOME_BMI_TITLE')}</h1>
    //     </Col>
    //   </Row>
    //   <Row className="flex-grow-1 pb-1">
    //     <Col className="shadow-sm border d-flex">

    //     </Col>
    //   </Row>
    // </Container>
  );
}
