import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  createExtracurricularNameOptions,
  deleteExtracurricular,
  patchExtracurricular,
  postExtracurricular,
  useGetExistingExtracurricularNames,
  useGetExtracurricularDetail,
} from '../../api/extracurricular_api';
import { Col, Container, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field, FieldProps, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildExtracurricularPatch, ChildExtracurricularPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, {
  createDoesOptionMatchFieldFn,
  GenericOption,
} from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import { useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import CustomErrorMessage from '../../shared/formik/CustomErrorMessage/CustomErrorMessage';
import CreatableSelect from 'react-select/creatable';

type ExtracurricularProps = {};

const DOMAIN_URL = 'extracurricular';
const DOMAIN = Domain.EXTRACURRICULAR;
const CHILD_TAB = 'transition';

const EXTRACURRICULAR_FORM_SCHEMA = Yup.object().shape({
  child: Yup.number().required('Required'),
  start_date: Yup.date().required('Required'),
  end_date: Yup.date().nullable(),
  extracurricular_name: Yup.string().required('Required'),
});

function getBlankExtracurricular(): ChildExtracurricularPost {
  return {
    child: undefined,
    start_date: '',
    end_date: null,
    extracurricular_name: '',
  };
}

const ExtracurricularPage = (props: ExtracurricularProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const { data: extracurricularNameData } = useGetExistingExtracurricularNames();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetExtracurricularDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let extracurricular: ChildExtracurricularPost | ChildExtracurricularPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    extracurricular = getBlankExtracurricular();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      extracurricular.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildExtracurricularPost, { setSubmitting }: any) => {
      try {
        await postExtracurricular(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    extracurricular = data as ChildExtracurricularPatch;

    onSubmit = async (dataToSubmit: ChildExtracurricularPatch, { setSubmitting }: any) => {
      try {
        await patchExtracurricular(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(extracurricular);
    navigateAfterCancel();
  }

  const childOptions = createChildOptions(childData);
  const extracurricularNameOptions = createExtracurricularNameOptions(extracurricularNameData);

  const onDelete = () => {
    if (id) {
      return deleteExtracurricular(id);
    } else {
      return Promise.resolve();
    }
  };

  const afterDelete = () => {
    if (id) {
      navigateAfterCancel();
    }
  };

  const extracurricularParamName = 'extracurricular_name';
  const extracurricularParamLabel = t('extracurricular.NAME');
  const extracurricularParamIsReadOnly = mode === Mode.VIEW;
  const extracurricularParamLabelWidth = 4;

  return (
    <Formik initialValues={extracurricular} validationSchema={EXTRACURRICULAR_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildExtracurricularPost | ChildExtracurricularPatch>) => {
        // TODO this should really be an enhancement to FormikSelect
        //  but it is a little unwieldy to add it because FormikSelect supports isMulti
        let extracurricularCreatableSelect = (
          <div>
            {/* This is a formik component so formik does validation and manages state */}
            <Field name={extracurricularParamName}>
              {(fieldProps: FieldProps) => {
                const { field, form } = fieldProps;
                const handleChange = (option: any) => {
                  let value;
                  if (option === undefined || option === null) {
                    // Purposely send null so the backend clears the value (django PATCH ignores undefineds by default)
                    value = null;
                  } else {
                    value = (option as GenericOption).value;
                  }
                  form.setFieldValue(field.name, value);
                };
                const doesOptionMatchField = createDoesOptionMatchFieldFn(field);
                const getValue = () => {
                  return extracurricularNameOptions.find(doesOptionMatchField);
                };

                let reactSelectComponent;
                if (extracurricularParamIsReadOnly) {
                  if (!getValue()) {
                    reactSelectComponent = <div />;
                  } else {
                    reactSelectComponent = <Col className="d-flex align-items-center">{getValue()?.label}</Col>;
                  }
                } else {
                  reactSelectComponent = (
                    <Col>
                      <CreatableSelect
                        placeholder={'Select or start typing to create'}
                        name={field.name}
                        value={getValue()}
                        onChange={handleChange}
                        options={extracurricularNameOptions}
                        isClearable={true}
                      />
                      {/* Not getting errors messages?
                        Try adding 'fieldName: undefined,' to initialStateOfEditableScheduleFields

                        Still not working and in AG grid? Try setting row height to 50
                      */}
                      <ErrorMessage name={extracurricularParamName}>
                        {(errorMessage) => {
                          return <CustomErrorMessage errorMessage={errorMessage} />;
                        }}
                      </ErrorMessage>
                    </Col>
                  );
                }
                return (
                  <FormGroup as={Row}>
                    {extracurricularParamLabel && (
                      // This is a react-bootstrap component match label look-and-feel
                      <FormLabel column={true} sm={extracurricularParamLabelWidth}>
                        {extracurricularParamLabel}
                      </FormLabel>
                    )}
                    {reactSelectComponent}
                  </FormGroup>
                );
              }}
            </Field>
          </div>
        );

        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('extracurricular.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'start_date'}
                    label={t('extracurricular.START_DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'end_date'}
                    label={t('extracurricular.END_DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  {extracurricularCreatableSelect}
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../extracurricular/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onDelete={onDelete}
                  afterDelete={afterDelete}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ExtracurricularPage;
