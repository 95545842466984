import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Home } from '../../typings/api-types';

type HomeSponsorProps = {
  home: Home;
};
export const HomeSponsor = (props: HomeSponsorProps) => {
  return (
    <div>
      <Alert>Nothing here yet!</Alert>
    </div>
  );
};

export default HomeSponsor;
export type { HomeSponsorProps };
