import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Home } from '../../typings/api-types';

type HomePhysicalProps = {
  home: Home;
};
export const HomePhysical = (props: HomePhysicalProps) => {
  return (
    <div>
      <Alert>Nothing here yet!</Alert>
    </div>
  );
};

export default HomePhysical;
export type { HomePhysicalProps };
