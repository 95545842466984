// Straight up copied from https://github.com/JedWatson/react-select/issues/3856#issuecomment-744746113
import React from 'react';
import { components } from 'react-select';
import DefaultTooltip from '../DefaultTooltip/DefaultTooltip';

// @ts-ignore
const SelectLimitedChipsContainer = ({ children, hasValue, ...props }) => {
  if (!hasValue) {
    // @ts-ignore
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const CHIPS_LIMIT = 2;
  const [chips, otherChildren] = children;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;
  const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);

  // HACKY! Would love to pass this in as a prop
  let extractedNames = '';
  if (chips) {
    extractedNames = chips
      .map((chip: JSX.Element) => {
        const keyString = '' + chip.key;
        if (keyString) {
          const lastIndex = keyString.lastIndexOf('-');
          return keyString.substring(0, lastIndex);
        } else {
          return 'UNKNOWN';
        }
      })
      .join('\n');
  }

  const tooltipText = 'All notified:\n\n' + extractedNames;

  return (
    // @ts-ignore
    <components.ValueContainer {...props}>
      {displayChips}

      {/* TODO does this need a more unique id if we use more than one on a page? */}
      <DefaultTooltip id={'limited-chips-tooltip'} tooltipText={tooltipText}>
        <span>{overflowCounter > 0 && `+ ${overflowCounter}`}</span>
      </DefaultTooltip>

      {otherChildren}
    </components.ValueContainer>
  );
};

export default SelectLimitedChipsContainer;
