import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { School } from '../typings/api-types';
import { SchoolPatch, SchoolPost } from '../typings/types-to-send';

const SCHOOL_REST_URL = '/school';

const useGetSchools = (enabled?: boolean) => {
  const requestConfig = {} as AxiosRequestConfig;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetSchools', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }

      const response = await axios.get<School[]>(SCHOOL_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetSchoolDetail = (id: number | undefined, enabled?: boolean) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetSchoolDetail', requestConfig, id],
    async () => {
      const response = await axios.get<School>(SCHOOL_REST_URL + '/' + id + '/', requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const postSchool = (school: SchoolPost) => {
  return axios.post<SchoolPost, AxiosResponse<School>>(SCHOOL_REST_URL + '/', school);
};

const patchSchool = (id: number | undefined, data: SchoolPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<SchoolPatch, AxiosResponse<School>>(SCHOOL_REST_URL + '/' + id + '/', data);
};

function createSchoolOptions(schoolData: undefined | School[]) {
  if (!schoolData) {
    return [];
  } else {
    return schoolData.map((school) => {
      return {
        label: school.name + ' (' + school.country + ')',
        value: school.id,
      };
    });
  }
}

// No delete for now

export { useGetSchools, useGetSchoolDetail, postSchool, patchSchool, createSchoolOptions };
