import * as React from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Home } from '../../typings/api-types';
import DueChildTrainingSkillIcon from '../../skill/DueChildTrainingSkillIcon/DueChildTrainingSkillIcon';
import styles from '../../home/HomeEducation/HomeEducation.module.scss';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useGetHomeEducation } from '../../api/home_api';
import HomeSkillsList from '../HomeSkillsList/HomeSkillsList';

type HomeEducationProps = {
  home: Home;
};
export const HomeEducation = (props: HomeEducationProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetHomeEducation(props.home.id!);

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return <div>Error loading {props.home.id}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.home.id}</div>;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label column>{t('home.education.INCOMPLETE_CHILD_TRAININGS')}</Form.Label>
            {data && data.home_training_skills_due_count > 0 && (
              <DueChildTrainingSkillIcon
                className={styles.dueTrainingIcon}
                numberDue={data.home_training_skills_due_count}
              />
            )}
          </Form.Group>
          <HomeSkillsList
            emptyListLabel={t('home.education.NO_REQUIRED_SKILLS')}
            skills={data.required_home_training_skills}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label column>{t('home.education.COMPLETED_CHILD_TRAININGS')}</Form.Label>
          </Form.Group>
          <HomeSkillsList
            emptyListLabel={t('home.education.NO_COMPLETED_SKILLS')}
            skills={data.completed_home_training_skills}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/home-child-training/create/new',
              search: '?homeId=' + props.home.id,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'home'} /> {t('childTraining.ADD_HOME_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeEducation;
export type { HomeEducationProps };
