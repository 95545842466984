import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';

type ChildGridActionProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: any;
};

const ChildGridActions = (props: ChildGridActionProps) => {
  return (
    <LinkContainer to={'/child/' + props.data.id + '/overview/view'}>
      <Button variant="outline-primary" size="sm">
        <FontAwesomeIcon icon={'eye'} />
      </Button>
    </LinkContainer>
  );
};

export default ChildGridActions;
