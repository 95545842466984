import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { patchChildGradeReport, postChildGradeReport, useGetChildGradeReportDetail } from '../../api/grade_report_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildGradeReportPatch, ChildGradeReportPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, {
  FormikSetFunction,
  GenericOption,
  OnChangeValueSingle,
} from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  getHomeIdFromChildId,
  setHomeOnChildChange,
  setSchoolOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import {
  createLetterOptions,
  doesGradingTypeHaveGpa,
  doesGradingTypeHaveLetter,
  doesGradingTypeHavePercent,
} from '../gradingScaleHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import { School, SimpleChildSummary } from '../../typings/api-types';
import { useGetSchools } from '../../api/school_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type ChildGradeReportProps = {};

const DOMAIN_URL = 'child-grade-report';
const DOMAIN = Domain.CHILD_GRADE_REPORT;
const CHILD_TAB = 'education';


function yupRequiredIfAllFalsy(fieldsToLookAt: any[], schema: any, school?: School) {
  let valueOfAll = false;
  fieldsToLookAt.forEach((fieldToLookAt) => {
    valueOfAll = valueOfAll || fieldToLookAt;
  });
  if (valueOfAll || school?.grading_scale_type === 'NO_OVERALL_GRADE') {
    return schema.nullable().optional();
  } else {
    return schema.required('One of these values is required');
  }
}

const getSchema = (school: School | undefined) => {
  let builder = (fieldsToLookAt: any[], schema: any) => yupRequiredIfAllFalsy(fieldsToLookAt, schema, school);
  return Yup.object().shape(
    {
      home: Yup.number().required('Required'),
      date: Yup.date().required('Required'),

      child: Yup.number().required('Required'),

      // Set on child load
      school: Yup.number().required('Required'),

      received_percentage: Yup.number().min(0).max(100).when(['received_gpa', 'received_letter'],
        builder),
      received_letter: Yup.string().when(['received_percentage', 'received_gpa'], builder),
      received_gpa: Yup.number().min(0).max(10).when(['received_percentage', 'received_letter'], builder),

      // Files - Sent in separate request
      original_scan: Yup.mixed().nullable().optional(),
    },
    // https://github.com/jquense/yup/issues/176#issuecomment-367524410
    [
      ['received_percentage', 'received_letter'],
      ['received_letter', 'received_gpa'],
      ['received_percentage', 'received_gpa'],
    ],
  );
};

function getBlankChildGradeReport(): ChildGradeReportPost {
  return {
    home: undefined,
    date: '',

    child: undefined,
    school: undefined,

    received_percentage: undefined,
    received_letter: '',
    received_gpa: undefined,

    original_scan: undefined,
  };
}

const ChildGradeReportPage = (props: ChildGradeReportProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const { data: schoolData, isLoading: isSchoolsLoading } = useGetSchools();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const [letterOptions, setLetterOptions] = useState<GenericOption[] | undefined>(undefined);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let foundChildSummary: SimpleChildSummary | undefined = undefined;
  if (childData) {
    foundChildSummary = childData.find((child) => child.id === childId);
  }

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildGradeReportDetail(
    id,
    undefined,
    undefined,
    mode !== Mode.CREATE && hasNumberId,
  );

  const school = schoolData?.find((school: School) => {
    return school.id === foundChildSummary?.current_school_id;
  });

  const schema = useMemo(() => getSchema(school), [
    school
  ]);

  if (isLoadingPermissions) {
    return <Spinner animation='border' role='status' />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let gradeReport: ChildGradeReportPost | ChildGradeReportPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    gradeReport = getBlankChildGradeReport();
    if (isChildrenLoading) {
      return <Spinner animation='border' role='status' />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      gradeReport.home = homeId;
      gradeReport.child = childId;
    }
    if (foundChildSummary) {
      gradeReport.school = foundChildSummary.current_school_id;
      gradeReport.school_name = foundChildSummary.current_school_name;
      gradeReport.grading_scale_type = foundChildSummary.current_school_grading_type;
    }
    onSubmit = async (dataToSubmit: ChildGradeReportPost, { setSubmitting }: any) => {
      try {
        await postChildGradeReport(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading || isChildrenLoading || isSchoolsLoading) {
      return <Spinner animation='border' role='status' />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    gradeReport = data as ChildGradeReportPatch;

    if (gradeReport.school && schoolData && !letterOptions) {
      const letterOptions = createLetterOptions(Number(gradeReport.school), schoolData);
      setLetterOptions(letterOptions);
    }

    onSubmit = async (dataToSubmit: ChildGradeReportPatch, { setSubmitting }: any) => {
      try {
        await patchChildGradeReport(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(gradeReport);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
    setSchoolOnChildChange(newChild, childData, formikSetFieldValue);

    if (newChild) {
      const child_id = newChild.value;
      if (childData) {
        const child = childData.find((child: SimpleChildSummary) => {
          return child.id === child_id;
        });
        if (child) {
          const letterOptions = createLetterOptions(child.current_school_id, schoolData);
          setLetterOptions(letterOptions);
        }
      }
    }
  };

  return (
    <Formik initialValues={gradeReport} validationSchema={schema} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildGradeReportPost | ChildGradeReportPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className='text-center font-weight-bold'>{t('gradeReport.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />

                  <br />
                  {formikProps.values.child && (
                    <span>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'school_name'}
                        label={t('gradeReport.SCHOOL_NAME')}
                        // Always read-only, using component for easy display style
                        isReadOnly={true}
                        labelWidth={4}
                        showSideBySide={true}
                      />
                      {doesGradingTypeHavePercent(formikProps.values) && (
                        <FormikTextInput
                          formikProps={formikProps}
                          name={'received_percentage'}
                          label={t('gradeReport.RECEIVED_PERCENTAGE')}
                          isReadOnly={mode === Mode.VIEW}
                          labelWidth={4}
                          showSideBySide={true}
                        />
                      )}
                      {!doesGradingTypeHavePercent(formikProps.values) && (
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>
                          {t('gradeReport.DOES_NOT_SEND_PERCENTAGE')}
                        </div>
                      )}
                      {doesGradingTypeHaveLetter(formikProps.values) && letterOptions && (
                        <FormikSelect
                          name={'received_letter'}
                          label={t('gradeReport.RECEIVED_LETTER')}
                          options={letterOptions}
                          isReadOnly={mode === Mode.VIEW}
                          labelWidth={4}
                          showSideBySide={true}
                        />
                      )}
                      {!doesGradingTypeHaveLetter(formikProps.values) && (
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>
                          {t('gradeReport.DOES_NOT_SEND_LETTER')}
                        </div>
                      )}
                      {doesGradingTypeHaveGpa(formikProps.values) && (
                        <FormikTextInput
                          formikProps={formikProps}
                          name={'received_gpa'}
                          label={t('gradeReport.RECEIVED_GPA')}
                          isReadOnly={mode === Mode.VIEW}
                          labelWidth={4}
                          showSideBySide={true}
                        />
                      )}
                      {!doesGradingTypeHaveGpa(formikProps.values) && (
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>{t('gradeReport.DOES_NOT_SEND_GPA')}</div>
                      )}
                      <br />
                      <FormikSinglePatchFileUpload
                        name={'original_scan'}
                        label={t('gradeReport.ORIGINAL_SCAN')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={4}
                        showSideBySide={true}
                      />
                    </span>
                  )}
                  {!formikProps.values.child && <div>{t('gradeReport.PICK_CHILD')}</div>}
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-grade-report/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildGradeReportPage;
