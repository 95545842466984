import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildDoctorAppointment } from '../typings/api-types';
import {
  ChildDoctorAppointmentPatch,
  ChildDoctorAppointmentPost,
  HomeDoctorAppointmentPost,
} from '../typings/types-to-send';

const CHILD_DOCTOR_APPOINTMENT_REST_URL = '/child-doctor-appointment';
const HOME_DOCTOR_APPOINTMENT_REST_URL = '/home-doctor-appointment';
const CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL = '/child-doctor-appointment-attachment';

const useGetChildDoctorAppointments = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildDoctorAppointments', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildDoctorAppointment[]>(CHILD_DOCTOR_APPOINTMENT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildDoctorAppointmentDetail = (
  id: number | undefined,
  params?: any,
  onSuccess?: any,
  enabled?: boolean,
) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildDoctorAppointments', requestConfig, id],
    () => {
      return axios.get<ChildDoctorAppointment>(CHILD_DOCTOR_APPOINTMENT_REST_URL + '/' + id, requestConfig);
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const patchChildDoctorAppointment = (id: number | undefined, data: ChildDoctorAppointmentPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<ChildDoctorAppointmentPatch>(CHILD_DOCTOR_APPOINTMENT_REST_URL + '/' + id + '/', data);
};

const postChildDoctorAppointment = (data: ChildDoctorAppointmentPost) => {
  return axios.post<ChildDoctorAppointmentPost, AxiosResponse<ChildDoctorAppointment>>(
    CHILD_DOCTOR_APPOINTMENT_REST_URL + '/',
    data,
  );
};

const postHomeDoctorAppointment = (data: HomeDoctorAppointmentPost) => {
  return axios.post<HomeDoctorAppointmentPost, AxiosResponse<ChildDoctorAppointment>[]>(
    HOME_DOCTOR_APPOINTMENT_REST_URL + '/',
    data,
  );
};

const deleteChildDoctorAppointment = (id: number) => {
  return axios.delete(CHILD_DOCTOR_APPOINTMENT_REST_URL + '/' + id + '/');
};

export {
  useGetChildDoctorAppointments,
  useGetChildDoctorAppointmentDetail,
  patchChildDoctorAppointment,
  postChildDoctorAppointment,
  postHomeDoctorAppointment,
  deleteChildDoctorAppointment,
  CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL,
};
