import React from 'react';
import { ChildDoctorAppointment } from '../../typings/api-types';
import { deleteChildDoctorAppointment, useGetChildDoctorAppointments } from '../../api/doctor_appointment_api';
import GridActions from '../../shared/grid/GridActions/GridActions';
import DoctorAppointmentSummary from '../DoctorAppointmentSummary/DoctorAppointmentSummary';
import { Domain } from '../../api/permissions_api';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type DoctorAppointmentSearchProps = {};

const getColumns = (
  onDelete: (objectToDelete: ChildDoctorAppointment) => Promise<any>,
): ColDef<ChildDoctorAppointment>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      width: 150,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 90,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 90,
    },
    {
      field: 'summary',
      headerName: t('doctorAppointment.SUMMARY'),
      cellRenderer: DoctorAppointmentSummary,
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-doctor-appointment',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchDoctorAppointmentPage = (props: DoctorAppointmentSearchProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'doctor-appointment'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_DOCTOR_APPOINTMENT}
      translatePrefix={'doctorAppointment.'}
      useGetListApiHook={useGetChildDoctorAppointments}
      deleteApiFunc={deleteChildDoctorAppointment}
      createForChildPageUrl={'/child-doctor-appointment/create/new'}
      createForHomePageUrl={'/home-doctor-appointment/create/new'}
      childButtonVariant={'outline-danger'}
      homeButtonVariant={'primary'}
    />
  );
};

export default SearchDoctorAppointmentPage;
