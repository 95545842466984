import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { GuidanceActivity } from '../typings/api-types';
import { GuidanceActivityPost } from '../typings/types-to-send';

const GUIDANCE_ACTIVITY_REST_URL = '/guidance-activity';

function createGuidanceActivityOptions(activityData: undefined | AxiosResponse<GuidanceActivity[]>) {
  if (!activityData || !activityData.data) {
    return [];
  } else {
    return activityData.data.map((guidanceActivity) => {
      return {
        label: guidanceActivity.name,
        value: guidanceActivity.id,
      };
    });
  }
}

const useGetGuidanceActivities = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  // TODO investigate this rule
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetGuidanceActivities', requestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      return axios.get<GuidanceActivity[]>(GUIDANCE_ACTIVITY_REST_URL, requestConfig);
    },
    {
      enabled,
    },
  );
};

const postGuidanceActivity = (guidanceActivity: GuidanceActivityPost) => {
  return axios.post<GuidanceActivityPost, AxiosResponse<GuidanceActivity>>(
    GUIDANCE_ACTIVITY_REST_URL + '/',
    guidanceActivity,
  );
};

export { useGetGuidanceActivities, createGuidanceActivityOptions, postGuidanceActivity };
