import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { patchChild, useGetChildOverviewSponsor } from '../../api/child_api';
import styles from '../../sponsorship/SearchSponsorshipPage/SearchSponsorshipPage.module.scss';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import { ChildDetail } from '../../typings/api-types';
import { ChildDetailPatch } from '../../typings/types-to-send';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';
import { LinkContainer } from 'react-router-bootstrap';
import GridActions from '../../shared/grid/GridActions/GridActions';

type ChildSponsorshipProps = {
  childId: number;
  child: ChildDetail;
  refetchChild: any;
};

const CHILD_SPONSOR_FORM_SCHEMA = Yup.object().shape({
  can_be_sponsored: Yup.boolean().required('Required'),
});

function getSponsorshipColumnDefs(
  // onDelete: (objectToDelete: ChildDoctorAppointment) => Promise<any>,
  childId: number,
): ColDef[] {
  const t = i18next.t;
  return [
    {
      field: 'sponsor_name',
      headerName: t('sponsorship.SPONSOR_NAME'),
      flex: 1,
    },
    {
      field: 'sponsor_email',
      headerName: t('sponsorship.SPONSOR_EMAIL'),
      flex: 1,
    },
    // {
    //   field: 'amount_in_dollars',
    //   headerName: t('sponsorship.AMOUNT_IN_DOLLARS'),
    //   valueFormatter: (params) => {
    //     return '$ ' + params.value;
    //   },
    //   width: 90,
    // },
    {
      field: 'frequency',
      headerName: t('sponsorship.FREQUENCY'),
      width: 90,
    },
    {
      field: 'status',
      headerName: t('sponsorship.STATUS'),
      cellStyle: (params) => {
        const style = {
          textTransform: 'capitalize',
          color: '',
        };
        if (params.value === 'active') {
          style.color = 'green';
        }
        return style;
      },
      width: 90,
    },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/sponsorship',
        disableDelete: true
        // onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
}

const ChildSponsor = (props: ChildSponsorshipProps) => {
  let { mode } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetChildOverviewSponsor(props.childId);
  const [serverError, setServerError] = useState(false);

  // No permission domain because (for now) sponsorship is not saved in the DB
  //  the tab permission should be enough anyway
  // const sponsorshipPermissionDomain = Domain.SPONSORSHIP;

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return <div>Error loading {props.childId}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.childId}</div>;
  }

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../sponsorship/view');
  }

  async function onSubmit(dataWithFiles: ChildDetailPatch, { setSubmitting }: any) {
    if (props.child && props.child.id) {
      try {
        await patchChild(props.child.id, dataWithFiles);
        await props.refetchChild();
        setSubmitting(false);
        navigate('../sponsorship/view');
      } catch (error) {
        setServerError(true);
      }
    } else {
      // LOG SOMETHING?
    }
  }

  if (serverError) {
    return <ErrorPage />;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Formik
        // Without enableReinitialization on the Formik tag,
        // opening edit again and cancelling will discard your SAVED changes\
        // Previously I used navigate(0); here to fix that
        // https://stackoverflow.com/a/73181059/13815107
        enableReinitialize
        initialValues={props.child}
        validationSchema={CHILD_SPONSOR_FORM_SCHEMA}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<any>) => {
          return (
            <FormikForm>
              <Row>
                <Col sm={{ span: 2, order: 1 }} xs={2}>
                  {mode === 'view' && (
                    <Link to="../sponsorship/edit">
                      <Button variant={'outline-primary'}>
                        <FontAwesomeIcon icon={'pencil'} />
                      </Button>
                    </Link>
                  )}
                  {mode === 'edit' && (
                    <Button variant={'outline-secondary'} onClick={onCancel.bind(null, formikProps.resetForm)}>
                      <FontAwesomeIcon icon={'close'} />
                    </Button>
                  )}
                </Col>
                <Col sm={{ span: 10, order: 0 }}>
                  <FormikSelect
                    label={t('child.sponsor.CAN_BE_SPONSORED')}
                    name='can_be_sponsored'
                    options={BOOLEAN_OPTIONS}
                    showSideBySide={true}
                    labelWidth={4}
                    isReadOnly={mode === 'view'}
                  />
                  <Form.Group>
                    <Form.Label column sm={4}>
                      {t('child.sponsor.SPONSORSHIPS')}
                    </Form.Label>
                  </Form.Group>
                  <div
                    className='ag-theme-balham'
                    style={{
                      height: '400px',
                      width: '100%',
                    }}
                  >
                    <AgGridReact
                      rowData={data.sponsorships}
                      defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                      columnDefs={getSponsorshipColumnDefs(props.childId)}
                      getRowId={(params) => params.data.id}
                      rowSelection={'single'}
                      animateRows={true}
                      getRowClass={(params) => {
                        if (params.data.status === 'active') {
                          return '';
                        } else {
                          return styles.cancelled;
                        }
                      }}
                    />
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <LinkContainer
                      to={{
                        pathname: '/sponsorship/create/new',
                        search: '?childId=' + props.childId,
                      }}
                    >
                      <Button>
                        {' '}
                        + <FontAwesomeIcon icon={'child'} /> {t('sponsorship.ADD_CHILD_BUTTON')}
                      </Button>
                    </LinkContainer>
                  </div>
                </Col>
              </Row>
              {mode === 'edit' && (
                <Row>
                  <Col lg={10}>
                    {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                    <div className='d-flex justify-content-end'>
                      <Button
                        className='mx-2 my-2'
                        variant='secondary'
                        onClick={onCancel.bind(null, formikProps.resetForm)}
                      >
                        {t('common.CANCEL')}
                      </Button>
                      <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                        {t('common.SAVE_CHANGES')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ChildSponsor;
