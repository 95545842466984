import { ColumnMenuTab, SuppressKeyboardEventParams } from 'ag-grid-community';

/** By Default, AgGrid captures tabs to move one cell left or right
 *   and captures pastes to auto-open its own cell-editing mode
 *   this reverts back to the browser default of next-input tabbing
 */
function suppressDataEntryEvents({ event }: SuppressKeyboardEventParams<any>) {
  const { key, ctrlKey } = event;
  const isTab = key === 'Tab';
  const isPaste = key === 'v' && ctrlKey;
  const isSelectAll = key === 'a' && ctrlKey;
  let suppressEvent = isTab || isPaste || isSelectAll;
  return suppressEvent;
}

function getDefaultColDef() {
  // 2022-18-07 For OD Kirk, Austin, Brad decided that pinning, column toggles, etc are too complex.
  //  instead, only show the filter menu to have the least amount of confusion.
  // 'filterMenuTab' | 'generalMenuTab' | 'columnsMenuTab'
  const menuTabs: ColumnMenuTab[] = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];
  return {
    tooltipComponent: 'customTooltip',
    initialWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    pinned: null, // important - clears pinned if not specified in col def
    sort: null, // important - clears sort if not specified in col def
    menuTabs: menuTabs,
    suppressKeyboardEvent: suppressDataEntryEvents,
  };
}

function isAggregate(params: any) {
  // TODO there has to be an API for this, right?
  return !params.data;
}

export { getDefaultColDef, isAggregate, suppressDataEntryEvents };
