import * as React from 'react';
import { ChildTransitionProject } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import styles from './TransitionProjectList.module.scss';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { replaceNewlinesWithBreaks } from '../../shared/helpers/formattingHelper';
import { Col, Row } from 'react-bootstrap';

type TransitionProjectListProps = {
  transitionProjects: ChildTransitionProject[];
};
export const TransitionProjectList = (props: TransitionProjectListProps) => {
  const { t } = useTranslation();
  if (props.transitionProjects.length === 0) {
    return <p className={styles.noTransitionProjects}>{t('transitionProject.NO_TRANSITION_PROJECTS')}</p>;
  } else {
    return (
      <div>
        {props.transitionProjects.map((transitionProject) => {
          return (
            <Row className={'pb-2'}>
              <Col xs={3}>
                <span className={'ps-2 fst-italic fw-semibold'}>{transitionProject.project_name} </span>
              </Col>
              <Col xs={9}>
                {replaceNewlinesWithBreaks(transitionProject.status_text)}{' '}
                <DefaultTooltip placement={'top'} id={'edit-transition-project-tooltip'} tooltipText={t('common.EDIT')}>
                  <Link
                    className={styles.editLink}
                    to={'/transition-project/edit/' + transitionProject.id + '/?childId=' + transitionProject.child}
                  >
                    <FontAwesomeIcon icon={'pencil'} />
                  </Link>
                </DefaultTooltip>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
};

export default TransitionProjectList;
export type { TransitionProjectListProps };
