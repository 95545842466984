import React from 'react';
import { Vaccination } from '../../typings/api-types';
import { deleteVaccination, useGetVaccinations } from '../../api/vaccination_api';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import VaccinationDateReceivedCell from '../VaccinationDateReceivedCell/VaccinationDateReceivedCell';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type VaccinationSearchProps = {};

const getColumns = (onDelete: (objectToDelete: Vaccination) => Promise<any>): ColDef<Vaccination>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      flex: 1,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 130,
    },
    {
      field: 'vaccine_name',
      headerName: t('vaccination.VACCINE'),
      width: 150,
    },
    {
      field: 'dose_number',
      headerName: t('vaccination.DOSE_NUMBER'),
      width: 80,
    },
    {
      field: 'recommended_date',
      headerName: t('vaccination.RECOMMENDED_DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 140,
    },
    {
      field: 'date_received',
      headerName: t('vaccination.DATE_RECEIVED'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 140,
    },
    {
      field: 'is_skipped',
      headerName: t('vaccination.IS_SKIPPED'),
      cellRenderer: VaccinationDateReceivedCell,
      width: 150,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/vaccination',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchVaccinationPage = (props: VaccinationSearchProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'vaccination'}
      getColumns={getColumns}
      permissionDomain={Domain.VACCINATION}
      translatePrefix={'vaccination.'}
      useGetListApiHook={useGetVaccinations}
      deleteApiFunc={deleteVaccination}
      createForChildPageUrl={'/vaccination/create/new'}
    />
  );
};

export default SearchVaccinationPage;
