import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildPhoto } from '../typings/api-types';
import { ChildPhotoPatch, ChildPhotoPost, ChildPhotoWithId } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const CHILD_PHOTO_REST_URL = '/child-photo';

const useGetChildPhotos = (childId: number) => {
  const requestConfig = {
    params: {
      child_id: childId,
    },
  } as AxiosRequestConfig;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(['useGetChildPhotos', requestConfig], async () => {
    // If the grid isn't ready, this won't work,
    //  but ag grid defaults to showing loading overlay initially anyway
    // if (gridApi) {
    //   gridApi.showLoadingOverlay();
    // }

    const response = await axios.get<ChildPhotoWithId[]>(CHILD_PHOTO_REST_URL, requestConfig);
    return response.data;
  });
};

const patchChildPhoto = (id: number | undefined, dataWithFiles: ChildPhotoPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const photo = dataWithFiles.photo;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.photo;

  return axios
    .patch<ChildPhotoPatch, AxiosResponse<ChildPhoto>>(CHILD_PHOTO_REST_URL + '/' + id + '/', dataWithoutFiles)
    .then((response) => {
      dataWithoutFiles.photo = photo;
      return updateFiles(response, photo);
    });
};

const postChildPhoto = (dataWithFiles: ChildPhotoPost) => {
  const rawImage = dataWithFiles.photo;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.photo;
  return axios
    .post<ChildPhotoPost, AxiosResponse<ChildPhoto>>(CHILD_PHOTO_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, rawImage);
    });
};

function updateFiles(response: AxiosResponse<ChildPhoto>, rawImage: any) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  if (!isFileObject(rawImage)) {
    return response;
  }
  console.debug('Got what looks like a file in updateFiles, sending to server');
  const fileFieldsToReplace = {
    photo: rawImage,
  };
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios
    .patch<FormData, AxiosResponse<ChildPhoto>>(CHILD_PHOTO_REST_URL + '/' + id + '/', formData)
    .then((response) => {
      console.log('child photo image saved');
      return response;
    });
}

const deleteChildPhoto = (idToDelete: number) => {
  return axios.delete(CHILD_PHOTO_REST_URL + '/' + idToDelete + '/');
};

export { useGetChildPhotos, postChildPhoto, patchChildPhoto, deleteChildPhoto };
