import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildSeeResult } from '../typings/api-types';
import { ChildSeeResultPatch, ChildSeeResultPost } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const SEE_RESULT_REST_URL = '/child-see-result';

const useGetChildSeeResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildSeeResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildSeeResult[]>(SEE_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildSeeResultDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckins', requestConfig, id],
    async () => {
      const response = await axios.get<ChildSeeResult>(SEE_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchChildSeeResult = (id: number | undefined, dataWithFiles: ChildSeeResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);

  // Send nulls for files to backend on first patch,
  // All new files do on a separate patch
  // Sending null as a multi-part-form doesn't work
  if (dataWithFiles.original_scan !== null) {
    delete dataWithoutFiles.original_scan;
  }

  return axios
    .patch<ChildSeeResultPatch, AxiosResponse<ChildSeeResult>>(SEE_RESULT_REST_URL + '/' + id + '/', dataWithoutFiles)
    .then((response) => {
      dataWithoutFiles.original_scan = originalScan;
      return updateFiles(response, originalScan);
    });
};

const postChildSeeResult = (dataWithFiles: ChildSeeResultPost) => {
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.original_scan;
  return axios
    .post<ChildSeeResultPost, AxiosResponse<ChildSeeResult>>(SEE_RESULT_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, originalScan);
    });
};

// THERE IS NO HOME ENDPOINT!
// Lazy way, just loop through every child and send to the backend
// TODO do this more efficiently with less calls
// const postHomeSeeResult = (dataWithFiles: HomeSeeResultPost) => {
//   const children = dataWithFiles.child_results;
//   if (children) {
//     const promises = children
//       .filter((child) => child.child_was_present)
//       .map((childResult) => {
//         const childCheckIn: ChildSeeResultPost = {
//           date: dataWithFiles.date,
//           home: dataWithFiles.home,
//           child: childResult.child,
//
//           raw_score: childResult.raw_score,
//
//           original_scan: childResult.original_scan,
//         };
//         return postChildSeeResult(childCheckIn);
//       });
//     return Promise.all(promises).then(() => console.log('All see results in promises finished'));
//   } else {
//     return Promise.resolve();
//   }
// };

function updateFiles(response: AxiosResponse<ChildSeeResult>, originalScan: any) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  if (!isFileObject(originalScan)) {
    return response;
  }
  console.debug('Got what looks like a file in updateFiles, sending to server');
  const fileFieldsToReplace = {
    original_scan: originalScan,
  };
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios
    .patch<FormData, AxiosResponse<ChildSeeResult>>(SEE_RESULT_REST_URL + '/' + id + '/', formData)
    .then((response) => {
      console.log('see result in scan saved');
      return response;
    });
}

const deleteChildSeeResult = (id: number) => {
  return axios.delete(SEE_RESULT_REST_URL + '/' + id + '/');
};

export {
  useGetChildSeeResults,
  useGetChildSeeResultDetail,
  patchChildSeeResult,
  postChildSeeResult,
  deleteChildSeeResult,
  // postHomeSeeResult,
};
