import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildGradeReport } from '../../typings/api-types';
import styles from './ReadOnlyChildGradeCell.module.scss';

type ReadOnlyChildGradeCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildGradeReport;
  };
export const ReadOnlyChildGradeCell = (props: ReadOnlyChildGradeCellProps) => {
  const { t } = useTranslation();

  let letter = props.data.received_letter;
  if (!letter) {
    letter = props.data.grading_scale_level?.letter_label;
  }

  // 0 is falsy so we need long checks
  const has_received_percentage =
    props.data.received_percentage !== undefined && props.data.received_percentage !== null;

  return (
    <div className={styles.container}>
      {has_received_percentage && <span className={styles.percent}>{props.data.received_percentage} %</span>}

      {letter && (
        <span className={styles.letter}>
          <span className={'pe-2'}>{letter}</span>
          {props.data.grading_scale_level?.min_percent !== undefined &&
            props.data.grading_scale_level?.min_percent !== null &&
            props.data.grading_scale_level.min_percent >= 0 &&
            props.data.grading_scale_level.max_percent >= 0 && (
              <span>
                ({props.data.grading_scale_level.min_percent}% - {props.data.grading_scale_level.max_percent}%)
              </span>
            )}
        </span>
      )}

      {/* Explicit checks instead of falsy because gpa can be 0 */}
      {props.data.gpa !== undefined && props.data.gpa !== null && (
        <span className={styles.gpa}>
          {t('gradeReport.GPA')} <span style={{ fontWeight: 'bold' }}>{props.data.gpa}</span> / 4
        </span>
      )}

      {!has_received_percentage && !letter && !props.data.gpa &&
        <span className={styles.gpa}>No Grade</span>}
    </div>
  );
};

export default ReadOnlyChildGradeCell;
export type { ReadOnlyChildGradeCellProps };
