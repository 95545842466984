import * as React from 'react';
import { ChildExtracurricular } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import styles from './ExtracurricularList.module.scss';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ExtracurricularListProps = {
  extracurriculars: ChildExtracurricular[];
};
export const ExtracurricularList = (props: ExtracurricularListProps) => {
  const { t } = useTranslation();
  if (props.extracurriculars.length === 0) {
    return <p className={styles.noExtracurriculars}>{t('extracurricular.NO_EXTRACURRICULARS')}</p>;
  } else {
    return (
      <div>
        {props.extracurriculars.map((extracurricular) => {
          const isEnded = !!extracurricular.end_date;
          let endDate = isoDateToCommonFormat(extracurricular.end_date);
          if (!isEnded) {
            endDate = 'Present';
          }
          return (
            <div className={isEnded ? styles.ended : ''}>
              {extracurricular.extracurricular_name} ({isoDateToCommonFormat(extracurricular.start_date)} - {endDate}){' '}
              <DefaultTooltip placement={'top'} id={'edit-extracurricular-tooltip'} tooltipText={t('common.EDIT')}>
                <Link
                  className={styles.editLink}
                  to={'/extracurricular/edit/' + extracurricular.id + '/?childId=' + extracurricular.child}
                >
                  <FontAwesomeIcon icon={'pencil'} />
                </Link>
              </DefaultTooltip>
            </div>
          );
        })}
      </div>
    );
  }
};

export default ExtracurricularList;
export type { ExtracurricularListProps };
