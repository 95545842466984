import { AgChartsReact } from 'ag-charts-react';
import React, { useState } from 'react';
import { ChildBmiCheck } from '../../typings/api-types';
import { AgChartOptions, AgNodeClickEvent, time } from 'ag-charts-community';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { roundTo2Decimals, roundTo4Decimals } from '../../shared/helpers/roundingHelper';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';

type ChildPhysicalBmiZscoreChartProps = {
  bmiChecks: ChildBmiCheck[];
};

type JsDate = {
  js_date: any;
};

const ChildPhysicalBmiZscoreChart = (props: ChildPhysicalBmiZscoreChartProps) => {
  const checksWithJsDates = props.bmiChecks.map((bmiCheck) => {
    const clonedCheck = _.clone(bmiCheck) as ChildBmiCheck & JsDate;
    clonedCheck.js_date = new Date(bmiCheck.date);
    // Make sure extreme dots still appear
    // Probably data entry problems that should be fixed
    if (clonedCheck.zscore > 3) {
      clonedCheck.zscore = 3;
    }
    if (clonedCheck.zscore < -3) {
      clonedCheck.zscore = -3;
    }
    return clonedCheck;
  });
  const navigate = useNavigate();
  const location = useLocation();
  // We do have data for longer than this but it's probably not relevant
  let graphStartDate = new Date(2020, 1, 1);
  let timeTick = {
    // max: date,
    interval: time.month.every(1),
  };
  const [options] = useState<AgChartOptions>({
    autoSize: true,
    data: checksWithJsDates,
    navigator: {
      enabled: true,
      height: 20,
      margin: 5,
      // This will take some fiddling over time to keep around 6 mo. displayed
      min: 0.8,
      max: 1,
    },
    theme: {
      overrides: {
        line: {
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    series: [
      {
        nodeClickRange: 2,
        type: 'line',
        xKey: 'js_date',
        yKey: 'zscore',
        stroke: '#000000',
        marker: {
          stroke: '#000000',
          fill: '#000000',
        },
        tooltip: {
          enabled: true,
          renderer: (params) => {
            const content =
              `Z-score: ${roundTo4Decimals(params.datum.zscore)}<br/>` +
              `BMI: ${roundTo2Decimals(params.datum.bmi)}<br/>` +
              `Height (cm): ${params.datum.height_in_cm}<br/>` +
              `Weight (kg): ${params.datum.weight_in_kgs}<br/>` +
              `Date: ${isoDateToCommonFormat(params.datum.date)}<br/>`;
            return {
              content: content,
            };
          },
        },
        listeners: {
          nodeClick: (params: AgNodeClickEvent<ChildBmiCheck>) => {
            navigate('/home-bmi-check/view/' + params.datum.home_bmi_check, {
              state: { requestingPage: location.pathname },
            });
          },
        },
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'time',
        min: graphStartDate,
        tick: timeTick,
        title: {
          text: 'Date',
        },
        label: {
          autoRotate: true,
          // https://www.ag-grid.com/javascript-charts/axes-labels/#time-formatting
          format: '%b %Y',
        },
      },
      {
        position: 'left',
        type: 'number',
        nice: false,
        min: -3,
        max: 3,
        title: {
          text: 'Z-Score',
        },
        label: {
          autoRotate: true,
        },
      },
    ],
  } as AgChartOptions);
  return <AgChartsReact options={options} />;
};

export default ChildPhysicalBmiZscoreChart;
