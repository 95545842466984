import { ChildGradeReportPost } from '../typings/types-to-send';
import { School } from '../typings/api-types';

function doesGradingTypeHaveGpa(childGradeReport: ChildGradeReportPost) {
  return (
    childGradeReport.grading_scale_type &&
    (childGradeReport.grading_scale_type === 'GPA_ONLY' ||
      childGradeReport.grading_scale_type === 'LETTER_AND_GPA' ||
      childGradeReport.grading_scale_type === 'AVG_PERCENT_AND_GPA' ||
      childGradeReport.grading_scale_type === 'AVE_PERCENT_LETTER_AND_GPA')
  );
}

function doesGradingTypeHaveLetter(childGradeReport: ChildGradeReportPost) {
  return (
    childGradeReport.grading_scale_type &&
    (childGradeReport.grading_scale_type === 'LETTER_AND_GPA' ||
      childGradeReport.grading_scale_type === 'AVG_PERCENT_AND_LETTER' ||
      childGradeReport.grading_scale_type === 'AVE_PERCENT_LETTER_AND_GPA')
  );
}

function doesGradingTypeHavePercent(childGradeReport: ChildGradeReportPost) {
  return (
    childGradeReport.grading_scale_type &&
    (childGradeReport.grading_scale_type === 'AVG_PERCENT_AND_LETTER' ||
      childGradeReport.grading_scale_type === 'AVG_PERCENT_AND_GPA' ||
      childGradeReport.grading_scale_type === 'AVE_PERCENT_LETTER_AND_GPA')
  );
}

function createLetterOptions(currentSchoolId: number, schoolData: undefined | School[]) {
  if (schoolData) {
    const school = schoolData.find((school: School) => {
      return school.id === currentSchoolId;
    });
    if (school && school.grading_scale_levels) {
      return school.grading_scale_levels.map((gradingScaleLevel) => {
        return {
          label:
            gradingScaleLevel.letter_label +
            ' (' +
            gradingScaleLevel.min_percent +
            '% - ' +
            gradingScaleLevel.max_percent +
            '%)',
          value: gradingScaleLevel.letter_label,
        };
      });
    }
  }
  return [];
}

export { doesGradingTypeHaveGpa, doesGradingTypeHaveLetter, doesGradingTypeHavePercent, createLetterOptions };
