import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildWratResult } from '../typings/api-types';
import { ChildWratResultPatch, ChildWratResultPost, HomeWratResultPost } from '../typings/types-to-send';

const WRAT_RESULT_REST_URL = '/child-wrat-result';

const useGetChildWratResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildWratResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildWratResult[]>(WRAT_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildWratResultDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckins', requestConfig, id],
    async () => {
      const response = await axios.get<ChildWratResult>(WRAT_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchChildWratResult = (id: number | undefined, data: ChildWratResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }

  return axios.patch<ChildWratResultPatch, AxiosResponse<ChildWratResult>>(WRAT_RESULT_REST_URL + '/' + id + '/', data);
};

const postChildWratResult = (data: ChildWratResultPost) => {
  return axios.post<ChildWratResultPost, AxiosResponse<ChildWratResult>>(WRAT_RESULT_REST_URL + '/', data);
};

// THERE IS NO HOME ENDPOINT!
// Lazy way, just loop through every child and send to the backend
// TODO do this more efficiently with less calls
const postHomeWratResult = (dataWithFiles: HomeWratResultPost) => {
  const children = dataWithFiles.child_results;
  if (children) {
    const promises = children
      .filter((child) => child.child_was_present)
      .map((childAtCheckIn) => {
        const childCheckIn: ChildWratResultPost = {
          date: dataWithFiles.date,
          home: dataWithFiles.home,
          child: childAtCheckIn.child,
          class_at_time: childAtCheckIn.class_at_time,

          word_reading_raw_score: childAtCheckIn.word_reading_raw_score,
          word_reading_percentage_score: childAtCheckIn.word_reading_percentage_score,
          word_reading_grade_level: childAtCheckIn.word_reading_grade_level,

          spelling_raw_score: childAtCheckIn.spelling_raw_score,
          spelling_percentage_score: childAtCheckIn.spelling_percentage_score,
          spelling_grade_level: childAtCheckIn.spelling_grade_level,

          math_computation_raw_score: childAtCheckIn.math_computation_raw_score,
          math_computation_percentage_score: childAtCheckIn.math_computation_percentage_score,
          math_computation_grade_level: childAtCheckIn.math_computation_grade_level,

          sentence_comprehension_raw_score: childAtCheckIn.sentence_comprehension_raw_score,
          sentence_comprehension_percentage_score: childAtCheckIn.sentence_comprehension_percentage_score,
          sentence_comprehension_grade_level: childAtCheckIn.sentence_comprehension_grade_level,

          reading_composite_raw_score: childAtCheckIn.reading_composite_raw_score,
          reading_composite_percentage_score: childAtCheckIn.reading_composite_percentage_score,
          //  No reading composite percentage score
        };
        return postChildWratResult(childCheckIn);
      });
    return Promise.all(promises).then(() => console.log('All wrat results in promises finished'));
  } else {
    return Promise.resolve();
  }
};

const deleteChildWratResult = (id: number) => {
  return axios.delete(WRAT_RESULT_REST_URL + '/' + id + '/');
};

export {
  useGetChildWratResults,
  useGetChildWratResultDetail,
  patchChildWratResult,
  postChildWratResult,
  postHomeWratResult,
  deleteChildWratResult,
};
