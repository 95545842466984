import * as React from 'react';
import { ChildSponsorship, Sponsorship } from '../../typings/api-types';
import DefaultTooltip, { getCellId } from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { getSponsorshipChildrenNames } from '../SearchSponsorshipPage/SearchSponsorshipPage';

type ChildSponsorshipChildCellProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: ChildSponsorship;
};
export const ChildSponsorshipChildCell = (props: ChildSponsorshipChildCellProps) => {
  const sponsorship: Sponsorship = props.data;
  const children = sponsorship.children;
  let firstChildNameAndHome = '';
  const allChildrenTooltip = getSponsorshipChildrenNames(props.data);
  if (children && children.length > 0) {
    firstChildNameAndHome = children[0].child_summary?.name + ' (' + children[0].child_summary?.public_id + ') - ' + children[0].child_summary?.home_name;
  }
  const externalLinks = (
    <span style={{ marginLeft: 3 }}>
      (
      {props.data.hubspot_link && (
        <a href={props.data.hubspot_link} target="_blank" rel="noreferrer">
          Hubspot
        </a>
      )}
      {props.data.fundraise_up_link && (
        <span>
          ,{' '}
          <a href={props.data.fundraise_up_link} target="_blank" rel="noreferrer">
            Fundraise Up
          </a>
        </span>
      )}
      )
    </span>
  );
  return (
    <span>
      {children && children.length > 0 && (
        <DefaultTooltip id={getCellId(props) + '-sponsorship-child-tooltip'} tooltipText={allChildrenTooltip}>
          <span>
            {firstChildNameAndHome}
            {children.length > 1 && (
              <span
                style={{
                  marginLeft: 3,
                  paddingLeft: 3,
                  paddingRight: 3,
                  background: '#445c8a',
                  borderRadius: 40,
                  color: 'white',
                }}
              >
                {' '}
                +{children.length - 1} more
              </span>
            )}
            {externalLinks}
          </span>
        </DefaultTooltip>
      )}
      {/* If it isn't active, we don't need to worry about fixing it, so don't put it in red */}
      {!children && (
        <span className={props.data.status === 'active' ? 'text-danger' : ''}>
          {props.data.hubspot_designation}
          {externalLinks}
        </span>
      )}
    </span>
  );
};

export default ChildSponsorshipChildCell;
export type { ChildSponsorshipChildCellProps };
