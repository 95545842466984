import React, { ReactNode } from 'react';

function replaceNewlinesWithBreaks(valueWithNewlines: string | undefined | null): ReactNode {
  if (!valueWithNewlines) {
    return null;
  }
  // https://stackoverflow.com/questions/44311109/how-do-i-generate-a-line-break-inside-a-loop-in-react
  const elementsWithBreaks = valueWithNewlines.split(/\r?\n/).map((lineOfValue: string, index: number) => {
    return index === 0 ? (
      <span key={index}>{lineOfValue}</span>
    ) : (
      [<br key={'break' + index} />, <span key={index}>{lineOfValue}</span>]
    );
  });
  return elementsWithBreaks;
}

export { replaceNewlinesWithBreaks };
