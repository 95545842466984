import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { GuidanceSession } from '../typings/api-types';
import { GuidanceSessionPatch, GuidanceSessionPost } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const GUIDANCE_SESSION_REST_URL = '/guidance-session';

const useGetGuidanceSessions = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getGuidanceSessions', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<GuidanceSession[]>(GUIDANCE_SESSION_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetGuidanceSessionDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetGuidanceSessionDetail', requestConfig, id],
    async () => {
      const response = await axios.get<GuidanceSession>(GUIDANCE_SESSION_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchGuidanceSession = (id: number | undefined, dataWithFiles: GuidanceSessionPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const file = dataWithFiles.file;

  const dataWithoutFiles = _.clone(dataWithFiles);

  // Send nulls for files to backend on first patch,
  // All new files do on a separate patch
  // Sending null as a multi-part-form doesn't work
  if (dataWithFiles.file !== null) {
    delete dataWithoutFiles.file;
  }

  return axios
    .patch<GuidanceSessionPatch, AxiosResponse<GuidanceSession>>(
      GUIDANCE_SESSION_REST_URL + '/' + id + '/',
      dataWithoutFiles,
    )
    .then((response) => {
      dataWithoutFiles.file = file;
      return updateFiles(response, file);
    });
};

const postGuidanceSession = (dataWithFiles: GuidanceSessionPost) => {
  const originalScan = dataWithFiles.file;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.file;
  return axios
    .post<GuidanceSessionPost, AxiosResponse<GuidanceSession>>(GUIDANCE_SESSION_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, originalScan);
    });
};

function updateFiles(response: AxiosResponse<GuidanceSession>, originalScan: any) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  if (!isFileObject(originalScan)) {
    return response;
  }
  console.debug('Got what looks like a file in updateFiles, sending to server');
  const fileFieldsToReplace = {
    file: originalScan,
  };
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios
    .patch<FormData, AxiosResponse<GuidanceSession>>(GUIDANCE_SESSION_REST_URL + '/' + id + '/', formData)
    .then((response) => {
      console.log('see result in scan saved');
      return response;
    });
}

const deleteGuidanceSession = (id: number) => {
  return axios.delete(GUIDANCE_SESSION_REST_URL + '/' + id + '/');
};

export {
  useGetGuidanceSessions,
  useGetGuidanceSessionDetail,
  patchGuidanceSession,
  postGuidanceSession,
  deleteGuidanceSession,
};
