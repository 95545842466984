import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Vaccination } from '../typings/api-types';
import { VaccinationPatch, VaccinationPost } from '../typings/types-to-send';

const VACCINATION_REST_URL = '/vaccination';

const useGetVaccinations = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getVaccinations', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<Vaccination[]>(VACCINATION_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetVaccinationDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetVaccinationDetail', requestConfig, id],
    async () => {
      const response = await axios.get<Vaccination>(VACCINATION_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchVaccination = (id: number | undefined, data: VaccinationPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<VaccinationPatch, AxiosResponse<Vaccination>>(VACCINATION_REST_URL + '/' + id + '/', data);
};

const postVaccination = (data: VaccinationPost) => {
  return axios.post<VaccinationPost, AxiosResponse<Vaccination>>(VACCINATION_REST_URL + '/', data);
};

const deleteVaccination = (id: number) => {
  return axios.delete(VACCINATION_REST_URL + '/' + id + '/');
};

export { useGetVaccinations, useGetVaccinationDetail, patchVaccination, postVaccination, deleteVaccination };
