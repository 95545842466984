import React from 'react';
import { Domain } from '../../api/permissions_api';
import { ChildToniResult } from '../../typings/api-types';
import { deleteChildToniResult, useGetChildToniResults } from '../../api/toni_result_api';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import ChildToniScoreCell from '../ChildToniScoreCell/ChildToniScoreCell';
import GridActions from '../../shared/grid/GridActions/GridActions';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchChildToniResultPageProps = {};

const getColumns = (onDelete: (objectToDelete: ChildToniResult) => {}): ColDef<ChildToniResult>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      width: 190,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 190,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('toniResult.SCORE'),
      cellRenderer: ChildToniScoreCell,
      width: 190,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-toni-result',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchChildToniResultPage = (props: SearchChildToniResultPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'toni-result'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_TONI_RESULT}
      translatePrefix={'toniResult.'}
      useGetListApiHook={useGetChildToniResults}
      deleteApiFunc={deleteChildToniResult}
      createForChildPageUrl={'/child-toni-result/create/new'}
      createForHomePageUrl={'/home-toni-result/create/new'}
    />
  );
};

export default SearchChildToniResultPage;
