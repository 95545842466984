import { useTranslation } from 'react-i18next';
import { useGetChildOverviewPhysical } from '../../api/child_api';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import React, { useContext, useRef } from 'react';
import ChildPhysicalNutritionSummary from '../ChildPhysicalNutritionSummary/ChildPhysicalNutritionSummary';
import {
  DEFAULT_DATE_FILTER_PARAMS,
  isMoreThanXMonthsOld,
  isoDateToCommonFormat,
} from '../../shared/helpers/dateHelper';
import styles from './ChildPhysical.module.scss';
import { AgGridReact } from 'ag-grid-react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import DoctorAppointmentSummary from '../../doctorAppointment/DoctorAppointmentSummary/DoctorAppointmentSummary';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import ChildPhysicalBmiZscoreChart from '../ChildPhysicalBmiZscoreChart/ChildPhysicalBmiZscoreChart';
import DueDoctorAppointmentIcon from '../../doctorAppointment/DueDoctorAppointmentIcon/DueDoctorAppointmentIcon';
import DueVaccineIcon from '../../vaccine/DueVaccineIcon/DueVaccineIcon';
import { replaceNewlinesWithBreaks } from '../../shared/helpers/formattingHelper';
import { deleteChildDoctorAppointment } from '../../api/doctor_appointment_api';
import { deleteVaccination } from '../../api/vaccination_api';
import { Domain } from '../../api/permissions_api';
import { genericOnDelete } from '../../shared/components/GenericSearchPage/GenericSearchPage';
import FeedbackMessagesContext from '../../shared/contexts/FeedbackMessagesContext';
import { ChildDoctorAppointment, Vaccination } from '../../typings/api-types';
import i18n from 'i18next';
import VaccinationDateReceivedCell from '../../vaccine/VaccinationDateReceivedCell/VaccinationDateReceivedCell';
import { suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';

type ChildPhysicalProps = {
  childId: number;
  age: number;
};

const NUMBER_OF_MONTHS_OLD_CHECKUP = 12;
const NUMBER_OF_MONTHS_OLD_PHYSICAL = 24;

function getMedicalHistoryColumnDefs(
  onDelete: (objectToDelete: ChildDoctorAppointment) => Promise<any>,
  childId: number,
) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      headerName: t('common.DATE'),
      valueFormatter: (params) => {
        return isoDateToCommonFormat(params.value);
      },
      field: 'date',
      flex: 2,
    },
    {
      field: 'summary',
      headerName: t('doctorAppointment.SUMMARY'),
      cellRenderer: DoctorAppointmentSummary,
      flex: 2,
    },
    {
      field: 'doctor_name',
      headerName: t('doctorAppointment.DOCTOR_NAME'),
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-doctor-appointment',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

const getVaccinationColumnDefs = (onDelete: (objectToDelete: Vaccination) => Promise<any>, childId: number) => {
  const t = i18n.t;
  return [
    {
      field: 'vaccine_name',
      headerName: t('vaccination.VACCINE'),
      flex: 1,
    },
    {
      field: 'dose_number',
      headerName: t('vaccination.DOSE_NUMBER'),
      width: 80,
    },
    {
      field: 'recommended_date',
      headerName: t('vaccination.RECOMMENDED_DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 140,
    },
    {
      field: 'date_received',
      headerName: t('vaccination.DATE_RECEIVED'),
      cellRenderer: VaccinationDateReceivedCell,
      width: 140,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/vaccination',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const ChildPhysical = (props: ChildPhysicalProps) => {
  const { t } = useTranslation();
  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);
  const { data, isLoading, error, refetch } = useGetChildOverviewPhysical(props.childId);

  const vaccinationGridRef = useRef<AgGridReact>(null);
  const vaccinationPermissionDomain = Domain.VACCINATION;
  const vaccinationTranslationPrefix = 'vaccination.';
  const onDeleteVaccination = (objectToDelete: Vaccination) => {
    return genericOnDelete(
      objectToDelete,

      deleteVaccination,
      vaccinationPermissionDomain,
      vaccinationTranslationPrefix,
      vaccinationGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  const doctorsAppointmentGridRef = useRef<AgGridReact>(null);
  const doctorsAppointmentPermissionDomain = Domain.CHILD_WRAT_RESULT;
  const doctorsAppointmentTranslationPrefix = 'wratResult.';
  const onDeleteDoctorsAppointment = (objectToDelete: ChildDoctorAppointment) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildDoctorAppointment,
      doctorsAppointmentPermissionDomain,
      doctorsAppointmentTranslationPrefix,
      doctorsAppointmentGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return <div>Error loading {props.childId}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.childId}</div>;
  }

  let lastCheckupElement = undefined;
  if (data.last_doctor_checkup) {
    // TODO get this from backend
    let needsDoctorCheckup = isMoreThanXMonthsOld(data.last_doctor_checkup.date, NUMBER_OF_MONTHS_OLD_CHECKUP);
    lastCheckupElement = (
      <>
        {data.last_doctor_checkup.has_notes && (
          <DefaultTooltip id={'checkup-notes-icon'} tooltipText={t('doctorAppointment.NOTES_ICON_TOOLTIP')}>
            <FontAwesomeIcon icon={'file-alt'} className={'pe-2 ' + styles.bad} />
          </DefaultTooltip>
        )}
        <LinkContainer
          to={{
            pathname: '/child-doctor-appointment/view/' + data.last_doctor_checkup.id,
            search: '?childId=' + props.childId,
          }}
        >
          <Button size="sm" variant={'outline-primary'}>
            {t('common.VIEW')}
          </Button>
        </LinkContainer>
        <div className={needsDoctorCheckup ? styles.bad + ' ms-2' : 'ms-2'}>
          {needsDoctorCheckup && <DueDoctorAppointmentIcon className={styles.bad + ' me-2'} />}
          {isoDateToCommonFormat(data.last_doctor_checkup.date)}
        </div>
      </>
    );
  } else {
    lastCheckupElement = (
      <div className={styles.bad + ' ms-2'}>
        <DueDoctorAppointmentIcon className={styles.bad + ' me-2'} />
        {t('child.physical.LAST_DOCTOR_CHECKUP_UNKNOWN')}
      </div>
    );
  }

  let lastPhysicalElement = undefined;
  if (data.last_doctor_physical) {
    // TODO get this from backend
    let needsDoctorPhysical = isMoreThanXMonthsOld(data.last_doctor_physical.date, NUMBER_OF_MONTHS_OLD_PHYSICAL);
    lastPhysicalElement = (
      <>
        {data.last_doctor_physical.has_notes && (
          <DefaultTooltip id={'physical-notes-icon'} tooltipText={t('doctorAppointment.NOTES_ICON_TOOLTIP')}>
            <FontAwesomeIcon icon={'file-alt'} className={'pe-2 ' + styles.bad} />
          </DefaultTooltip>
        )}
        <LinkContainer
          to={{
            pathname: '/child-doctor-appointment/view/' + data.last_doctor_physical.id,
            search: '?childId=' + props.childId,
          }}
        >
          <Button size="sm" variant={'outline-primary'}>
            {t('common.VIEW')}
          </Button>
        </LinkContainer>
        <div className={needsDoctorPhysical ? styles.bad + ' ms-2' : 'ms-2'}>
          {needsDoctorPhysical && <DueDoctorAppointmentIcon className={styles.bad + ' me-2'} />}
          {isoDateToCommonFormat(data.last_doctor_physical.date)}
        </div>
      </>
    );
  } else {
    lastPhysicalElement = (
      <div className={styles.bad + ' ms-2'}>
        <DueDoctorAppointmentIcon className={styles.bad + ' me-2'} />
        {t('child.physical.LAST_DOCTOR_PHYSICAL_UNKNOWN')}
      </div>
    );
  }

  let doctor_appointments_with_intake = undefined;
  if (data.doctor_appointments) {
    doctor_appointments_with_intake = data.doctor_appointments.slice();
    doctor_appointments_with_intake.push({
      appointment_type: 'Sick/Emergency',
      doctor_name: 'Intake',
      summary: 'Intake',
      home: '',
      child: props.childId,
      date: data.intake_date,
      attachments: [],
    });
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label column sm={2}>
              {t('child.physical.NUTRITION')}
            </Form.Label>
            <ChildPhysicalNutritionSummary
              zscore_6mo_trend={data.zscore_6mo_trend}
              zscore_level={data.zscore_level}
              last_bmi_check={data.last_bmi_check}
              age={props.age}
            />{' '}
          </Form.Group>

          <Form.Group>
            <Form.Label column sm={2}>
              {t('common.AGE')}
            </Form.Label>
            <span>{data.age}</span>
          </Form.Group>

          <Form.Group>
            <Form.Label column sm={4}>
              {t('child.physical.BMI_ZSCORE_CHART_TITLE')}
            </Form.Label>
          </Form.Group>
          {data.bmi_checks && (
            <div>
              <Alert variant={'info'}>{t('bmiCheck.CHART_HELP')}</Alert>
              <div
                style={{
                  height: '350px',
                  width: '100%',
                  padding: 5,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  // Ag border color
                  borderColor: '#bdc3c7',
                }}
              >
                <ChildPhysicalBmiZscoreChart bmiChecks={data.bmi_checks} />
              </div>
            </div>
          )}
          <Row>
            <Col className="d-flex flex-column align-items-end">
              <LinkContainer
                to={{
                  pathname: '/home-bmi-check/create/new',
                  search: '?childId=' + props.childId,
                }}
              >
                <Button>
                  {' '}
                  + <FontAwesomeIcon icon={'home'} /> {t('child.physical.ADD_BMI_FOR_CHILDS_HOME_BUTTON')}
                </Button>
              </LinkContainer>
            </Col>
          </Row>

          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="me-3">{t('child.physical.LAST_DOCTOR_CHECKUP')}</Form.Label>
                {lastCheckupElement}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="me-3">{t('child.physical.LAST_DOCTOR_PHYSICAL')}</Form.Label>
                {lastPhysicalElement}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label column sm={4}>
              {t('child.physical.MEDICAL_HISTORY')}
            </Form.Label>
          </Form.Group>
          <div
            className="ag-theme-balham"
            style={{
              height: '400px',
              width: '100%',
            }}
          >
            <AgGridReact
              rowData={doctor_appointments_with_intake}
              defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
              columnDefs={getMedicalHistoryColumnDefs(onDeleteDoctorsAppointment, props.childId)}
              getRowId={(params) => params.data.id}
              rowSelection={'single'}
              animateRows={true}
            />
          </div>
          <Row>
            <Col className="d-flex flex-column align-items-end">
              <LinkContainer
                to={{
                  pathname: '/child-doctor-appointment/create/new',
                  search: '?childId=' + props.childId,
                }}
              >
                <Button>
                  {' '}
                  + <FontAwesomeIcon icon={'child'} /> {t('doctorAppointment.ADD_CHILD_BUTTON')}
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label column sm={4}>
              {t('child.physical.VACCINATIONS')}
              {data && data.vaccination_due_count > 0 && (
                <DueVaccineIcon className={styles.dueVaccinationIcon} numberDue={data.vaccination_due_count} />
              )}
            </Form.Label>
          </Form.Group>
          <div
            className="ag-theme-balham"
            style={{
              height: '400px',
              width: '100%',
            }}
          >
            <AgGridReact
              rowData={data.vaccination_schedule}
              defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
              columnDefs={getVaccinationColumnDefs(onDeleteVaccination, props.childId)}
              rowSelection={'single'}
              animateRows={true}
            />
          </div>
          <Row>
            <Col className="d-flex flex-column align-items-end">
              <LinkContainer
                to={{
                  pathname: '/vaccination/create/new',
                  search: '?childId=' + props.childId,
                }}
              >
                <Button>
                  {' '}
                  + <FontAwesomeIcon icon={'child'} /> {t('vaccination.ADD_CHILD_BUTTON')}
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label column sm={4}>
              {t('child.physical.INTAKE_NOTES')}
            </Form.Label>
            {data.intake_physical_health_narrative ? (
              <p>{replaceNewlinesWithBreaks(data.intake_physical_health_narrative)}</p>
            ) : (
              <p style={{ fontStyle: 'italic' }}>{t('child.physical.NO_INTAKE_NOTES')}</p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ChildPhysical;
