import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildRiasecResult } from '../typings/api-types';
import { ChildRiasecResultPatch, ChildRiasecResultPost } from '../typings/types-to-send';

const CHILD_RIASEC_RESULT_REST_URL = '/child-riasec-result';

const useGetChildRiasecResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetChildRiasecResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildRiasecResult[]>(CHILD_RIASEC_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildRiasecResultDetail = (id: number | undefined, enabled: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetChildRiasecResultDetail', requestConfig, id],
    async () => {
      const response = await axios.get<ChildRiasecResult>(CHILD_RIASEC_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    { enabled: enabled },
  );
};

const patchChildRiasecResult = (id: number | undefined, data: ChildRiasecResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<ChildRiasecResultPatch>(CHILD_RIASEC_RESULT_REST_URL + '/' + id + '/', data);
};

const postChildRiasecResult = (data: ChildRiasecResultPost) => {
  return axios.post<ChildRiasecResultPost, AxiosResponse<ChildRiasecResult>>(CHILD_RIASEC_RESULT_REST_URL + '/', data);
};

const deleteChildRiasecResult = (id: number) => {
  return axios.delete(CHILD_RIASEC_RESULT_REST_URL + '/' + id + '/');
};

export {
  useGetChildRiasecResults,
  useGetChildRiasecResultDetail,
  patchChildRiasecResult,
  postChildRiasecResult,
  deleteChildRiasecResult,
};
