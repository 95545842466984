import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchSkillInProject from '../SearchSkillInProject/SearchSkillInProject';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import React from 'react';
import { Mode } from '../../shared/helpers/modeHelper';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type SkillProjectPageProps = {};

const DOMAIN = Domain.SKILL;

const SkillProjectPage = (props: SkillProjectPageProps) => {
  const { t } = useTranslation();

  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = Mode.VIEW;

  if (isLoadingPermissions) {
    return <Spinner animation='border' role='status' />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  return (
    <Container fluid className='d-flex flex-column flex-grow-1'>
      <Row>
        <Col>
          <h1 className='text-center font-weight-bold'>{t('skill.ALL_TRAINING_PROJECTS_TITLE')}</h1>
        </Col>
      </Row>
      <div className='d-flex flex-column flex-grow-1' style={{ minHeight: 600 }}>
        <SearchSkillInProject projectTitle={t('skill.CHILD_SKILLS_PROJECT_TITLE')} projectCode={'CHILD_TRAININGS'} />
      </div>
      <div className='d-flex flex-column flex-grow-1' style={{ minHeight: 600 }}>
        <SearchSkillInProject projectTitle={t('skill.LIFE_SKILLS_PROJECT_TITLE')} projectCode={'LIFE_SKILLS'} />
      </div>
    </Container>
  );
};

export default SkillProjectPage;
