// TODO generate this from the backend somehow?
const CLASS_LEVEL_OPTIONS = [
  { value: -101, label: 'Not Applicable', shortLabel: 'N/A' },

  { value: -100, label: 'Unknown', shortLabel: 'UNK' },

  { value: -99, label: 'Unknown (Class 1 or lower)', shortLabel: '<= 1' },

  { value: -1, label: 'Lower Kindergarten', shortLabel: 'LKG' },
  { value: 0, label: 'Upper Kindergarten', shortLabel: 'UKG' },

  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },

  { value: 13, label: 'Unknown (12+)', shortLabel: '12+' },
];

function getShortenedClassDisplay(classEnum: number): string {
  if (!classEnum) {
    return '';
  }
  const foundLevel = CLASS_LEVEL_OPTIONS.find((option) => option.value === classEnum);
  if (foundLevel && foundLevel.shortLabel) {
    return foundLevel.shortLabel;
  } else if (foundLevel && foundLevel.label) {
    return foundLevel.label;
  } else {
    return classEnum.toString();
  }
}

function getClassDisplay(classEnum: number): string {
  if (!classEnum) {
    return '';
  }
  const foundLevel = CLASS_LEVEL_OPTIONS.find((option) => option.value === classEnum);
  if (foundLevel && foundLevel.label) {
    return foundLevel.label;
  } else {
    return classEnum.toString();
  }
}

export { getShortenedClassDisplay, getClassDisplay, CLASS_LEVEL_OPTIONS };
