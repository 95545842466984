// Combines Formik with react-select and giving it react-bootstrap styling
// Based on the LAST few examples on https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
import { ErrorMessage, Field, FieldInputProps, FieldProps } from 'formik';
import React from 'react';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import Select, { OnChangeValue, Options } from 'react-select';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import { getReactSelectStylesForAgGridCell } from '../../helpers/reactSelectStyleHelpers';

interface GenericOption<ValueType = any> {
  label: string;
  // I usually use the object itself as the value.
  value: ValueType;
}

type OnChangeValueSingle<ValueType = any> = OnChangeValue<GenericOption<ValueType> | null, false>;
type OnChangeValueMulti<ValueType = any> = OnChangeValue<GenericOption<ValueType>[] | null, true>;
type FormikSetFunction = (field: string, value: any) => void;

type FormikSelectConditionalProps =
  | {
      onChange?: (
        selectedOptionOrOptions: OnChangeValue<GenericOption | null, false>,
        formikSetFieldValue: (field: string, value: any) => void,
      ) => void;
    }
  | {
      onChange?: (
        selectedOptionOrOptions: OnChangeValue<GenericOption[] | null, true>,
        formikSetFieldValue: (field: string, value: any) => void,
      ) => void;
    };

type FormikSelectProps = {
  name: string;
  // Null needed for i18n
  label?: string | null;
  options: Options<GenericOption>;
  className?: any;
  isMulti?: boolean;
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  smallLabelSize?: boolean;
  isReadOnly?: boolean;
  isInAgGrid?: boolean;
  defaultValue?: any;
  readOnlyComponent?: JSX.Element;
} & FormikSelectConditionalProps;

function createDoesOptionMatchFieldFn(field: FieldInputProps<any>) {
  const doesOptionMatchField = (option: GenericOption) => {
    if (option.value && option.value.id && field.value && field.value.id) {
      // Assuming we are comparing model objects, just need same id to match
      return option.value.id === field.value.id;
    } else {
      return option.value === field.value;
    }
  };
  return doesOptionMatchField;
}

function FormikSelect(props: FormikSelectProps) {
  const {
    onChange = (selectedOptionOrOptions: OnChangeValue<GenericOption | GenericOption[] | null, boolean>) => {},
    isMulti = false,
    showSideBySide = false,
  } = props;

  let labelColumnValue: any = undefined;
  if (showSideBySide) {
    if (props.smallLabelSize) {
      labelColumnValue = 'sm';
    } else {
      labelColumnValue = true;
    }
  }

  return (
    <div className={props.className}>
      {/* This is a formik component so formik does validation and manages state */}
      <Field name={props.name}>
        {(fieldProps: FieldProps) => {
          const { field, form } = fieldProps;
          const handleChange = (option: any) => {
            onChange(option, form.setFieldValue);

            let value;
            if (option === undefined || option === null) {
              // Purposely send null so the backend clears the value (django PATCH ignores undefineds by default)
              value = null;
            } else if (isMulti) {
              value = (option as GenericOption[]).map((item: GenericOption) => item.value);
            } else {
              value = (option as GenericOption).value;
            }
            // Specifically checking for undefined here because
            // I want to set props.defaultValue to empty string, which is falsy
            if (value === null && props.defaultValue !== undefined) {
              form.setFieldValue(field.name, props.defaultValue);
            } else {
              form.setFieldValue(field.name, value);
            }
          };
          const doesOptionMatchField = createDoesOptionMatchFieldFn(field);
          const getValue = () => {
            if (props.options) {
              if (isMulti) {
                return props.options.filter((option: GenericOption) => field.value.indexOf(option.value) >= 0);
              } else {
                return props.options.find(doesOptionMatchField);
              }
            } else {
              console.warn('No options passed to FormikSelect with label: ' + props.label);
              return isMulti ? [] : ('' as any);
            }
          };

          let reactSelectComponent = (
            <>
              <Select
                name={field.name}
                value={getValue()}
                onChange={handleChange}
                options={props.options}
                isMulti={isMulti}
                isClearable={true}
                styles={props.isInAgGrid ? getReactSelectStylesForAgGridCell() : undefined}
                // Allows to break out of Ag Grids
                menuPortalTarget={props.isInAgGrid ? document.body : undefined}
              />
              {/* Not getting errors messages?
                  Try adding 'fieldName: undefined,' to initialStateOfEditableScheduleFields

                  Still not working and in AG grid? Try setting row height to 50
                */}
              <ErrorMessage name={props.name}>
                {(errorMessage) => {
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </>
          );
          if (props.isReadOnly) {
            if (props.readOnlyComponent) {
              reactSelectComponent = props.readOnlyComponent;
            } else {
              if (!getValue()) {
                reactSelectComponent = <div />;
              } else {
                reactSelectComponent = <Col className="d-flex align-items-center">{getValue().label}</Col>;
              }
            }
          } else if (props.showSideBySide) {
            reactSelectComponent = (
              <Col>
                <Select
                  name={field.name}
                  value={getValue()}
                  onChange={handleChange}
                  options={props.options}
                  isMulti={isMulti}
                  isClearable={true}
                  styles={props.isInAgGrid ? getReactSelectStylesForAgGridCell() : undefined}
                  // Allows to break out of Ag Grids
                  menuPortalTarget={props.isInAgGrid ? document.body : undefined}
                />
                {/* Not getting errors messages?
                  Try adding 'fieldName: undefined,' to initialStateOfEditableScheduleFields

                  Still not working and in AG grid? Try setting row height to 50
                */}
                <ErrorMessage name={props.name}>
                  {(errorMessage) => {
                    return <CustomErrorMessage errorMessage={errorMessage} />;
                  }}
                </ErrorMessage>
              </Col>
            );
          }
          return (
            <FormGroup as={showSideBySide ? Row : undefined}>
              {props.label && (
                // This is a react-bootstrap component match label look-and-feel
                <FormLabel column={labelColumnValue} sm={props.labelWidth}>
                  {props.label}
                </FormLabel>
              )}
              {reactSelectComponent}
            </FormGroup>
          );
        }}
      </Field>
    </div>
  );
}

export default FormikSelect;
export { createDoesOptionMatchFieldFn };
export type { GenericOption, OnChangeValueSingle, OnChangeValueMulti, FormikSetFunction };
