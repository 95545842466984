import * as React from 'react';
import { useState } from 'react';
import { Button, Col, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field, FieldProps } from 'formik';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import axios, { AxiosResponse } from 'axios';
import { jsonWithAttachmentsToFormData } from '../../helpers/formHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type FormikMultipleAsyncFileUploadProps = {
  baseUrl: string;

  name: string;
  // Null needed for translate for some reason
  label?: string | null;
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  isReadOnly?: boolean;
};

type GenericAttachment = {
  id?: number;
  file: any;
};

export const FormikMultipleAsyncFileUpload = (props: FormikMultipleAsyncFileUploadProps) => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const postAttachment = (data: GenericAttachment) => {
    const formData = jsonWithAttachmentsToFormData(data);
    return axios.post<FormData, AxiosResponse<GenericAttachment>>(props.baseUrl + '/', formData);
  };
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          let filesOfInput = event.target.files;
          let newFile;
          if (filesOfInput) {
            newFile = filesOfInput[0];
            const objectWithAttachment = {
              file: newFile,
            };
            setIsSending(true);
            postAttachment(objectWithAttachment).then((result) => {
              const savedAttachment: GenericAttachment = result.data;
              let existingAttachments = fieldProps.field.value;
              if (existingAttachments === null || existingAttachments === undefined) {
                existingAttachments = [];
              }
              const updatedAttachmentsArray = [...existingAttachments, savedAttachment];
              fieldProps.form.setFieldValue(fieldProps.field.name, updatedAttachmentsArray);
              setIsSending(false);
            });
          }
        };
        const removeAttachmentFromList = (attachmentToRemove: GenericAttachment) => {
          // DON'T delete it on the backend at this point.
          // The user can still change their mind, so we will clean it up after they submit the form
          const existingAttachments = fieldProps.field.value;
          const updatedAttachmentsArray = existingAttachments.filter(
            (attachment: GenericAttachment) => attachment.id !== attachmentToRemove.id,
          );
          fieldProps.form.setFieldValue(fieldProps.field.name, updatedAttachmentsArray);
        };

        const existingFiles = fieldProps.field.value as GenericAttachment[];
        let innerComponent = (
          <>
            {existingFiles &&
              existingFiles.length > 0 &&
              existingFiles.map((attachment) => {
                return (
                  <div key={attachment.file}>
                    <a className={'pe-2'} href={attachment.file}>
                      {attachment.file.split('/').pop()}
                    </a>
                    {!props.isReadOnly && (
                      <Button onClick={() => removeAttachmentFromList(attachment)} variant={'outline-danger'} size="sm">
                        <FontAwesomeIcon icon={'trash'} />
                      </Button>
                    )}
                  </div>
                );
              })}
            {(!existingFiles || existingFiles.length === 0) && <div className={'pb-2'}>{t('files.NO_MULTI')}</div>}
            {!props.isReadOnly && (
              <div className={'ms-4'}>
                <span>{t('files.ADD_MULTI')}</span>
                <FormControl type="file" onChange={handleChange} />
              </div>
            )}
            <ErrorMessage name={props.name}>
              {(errorMessage) => {
                return <CustomErrorMessage errorMessage={errorMessage} />;
              }}
            </ErrorMessage>
          </>
        );
        if (isSending) {
          innerComponent = (
            <div>
              <Spinner />
              {t('common.SENDING_FILE')}
            </div>
          );
        }
        if (props.showSideBySide) {
          innerComponent = <Col>{innerComponent}</Col>;
        }
        return (
          <Form.Group as={props.showSideBySide ? Row : undefined}>
            {props.label && (
              <Form.Label column={props.showSideBySide} sm={props.labelWidth}>
                {props.label}
              </Form.Label>
            )}
            {innerComponent}
          </Form.Group>
        );
      }}
    </Field>
  );
};

export default FormikMultipleAsyncFileUpload;
export type { FormikMultipleAsyncFileUploadProps };
