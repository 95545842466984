import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildBeckResult } from '../typings/api-types';
import { ChildBeckResultPatch, ChildBeckResultPost } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const CHILD_BECK_RESULT_REST_URL = '/child-beck-result';

const useGetChildBeckResults = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildBeckResults', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildBeckResult[]>(CHILD_BECK_RESULT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildBeckResultDetail = (id: number | undefined, params?: any, onSuccess?: any, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckins', requestConfig, id],
    async () => {
      const response = await axios.get<ChildBeckResult>(CHILD_BECK_RESULT_REST_URL + '/' + id, requestConfig);
      return response.data;
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const patchChildBeckResult = (id: number | undefined, dataWithFiles: ChildBeckResultPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);

  // Send nulls for files to backend on first patch,
  // All new files do on a separate patch
  // Sending null as a multi-part-form doesn't work
  if (dataWithFiles.original_scan !== null) {
    delete dataWithoutFiles.original_scan;
  }

  return axios
    .patch<ChildBeckResultPatch, AxiosResponse<ChildBeckResult>>(
      CHILD_BECK_RESULT_REST_URL + '/' + id + '/',
      dataWithoutFiles,
    )
    .then((response) => {
      dataWithoutFiles.original_scan = originalScan;
      return updateFiles(response, originalScan);
    });
};

const postChildBeckResult = (dataWithFiles: ChildBeckResultPost) => {
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.original_scan;
  return axios
    .post<ChildBeckResultPost, AxiosResponse<ChildBeckResult>>(CHILD_BECK_RESULT_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, originalScan);
    });
};

const deleteChildBeckResult = (id: number) => {
  return axios.delete(CHILD_BECK_RESULT_REST_URL + '/' + id + '/');
};

function updateFiles(response: AxiosResponse<ChildBeckResult>, originalScan: any) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  if (!isFileObject(originalScan)) {
    return response;
  }

  console.debug('Got what looks like a file in updateFiles, sending to server');
  const fileFieldsToReplace = {
    original_scan: originalScan,
  };
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios.patch<FormData, AxiosResponse<ChildBeckResult>>(CHILD_BECK_RESULT_REST_URL + '/' + id + '/', formData);
}

export {
  useGetChildBeckResults,
  useGetChildBeckResultDetail,
  patchChildBeckResult,
  postChildBeckResult,
  deleteChildBeckResult,
};
