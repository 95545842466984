import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { Accordion, Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { CHILD_INTAKE_ATTACHMENT_REST_URL, patchChild, useGetChildOverviewTabSummary } from '../../api/child_api';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { ChildDetail } from '../../typings/api-types';
import { isMoreThanXMonthsOld, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import CustomErrorMessage from '../../shared/formik/CustomErrorMessage/CustomErrorMessage';
import { ChildDetailPatch } from '../../typings/types-to-send';
import FormikMultipleAsyncFileUpload
  from '../../shared/formik/FormikMultipleAsyncFileUpload/FormikMultipleAsyncFileUpload';
import { LinkContainer } from 'react-router-bootstrap';
import ChildPhysicalNutritionSummary from '../ChildPhysicalNutritionSummary/ChildPhysicalNutritionSummary';
import DueDoctorAppointmentIcon from '../../doctorAppointment/DueDoctorAppointmentIcon/DueDoctorAppointmentIcon';
import styles from './ChildOverview.module.scss';
import DueVaccineIcon from '../../vaccine/DueVaccineIcon/DueVaccineIcon';
import { NUMBER_OF_MONTHS_OLD_GRADE } from '../ChildEducation/ChildEducation';
import DueChildTrainingSkillIcon from '../../skill/DueChildTrainingSkillIcon/DueChildTrainingSkillIcon';
import ChildMentalEmotionalCheckInFace from '../ChildMentalEmotionalCheckInFace/ChildMentalEmotionalCheckInFace';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { PermissionsMap } from '../../api/permissions_api';
import { CLASS_LEVEL_OPTIONS } from '../../shared/helpers/classLevelHelper';
import { replaceNewlinesWithBreaks } from '../../shared/helpers/formattingHelper';
import { findOptionLabelFromValue } from '../../shared/helpers/optionsHelper';
import { BOOLEAN_OPTIONS, isFileObject } from '../../shared/helpers/formHelper';
import { useGetSponsorshipsForChild } from '../../api/sponsorship_api';
import { JOB_STATUS_OPTIONS } from '../ChildTransition/ChildTransition';
import { BeckResultIcon } from '../../beckResult/BeckResultIcon/BeckResultIcon';
import { RscaResultIcon } from '../../rscaResult/RscaResultIcon/RscaResultIcon';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import { Mode } from '../../shared/helpers/modeHelper';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import AsOfDate from '../../shared/components/AsOfDate/AsOfDate';

const BIO_STATE_OPTIONS = [
  {
    label: 'Yes - Parent\'s Bio Child',
    value: 'PARENTS_BIO_CHILD',
  },
  {
    label: 'Yes - Helper\'s Bio Child',
    value: 'HELPERS_BIO_CHILD',
  },
  {
    label: 'Not Biological Child',
    value: 'NO',
  },
];

const SITUATION_STATUS_OPTIONS = [
  {
    label: 'In Home',
    value: 'IN_HOME',
  },
  {
    label: 'Independent Living',
    value: 'INDEPENDENT_LIVING',
  },
  {
    label: 'Graduate',
    value: 'GRADUATE',
  },
  {
    label: 'Premature Departure - College Drop Out',
    value: 'PREMATURE_DEPARTURE_COLLEGE_DROP_OUT',
  },
  {
    label: 'Premature Departure - Ran Away',
    value: 'PREMATURE_DEPARTURE_RAN_AWAY',
  },
  {
    label: 'Premature Departure - Discipline Issues',
    value: 'PREMATURE_DEPARTURE_DISOBEDIENCE',
  },
  {
    label: 'Reunited with Family',
    value: 'REUNITED',
  },
];

const TRACK_STATUS_OPTIONS = [
  {
    label: 'Pre-secondary',
    value: 'PRE_SECONDARY',
  },
  {
    label: 'Life Skills',
    value: 'LIFE_SKILLS',
  },
  {
    label: 'Vocational',
    value: 'VOCATIONAL',
  },
  {
    label: 'College',
    value: 'COLLEGE',
  },
  {
    label: 'University',
    value: 'UNIVERSITY',
  },
  {
    label: 'University Abroad',
    value: 'UNIVERSITY_ABROAD',
  },
  {
    label: 'Gap Year',
    value: 'GAP_YEAR',
  },
  {
    label: 'Developmental Disabilities',
    value: 'DEVELOPMENTAL_DISABILITIES',
  },
];

const SITUATION_STATUS_STRINGS = SITUATION_STATUS_OPTIONS.map((option) => option.value);
const TRACK_STATUS_STRINGS = TRACK_STATUS_OPTIONS.map((option) => option.value);

const CHILD_OVERVIEW_FORM_SCHEMA = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  middle_name: Yup.string().optional(),
  nickname: Yup.string().optional(),
  last_name: Yup.string().required('Required'),
  public_id: Yup.string().required('Required'),
  gender: Yup.string().oneOf(['M', 'F']),
  situation_status: Yup.string().oneOf(SITUATION_STATUS_STRINGS),
  track_status: Yup.string().oneOf(TRACK_STATUS_STRINGS),
  // Set by onSubmit
  // situation_status_last_updated
  date_of_birth: Yup.date().required('Required'),
  is_bio_child: Yup.boolean().required('Required'),
  intake_date: Yup.date().required('Required'),

  // Not required because initially loaded kids did not have these.
  // intake_reference: Yup.string().required('Required'),
  // intake_photo: Yup.mixed().required('Required'),
  // intake_form_scan: Yup.mixed().required('Required'),
  intake_other_docs: Yup.mixed().optional(),
  // intake_class: Yup.number().required('Required'),
  // intake_physical_health_narrative: Yup.string().required('Required'),
  // intake_education_narrative: Yup.string().required('Required'),
  // intake_general_notes: Yup.string().required('Required'),

  // Set by onSubmit
  // current_class

  // Moved to Sponsor page
  // can_be_sponsored: Yup.boolean().required('Required'),

  // home: Yup.number().required('Required'),
  // current_school: Yup.number().required('Required'),

  is_eligible_for_child_trainings: Yup.boolean().required('Required'),
  is_eligible_for_life_skills: Yup.boolean().required('Required'),
});

type ChildOverviewProps = {
  child: ChildDetail;
  permissionsMap: PermissionsMap;
  refetchChild: () => void;
};

const ChildOverview = (props: ChildOverviewProps) => {
  let { mode } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: homeData } = useGetHomes();
  const [serverError, setServerError] = useState(false);

  const { data, isLoading } = useGetChildOverviewTabSummary(props.child.id!);
  // Decided to make this a separate call so page doesn't break if hubspot is down, etc
  const { data: sponsorshipData, isLoading: isLoadingSponsorships } = useGetSponsorshipsForChild(props.child.id);

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../view');
  }

  const homeOptions = createHomeOptions(homeData);

  async function onSubmit(dataWithFiles: ChildDetailPatch, { setSubmitting }: any) {
    if (props.child && props.child.id) {
      try {
        await patchChild(props.child.id, dataWithFiles);
        // TODO should I send the intake files here?
        await props.refetchChild();
        setSubmitting(false);
        navigate('../view');
      } catch (error) {
        setServerError(true);
      }
    } else {
      // LOG SOMETHING?
    }
  }

  if (serverError) {
    return <ErrorPage />;
  }

  let who_is_parent = null;
  if (props.child.bio_state === 'PARENTS_BIO_CHILD') {
    who_is_parent = ' the home parents.';
  } else if (props.child.bio_state === 'HELPERS_BIO_CHILD') {
    who_is_parent = ' a helper.';
  }

  // noinspection RequiredAttributes
  const bioIconWithTooltip = (
    <DefaultTooltip id={'bio-icon-tooltip'} tooltipText={t('child.overview.BIO_ICON_TOOLTIP_PREFIX') + who_is_parent}>
      <FontAwesomeIcon className='p-2' icon='dna' />
    </DefaultTooltip>
  );

  let lastGradeReportElement: JSX.Element;
  if (data && data.last_grade_report) {
    let dateColoringClass = '';
    if (isMoreThanXMonthsOld(data.last_grade_report.date, NUMBER_OF_MONTHS_OLD_GRADE)) {
      dateColoringClass = 'bad';
    }
    lastGradeReportElement = (
      <>
        <span>
          <span style={{ fontWeight: 'bold', fontSize: 18 }}>{data.last_grade_report.gpa}</span> / 4 GPA at{' '}
          {data.current_school?.name}
          <AsOfDate colorKey={dateColoringClass} date={data.last_grade_report.date} />
        </span>
      </>
    );
  } else {
    lastGradeReportElement = <div>{t('child.education.LAST_GRADE_REPORT_UNKNOWN')}</div>;
  }

  const show_bachelors_section = props.child.higher_ed_bachelors_school_name || props.child.higher_ed_bachelors_school_start_date || props.child.higher_ed_bachelors_graduated_date

  return (
    <Formik
      // Without enableReinitialize if you save edits and then edit + cancel + edit,
      // your old edits get wiped out
      enableReinitialize
      initialValues={props.child}
      validationSchema={CHILD_OVERVIEW_FORM_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<any>) => {
        // Set this if undefined or a string
        let intakePhotoUrl = formikProps.values.intake_photo;
        if (formikProps.values.intake_photo && isFileObject(formikProps.values.intake_photo)) {
          // Replace if it is a file object
          // TODO this creates a memory leak
          //  but I don't know how to fix it https://stackoverflow.com/a/57781164/13815107
          intakePhotoUrl = URL.createObjectURL(formikProps.values.intake_photo);
        }

        return (
          <FormikForm>
            <Container>
              <Row>
                <Col lg={{ span: 3, order: 2 }} xs={10}>
                  <div className='float-end'>
                    {props.child.most_recent_photo ? (
                      <img
                        style={{ width: 200 }}
                        src={props.child.most_recent_photo.photo}
                        alt={'Photo of ' + props.child.name}
                      />
                    ) : (
                      <div
                        className='d-flex flex-column justify-content-center'
                        style={{ minHeight: 200, minWidth: 200, borderStyle: 'solid' }}
                      >
                        <p style={{ textAlign: 'center' }}>{t('child.overview.NO_OVERVIEW_PHOTO')}</p>
                      </div>
                    )}
                    <div>
                      <LinkContainer className={'float-end'} to={'/child/' + props.child.id + '/photos'}>
                        <Button size={'sm'} variant={'outline-primary'}>
                          {t('child.overview.PHOTOS')}
                        </Button>
                      </LinkContainer>
                    </div>
                  </div>
                </Col>
                <Col lg={{ span: 1, order: 1 }} xs={2}>
                  {mode === 'view' && (
                    <Link to='../overview/edit'>
                      <Button variant={'outline-primary'}>
                        <FontAwesomeIcon icon={'pencil'} />
                      </Button>
                    </Link>
                  )}
                  {mode === 'edit' && (
                    <Button variant={'outline-secondary'} onClick={onCancel.bind(null, formikProps.resetForm)}>
                      <FontAwesomeIcon icon={'close'} />
                    </Button>
                  )}
                </Col>
                <Col lg={{ span: 8, order: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('child.overview.NAME')}
                    </Form.Label>
                    {mode === 'view' && (
                      <Col className='d-flex align-items-center'>
                        {props.child.name_with_nickname}
                        {props.child.is_bio_child && bioIconWithTooltip}
                      </Col>
                    )}
                    {mode === 'edit' && (
                      <Col sm={3}>
                        <Field as={Form.Control} type='text' name='first_name' />
                        <ErrorMessage name='first_name'>
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Col>
                    )}
                    {mode === 'edit' && (
                      <Col sm={3}>
                        <span>"</span>
                        <span>
                          <Field
                            style={{ width: 'calc(100% - 15px)', display: 'inline' }}
                            as={Form.Control}
                            type='text'
                            name='nickname'
                          />
                        </span>
                        <span>"</span>
                        <ErrorMessage name='first_name'>
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Col>
                    )}
                    {mode === 'edit' && (
                      <Col sm={4}>
                        <Field as={Form.Control} type='text' name='last_name' />
                        <ErrorMessage name='last_name'>
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Col>
                    )}
                    {mode === 'edit' && (
                      <FormikSelect
                        label={t('child.intake.IS_BIO_CHILD')}
                        name='bio_state'
                        options={BIO_STATE_OPTIONS}
                        showSideBySide={true}
                        labelWidth={2}
                      />
                    )}
                  </Form.Group>
                  <FormikSelect
                    label={t('child.overview.SITUATION_STATUS')}
                    name='situation_status'
                    options={SITUATION_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={2}
                    isReadOnly={mode === 'view'}
                    readOnlyComponent={
                      <Col className='d-flex align-items-center'>
                        <span>{findOptionLabelFromValue(props.child.situation_status, SITUATION_STATUS_OPTIONS)}</span>
                        <span className={styles.asOfDate + ' ps-2 pt-1'}>
                          {t('child.overview.SITUATION_STATUS_LAST_UPDATED')}{' '}
                          {isoDateToCommonFormat(props.child.situation_status_last_updated)}
                        </span>
                      </Col>
                    }
                  />
                  <FormikSelect
                    label={t('child.overview.TRACK_STATUS')}
                    name='track_status'
                    options={TRACK_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={2}
                    isReadOnly={mode === 'view'}
                    readOnlyComponent={
                      <Col className='d-flex align-items-center'>
                        <span>{findOptionLabelFromValue(props.child.track_status, TRACK_STATUS_OPTIONS)}</span>
                        <span className={styles.asOfDate + ' ps-2 pt-1'}>
                          {t('child.overview.TRACK_STATUS_LAST_UPDATED')}{' '}
                          {isoDateToCommonFormat(props.child.situation_status_last_updated)}
                        </span>
                      </Col>
                    }
                  />
                  <FormikSelect
                    label={t('common.HOME_NAME')}
                    name='home'
                    options={homeOptions}
                    showSideBySide={true}
                    labelWidth={2}
                    isReadOnly={mode === 'view'}
                  />

                  <hr />

                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {props.permissionsMap.pagePermissions.canViewPhysicalTab ? (
                        <Link to={'../physical/view'}>{t('child.tabs.PHYSICAL_TAB')}</Link>
                      ) : (
                        <span>{t('child.tabs.PHYSICAL_TAB')}</span>
                      )}
                    </Form.Label>
                    {isLoading && <Spinner />}
                    {data && (
                      <Col className='d-flex align-items-center'>
                        <ChildPhysicalNutritionSummary
                          zscore_6mo_trend={data.zscore_6mo_trend}
                          zscore_level={data.zscore_level}
                          last_bmi_check={data.last_bmi_check}
                          age={props.child.age}
                        />
                        {data.needs_doctor_appointment && (
                          <span className={'ps-2'}>
                            <DueDoctorAppointmentIcon className={styles.bad} />{' '}
                          </span>
                        )}
                        {data.vaccinations_due_count > 0 && (
                          <span className={'ps-2'}>
                            <DueVaccineIcon className={styles.bad} numberDue={data.vaccinations_due_count} />
                          </span>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {props.permissionsMap.pagePermissions.canViewMentalTab ? (
                        <Link to={'../mental/view'}>{t('child.tabs.MENTAL_TAB')}</Link>
                      ) : (
                        <span>{t('child.tabs.MENTAL_TAB')}</span>
                      )}
                    </Form.Label>
                    {isLoading && <Spinner />}
                    {data && (
                      <Col className='d-flex align-items-center'>
                        <ChildMentalEmotionalCheckInFace
                          hasRisk={data.last_emotional_check_in_has_risk}
                          date={data.last_emotional_check_in_date}
                        />
                        {data.has_beck_result_alert && (
                          <DefaultTooltip
                            id={'child-overview-beck-risk'}
                            tooltipText={t('beckResult.HAS_RISK_TOOLTIP') + '\n(' + data.beck_result_alert_date + ')'}
                          >
                            <span className={styles.bad + ' ps-2'}>
                              <BeckResultIcon />
                            </span>
                          </DefaultTooltip>
                        )}
                        {data.has_rsca_result_alert && (
                          <DefaultTooltip
                            id={'child-overview-rsca-risk'}
                            tooltipText={t('rscaResult.HAS_RISK_TOOLTIP') + '\n(' + data.rsca_result_alert_date + ')'}
                          >
                            <span className={styles.bad}>
                              <RscaResultIcon />
                            </span>
                          </DefaultTooltip>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {props.permissionsMap.pagePermissions.canViewEducationTab ? (
                        <Link to={'../education/view'}>{t('child.tabs.EDUCATION_TAB')}</Link>
                      ) : (
                        <span>{t('child.tabs.EDUCATION_TAB')}</span>
                      )}
                    </Form.Label>
                    {isLoading && <Spinner />}
                    {data && (
                      <Col className='d-flex align-items-center'>
                        {lastGradeReportElement}
                        {data && data.child_training_skills_due_count > 0 && (
                          <span className={'ps-2'}>
                            <DueChildTrainingSkillIcon
                              className={styles.bad}
                              numberDue={data.child_training_skills_due_count}
                            />
                          </span>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {props.permissionsMap.pagePermissions.canViewTransitionTab ? (
                        <Link to={'../transition/view'}>{t('child.tabs.TRANSITION_TAB')}</Link>
                      ) : (
                        <span>{t('child.tabs.TRANSITION_TAB')}</span>
                      )}
                    </Form.Label>
                    {isLoading && <Spinner />}
                    {data && (
                      <Col className='d-flex align-items-center'>
                        {show_bachelors_section && (
                          <span>
                            {t('child.transition.BACHELORS')}:
                            <DefaultTooltip
                              id={'child-profile-higher_ed_school'}
                              tooltipText={props.child.higher_ed_bachelors_city + ', ' + props.child.higher_ed_bachelors_country}
                            >
                              <span>{props.child.higher_ed_bachelors_school_name}</span>
                            </DefaultTooltip>{' '}
                            {props.child.higher_ed_bachelors_graduated_date &&
                              <span className={styles.asOfDate}>
                              {t('child.transition.GRADUATED_ON')}{' '}
                                <span>{isoDateToCommonFormat(props.child.higher_ed_bachelors_graduated_date)}</span>
                              </span>
                            }
                            {!props.child.higher_ed_bachelors_graduated_date && props.child.higher_ed_bachelors_school_start_date &&
                              <span className={styles.asOfDate}>
                              {t('child.transition.STARTING_ON')}{' '}
                                <span>{isoDateToCommonFormat(props.child.higher_ed_bachelors_school_start_date)}</span>
                            </span>
                            }
                          </span>
                        )}
                        {props.child.job_status && (
                          <span className={'ps-2'}>
                            {findOptionLabelFromValue(props.child.job_status, JOB_STATUS_OPTIONS)}
                          </span>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {props.permissionsMap.pagePermissions.canViewSponsorshipTab ? (
                        <Link to={'../sponsorship/view'}>{t('child.tabs.SPONSOR_TAB')}</Link>
                      ) : (
                        <span>{t('child.tabs.SPONSOR_TAB')}</span>
                      )}
                    </Form.Label>
                    {isLoadingSponsorships && <Spinner />}
                    {!props.child.can_be_sponsored && (
                      <Col sm={9} className={'d-flex align-items-center'}>
                        <span>{t('child.sponsor.CANNOT_BE_SPONSORED')}</span>
                      </Col>
                    )}
                    {!isLoadingSponsorships &&
                      props.child.can_be_sponsored &&
                      (!sponsorshipData || sponsorshipData.length === 0) && (
                        <Col sm={9} className={'d-flex align-items-center ' + styles.noSponsors}>
                          <span>{t('child.sponsor.NO_SPONSORS')}</span>
                        </Col>
                      )}
                    {sponsorshipData && (
                      <Col sm={9} className='d-flex align-items-center'>
                        {sponsorshipData
                          .map((sponsorship) => {
                            return sponsorship.sponsor_name;
                          })
                          .join(', ')}
                      </Col>
                    )}
                  </Row>

                  <hr />

                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {t('common.PUBLIC_ID')}
                    </Form.Label>
                    <Col className='d-flex align-items-center'>{props.child.public_id}</Col>
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {t('child.overview.DATE_OF_BIRTH_ABBR')}
                    </Form.Label>
                    {mode === 'edit' && (
                      <Col sm={9}>
                        <Field as={Form.Control} type='date' name='date_of_birth' />
                        <ErrorMessage name='date_of_birth'>
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Col>
                    )}
                    {mode === 'view' && (
                      <Col className='d-flex align-items-center'>
                        {isoDateToCommonFormat(props.child.date_of_birth)}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {t('common.AGE')}
                    </Form.Label>
                    <Col className='d-flex align-items-center'>{props.child.age}</Col>
                  </Row>
                  <FormikSelect
                    label={t('common.CURRENT_CLASS')}
                    name='current_class'
                    options={CLASS_LEVEL_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                  />
                  <Row>
                    <Form.Label column='sm' sm={3}>
                      {t('common.GENDER')}
                    </Form.Label>
                    <Col className='d-flex align-items-center'>{props.child.gender}</Col>
                  </Row>
                  <FormikSelect
                    label={t('child.overview.IS_ELIGIBLE_FOR_CHILD_TRAININGS')}
                    name='is_eligible_for_child_trainings'
                    options={BOOLEAN_OPTIONS}
                    showSideBySide={true}
                    labelWidth={4}
                    isReadOnly={mode === 'view'}
                  />
                  <FormikSelect
                    label={t('child.overview.IS_ELIGIBLE_FOR_LIFE_SKILLS')}
                    name='is_eligible_for_life_skills'
                    options={BOOLEAN_OPTIONS}
                    showSideBySide={true}
                    labelWidth={4}
                    isReadOnly={mode === 'view'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <Accordion className={styles.thinAccordion + ' pt-3'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>{t('child.overview.INTAKE_COLLAPSE_TITLE')}</Accordion.Header>
                      <Accordion.Body>
                        <Container>
                          <Row>
                            <Col lg={6}>
                              <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                  {t('child.intake.INTAKE_DATE')}
                                </Form.Label>
                                {mode === 'edit' && (
                                  <Col>
                                    <Field as={Form.Control} type='date' name='intake_date' />
                                    <ErrorMessage name='intake_date'>
                                      {(errorMessage) => {
                                        return <CustomErrorMessage errorMessage={errorMessage} />;
                                      }}
                                    </ErrorMessage>
                                  </Col>
                                )}
                                {mode === 'view' && (
                                  <Col sm={9} className='d-flex align-items-center'>
                                    {isoDateToCommonFormat(props.child.intake_date)}
                                  </Col>
                                )}
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                  {t('child.intake.REFERENCE')}
                                </Form.Label>
                                {mode === 'edit' && (
                                  <Col sm={9}>
                                    <Field as={Form.Control} type='text' name='intake_reference' />
                                    <ErrorMessage name='intake_reference'>
                                      {(errorMessage) => {
                                        return <CustomErrorMessage errorMessage={errorMessage} />;
                                      }}
                                    </ErrorMessage>
                                  </Col>
                                )}
                                {mode === 'view' && (
                                  <Col sm={9} className='d-flex align-items-center'>
                                    {props.child.intake_reference}
                                  </Col>
                                )}
                              </Form.Group>
                              <FormikSinglePatchFileUpload
                                name={'intake_form_scan'}
                                label={t('child.intake.INTAKE_FORM_SCAN')}
                                isReadOnly={mode === Mode.VIEW}
                                showSideBySide={true}
                                labelWidth={3}
                              />
                              <FormikSinglePatchFileUpload
                                name={'intake_photo'}
                                label={t('child.intake.PHOTO')}
                                isReadOnly={mode === Mode.VIEW}
                                showSideBySide={true}
                                labelWidth={3}
                              />
                              {formikProps.values.intake_photo && (
                                <img alt='Child on Intake' height='200px' width='200px' src={intakePhotoUrl} />
                              )}
                              <FormikMultipleAsyncFileUpload
                                baseUrl={CHILD_INTAKE_ATTACHMENT_REST_URL}
                                name={'intake_attachments'}
                                label={t('child.intake.OTHER_INTAKE_DOCS')}
                                labelWidth={3}
                                isReadOnly={true}
                              />
                              <Alert variant={'warning'}>
                                <FontAwesomeIcon icon={faTriangleExclamation} color='orange' /> Cannot edit intake
                                documents at this time, message LJI IT
                              </Alert>
                              <br />
                              <h4>Intake Narratives</h4>
                              <Form.Group>
                                <Form.Label>{t('child.intake.PHYSICAL_NARRATIVE')}</Form.Label>
                                <p className={styles.narrative}>
                                  {replaceNewlinesWithBreaks(props.child.intake_physical_health_narrative)}
                                </p>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>{t('child.intake.EDUCATION_NARRATIVE')}</Form.Label>
                                <p className={styles.narrative}>
                                  {replaceNewlinesWithBreaks(props.child.intake_education_narrative)}
                                </p>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>{t('child.intake.GENERAL_NOTES')}</Form.Label>
                                <p className={styles.narrative}>
                                  {replaceNewlinesWithBreaks(props.child.intake_general_notes)}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
              {mode === 'edit' && (
                <Row>
                  <Col lg={10}>
                    {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                    <div className='d-flex justify-content-end'>
                      <Button
                        className='mx-2 my-2'
                        variant='secondary'
                        onClick={onCancel.bind(null, formikProps.resetForm)}
                      >
                        {t('common.CANCEL')}
                      </Button>
                      <Button className='my-2' variant='primary' type='submit' disabled={formikProps.isSubmitting}>
                        {t('common.SAVE_CHANGES')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildOverview;
export { BIO_STATE_OPTIONS, SITUATION_STATUS_OPTIONS, TRACK_STATUS_OPTIONS };
