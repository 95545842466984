import React from 'react';
import { ChildAlert } from '../../typings/api-types';
import { Domain } from '../../api/permissions_api';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import { useGetChildAlerts } from '../../api/alert_api';
import i18n from 'i18next';

type SearchChildAlertProps = {};

const getColumns = (onDelete: (objectToDelete: ChildAlert) => Promise<any>): ColDef<ChildAlert>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      width: 150,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 150,
    },
    {
      field: 'date',
      headerName: t('alert.DATE'),
      width: 150,
    },
    {
      field: 'summary',
      headerName: t('alert.SUMMARY'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('alert.STATUS'),
      width: 150,
    },
    {
      field: 'status_date',
      headerName: t('alert.STATUS_DATE'),
      width: 150,
    },
  ];
};

const SearchChildAlertPage = (props: SearchChildAlertProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'alert'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_ALERT}
      translatePrefix={'alert.'}
      useGetListApiHook={useGetChildAlerts}
    />
  );
};

export default SearchChildAlertPage;
