import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  faBrain,
  faGraduationCap,
  faHeartPulse,
  faPencil,
  faPersonWalkingLuggage,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BeckResultIcon } from '../../beckResult/BeckResultIcon/BeckResultIcon';
import { RscaResultIcon } from '../../rscaResult/RscaResultIcon/RscaResultIcon';
import { Domain, getDomainPermission, PermissionsMap } from '../../api/permissions_api';

type DataEntryDropdownProps = {
  permissionMap: PermissionsMap;
};

const DataEntryDropdown = (props: DataEntryDropdownProps) => {
  const { t } = useTranslation();

  const homeBmi = getDomainPermission(props.permissionMap, Domain.HOME_BMI);
  const childDoctorAppointment = getDomainPermission(props.permissionMap, Domain.CHILD_DOCTOR_APPOINTMENT);
  const vaccination = getDomainPermission(props.permissionMap, Domain.VACCINATION);
  const childEmotionalCheckIn = getDomainPermission(props.permissionMap, Domain.CHILD_EMOTIONAL_CHECK_IN);
  const childBeckResult = getDomainPermission(props.permissionMap, Domain.CHILD_BECK_RESULT);
  const childRscaResult = getDomainPermission(props.permissionMap, Domain.CHILD_RSCA_RESULT);
  const childWratResult = getDomainPermission(props.permissionMap, Domain.CHILD_WRAT_RESULT);
  const childToniResult = getDomainPermission(props.permissionMap, Domain.CHILD_TONI_RESULT);
  const childSeeResult = getDomainPermission(props.permissionMap, Domain.CHILD_SEE_RESULT);
  const childGradeReport = getDomainPermission(props.permissionMap, Domain.CHILD_GRADE_REPORT);
  const homeSkillTraining = getDomainPermission(props.permissionMap, Domain.HOME_SKILL_TRAINING);

  const canViewPhysicalHeader = homeBmi.canView || childDoctorAppointment.canView || vaccination.canView;
  const canViewMentalHeader = childEmotionalCheckIn.canView || childBeckResult.canView || childRscaResult.canView;
  const canViewEducationHeader =
    childWratResult.canView ||
    childToniResult.canView ||
    childSeeResult.canView ||
    childGradeReport.canView ||
    homeSkillTraining.canView;
  const canViewTransitionHeader = homeSkillTraining.canView;

  const canViewAnyHeader =
    canViewPhysicalHeader || canViewMentalHeader || canViewEducationHeader || canViewTransitionHeader;

  if (!canViewAnyHeader) {
    return null;
  }

  return (
    <Dropdown className="ms-1">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        <FontAwesomeIcon icon={faPencil} /> Data Entry
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {canViewPhysicalHeader && (
          <>
            <Dropdown.Header>
              <FontAwesomeIcon icon={faHeartPulse} color="salmon" /> {t('physical.TITLE')}
            </Dropdown.Header>
            {homeBmi.canView && (
              <Dropdown.Item as={Link} to="/home-bmi-check/search">
                <FontAwesomeIcon icon={'weight-scale'} /> {t('physical.BMI')}
              </Dropdown.Item>
            )}
            {childDoctorAppointment.canView && (
              <Dropdown.Item as={Link} to="child-doctor-appointment/search">
                <FontAwesomeIcon icon={'stethoscope'} /> {t('physical.DOCTOR_APPOINTMENTS')}
              </Dropdown.Item>
            )}
            {vaccination.canView && (
              <Dropdown.Item as={Link} to="vaccination/search">
                <FontAwesomeIcon icon={'syringe'} transform={'flip-h'} /> {t('physical.VACCINATIONS')}
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
          </>
        )}

        {canViewMentalHeader && (
          <>
            <Dropdown.Header>
              <FontAwesomeIcon icon={faBrain} color="pink" /> {t('mental.TITLE')}
            </Dropdown.Header>
            {childEmotionalCheckIn.canView && (
              <Dropdown.Item as={Link} to="/child-mental-check-in/search">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={'face-smile'} />
                </span>{' '}
                {t('mental.EMOTIONAL_CHECK_INS')}
              </Dropdown.Item>
            )}
            {childBeckResult.canView && (
              <Dropdown.Item as={Link} to="/child-beck-result/search">
                <BeckResultIcon /> {t('mental.BECK_RESULTS')}
              </Dropdown.Item>
            )}
            {childRscaResult.canView && (
              <Dropdown.Item as={Link} to="/child-rsca-result/search">
                <RscaResultIcon /> {t('mental.RSCA_RESULTS')}
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
          </>
        )}

        {canViewEducationHeader && (
          <>
            <Dropdown.Header>
              <FontAwesomeIcon icon={faGraduationCap} color="black" /> {t('education.TITLE')}
            </Dropdown.Header>
            {childWratResult.canView && (
              <Dropdown.Item as={Link} to="/child-wrat-result/search">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={icon({ name: 'file', style: 'regular' })} />
                  <FontAwesomeIcon size={'2xs'} icon={'w'} />
                </span>{' '}
                {t('education.WRAT5')}
              </Dropdown.Item>
            )}
            {childToniResult.canView && (
              <Dropdown.Item as={Link} to="/child-toni-result/search">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={icon({ name: 'file', style: 'regular' })} />
                  <FontAwesomeIcon size={'2xs'} icon={'t'} />
                </span>{' '}
                {t('education.TONI')}
              </Dropdown.Item>
            )}
            {childSeeResult.canView && (
              <Dropdown.Item as={Link} to="/child-see-result/search">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={icon({ name: 'file', style: 'regular' })} />
                  <FontAwesomeIcon size={'2xs'} icon={faGraduationCap} />
                </span>{' '}
                {t('education.SEE')}
              </Dropdown.Item>
            )}
            {childGradeReport.canView && (
              <Dropdown.Item as={Link} to="/child-grade-report/search">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={icon({ name: 'file', style: 'regular' })} />
                  <FontAwesomeIcon size={'2xs'} icon={'school'} />
                </span>{' '}
                {t('education.GRADE_REPORT')}
              </Dropdown.Item>
            )}
            {homeSkillTraining.canView && (
              <Dropdown.Item as={Link} to="/home-child-training/search">
                <FontAwesomeIcon icon={'person-chalkboard'} /> {t('education.CHILD_TRAININGS')}
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
          </>
        )}

        {canViewTransitionHeader && (
          <>
            <Dropdown.Header>
              <FontAwesomeIcon icon={faPersonWalkingLuggage} color="#FFAA33" /> {t('transition.TITLE')}
            </Dropdown.Header>
            {homeSkillTraining.canView && (
              <Dropdown.Item as={Link} to="/home-life-skill/search">
                <FontAwesomeIcon icon={faUserTie} /> {t('transition.LIFE_SKILL_TRAININGS')}
              </Dropdown.Item>
            )}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DataEntryDropdown;
