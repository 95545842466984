// Modifying does NOT have a generic HTML
// However there are some shared objects and functions

import React from 'react';

type ModifyModalState<DataType> = {
  isOpen: boolean;
  objectToModify: DataType | null;
};

function useModifyModal<DataType>() {
  const [modifyModalState, setModifyModalState] = React.useState({
    isOpen: false,
    objectToModify: null,
  } as ModifyModalState<DataType>);
  const openModifyModal = (objectToModify: DataType | null) => {
    setModifyModalState({
      isOpen: true,
      objectToModify: objectToModify,
    });
  };
  const closeModifyModal = () => {
    setModifyModalState({
      isOpen: false,
      objectToModify: null,
    });
  };

  return {
    modifyModalState,
    openModifyModal,
    // Formik handles disabling submission, no need for a disable method
    closeModifyModal,
  };
}

export type { ModifyModalState };
export { useModifyModal };
