import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ChildDoctorAppointment } from '../../typings/api-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultTooltip, { getCellId } from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { useTranslation } from 'react-i18next';
import styles from './DoctorAppointmentSummary.module.scss';

type DoctorAppointmentSummaryProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: ChildDoctorAppointment;
};
export const DoctorAppointmentSummary = (props: DoctorAppointmentSummaryProps) => {
  const { t } = useTranslation();
  const isEmergencyType = 'Sick/Emergency' === props.data.appointment_type;
  const displayNotesIcon = props.data.has_notes;
  const showDangerColor = (props.data.has_notes || isEmergencyType) && !props.data.is_chronic_condition;
  const showChronicColor = props.data.is_chronic_condition;
  let colorClass = '';
  if (showDangerColor) {
    colorClass = 'text-danger';
  } else if (showChronicColor) {
    colorClass = styles.colorClass;
  }

  return (
    <span className={colorClass}>
      <span>{props.value}</span>
      {props.data.is_chronic_condition && (
        <DefaultTooltip
          id={getCellId(props) + '-chronic-icon'}
          tooltipText={t('doctorAppointment.CHRONIC_ICON_TOOLTIP') + '\n\n"' + props.data.notes + '"'}
        >
          <FontAwesomeIcon className={'ps-2'} icon="arrows-spin" />
        </DefaultTooltip>
      )}
      {displayNotesIcon && (
        <DefaultTooltip
          id={getCellId(props) + '-notes-icon'}
          tooltipText={t('doctorAppointment.NOTES_ICON_TOOLTIP') + '\n\n"' + props.data.notes + '"'}
        >
          <FontAwesomeIcon className={'ps-2'} icon="file-alt" />
        </DefaultTooltip>
      )}
    </span>
  );
};

export default DoctorAppointmentSummary;
export type { DoctorAppointmentSummaryProps };
