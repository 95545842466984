import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { CountryWithEventTypes } from '../typings/api-types';

const COUNTRY_EVENT_TYPE_URL = '/country-event-type';

const useGetCountryEventTypes = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  // TODO investigate this rule
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetCountriesWithEvents', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<CountryWithEventTypes[]>(COUNTRY_EVENT_TYPE_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchCountryEventType = (country_id: number, event_type: string, accounts: number[]) => {
  if (country_id === undefined || event_type === undefined) {
    throw new Error('country_id or event_type was undefined, please set enabled property on useQuery');
  }
  const requestBody = {
    accounts_to_notify: accounts,
  };
  return axios.patch<any, any>(COUNTRY_EVENT_TYPE_URL + '/' + country_id + '/' + event_type + '/', requestBody);
};

export { useGetCountryEventTypes, patchCountryEventType };
