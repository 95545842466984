import React from 'react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import { deleteChildBeckResult, useGetChildBeckResults } from '../../api/beck_result_api';
import { ChildBeckResult } from '../../typings/api-types';
import BeckResultScoreCell from '../BeckResultScoreCell/BeckResultScoreCell';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ColDef } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchBeckPageProps = {};

const getColumns = (onDelete: (objectToDelete: ChildBeckResult) => Promise<any>): ColDef<ChildBeckResult>[] => {
  const t = i18n.t;
  return [
    {
      field: 'child_name',
      headerName: t('common.CHILD_NAME'),
      flex: 1,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 190,
    },
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('beckResult.SCORE'),
      cellRenderer: BeckResultScoreCell,
      width: 210,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-beck-result',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

const SearchBeckResultPage = (props: SearchBeckPageProps) => {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'beck-result'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD_BECK_RESULT}
      translatePrefix={'beckResult.'}
      useGetListApiHook={useGetChildBeckResults}
      deleteApiFunc={deleteChildBeckResult}
      createForChildPageUrl={'/child-beck-result/create/new'}
    />
  );
};

export default SearchBeckResultPage;
