function getReactSelectStylesForAgGridCell() {
  // Weird css-in-js-functions is from 'emotion'
  // https://react-select.com/styles
  const customStyles = {
    control: (providedStyles: any) => ({
      ...providedStyles,
      height: 25,
      minHeight: 25,
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    }),
    valueContainer: (providedStyles: any) => ({
      ...providedStyles,
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
      alignItems: 'start',
      height: '25px',
      display: 'flex',
    }),
    placeholder: (providedStyles: any) => ({
      ...providedStyles,
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    }),
    input: (providedStyles: any) => ({
      ...providedStyles,
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    }),
    indicatorsContainer: (providedStyles: any) => ({
      ...providedStyles,
      height: 25,
    }),
    menuPortal: (providedStyles: any) => ({
      ...providedStyles,
      zIndex: 9999,
    }),
    multiValue: (providedStyles: any) => ({
      ...providedStyles,
      height: 20,
      lineHeight: '15px',
    }),
  };
  return customStyles;
}

export { getReactSelectStylesForAgGridCell };
