import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { GuidanceSession } from '../../typings/api-types';
import styles from './GuidanceSessionList.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type GuidanceSessionListProps = {
  sessions: GuidanceSession[];
};
export const GuidanceSessionList = (props: GuidanceSessionListProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.sessions.map((session) => {
        let checklistIcon: IconProp;
        let colorClass;
        if (session.was_required) {
          checklistIcon = 'circle-check';
          colorClass = styles.wasRequired;
        } else {
          checklistIcon = 'circle';
        }
        return (
          <div key={session.id} className={colorClass}>
            <FontAwesomeIcon icon={checklistIcon} /> {session.activity_name}{' '}
            {session.notes && (
              <DefaultTooltip
                placement={'top'}
                id={'counseling-note'}
                tooltipText={'Note (click to copy):\n' + session.notes}
              >
                <span
                  onClick={() => {
                    if (session.notes) {
                      return navigator.clipboard.writeText(session.notes);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={icon({ name: 'file-lines', style: 'regular' })} />
                </span>
              </DefaultTooltip>
            )}{' '}
            {session.link && (
              <DefaultTooltip placement={'top'} id={'link-tooltip'} tooltipText={t('guidanceSession.LINK_TOOLTIP')}>
                <a href={session.link}>
                  <FontAwesomeIcon icon={'link'} />
                </a>
              </DefaultTooltip>
            )}{' '}
            {session.file && (
              <DefaultTooltip placement={'top'} id={'link-tooltip'} tooltipText={t('guidanceSession.FILE_TOOLTIP')}>
                <a href={session.file}>
                  <FontAwesomeIcon icon={'download'} />
                </a>
              </DefaultTooltip>
            )}{' '}
            <span className={styles.onDate}>on {isoDateToCommonFormat(session.date)}</span>{' '}
            <DefaultTooltip placement={'top'} id={'edit-session-tooltip'} tooltipText={t('common.EDIT')}>
              <Link
                className={styles.editLink}
                to={'/guidance-session/edit/' + session.id + '/?childId=' + session.child}
              >
                <FontAwesomeIcon icon={'pencil'} />
              </Link>
            </DefaultTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default GuidanceSessionList;
export type { GuidanceSessionListProps };
