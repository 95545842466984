import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styles from './DueVaccineIcon.module.scss';

type DueVaccineIconProps = {
  className?: string;
  numberDue: number;
};
export const DueVaccineIcon = (props: DueVaccineIconProps) => {
  const { t } = useTranslation();
  const className = props.className || '';
  return (
    <DefaultTooltip id={'due-vaccination-tooltip'} tooltipText={t('vaccination.DUE_VACCINATION_TOOLTIP')}>
      <span className={className + ' fa-layers fa-fw'}>
        <FontAwesomeIcon icon={'syringe'} transform={'right-4 flip-h'} />
        <FontAwesomeIcon style={{ color: 'white' }} icon={'circle'} transform={'shrink-6 left-3 down-4'} />
        <FontAwesomeIcon icon={icon({ name: 'clock', style: 'regular' })} transform={'shrink-6 left-3 down-4'} />
        <span className={styles.notificationCount}>{props.numberDue}</span>
      </span>
    </DefaultTooltip>
  );
};

export default DueVaccineIcon;
export type { DueVaccineIconProps };
