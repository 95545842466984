import * as React from 'react';
import styles from '../../../child/ChildEducation/ChildEducation.module.scss';
import { isoDateToCommonFormat } from '../../helpers/dateHelper';
import { useTranslation } from 'react-i18next';

type AsOfDateProps = {
  date: string;
  label?: string;
  // really just 'bad' or 'good'
  colorKey?: string;
};
export const AsOfDate = (props: AsOfDateProps) => {
  const { t } = useTranslation();
  let colorClass;
  if (props.colorKey === 'bad') {
    colorClass = styles.bad;
  } else if (props.colorKey === 'good') {
    colorClass = styles.good;
  }
  return (
    <span className={styles.asOfDate}>
      {props.label || t('common.AS_OF')} <span className={colorClass}>{isoDateToCommonFormat(props.date)}</span>
    </span>
  );
};

export default AsOfDate;
export type { AsOfDateProps };
