import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ChildAlert, ChildForRoster } from '../../typings/api-types';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import _ from 'lodash';
import styles from './AlertIconsCell.module.scss';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';

type AlertIconsCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildForRoster;
  };

// function getIconFromAlert(alert: ChildAlert) {
//   let icon = <FontAwesomeIcon icon={'question'} />;
//   if (alert.object_type === 'Vaccination') {
//     icon = <FontAwesomeIcon icon={'syringe'} transform={'flip-h'} />;
//   } else if (alert.object_type === 'ChildSkill') {
//     icon = <FontAwesomeIcon icon={'person-chalkboard'} />;
//   }
//   return (
//     <DefaultTooltip id={alert.id + '-alert-tooltip'} tooltipText={alert.object_type || ''}>
//       {icon}
//     </DefaultTooltip>
//   );
// }

function flattenAlertsToTextBlock(alerts: ChildAlert[]) {
  const alertTooltipsList = alerts
    .sort((alertA, alertB) => {
      //  Since date is always ISO format, alphabetical sort works.
      //  Going to sort in reverse so biggest years are first
      if (alertA.date > alertB.date) {
        return -1;
      } else if (alertA.date < alertB.date) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((alert) => {
      return isoDateToCommonFormat(alert.date) + ' - ' + alert.summary;
    });
  let textBlock = alertTooltipsList.join('\n');
  return textBlock;
}

function getIconFromAlertGroup(groupCode: string) {
  const unknownIcon = (
    <span>
      <FontAwesomeIcon className={styles.icon} icon={'question'} />
      {/*{groupCode}*/}
    </span>
  );

  let icon;
  if (groupCode === 'BIRTHDAY') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'cake-candles'} />;
  } else if (groupCode === 'SITUATION_STATUS_CHANGE') {
    icon = unknownIcon;
  } else if (groupCode === 'TRACK_CHANGE') {
    icon = unknownIcon;
  } else if (groupCode === 'NUTRITION_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'weight-scale'} />;
  } else if (groupCode === 'LATE_VACCINATIONS') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'syringe'} transform={'flip-h'} />;
  } else if (groupCode === 'EMOTIONAL_CHECK_IN_ALERT' || groupCode === 'EMOTIONAL_CHECK_IN_DISTRESSED_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'face-frown'} />;
  } else if (groupCode === 'BECK_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'b'} />;
  } else if (groupCode === 'RSCA_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'r'} />;
  } else if (groupCode === 'GRADE_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'school'} />;
  } else if (groupCode === 'CHILD_TRAINING_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'person-chalkboard'} />;
  } else if (groupCode === 'LIFE_SKILL_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'user-tie'} />;
  } else if (groupCode === 'BLANK_CHURCH_STATUS_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'file-circle-question'} />;
  } else if (groupCode === 'BLANK_JOB_STATUS_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'file-circle-question'} />;
  } else if (groupCode === 'BLANK_CITIZENSHIP_STATUS_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'file-circle-question'} />;
  } else if (groupCode === 'BLANK_SCHOLARSHIP_TIER_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={'file-circle-question'} />;
  } else if (groupCode === 'CHURCH_STATUS_ALERT') {
    // TODO should this be black but with an X or something instead of red?
    icon = <FontAwesomeIcon className={styles.icon} icon={'cross'} />;
  } else if (groupCode === 'JOB_STATUS_ALERT') {
    // TODO should this be black but with an X or something instead of red?
    icon = <FontAwesomeIcon className={styles.icon} icon={'briefcase'} />;
  } else if (groupCode === 'CITIZENSHIP_STATUS_ALERT') {
    // TODO should this be black but with an X or something instead of red?
    icon = <FontAwesomeIcon className={styles.icon} icon={'id-card'} />;
  } else if (groupCode === 'SPONSORSHIP_ALERT') {
    icon = <FontAwesomeIcon className={styles.icon} icon={faHandHoldingDollar} />;
  } else {
    icon = unknownIcon;
  }
  return icon;
}

function getImportanceFromAlertGroup(groupName: string) {
  let importance = 'low';
  if (
    groupName === 'BECK_ALERT' ||
    groupName === 'RSCA_ALERT' ||
    groupName === 'EMOTIONAL_CHECK_IN_DISTRESSED_ALERT' ||
    groupName === 'NUTRITION_ALERT' ||
    groupName === 'CHURCH_STATUS_ALERT' ||
    groupName === 'JOB_STATUS_ALERT' ||
    groupName === 'CITIZENSHIP_STATUS_ALERT'
  ) {
    importance = 'high';
  }
  return importance;
}

function getIconWithTooltipFromAlertGroup(childId: number | undefined, groupName: string, alerts: ChildAlert[]) {
  let tooltipText = flattenAlertsToTextBlock(alerts);
  if (groupName === 'EMOTIONAL_CHECK_IN_ALERT' || groupName === 'EMOTIONAL_CHECK_IN_DISTRESSED_ALERT') {
    // Hide details from roster for privacy
    // TODO we could allow these details with the right permission
    tooltipText = i18n.t('emotionalCheckIn.HAS_RISK');
  }
  const icon = getIconFromAlertGroup(groupName);
  let tooltipKey = childId + groupName + '-alert-tooltip';
  const importance = getImportanceFromAlertGroup(groupName);
  return (
    <DefaultTooltip key={tooltipKey} id={tooltipKey} tooltipText={tooltipText}>
      <span className={importance === 'high' ? styles.highImportance : ''}>{icon}</span>
    </DefaultTooltip>
  );
}

function getGroupName(alert: ChildAlert) {
  if (!alert.event_type) {
    return 'Unknown';
  } else {
    return alert.event_type;
  }
}

export const AlertIconsCell = (props: AlertIconsCellProps) => {
  const activeAlerts = props.data.active_alerts;
  if (!activeAlerts) {
    return <div />;
  }

  const groupedAlerts = _.groupBy(activeAlerts, getGroupName);

  return (
    <div>
      {Object.keys(groupedAlerts)
        .sort()
        .map((key) => {
          return getIconWithTooltipFromAlertGroup(props.data.id, key, groupedAlerts[key]);
        })}
    </div>
  );
};

export default AlertIconsCell;
export type { AlertIconsCellProps };
export { getGroupName, flattenAlertsToTextBlock, getIconFromAlertGroup, getIconWithTooltipFromAlertGroup };
