import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { School, Sponsorship } from '../typings/api-types';
import {
  SponsorshipPatch,
  SponsorshipPost,
} from '../typings/types-to-send';

const SPONSORSHIP_REST_URL = '/sponsorship';
const SPONSORSHIP_FOR_CHILD_REST_URL = '/sponsorship-for-child';

const useGetSponsorships = () => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetSponsorships', requestConfig], async () => {
    const response = await axios.get<Sponsorship[]>(SPONSORSHIP_REST_URL + '/', requestConfig);
    return response.data;
  });
};

const useGetSponsorshipDetail = (id: number | undefined, enabled?: boolean) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetSponsorshipDetail', requestConfig, id],
    async () => {
      const response = await axios.get<School>(SPONSORSHIP_REST_URL + '/' + id + '/', requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};


const patchSponsorship = (id: number | undefined, data: SponsorshipPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }

  return axios
    .patch<SponsorshipPatch, AxiosResponse<Sponsorship>>(
      SPONSORSHIP_REST_URL + '/' + id + '/',
      data,
    );
};

const postSponsorship = (data: SponsorshipPost) => {
  return axios
    .post<SponsorshipPost, AxiosResponse<Sponsorship>>(SPONSORSHIP_REST_URL + '/', data);
};

const useGetSponsorshipsForChild = (childId?: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetSponsorshipsForChild', requestConfig, childId],
    async () => {
      const response = await axios.get<Sponsorship[]>(
        SPONSORSHIP_FOR_CHILD_REST_URL + '/' + childId,
        requestConfig,
      );
      return response.data;
    },
    { enabled: !!childId },
  );
};

export { useGetSponsorships, useGetSponsorshipsForChild, useGetSponsorshipDetail, patchSponsorship, postSponsorship };
