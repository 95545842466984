import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ModifyModalState } from '../../shared/modals/ModifyModal/ModifyModal';
import { Form, Formik } from 'formik';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import { Mode } from '../../shared/helpers/modeHelper';
import { ChildPhoto } from '../../typings/api-types';
import { ChildPhotoPatch, ChildPhotoPost } from '../../typings/types-to-send';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';

export interface ModifyChildPhotoModalProps {
  childId: number;
  state: ModifyModalState<ChildPhoto>;
  onOk: (objectToModify: ChildPhotoPatch, idToEditPhoto: number | undefined) => Promise<any>;
  onCancel: () => void;
}

const getMode = (state: ModifyModalState<ChildPhoto>) => {
  if (state.objectToModify) {
    return Mode.EDIT;
  }
  return Mode.CREATE;
};

const CHILD_PHOTO_SCHEMA = Yup.object().shape({
  date: Yup.date().required('Required'),
  photo: Yup.mixed().required('Required'),
});

const ModifyChildPhotoModal = (props: ModifyChildPhotoModalProps) => {
  const { state, onOk, onCancel } = props;
  const { t } = useTranslation();
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableScheduleFields;
  let onSubmit;
  if (mode === Mode.EDIT) {
    // Tell typescript that object is definitely not null
    // TODO this is hacky, find a way to fix it
    const objectToModify = state.objectToModify!;
    const id = objectToModify.id;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: id,
      // child: objectToModify.child
      date: objectToModify.date,
      photo: objectToModify.photo,
    } as ChildPhotoPatch;
    onSubmit = (modifiedObject: ChildPhotoPatch | null, { setSubmitting }: any) => {
      setTimeout(() => {
        if (modifiedObject === null || !id) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('modifiedObject was null or id was null on edit, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(modifiedObject, id).then(() => {
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      date: '',
      child: props.childId,
    } as ChildPhotoPost;
    onSubmit = (newSchedule: ChildPhotoPost, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new objectToModify was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, undefined).then(() => {
          setSubmitting(false);
        });
      }, 400);
    };
  }

  return (
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={CHILD_PHOTO_SCHEMA}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => {
            // TODO this creates a memory leak
            //  but I don't know how to fix it https://stackoverflow.com/a/57781164/13815107
            let createdPhotoUrl = undefined;
            if (state.isOpen && mode === Mode.CREATE && formikProps.values.photo) {
              createdPhotoUrl = URL.createObjectURL(formikProps.values.photo);
            }
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {mode === Mode.CREATE ? t('child.photo.CREATE_MODAL_TITLE') : t('child.photo.EDIT_MODAL_TITLE')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormikDateInput name={'date'} label={t('child.photo.DATE')} formikProps={formikProps} />
                  <br />
                  {/*  No re-upload right now*/}
                  {mode === Mode.CREATE && (
                    <FormikSinglePatchFileUpload name={'photo'} label={t('child.photo.PHOTO')} />
                  )}
                  {mode === Mode.CREATE && formikProps.values.photo && (
                    <img
                      alt='Uploaded'
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                      }}
                      src={createdPhotoUrl}
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant='primary' type='submit' disabled={formikProps.isSubmitting}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      }
    </Modal>
  );
};

export default ModifyChildPhotoModal;
