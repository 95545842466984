import * as React from 'react';
import { ChildToniResult } from '../../typings/api-types';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';

type ChildToniScoreCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: ChildToniResult;
  };
export const ChildToniScoreCell = (props: ChildToniScoreCellProps) => {
  return (
    <span className={props.data.has_risk ? 'text-danger' : ''}>
      <span className={'pe-2'}>{roundTo2Decimals(props.data.percentage_score)}%</span>
    </span>
  );
};

export default ChildToniScoreCell;
export type { ChildToniScoreCellProps };
