import { Link, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import ChildOverview from '../ChildOverview/ChildOverview';
import React from 'react';
import ChildPhysical from '../ChildPhysical/ChildPhysical';
import ChildMental from '../ChildMental/ChildMental';
import ChildEducation from '../ChildEducation/ChildEducation';
import ChildTransition from '../ChildTransition/ChildTransition';
import { Col, Container, Nav, Row, Spinner } from 'react-bootstrap';
import { useGetChildDetail } from '../../api/child_api';
import { ChildDetail } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { Mode } from '../../shared/helpers/modeHelper';
import ChildSponsor from '../ChildSponsor/ChildSponsor';
import WithDomainPermission from '../../shared/components/WithDomainPermission/WithDomainPermission';

type ChildProps = {};

const ChildPage = (props: ChildProps) => {
  let { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useGetChildDetail(id);
  const { isLoadingPermissions, permissionMap } = usePermissions();

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(Mode.VIEW, getDomainPermission(permissionMap, Domain.CHILD))) {
    return <NoPermissionPage />;
  }

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (!data || !data.data) {
    return (
      <div>
        {t('child.shared.NO_CHILD_DATA')} {id}
      </div>
    );
  } else if (error) {
    return (
      <div>
        {t('child.shared.CHILD_DATA_ERROR')} {id}
      </div>
    );
  }
  const child: ChildDetail = data.data;
  const currentTabFromUrl = location.pathname.split('/')[3];

  let childIdNum = Number(id);
  return (
    <Container className="flex-grow-1 d-flex flex-column">
      <Row>
        <Col>
          <h1 className="text-center font-weight-bold">
            {child.name_with_nickname} ({child.gender}) - {child.public_id}
          </h1>
        </Col>
      </Row>
      <div className="border p-3 mb-3 flex-grow-1">
        <Nav variant="tabs" activeKey={currentTabFromUrl}>
          <Nav.Item>
            <Nav.Link eventKey="overview" as={Link} to={'overview/view'}>
              {t('child.tabs.OVERVIEW_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="physical"
              as={Link}
              to={'physical/view'}
              disabled={!permissionMap.pagePermissions.canViewPhysicalTab}
            >
              {t('child.tabs.PHYSICAL_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="mental"
              as={Link}
              to={'mental/view'}
              disabled={!permissionMap.pagePermissions.canViewMentalTab}
            >
              {t('child.tabs.MENTAL_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="education"
              as={Link}
              to={'education/view'}
              disabled={!permissionMap.pagePermissions.canViewEducationTab}
            >
              {t('child.tabs.EDUCATION_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="transition"
              as={Link}
              to={'transition/view'}
              disabled={!permissionMap.pagePermissions.canViewTransitionTab}
            >
              {t('child.tabs.TRANSITION_TAB')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="sponsorship"
              as={Link}
              to={'sponsorship/view'}
              disabled={!permissionMap.pagePermissions.canViewSponsorshipTab}
            >
              {t('child.tabs.SPONSOR_TAB')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route
            path="overview/:mode"
            element={<ChildOverview permissionsMap={permissionMap} child={child} refetchChild={refetch} />}
          />
          {id && (
            <Route
              path="physical/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.PHYSICAL_TAB}>
                  <ChildPhysical childId={Number(id)} age={child.age} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="mental/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.MENTAL_TAB}>
                  <ChildMental child={child} refetchChild={refetch} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="education/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.EDUCATION_TAB}>
                  <ChildEducation childId={childIdNum} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="transition/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.TRANSITION_TAB}>
                  <ChildTransition childId={childIdNum} child={child} refetchChild={refetch} />
                </WithDomainPermission>
              }
            />
          )}
          {id && (
            <Route
              path="sponsorship/:mode"
              element={
                <WithDomainPermission domainToCheck={Domain.SPONSOR_TAB}>
                  <ChildSponsor childId={childIdNum} child={child} refetchChild={refetch} />
                </WithDomainPermission>
              }
            />
          )}
          {id && <Route path="*" element={<Navigate to="overview/view" />} />}
        </Routes>
      </div>
    </Container>
  );
};

export default ChildPage;
