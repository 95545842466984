import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { Button, Col, Container, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { CHILD_INTAKE_ATTACHMENT_REST_URL, postChild } from '../../api/child_api';
import { ErrorMessage, Field, Form as FormikForm, Formik, FormikProps } from 'formik';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import * as Yup from 'yup';
import { ChildDetailPost } from '../../typings/types-to-send';
import { getTodayIsoDate } from '../../shared/helpers/dateHelper';
import CustomErrorMessage from '../../shared/formik/CustomErrorMessage/CustomErrorMessage';
import FormikMultipleAsyncFileUpload
  from '../../shared/formik/FormikMultipleAsyncFileUpload/FormikMultipleAsyncFileUpload';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import { Mode } from '../../shared/helpers/modeHelper';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { BIO_STATE_OPTIONS, SITUATION_STATUS_OPTIONS, TRACK_STATUS_OPTIONS } from '../ChildOverview/ChildOverview';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';

type ChildIntakeProps = {};

const DOMAIN = Domain.CHILD;

const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

const SITUATION_STATUS_STRINGS = SITUATION_STATUS_OPTIONS.map((option) => option.value);
const TRACK_STATUS_STRINGS = TRACK_STATUS_OPTIONS.map((option) => option.value);

const CHILD_INTAKE_FORM_SCHEMA = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  nickname: Yup.string().optional(),
  middle_name: Yup.string().optional(),
  last_name: Yup.string().required('Required'),
  public_id: Yup.string()
    .matches(/^[A-z]{3}\d{3}$/, 'Must be in the format `AAA111`')
    .required('Required'),
  gender: Yup.string().required('Required').oneOf(['M', 'F']),
  situation_status: Yup.string().oneOf(SITUATION_STATUS_STRINGS),
  track_status: Yup.string().oneOf(TRACK_STATUS_STRINGS),
  // Set by onSubmit
  // situation_status_last_updated
  date_of_birth: Yup.date().required('Required'),
  bio_state: Yup.string().required('Required'),
  intake_date: Yup.date().required('Required'),
  intake_reference: Yup.string().required('Required'),
  intake_photo: Yup.mixed().required('Required'),
  intake_form_scan: Yup.mixed().required('Required'),
  intake_other_docs: Yup.mixed().optional(),
  intake_class: Yup.number().required('Required'),
  intake_physical_health_narrative: Yup.string().required('Required'),
  intake_education_narrative: Yup.string().required('Required'),
  intake_general_notes: Yup.string().required('Required'),

  // Set by onSubmit
  // current_class

  // can_be_sponsored: Yup.boolean().required('Required'),
  home: Yup.number().required('Required'),
  // current_school: Yup.number().required('Required'),
});

const ChildIntakePage = (props: ChildIntakeProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: homeData } = useGetHomes();
  const [serverError, setServerError] = useState(false);

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('/child/roster');
  }

  async function onSubmit(data: ChildDetailPost, { setSubmitting }: any) {
    // TODO where should I put code like this?
    data.current_class = data.intake_class;
    data.situation_status_last_updated = getTodayIsoDate();
    data.current_school = null;

    setSubmitting(true);
    try {
      await postChild(data);
      setSubmitting(false);
      navigate('/child/roster');
    } catch (error) {
      setServerError(true);
    }
  }

  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = Mode.CREATE;

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  if (serverError) {
    return <ErrorPage />;
  }

  const FormControlTextArea = (props: any) => <FormControl as={'textarea'} {...props} />;

  return (
    <Container className="flex-grow-1 d-flex flex-column">
      <Row>
        <Col>
          <h1 className="text-center font-weight-bold">{t('child.intake.PAGE_TITLE')}</h1>
        </Col>
      </Row>
      <div className="border p-3 mb-3 flex-grow-1">
        <Formik
          initialValues={
            {
              age: undefined,
              first_name: '',
              middle_name: '',
              last_name: '',
              public_id: '',
              // If appointment_type should be '', need to fiddle with typescript definition
              gender: undefined,
              situation_status: 'IN_HOME',
              situation_status_last_updated: '',
              date_of_birth: '',
              bio_state: 'NO',
              intake_date: '',
              intake_reference: '',
              intake_form_scan: undefined,
              intake_photo: undefined,
              intake_class: undefined,
              intake_physical_health_narrative: '',
              intake_education_narrative: '',
              intake_general_notes: '',

              can_be_sponsored: false,
              home: undefined,
              current_school: undefined,
              track_status: undefined,
            } as ChildDetailPost
          }
          validationSchema={CHILD_INTAKE_FORM_SCHEMA}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<any>) => {
            // TODO this creates a memory leak
            //  but I don't know how to fix it https://stackoverflow.com/a/57781164/13815107
            let intakePhotoUrl = undefined;
            if (formikProps.values.intake_photo) {
              intakePhotoUrl = URL.createObjectURL(formikProps.values.intake_photo);
            }
            return (
              <FormikForm>
                <Container>
                  <Row>
                    <Col lg={10}>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          {t('child.intake.NAME')}
                        </Form.Label>
                        <Col sm={3}>
                          <Field as={Form.Control} type="text" name="first_name" />
                          <ErrorMessage name="first_name">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                        <Col sm={2}>
                          <span>"</span>
                          <span>
                            <Field
                              style={{ width: 'calc(100% - 15px)', display: 'inline' }}
                              as={Form.Control}
                              type="text"
                              name="nickname"
                            />
                          </span>
                          <span>"</span>
                          <ErrorMessage name="first_name">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                        <Col sm={4}>
                          <Field as={Form.Control} type="text" name="last_name" />
                          <ErrorMessage name="last_name">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                      </Form.Group>
                      <FormikSelect
                        name="home"
                        label={t('common.HOME_NAME')}
                        options={createHomeOptions(homeData)}
                        showSideBySide={true}
                        labelWidth={3}
                      />
                      <FormikSelect
                        name={'gender'}
                        label={t('common.GENDER')}
                        options={GENDER_OPTIONS}
                        showSideBySide={true}
                        labelWidth={3}
                      />
                      <br />
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          {t('common.PUBLIC_ID')}
                        </Form.Label>
                        <Col>
                          <Field as={Form.Control} type="text" name="public_id" />
                          <ErrorMessage name="public_id">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                      </Form.Group>
                      <FormikDateInput
                        name={'date_of_birth'}
                        label={t('child.intake.DATE_OF_BIRTH')}
                        formikProps={formikProps}
                        labelWidth={3}
                        showSideBySide={true}
                      />
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          {t('common.CURRENT_CLASS')}
                        </Form.Label>
                        <Col>
                          <Field as={Form.Control} type="number" name="intake_class" />
                          <ErrorMessage name="intake_class">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                      </Form.Group>
                      <FormikSelect
                        label={t('child.overview.TRACK_STATUS')}
                        name="track_status"
                        options={TRACK_STATUS_OPTIONS}
                        showSideBySide={true}
                        labelWidth={3}
                      />
                      <br />
                      <FormikSelect
                        label={t('child.intake.IS_BIO_CHILD')}
                        name="bio_state"
                        options={BIO_STATE_OPTIONS}
                        showSideBySide={true}
                        labelWidth={3}
                      />
                      <FormikDateInput
                        name={'intake_date'}
                        label={t('child.intake.INTAKE_DATE')}
                        formikProps={formikProps}
                        labelWidth={3}
                        showSideBySide={true}
                      />
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          {t('child.intake.REFERENCE')}
                        </Form.Label>
                        <Col sm={9}>
                          <Field as={Form.Control} type="text" name="intake_reference" />
                          <ErrorMessage name="intake_reference">
                            {(errorMessage) => {
                              return <CustomErrorMessage errorMessage={errorMessage} />;
                            }}
                          </ErrorMessage>
                        </Col>
                      </Form.Group>
                      <br />

                      <FormikSinglePatchFileUpload
                        name={'intake_form_scan'}
                        label={t('child.intake.INTAKE_FORM_SCAN')}
                      />
                      <br />

                      <FormikMultipleAsyncFileUpload
                        baseUrl={CHILD_INTAKE_ATTACHMENT_REST_URL}
                        name={'intake_attachments'}
                        label={t('child.intake.OTHER_INTAKE_DOCS')}
                      />
                      <br />

                      <FormikSinglePatchFileUpload name={'intake_photo'} label={t('child.intake.PHOTO')} />
                      {formikProps.values.intake_photo && (
                        <img alt="Child on Intake" height="200px" width="200px" src={intakePhotoUrl} />
                      )}
                      <FormikSelect
                        label={t('child.sponsor.CAN_BE_SPONSORED')}
                        name="can_be_sponsored"
                        options={BOOLEAN_OPTIONS}
                        showSideBySide={true}
                        labelWidth={4}
                      />
                      <br />
                      <h4>Intake Narratives</h4>
                      <Form.Group>
                        <Form.Label>{t('child.intake.PHYSICAL_NARRATIVE')}</Form.Label>
                        <Field as={FormControlTextArea} name="intake_physical_health_narrative" />
                        <ErrorMessage name="intake_physical_health_narrative">
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('child.intake.EDUCATION_NARRATIVE')}</Form.Label>
                        <Field as={FormControlTextArea} name="intake_education_narrative" />
                        <ErrorMessage name="intake_education_narrative">
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('child.intake.GENERAL_NOTES')}</Form.Label>
                        <Field as={FormControlTextArea} name="intake_general_notes" />
                        <ErrorMessage name="intake_general_notes">
                          {(errorMessage) => {
                            return <CustomErrorMessage errorMessage={errorMessage} />;
                          }}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10}>
                      {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                      <div className="d-flex justify-content-end">
                        <Button
                          className="mx-2 my-2"
                          variant="secondary"
                          onClick={onCancel.bind(null, formikProps.resetForm)}
                        >
                          {t('common.CANCEL')}
                        </Button>
                        <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                          {mode === Mode.CREATE ? t('common.CREATE') : t('common.SAVE_CHANGES')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default ChildIntakePage;
