import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postHomeGradeReport } from '../../api/grade_report_api';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  ChildAtHomeGradeReportPost,
  ChildGradeReportPatch,
  ChildGradeReportPost,
  HomeGradeReportPost,
} from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { getChildrenInHome } from '../../api/child_api';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { School, SimpleChildSummary } from '../../typings/api-types';
import i18n from 'i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import CustomErrorMessage from '../../shared/formik/CustomErrorMessage/CustomErrorMessage';
import {
  createLetterOptions,
  doesGradingTypeHaveGpa,
  doesGradingTypeHaveLetter,
  doesGradingTypeHavePercent,
} from '../gradingScaleHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import { useGetSchools } from '../../api/school_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';
import { GetRowIdParams } from 'ag-grid-community';

type HomeGradeReportProps = {};

const DOMAIN = Domain.CHILD_GRADE_REPORT;

const HOME_GRADE_REPORT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child_grade_reports: Yup.array()
    .of(
      Yup.object()
        .shape({
          // Auto-populated and can't be modified
          child: Yup.number().required('Required'),

          child_was_present: Yup.boolean().required('Required'),

          // Set when child loads
          school: Yup.number().required('Required'),

          received_percentage: Yup.number().min(0).max(100).optional(),
          received_letter: Yup.string().optional(),
          received_gpa: Yup.number().min(0).max(10).optional(),

          // Files - Sent in separate request, nullable because that's how clear on edit works
          original_scan: Yup.mixed().nullable().optional(),
        })
        .test(
          'must-receive-mark-if-present',
          'Percentage, Letter, or GPA is required',
          function ({ child_was_present, received_percentage, received_letter, received_gpa }) {
            const has_percentage = received_percentage !== undefined;
            const has_letter = received_letter !== '' && received_letter !== undefined && received_letter != null;
            const has_gpa = received_gpa !== undefined;
            if (child_was_present) {
              return has_percentage || has_letter || has_gpa;
            } else {
              return true;
            }
          },
        ),
    )
    .required('Required'),
});

function getBlankHomeCheckInNoChildren(): HomeGradeReportPost {
  return {
    home: undefined,
    date: '',
    // Null for no children loaded yet, empty array for no children in home
    child_grade_reports: null,
  };
}

function getInitialChildrenAtHomeReports(children: SimpleChildSummary[]): ChildAtHomeGradeReportPost[] {
  const childrenAtAppointment = children.map((child) => {
    return {
      date: '',
      child: child.id,
      school: child.current_school_id,
      school_name: child.current_school_name,
      grading_scale_type: child.current_school_grading_type,

      child_name: child.name,
      child_gender: child.gender,

      child_was_present: undefined,

      received_percentage: undefined,
      received_letter: '',
      received_gpa: undefined,

      original_scan: undefined,
    } as ChildGradeReportPost;
  });
  return childrenAtAppointment;
}

const getColumns = (mode: Mode, schoolData: School[] | undefined): ColDef[] => {
  const t = i18n.t;
  return [
    { headerName: t('common.CHILD'), field: 'child_name', flex: 1 },
    { headerName: t('common.GENDER'), field: 'child_gender', width: 90 },
    { headerName: t('gradeReport.SCHOOL_NAME'), field: 'school_name', width: 150 },
    {
      headerName: t('common.PRESENT'),
      field: 'child_was_present',
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <div>
            <FormikSelect
              name={`child_grade_reports[${params.rowIndex}].child_was_present`}
              options={BOOLEAN_OPTIONS}
              isReadOnly={false}
              isInAgGrid={true}
            />
            <ErrorMessage name={`child_grade_reports[${params.rowIndex}]`}>
              {(errorMessage) => {
                return <CustomErrorMessage errorMessage={errorMessage} />;
              }}
            </ErrorMessage>
          </div>
        );
      },
    },
    {
      headerName: t('gradeReport.RECEIVED_PERCENTAGE'),
      field: 'received_percentage',
      width: 200,
      cellRenderer: (params: any) => {
        const childGradeReport: ChildGradeReportPost = params.data;
        if (doesGradingTypeHavePercent(childGradeReport)) {
          return (
            <FormikTextInput name={`child_grade_reports[${params.rowIndex}].received_percentage`} isInAgGrid={true} />
          );
        } else {
          return (
            <span style={{ color: 'grey', fontStyle: 'italic' }}>{t('gradeReport.DOES_NOT_SEND_PERCENTAGE')}</span>
          );
        }
      },
    },
    {
      headerName: t('gradeReport.RECEIVED_LETTER'),
      field: 'received_letter',
      width: 200,
      cellRenderer: (params: any) => {
        const childGradeReport: ChildGradeReportPost = params.data;
        if (doesGradingTypeHaveLetter(childGradeReport)) {
          if (childGradeReport.school) {
            const letterOptions = createLetterOptions(Number(childGradeReport.school), schoolData);
            return (
              <FormikSelect
                name={`child_grade_reports[${params.rowIndex}].received_letter`}
                options={letterOptions}
                isReadOnly={mode === Mode.VIEW}
                isInAgGrid={true}
                defaultValue={''}
              />
            );
          } else {
            return <span>Loading school data</span>;
          }
        } else {
          return <span style={{ color: 'grey', fontStyle: 'italic' }}>{t('gradeReport.DOES_NOT_SEND_LETTER')}</span>;
        }
      },
    },
    {
      headerName: t('gradeReport.RECEIVED_GPA'),
      field: 'received_gpa',
      width: 200,
      cellRenderer: (params: any) => {
        const childGradeReport: ChildGradeReportPost = params.data;
        if (doesGradingTypeHaveGpa(childGradeReport)) {
          return <FormikTextInput name={`child_grade_reports[${params.rowIndex}].received_gpa`} isInAgGrid={true} />;
        } else {
          return <span style={{ color: 'grey', fontStyle: 'italic' }}>{t('gradeReport.DOES_NOT_SEND_GPA')}</span>;
        }
      },
    },
    {
      headerName: t('gradeReport.ORIGINAL_SCAN'),
      field: 'original_scan',
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <FormikSinglePatchFileUpload
            name={`child_grade_reports[${params.rowIndex}].original_scan`}
            isInAgGrid={true}
          />
        );
      },
    },
  ] as ColDef[];
};

/*
  As of 2023-05-12 this is CREATE ONLY, they will be saved as Child Doctor Appointments
 */
const HomeGradeReportPage = (props: HomeGradeReportProps) => {
  let { mode: modeString } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: homeData, isLoading: isHomesLoading } = useGetHomes();
  const { data: schoolData, isLoading: isSchoolsLoading } = useGetSchools();
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = parseMode(modeString);
  if (mode !== Mode.CREATE) {
    return <div>Please view or edit individually created Child Grade Reports</div>;
  }
  const columnDefs = getColumns(mode, schoolData);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  if (isHomesLoading || isSchoolsLoading) {
    return <Spinner />;
  }

  const homeAppointment = getBlankHomeCheckInNoChildren();
  const onSubmit = async (data: HomeGradeReportPost, { setSubmitting }: any) => {
    await postHomeGradeReport(data);
    // TODO error handling?
    setSubmitting(false);
    navigate('../child-grade-report/search/');
  };

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../child-grade-report/search');
  }

  const homeOptions = createHomeOptions(homeData);

  return (
    <Formik
      initialValues={homeAppointment}
      validationSchema={HOME_GRADE_REPORT_FORM_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeGradeReportPost>) => {
        function onHomeChange(newHome: any) {
          // setSelectedHome(newHome.value);
          formikProps.setFieldValue('child_grade_reports', undefined);
          getChildrenInHome(newHome.value).then((childData: SimpleChildSummary[]) => {
            formikProps.setFieldValue('child_grade_reports', getInitialChildrenAtHomeReports(childData));
          });
        }

        // Without this, when a cell changes the whole row component is deleted and remade
        // That causes inputs to lose focus, because the html input underneath was deleted and recreated
        // It also probably degrades performance
        const getRowId = (params: GetRowIdParams<ChildGradeReportPatch>) => {
          if (params.data.id) {
            return '' + params.data.id;
          } else {
            return '' + params.data.child;
          }
        };

        return (
          <FormikForm className={'d-flex flex-grow-1'}>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('gradeReport.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
              </Row>
              <Row className="flex-grow-1 pb-1">
                {!formikProps.values.home && <div>Please pick a home</div>}
                {formikProps.values.home && (
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <AgGridReact
                      getRowId={getRowId}
                      rowData={formikProps.values.child_grade_reports}
                      defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                      rowHeight={50}
                      columnDefs={columnDefs}
                      gridOptions={{
                        suppressContextMenu: true,
                      }}
                      rowSelection={'single'}
                      animateRows={true}
                    />
                  </div>
                )}
              </Row>
              <Row>
                <Col sm={12}>
                  {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                  <div className="d-flex justify-content-end">
                    <Button
                      className="mx-2 my-2"
                      variant="secondary"
                      onClick={onCancel.bind(null, formikProps.resetForm)}
                    >
                      {t('common.CANCEL')}
                    </Button>
                    <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                      {mode === Mode.CREATE ? t('common.CREATE') : t('common.SAVE_CHANGES')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default HomeGradeReportPage;
