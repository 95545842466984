import React, { useCallback, useMemo } from 'react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { patchCountryEventType, useGetCountryEventTypes } from '../../api/country_event_types_api';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { getDefaultColDef } from '../../shared/grid/gridComponentHelpers';
import { ICellRendererParams, IDetailCellRendererParams } from 'ag-grid-community';
import BooleanCell from '../../shared/grid/BooleanCell/BooleanCell';
import style from './SearchEventTypesPage.module.scss';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { getOpsManBaseUrl } from '../../shared/helpers/environmentHelper';
import AccountsMultiSelect from '../../shared/components/AccountsMultiSelect/AccountsMultiSelect';
import { AccountOption } from '../../api/account_api';
import { MultiValue } from 'react-select';
import _ from 'lodash';
import { Account } from '../../typings/api-types';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import i18n from 'i18next';

type SearchEventTypesPageProps = {};

function getColumnDefs(
  onAccountsUpdate: (country_id: number, event_type: string, selectedAccountIds: number[]) => void,
): ColDef<any>[] {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    // group cell renderer needed for expand / collapse icons
    { headerName: t('eventTypes.EVENT_TYPE'), field: 'event_type_label', flex: 1 },
    {
      headerName: t('eventTypes.NOTIFICATIONS'),
      field: 'accounts_to_notify',
      cellRenderer: (cellRendererParams: ICellRendererParams) => {
        const selectedAccounts = cellRendererParams.data.accounts_to_notify;
        const selectedAccountIds = selectedAccounts.map((account: Account) => {
          return account.id;
        });
        const onUpdateFromMultiSelect = _.debounce((selectedAccounts: MultiValue<AccountOption>) => {
          const selectedAccountIds = selectedAccounts
            .filter((accountOption) => {
              return accountOption.value !== undefined;
            })
            .map((accountOption) => {
              // Exclamation point because the filter should leave only numbers
              return accountOption.value!;
            });
          let country_id = cellRendererParams.data.country_id;
          return onAccountsUpdate(country_id, cellRendererParams.data.event_type, selectedAccountIds);
        }, 600);
        return <AccountsMultiSelect onChange={onUpdateFromMultiSelect} selectedAccountIds={selectedAccountIds} />;
      },
      flex: 2,
    },
    {
      headerName: t('eventTypes.IS_SET_UP_IN_OPS_MAN'),
      field: 'is_set_up_in_ops_man',
      cellRenderer: BooleanCell,
      cellClass: (params) => {
        return params.value ? style.good : '';
      },
      width: 90,
    },
    {
      headerName: t('eventTypes.TRIGGER_RULES'),
      valueGetter: (params) => {
        return params.data.trigger_rules.length || '';
      },
      cellRenderer: 'agGroupCellRenderer',
      flex: 1,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

// Functions copied from Ops Man
function getAssignedToString(assignedTo: any) {
  if (!assignedTo) {
    return 'UNASSIGNED!';
  } else if (!assignedTo.account || assignedTo.deleted) {
    return 'UNASSIGNED! - Was ' + getCallSignWithShortName(assignedTo);
  }
  return getCallSignWithShortName(assignedTo);
}

function getCallSignWithShortName(teamMember: any): string {
  let callSignWithName = teamMember.callSign;
  if (teamMember.account) {
    callSignWithName += ' - ' + getShortAccountName(teamMember.account);
  }
  return callSignWithName;
}

function getShortAccountName(account: any): string {
  let accountString;
  if (account.firstName) {
    accountString = account.firstName;
  } else if (account.email) {
    accountString = account.email.split('@')[0];
  } else {
    accountString = 'UNASSIGNED';
  }
  return accountString;
}

export default function SearchEventTypesPage(props: SearchEventTypesPageProps) {
  const { t } = useTranslation();
  const { data, error, isFetching, refetch } = useGetCountryEventTypes();

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: t('eventTypes.SUMMARY_TEMPLATE'),
            field: 'templateTaskToCreate.summaryTemplate',
            flex: 1,
          },
          {
            headerName: t('eventTypes.FCP'),
            field: 'fieldCoreProcess.name',
            width: 150,
          },
          {
            headerName: t('eventTypes.DAYS_TO_COMPLETE'),
            field: 'templateTaskToCreate.daysToComplete',
            width: 150,
          },
          {
            headerName: t('eventTypes.ASSIGNED_TO'),
            field: 'templateTaskToCreate.assignedToMember',
            valueGetter: (params) => getAssignedToString(params.data.templateTaskToCreate.assignedToMember),
            width: 180,
          },
          {
            headerName: t('eventTypes.WEIGHT'),
            field: 'templateTaskToCreate.totalPoints',
            width: 90,
          },
        ],
        defaultColDef: getDefaultColDef(),
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.trigger_rules);
      },
    } as IDetailCellRendererParams;
  }, [t]);

  const opsManCoreEventTypesEditLink = getOpsManBaseUrl() + '/field-core-assignments';

  const onAccountChange = useCallback(
    async (country_id: number, event_type: string, accounts: number[]) => {
      await patchCountryEventType(country_id, event_type, accounts);
      await refetch();
    },
    [refetch],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center font-weight-bold">{t('eventTypes.TITLE')}</h1>
          <a target={'_blank'} href={opsManCoreEventTypesEditLink} rel="noreferrer">
            {t('eventTypes.EDIT_LINK')}
          </a>

          <p className={'w-50'}>
            <b>{t('eventTypes.EDIT_INSTRUCTIONS_LABEL')}: </b>
            <em>{t('eventTypes.EDIT_INSTRUCTIONS')}</em>
            <br />
            <b>{t('eventTypes.ASSIGN_LABEL')}: </b>
            <em>{t('eventTypes.ASSIGN_INSTRUCTIONS')}</em>
          </p>
        </Col>
      </Row>
      <>
        {isFetching && <Spinner />}
        {error && <ErrorPage />}
        {data &&
          !isFetching &&
          data?.map((country) => {
            return (
              <Row key={country.country_id}>
                <h2>{country.country_name}</h2>
                <div
                  className="ag-theme-balham"
                  style={{
                    height: '400px',
                    width: '90%',
                  }}
                >
                  {/* masterDetail is an Enterprise Feature! */}
                  <AgGridReact
                    columnDefs={getColumnDefs(onAccountChange)}
                    rowData={country.event_types}
                    getRowId={(params) => params.data.event_type}
                    defaultColDef={getDefaultColDef()}
                    detailRowAutoHeight={true}
                    animateRows={true}
                    masterDetail={true}
                    detailCellRendererParams={detailCellRendererParams}
                  />
                </div>
              </Row>
            );
          })}
      </>
    </Container>
  );
}
