import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { useNavigate, useParams } from 'react-router-dom';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import { GradingScaleLevelAtSchool, SchoolPatch } from '../../typings/types-to-send';
import i18n from 'i18next';
import DefaultPageActionButtons from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import * as Yup from 'yup';
import { GetRowIdParams } from 'ag-grid-community';
import { patchSchool, postSchool, useGetSchoolDetail } from '../../api/school_api';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { createCountryOptions, useGetCountries } from '../../api/country_api';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';

type SchoolPageProps = {};

const DOMAIN = Domain.SCHOOL;

const SCHOOL_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  country: Yup.number().required('Required'),
  grading_scale_type: Yup.string().required('Required'),

  grading_scale_levels: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required('Required'),
        min_percent: Yup.number().required('Required'),
        max_percent: Yup.number().required('Required'),
        resulting_gpa: Yup.number().optional(),
      }),
    )
    .required('Required'),
});

const GRADING_SCALE_TYPE_OPTIONS = [
  {
    label: 'GPA Only',
    value: 'GPA_ONLY',
  },
  {
    label: 'Letter Grade and GPA',
    value: 'LETTER_AND_GPA',
  },
  {
    label: 'Average Percentage and Letter Grade',
    value: 'AVG_PERCENT_AND_LETTER',
  },
  {
    label: 'Average Percentage, Letter Grade, and GPA',
    value: 'AVE_PERCENT_LETTER_AND_GPA',
  },
];

const getColumns = (mode: Mode): ColDef[] => {
  const t = i18n.t;
  return [
    {
      field: 'letter_label',
      headerName: t('school.LETTER_LABEL'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
    {
      field: 'min_percent',
      headerName: t('school.MIN_PERCENT'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
    {
      field: 'max_percent',
      headerName: t('school.MAX_PERCENT'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
    {
      field: 'resulting_gpa',
      headerName: t('school.RESULTING_GPA'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
  ] as ColDef[];
};

function getBlankSchoolNoGradingScaleLevels(): SchoolPatch {
  return {
    country: undefined,
    name: '',
    grading_scale_type: '',
    // Undefined for no child_bmi_checks loaded yet, empty array for no child_bmi_checks in home
    grading_scale_levels: undefined,
  };
}

export default function SchoolPage(props: SchoolPageProps) {
  let { mode: modeString, id: idString } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mode = parseMode(modeString);
  const columnDefs = getColumns(mode);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }

  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: countryData, isLoading: isCountriesLoading } = useGetCountries();

  const { data, isLoading, error } = useGetSchoolDetail(id, hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  const countryOptions = createCountryOptions(countryData);

  let school: SchoolPatch;
  let onSubmit;
  let onCancel: (resetForm: (dataToResetTo?: any) => void) => void;
  if (mode === Mode.CREATE) {
    school = getBlankSchoolNoGradingScaleLevels();
    onSubmit = async (data: SchoolPatch, { setSubmitting }: any) => {
      try {
        await postSchool(data);
        // TODO error handling?
        setSubmitting(false);
        navigate('../school/search/');
      } catch (error) {
        setServerError(true);
      }
    };

    onCancel = (resetForm: () => void) => {
      resetForm();
      navigate('../school/search');
    };
  } else if (serverError) {
    return <div> Something went wrong, contact LJI IT </div>;
  } else if (hasNumberId) {
    if (isLoading || isCountriesLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    }

    school = data as SchoolPatch;

    onSubmit = async (data: SchoolPatch, { setSubmitting }: any) => {
      try {
        await patchSchool(data.id, data);
        // TODO error handling?
        setSubmitting(false);
        navigate('../school/view/' + idString);
      } catch (error) {
        setServerError(true);
      }
    };
    onCancel = (resetForm: () => void) => {
      resetForm();
      navigate('../school/view/' + idString);
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function onBack() {
    navigate('../school/search');
  }

  return (
    <Formik
      initialValues={school}
      validationSchema={SCHOOL_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<SchoolPatch>) => {
        // Without this, when a cell changes the whole row component is deleted and remade
        // That causes inputs to lose focus, because the html input underneath was deleted and recreated
        // It also probably degrades performance
        const getRowId = (params: GetRowIdParams<GradingScaleLevelAtSchool>) => {
          if (params.data.id) {
            return '' + params.data.id;
          } else {
            return '' + params.data.letter_label;
          }
        };

        return (
          <FormikForm className={'d-flex flex-grow-1'}>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('school.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={11}>
                  <FormikTextInput
                    name={'name'}
                    label={t('school.NAME')}
                    formikProps={formikProps}
                    // TODO Change when we tackle editing these
                    isReadOnly={true}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'country'}
                    label={t('school.COUNTRY')}
                    options={countryOptions}
                    // TODO Change when we tackle editing these
                    isReadOnly={true}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'grading_scale_type'}
                    label={t('school.GRADING_SCALE_TYPE')}
                    options={GRADING_SCALE_TYPE_OPTIONS}
                    // TODO Change when we tackle editing these
                    isReadOnly={true}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={1}>
                  {/* TODO Change when we tackle editing these */}
                  {/*<EditToggle*/}
                  {/*  mode={mode}*/}
                  {/*  formikProps={formikProps}*/}
                  {/*  editUrl={'../school/edit/' + idString}*/}
                  {/*  onCancel={onCancel.bind(null, formikProps.resetForm)}*/}
                  {/*/>*/}
                </Col>
              </Row>
              <Row className="flex-grow-1 pb-1">
                {!formikProps.values.grading_scale_type && <div>Please pick a grading scale type</div>}
                {formikProps.values.grading_scale_type && (
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <AgGridReact
                      getRowId={getRowId}
                      rowData={formikProps.values.grading_scale_levels}
                      defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                      rowHeight={50}
                      columnDefs={columnDefs}
                      gridOptions={{
                        suppressContextMenu: true,
                      }}
                      rowSelection={'single'}
                      animateRows={true}
                    />
                  </div>
                )}
              </Row>
              <DefaultPageActionButtons
                mode={mode}
                formikProps={formikProps}
                onCancelEdit={onCancel.bind(null, formikProps.resetForm)}
                onBack={onBack}
              />
              {/*<DisplayFormikState formikProps={formikProps} />*/}
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
