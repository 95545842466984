import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postHomeWratResult } from '../../api/wrat_result_api';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FieldArray, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildAtHomeWratResultPost, HomeWratResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { GenericOption } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildrenInHome } from '../../api/child_api';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { SimpleChildSummary } from '../../typings/api-types';
import i18n from 'i18next';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import DefaultPageActionButtons from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { CLASS_LEVEL_OPTIONS } from '../../shared/helpers/classLevelHelper';

type HomeWratResultProps = {};

const DOMAIN = Domain.CHILD_WRAT_RESULT;

let required_0_to_100_if_truthy = (values: any[], schema: any) => {
  if (values[0]) {
    return schema.min(0).max(100).required('Required');
  } else {
    return schema.nullable().optional();
  }
};

let required_0_to_20_if_truthy = (values: any[], schema: any) => {
  if (values[0]) {
    return schema.min(0).max(20).required('Required');
  } else {
    return schema.nullable().optional();
  }
};

const HOME_EMOTIONAL_CHECK_IN_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child_results: Yup.array()
    .of(
      Yup.object().shape({
        // Auto-populated and can't be modified
        child: Yup.number().required('Required'),

        child_was_present: Yup.boolean().required('Required'),

        class_at_time: Yup.number().nullable(),

        word_reading_raw_score: Yup.number().nullable().optional(),
        word_reading_percentage_score: Yup.number().when('child_was_present', required_0_to_100_if_truthy),
        word_reading_grade_level: Yup.number().when('child_was_present', required_0_to_20_if_truthy),

        spelling_raw_score: Yup.number().nullable().optional(),
        spelling_percentage_score: Yup.number().when('child_was_present', required_0_to_100_if_truthy),
        spelling_grade_level: Yup.number().when('child_was_present', required_0_to_20_if_truthy),

        math_computation_raw_score: Yup.number().nullable().optional(),
        math_computation_percentage_score: Yup.number().when('child_was_present', required_0_to_100_if_truthy),
        math_computation_grade_level: Yup.number().when('child_was_present', required_0_to_20_if_truthy),

        sentence_comprehension_raw_score: Yup.number().nullable().optional(),
        sentence_comprehension_percentage_score: Yup.number().when('child_was_present', required_0_to_100_if_truthy),
        sentence_comprehension_grade_level: Yup.number().when('child_was_present', required_0_to_20_if_truthy),

        reading_composite_raw_score: Yup.number().nullable().optional(),
        reading_composite_percentage_score: Yup.number().when('child_was_present', required_0_to_100_if_truthy),
      }),
    )
    .required('Required'),
});

function getBlankHomeResultNoChildren(): HomeWratResultPost {
  return {
    home: undefined,
    date: '',
    // Null for no child_results loaded yet, empty array for no child_results in home
    child_results: null,
  };
}

function setInitialChildrenAtHomeCheckIn(formikProps: any, children: SimpleChildSummary[]): void {
  const childrenAtAppointment = children.map((child) => {
    return {
      child: child.id,

      child_was_present: undefined,

      class_at_time: child.current_class,

      word_reading_raw_score: undefined,
      word_reading_percentage_score: undefined,
      word_reading_grade_level: undefined,

      spelling_raw_score: undefined,
      spelling_percentage_score: undefined,
      spelling_grade_level: undefined,

      math_computation_raw_score: undefined,
      math_computation_percentage_score: undefined,
      math_computation_grade_level: undefined,

      sentence_comprehension_raw_score: undefined,
      sentence_comprehension_percentage_score: undefined,
      sentence_comprehension_grade_level: undefined,

      reading_composite_raw_score: undefined,
      reading_composite_percentage_score: undefined,
      //  No reading composite percentage score
    } as ChildAtHomeWratResultPost;
  });
  formikProps.setFieldValue('child_results', childrenAtAppointment);
}

function getChildrenAtCheckInGrid(formikProps: any, childOptions: GenericOption[], mode: Mode) {
  const t = i18n.t;
  return (
    <FieldArray
      name={'child_results'}
      render={(arrayHelpers) => {
        const children = formikProps.values.child_results;
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('common.CHILD')}</th>
                <th>{t('wratResult.IS_PRESENT')}</th>
                <th>{t('wratResult.CLASS_AT_TIME')}</th>

                <th>{t('wratResult.WORD_READING')}</th>
                <th>{t('wratResult.SPELLING')}</th>
                <th>{t('wratResult.MATH_COMPUTATION')}</th>
                <th>{t('wratResult.SENTENCE_COMPREHENSION')}</th>
                <th>{t('wratResult.READING_COMPOSITE')}</th>
              </tr>
            </thead>
            <tbody>
              {children && children.length > 0 ? (
                children.map((childOfHomeAppointment: ChildAtHomeWratResultPost, index: number) => (
                  <tr key={index}>
                    <td>
                      <FormikSelect name={`child_results[${index}].child`} options={childOptions} isReadOnly={true} />
                    </td>
                    <td>
                      <FormikSelect
                        name={`child_results[${index}].child_was_present`}
                        options={BOOLEAN_OPTIONS}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                    <td>
                      <FormikSelect
                        name={`child_results[${index}].class_at_time`}
                        options={CLASS_LEVEL_OPTIONS}
                        isReadOnly={mode === 'view'}
                      />
                    </td>
                    <td>
                      <FormikTextInput
                        label={t('wratResult.RAW_SCORE_ABBR')}
                        name={`child_results[${index}].word_reading_raw_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.PERCENTAGE_SCORE_ABBR')}
                        name={`child_results[${index}].word_reading_percentage_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.GRADE_LEVEL_ABBR')}
                        name={`child_results[${index}].word_reading_grade_level`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={5}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                    <td>
                      <FormikTextInput
                        label={t('wratResult.RAW_SCORE_ABBR')}
                        name={`child_results[${index}].spelling_raw_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.PERCENTAGE_SCORE_ABBR')}
                        name={`child_results[${index}].spelling_percentage_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.GRADE_LEVEL_ABBR')}
                        name={`child_results[${index}].spelling_grade_level`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={5}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                    <td>
                      <FormikTextInput
                        label={t('wratResult.RAW_SCORE_ABBR')}
                        name={`child_results[${index}].math_computation_raw_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.PERCENTAGE_SCORE_ABBR')}
                        name={`child_results[${index}].math_computation_percentage_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.GRADE_LEVEL_ABBR')}
                        name={`child_results[${index}].math_computation_grade_level`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={5}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                    <td>
                      <FormikTextInput
                        label={t('wratResult.RAW_SCORE_ABBR')}
                        name={`child_results[${index}].sentence_comprehension_raw_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.PERCENTAGE_SCORE_ABBR')}
                        name={`child_results[${index}].sentence_comprehension_percentage_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.GRADE_LEVEL_ABBR')}
                        name={`child_results[${index}].sentence_comprehension_grade_level`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={5}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                    <td>
                      <FormikTextInput
                        label={t('wratResult.RAW_SCORE_ABBR')}
                        name={`child_results[${index}].reading_composite_raw_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                      <FormikTextInput
                        label={t('wratResult.PERCENTAGE_SCORE_ABBR')}
                        name={`child_results[${index}].reading_composite_percentage_score`}
                        formikProps={formikProps}
                        showSideBySide={true}
                        labelWidth={4}
                        isReadOnly={mode === Mode.VIEW}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No children in this home</td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      }}
    />
  );
}

/*
  As of 2023-05-12 this is CREATE ONLY, they will be saved as Child Doctor Appointments
 */
const HomeWratResultPage = (props: HomeWratResultProps) => {
  let { mode: modeString, id: idString } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mode = parseMode(modeString);

  // const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  // let id: number | undefined = undefined;
  // if (idString) {
  //   id = parseInt(idString);
  // }

  const [serverError, setServerError] = useState(false);
  const [selectedHome, setSelectedHome] = useState<number | undefined>(undefined);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: homeData } = useGetHomes();
  const {
    data: childData,
    isFetching: isChildrenFetching,
    isIdle: isChildrenIdle,
  } = useGetChildrenInHome(selectedHome);

  // const { data, isLoading, error } = useGetHomeWratResultDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  let homeWratResult;
  let onSubmit;
  let onCancel: (resetForm: (dataToResetTo?: any) => void) => void;
  if (mode === Mode.CREATE) {
    homeWratResult = getBlankHomeResultNoChildren();
    onSubmit = async (data: HomeWratResultPost, { setSubmitting }: any) => {
      try {
        await postHomeWratResult(data);
        // TODO error handling?
        setSubmitting(false);
        navigate('../child-wrat-result/search/');
      } catch (error) {
        setServerError(true);
      }
    };

    onCancel = (resetForm: () => void) => {
      resetForm();
      navigate('../child-wrat-result/search');
    };
  } else if (serverError) {
    return <div> Something went wrong, contact LJI IT </div>;
  }
  // else if (hasNumberId) {
  //   if (isLoading || isHomesLoading || isChildrenFetching) {
  //     return <Spinner animation="border" role="status" />;
  //   } else if (!data) {
  //     return <div>Could not find data for {id}</div>;
  //   } else if (error) {
  //     return <div>Error loading {id}</div>;
  //  }
  //   homeWratResult = data as HomeWratResultPatch;
  //
  //   if (!selectedHome) {
  //     setSelectedHome(homeWratResult.home);
  //   }
  //
  //   onSubmit = async (data: HomeWratResultPatch, { setSubmitting }: any) => {
  //     try {
  //       // TODO where should I put code like this?
  //       // delete data.intake_photo;
  //       // delete data.intake_form_scan;
  //       await patchHomeWratResult(data.id, data);
  //       // TODO error handling?
  //       setSubmitting(false);
  //       navigate('../home-wrat-result/view/' + idString);
  //     } catch (error) {
  //       setServerError(true);
  //     }
  //   };
  //   onCancel = (resetForm: () => void) => {
  //     resetForm();
  //     navigate('../home-wrat-result/view/' + idString);
  //   };
  // }
  else {
    throw Error('Bad id: ' + idString);
  }

  function onBack() {
    navigate('../child-wrat-result/search');
  }

  return (
    <Formik
      initialValues={homeWratResult}
      validationSchema={HOME_EMOTIONAL_CHECK_IN_FORM_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeWratResultPost>) => {
        function onHomeChange(newHome: any) {
          setSelectedHome(newHome.value);
          formikProps.setFieldValue('child_results', null);
        }

        if (mode === Mode.CREATE) {
          if (!formikProps.values.child_results && childData) {
            // This seems like a bad place to set this state
            setInitialChildrenAtHomeCheckIn(formikProps, childData);
          }
        }
        return (
          <FormikForm>
            <Container fluid className={'p-4'}>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('wratResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={11}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    // isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    // isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                {/*<Col sm={1}>*/}
                {/*  <EditToggle*/}
                {/*    mode={mode}*/}
                {/*    formikProps={formikProps}*/}
                {/*    editUrl={'../home-wrat-result/edit/' + idString}*/}
                {/*    onCancel={onCancel.bind(null, formikProps.resetForm)}*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>
              <br />
              <Row>
                {isChildrenIdle && <div>Please pick a home</div>}
                {isChildrenFetching && <Spinner />}
                {formikProps.values.home &&
                  !isChildrenIdle &&
                  !isChildrenFetching &&
                  getChildrenAtCheckInGrid(formikProps, childOptions, mode)}
              </Row>
              <DefaultPageActionButtons
                mode={mode}
                formikProps={formikProps}
                onCancelEdit={onCancel.bind(null, formikProps.resetForm)}
                onBack={onBack}
              />
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default HomeWratResultPage;
