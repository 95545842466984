import React from 'react';
import { useTranslation } from 'react-i18next';

const NoPermissionPage = () => {
  const { t } = useTranslation();

  return (
    <p>
      {t('common.NO_PERMISSION_TITLE')}
      <br />
      {t('common.NO_PERMISSION_BODY')}
    </p>
  );
};

export default NoPermissionPage;
