import * as React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ChildBeckResultPage.module.scss';

type RiskLevelDisplayProps = {
  riskLevel?: string;
  shouldDisplay: boolean;
};

enum RiskLevel {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  CONCERNING = 'CONCERNING',
  HIGH = 'HIGH',
}

function getRiskLevelIcon(riskLevelString: string) {
  if (riskLevelString === RiskLevel.LOW) {
    return 'arrow-down';
  } else if (riskLevelString === RiskLevel.NORMAL) {
    return 'minus';
  } else if (riskLevelString === RiskLevel.CONCERNING) {
    return 'arrow-up';
  } else if (riskLevelString === RiskLevel.HIGH) {
    return 'angles-up';
  } else {
    throw Error("Tried to display icon that doesn't exist. Use {getRiskLevelIcon() && ( element )}");
  }
}

function getRiskLevelClass(riskLevelString: string) {
  if (riskLevelString === RiskLevel.LOW) {
    return styles.lowRisk;
  } else if (riskLevelString === RiskLevel.NORMAL) {
    return styles.normalRisk;
  } else if (riskLevelString === RiskLevel.CONCERNING) {
    return styles.concerningRisk;
  } else if (riskLevelString === RiskLevel.HIGH) {
    return styles.highRisk;
  } else {
    return '';
  }
}

export const RiskLevelDisplay = (props: RiskLevelDisplayProps) => {
  const riskLevel = props.riskLevel;
  if (props.shouldDisplay && riskLevel) {
    return (
      <Col className={'d-flex'}>
        <span className={'d-flex align-items-center ' + getRiskLevelClass(riskLevel)}>
          {getRiskLevelIcon(riskLevel) && <FontAwesomeIcon className={'pe-2'} icon={getRiskLevelIcon(riskLevel)} />}{' '}
          {riskLevel} Risk
        </span>
      </Col>
    );
  } else {
    return null;
  }
};

export default RiskLevelDisplay;
export { RiskLevel };
export type { RiskLevelDisplayProps };
