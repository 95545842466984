import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildGradeReport } from '../typings/api-types';
import { ChildGradeReportPatch, ChildGradeReportPost, HomeGradeReportPost } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const CHILD_GRADE_REPORT_REST_URL = '/child-grade-report';
const CHILD_GRADE_REPORT_ATTACHMENT_REST_URL = '/child-grade-report-attachment';

const useGetChildGradeReports = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildGradeReports', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildGradeReport[]>(CHILD_GRADE_REPORT_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildGradeReportDetail = (id: number | undefined, params?: any, onSuccess?: any, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildGradeReports', requestConfig, id],
    async () => {
      const response = await axios.get<ChildGradeReport>(CHILD_GRADE_REPORT_REST_URL + '/' + id, requestConfig);
      return response?.data;
    },
    {
      onSuccess,
      enabled,
    },
  );
};

const patchChildGradeReport = (id: number | undefined, dataWithFiles: ChildGradeReportPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);

  // Send nulls for files to backend on first patch,
  // All new files do on a separate patch
  // Sending null as a multi-part-form doesn't work
  if (dataWithFiles.original_scan !== null) {
    delete dataWithoutFiles.original_scan;
  }

  return axios
    .patch<ChildGradeReportPatch, AxiosResponse<ChildGradeReport>>(
      CHILD_GRADE_REPORT_REST_URL + '/' + id + '/',
      dataWithoutFiles,
    )
    .then((response) => {
      dataWithoutFiles.original_scan = originalScan;
      return updateFiles(response, originalScan);
    });
};

const postChildGradeReport = (dataWithFiles: ChildGradeReportPost) => {
  const originalScan = dataWithFiles.original_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.original_scan;
  return axios
    .post<ChildGradeReportPost, AxiosResponse<ChildGradeReport>>(CHILD_GRADE_REPORT_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, originalScan);
    });
};

function updateFiles(response: AxiosResponse<ChildGradeReport>, originalScan: any) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  if (!isFileObject(originalScan)) {
    return response;
  }
  console.debug('Got what looks like a file in updateFiles, sending to server');
  const fileFieldsToReplace = {
    original_scan: originalScan,
  };
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios
    .patch<FormData, AxiosResponse<ChildGradeReport>>(CHILD_GRADE_REPORT_REST_URL + '/' + id + '/', formData)
    .then((response) => {
      return response;
    });
}

// THERE IS NO HOME ENDPOINT!
// Lazy way, just loop through every child and send to the backend
// TODO do this more efficiently with less calls
const postHomeGradeReport = (dataWithFiles: HomeGradeReportPost) => {
  const children = dataWithFiles.child_grade_reports;
  if (children) {
    const promises = children
      .filter((child) => child.child_was_present)
      .map((childWithReport) => {
        const childGradeReport: ChildGradeReportPost = {
          date: dataWithFiles.date,
          home: dataWithFiles.home,
          child: childWithReport.child,
          school: childWithReport.school,
          received_percentage: childWithReport.received_percentage,
          received_letter: childWithReport.received_letter,
          received_gpa: childWithReport.received_gpa,
          original_scan: childWithReport.original_scan,
        };
        return postChildGradeReport(childGradeReport);
      });
    return Promise.all(promises).then(() => console.log('All grade report in promises finished'));
  } else {
    return Promise.resolve();
  }
};

const deleteChildGradeReport = (id: number) => {
  return axios.delete(CHILD_GRADE_REPORT_REST_URL + '/' + id + '/');
};

export {
  useGetChildGradeReports,
  useGetChildGradeReportDetail,
  patchChildGradeReport,
  postChildGradeReport,
  postHomeGradeReport,
  deleteChildGradeReport,
  CHILD_GRADE_REPORT_ATTACHMENT_REST_URL,
};
