import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { useTranslation } from 'react-i18next';

type DueDoctorAppointmentIconProps = {
  className?: string;
};
export const DueDoctorAppointmentIcon = (props: DueDoctorAppointmentIconProps) => {
  const { t } = useTranslation();
  const className = props.className || '';
  return (
    <DefaultTooltip
      id={'due-doctor-appointment-tooltip'}
      tooltipText={t('doctorAppointment.DUE_DOCTOR_APPOINTMENT_TOOLTIP')}
    >
      <span className={className + ' fa-layers fa-fw'}>
        <FontAwesomeIcon icon={'stethoscope'} transform={'right-4'} />
        <FontAwesomeIcon style={{ color: 'white' }} icon={'circle'} transform={'shrink-6 left-3 down-4'} />
        <FontAwesomeIcon icon={icon({ name: 'clock', style: 'regular' })} transform={'shrink-6 left-3 down-4'} />
      </span>
    </DefaultTooltip>
  );
};

export default DueDoctorAppointmentIcon;
export type { DueDoctorAppointmentIconProps };
