import { useGetMonthlyImpact } from '../../api/monthly_impact_api';
import { Col, Container, Row, Spinner, Stack } from 'react-bootstrap';
import React from 'react';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';
import { findOptionLabelFromValue } from '../../shared/helpers/optionsHelper';
import { SITUATION_STATUS_OPTIONS } from '../../child/ChildOverview/ChildOverview';
import styles from './MonthlyImpactPage.module.css';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';

type MonthlyImpactPageProps = {};
export const MonthlyImpactPage = (props: MonthlyImpactPageProps) => {
  const { data, isLoading, error } = useGetMonthlyImpact();

  if (isLoading) {
    return <Spinner animation='border' role='status' />;
  } else if (!data) {
    return <div>Could not find monthly impact data</div>;
  } else if (error) {
    return <div>Error loading monthly impact</div>;
  }

  return (
    <Container>
      <h1>Monthly Impact</h1>
      <br />
      <Row>
        <Col xs={6}>
          <h3 className={styles.heading}>Kids in Numbers</h3>
        </Col>
        <Col>
          <span className={styles.periodText}>(as of today)</span>
        </Col>
      </Row>
      <Stack gap={3}>
        {data.counts_by_situation_status && data.counts_by_situation_status.map((statusCount) => {
          return <Row>
            <Col xs={6}>
              <span
                className={styles.dataLabel}>{findOptionLabelFromValue(statusCount.situation_status, SITUATION_STATUS_OPTIONS)}</span>
            </Col>
            <Col>
              <span className={styles.dataDisplay}>{statusCount.child_count}</span>
            </Col>
          </Row>;
        })}
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Total Children in Childlight</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.total_children}</span>
          </Col>
        </Row>
      </Stack>
      <br />

      <Row>
        <Col xs={6}>
          <h3 className={styles.heading}>Physical</h3>
        </Col>
        <Col>
          <span className={styles.periodText}>(from {isoDateToCommonFormat(data.month_start)} - {isoDateToCommonFormat(data.month_end)})</span>
        </Col>
      </Row>
      <Stack gap={3}>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Malnourished Count</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.malnourished_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Malnourished Percent (of total BMI checks)</span>
          </Col>
          <Col>
            <span
              className={styles.dataDisplay}>{data.malnourished_percent !== undefined && roundTo2Decimals(data.malnourished_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Severely Malnourished Count</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.severely_malnourished_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Severely Malnourished Percent (of total BMI checks)</span>
          </Col>
          <Col>
            <span
              className={styles.dataDisplay}>{data.severely_malnourished_percent !== undefined && roundTo2Decimals(data.severely_malnourished_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Total BMI checks</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.total_child_bmi_checks}</span>
          </Col>
        </Row>
      </Stack>
      <br />

      <Row>
        <Col xs={6}>
          <h3 className={styles.heading}>Mental</h3>
        </Col>
        <Col>
          <span className={styles.periodText}>(from {isoDateToCommonFormat(data.month_start)} - {isoDateToCommonFormat(data.month_end)})</span>
        </Col>
      </Row>
      <Stack gap={3}>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Average Emotional Check-In Score</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{roundTo2Decimals(data.average_emotional_check_in_score)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Average Happy Score</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{roundTo2Decimals(data.average_happy_score)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Average Calm Score</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{roundTo2Decimals(data.average_calm_score)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Average Peaceful Score</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{roundTo2Decimals(data.average_peaceful_score)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Average Accepted Score</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{roundTo2Decimals(data.average_accepted_score)}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Distressed Count</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.distressed_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Distressed Percent</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.distressed_percent &&
              roundTo2Decimals(data.distressed_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Total Emotional Check-ins</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.total_emotional_check_ins}</span>
          </Col>
        </Row>
      </Stack>
      <br />

      <Row>
        <Col xs={6}>
          <h3 className={styles.heading}>Children's Futures</h3>
        </Col>
        <Col>
          <span className={styles.periodText}>(as of today)</span>
        </Col>
      </Row>
      <Stack gap={3}>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Count of Graduates of FH Program</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduate_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates with Bachelor's</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_with_bachelors_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates with Bachelor's %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_with_bachelors_percent &&
              roundTo2Decimals(data.graduates_with_bachelors_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates with Full Time Employment</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_with_full_time_employment_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates with Full Time Employment %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_with_full_time_employment_percent &&
              roundTo2Decimals(data.graduates_with_full_time_employment_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates Attending Church</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_attending_church_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Graduates Attending Church %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.graduates_attending_church_percent &&
              roundTo2Decimals(data.graduates_attending_church_percent * 100)}%</span>
          </Col>
        </Row>
        <br />

        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Independent Living</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_count}</span>
          </Col>
        </Row>
        <Row style={{color: 'red'}}>
          <Col xs={6}>
            <span className={styles.dataLabel}>BAD DATA - Independent Living with a Bachelors</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_with_bachelors_count}</span>
          </Col>
        </Row>
        <Row style={{color: 'red'}}>
          <Col xs={6}>
            <span className={styles.dataLabel}>BAD DATA - Independent Living with a Bachelors %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_with_bachelors_percent &&
              roundTo2Decimals(data.independent_with_bachelors_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Independent Living with Full-time Job</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_with_full_time_employment_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Independent Living with Full-time Job %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_with_full_time_employment_percent &&
              roundTo2Decimals(data.independent_with_full_time_employment_percent * 100)}%</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Independent Living Attending Church</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_attending_church_count}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className={styles.dataLabel}>Independent Living Attending Church %</span>
          </Col>
          <Col>
            <span className={styles.dataDisplay}>{data.independent_attending_church_percent &&
              roundTo2Decimals(data.independent_attending_church_percent * 100)}%</span>
          </Col>
        </Row>
      </Stack>
      <br />
      <br />
      <br />

    </Container>
  );
};

export default MonthlyImpactPage;