import { t } from 'i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ObjectWithId } from '../../../typings/shared-types';
import React from 'react';

type CommonDeleteProps<DataType extends ObjectWithId> = {
  objectOpenToDelete: DataType | null;
  onCancel: () => void;
  onDelete: (objectOpenToDelete: ObjectWithId) => Promise<any>;
  title?: string | null;
  body?: string | null;
};

export default function CommonDeleteModal(props: CommonDeleteProps<ObjectWithId>) {
  const [isSubmitting, setSubmitting] = React.useState(false);

  let onClick = () => {
    setSubmitting(true);
    props.onDelete(props.objectOpenToDelete!).then(() => {
      // This is only really necessary for the next time this is opened
      setSubmitting(false);
    });
  };
  return (
    <Modal centered show={!!props.objectOpenToDelete} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title ? props.title : t('common.GENERIC_DELETE_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body ? props.body : t('common.GENERIC_DELETE_BODY')}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
          {t('common.CANCEL')}
        </Button>
        <Button disabled={isSubmitting || !props.objectOpenToDelete} variant="danger" onClick={onClick}>
          {t('common.DELETE')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function useDeleteModal<DataType extends ObjectWithId>() {
  const [objectOpenToDelete, setObjectOpenToDelete] = React.useState<DataType | null>(null);
  const openDeleteModal = (objectToDelete: DataType) => {
    setObjectOpenToDelete(objectToDelete);
  };
  const closeDeleteModal = () => {
    setObjectOpenToDelete(null);
  };
  return {
    objectOpenToDelete,
    openDeleteModal,
    closeDeleteModal,
  };
}
