import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FormikProps } from 'formik';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import React from 'react';
import _ from 'lodash';
import AsOfDate from '../../components/AsOfDate/AsOfDate';
import { replaceNewlinesWithBreaks } from '../../helpers/formattingHelper';

interface FormikTextAreaInputProps {
  name: string;
  // Null needed for i18n
  label?: string | null;
  // TODO make false actually work
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  isReadOnly?: boolean;
  smallLabelSize?: boolean;

  formikProps: FormikProps<any>;

  lastUpdatedDate?: string;
}

const FormControlTextArea = (props: any) => <FormControl as={'textarea'} {...props} />;

function FormikTextAreaInput(props: FormikTextAreaInputProps) {
  let labelColumnValue: any;
  if (props.smallLabelSize) {
    labelColumnValue = 'sm';
  } else {
    labelColumnValue = true;
  }
  return (
    <Form.Group as={props.showSideBySide ? Row : undefined}>
      {props.label && props.showSideBySide && (
        <Col sm={props.labelWidth}>
          <Form.Label column={labelColumnValue}>{props.label}</Form.Label>
          {props.lastUpdatedDate && (
            <div className={'ps-4'}>
              <AsOfDate date={props.lastUpdatedDate} />
            </div>
          )}
        </Col>
      )}
      {props.label && !props.showSideBySide && (
        <span>
          <Form.Label>{props.label}</Form.Label>
          {props.lastUpdatedDate && <AsOfDate date={props.lastUpdatedDate} />}
        </span>
      )}
      {props.isReadOnly && (
        <Col>{replaceNewlinesWithBreaks(_.get(props.formikProps.values, props.name))}</Col>
      )}
      {!props.isReadOnly && (
        <Col>
          <Field as={FormControlTextArea} name={props.name} />
          <ErrorMessage name={props.name}>
            {(errorMessage) => {
              return <CustomErrorMessage errorMessage={errorMessage} />;
            }}
          </ErrorMessage>
        </Col>
      )}
    </Form.Group>
  );
}

export default FormikTextAreaInput;
