import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import {
  ChildDetail,
  ChildForRoster,
  ChildOverviewEducation,
  ChildOverviewMental,
  ChildOverviewPhysical,
  ChildOverviewSponsor,
  ChildOverviewTabSummary,
  ChildOverviewTransition,
  SimpleChildSummary,
} from '../typings/api-types';
import { ChildDetailPatch, ChildDetailPost } from '../typings/types-to-send';
import { isFileObject, jsonWithAttachmentsToFormData } from '../shared/helpers/formHelper';
import _ from 'lodash';

const CHILD_REST_URL = '/child';
const CHILD_FOR_ROSTER_REST_URL = '/child/list-for-roster';
const CHILD_INTAKE_ATTACHMENT_REST_URL = '/child-intake-attachment';

const CHILD_TAB_SUMMARY_OVERVIEW_REST_URL = '/child-overview-tab-summary';
const CHILD_PHYSICAL_OVERVIEW_REST_URL = '/child-overview-physical';
const CHILD_MENTAL_OVERVIEW_REST_URL = '/child-overview-mental';
const CHILD_EDUCATION_OVERVIEW_REST_URL = '/child-overview-education';
const CHILD_TRANSITION_OVERVIEW_REST_URL = '/child-overview-transition';
const CHILD_SPONSOR_OVERVIEW_REST_URL = '/child-overview-sponsor';

const useGetChildrenForRoster = (
  params?: any,
  enabled?: boolean,
  gridApi?: any,
): QueryObserverResult<ChildForRoster[]> => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetChildrenForRoster', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridApi) {
        gridApi.showLoadingOverlay();
      }
      const response = await axios.get<ChildForRoster[]>(CHILD_FOR_ROSTER_REST_URL, requestConfig);
      if (gridApi) {
        gridApi.hideOverlay();
      }
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildren = (params?: any, enabled?: boolean): QueryObserverResult<SimpleChildSummary[]> => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['SimpleChildSummary', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }

      const response = await axios.get<SimpleChildSummary[]>(CHILD_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildDetail = (id?: number | string | null) => {
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['useGetChildDetail', requestConfig, id],
    () => {
      return axios.get<ChildDetail>(CHILD_REST_URL + '/' + id + '/', requestConfig);
    },
    { enabled: !!id },
  );
};

const postChild = (dataWithFiles: ChildDetailPost) => {
  const intakePhoto = dataWithFiles.intake_photo;
  const intakeFormScan = dataWithFiles.intake_form_scan;
  const exitFormScan = dataWithFiles.exit_home_form_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);
  delete dataWithoutFiles.intake_photo;
  delete dataWithoutFiles.intake_form_scan;
  delete dataWithoutFiles.exit_home_form_scan;
  // YES send intake_attachments. We already uploaded them without the child and POST backend associates them

  return axios
    .post<ChildDetailPost, AxiosResponse<ChildDetail>>(CHILD_REST_URL + '/', dataWithoutFiles)
    .then((response) => {
      return updateFiles(response, intakePhoto, intakeFormScan, exitFormScan);
    });
};

const patchChild = (id: number, dataWithFiles: ChildDetailPatch) => {
  const intakePhoto = dataWithFiles.intake_photo;
  const intakeFormScan = dataWithFiles.intake_form_scan;
  const exitFormScan = dataWithFiles.exit_home_form_scan;

  const dataWithoutFiles = _.clone(dataWithFiles);

  // Send nulls for files to backend on first patch,
  // All new files do on a separate patch
  // Sending null as a multi-part-form doesn't work
  if (dataWithFiles.intake_photo !== null) {
    delete dataWithoutFiles.intake_photo;
  }
  if (dataWithFiles.intake_form_scan !== null) {
    delete dataWithoutFiles.intake_form_scan;
  }
  if (dataWithFiles.exit_home_form_scan !== null) {
    delete dataWithoutFiles.exit_home_form_scan;
  }
  // TODO intake attachments. Any changes are just dropped and I have not written the UI to handle them yet
  delete dataWithoutFiles.intake_attachments;

  return axios.patch<ChildDetailPatch>(CHILD_REST_URL + '/' + id + '/', dataWithoutFiles).then((response) => {
    return updateFiles(response, intakePhoto, intakeFormScan, exitFormScan);
  });
};

// No delete for now

function updateFiles(
  response: AxiosResponse<ChildDetailPatch>,
  intakePhoto: any,
  intakeFormScan: any,
  exitFormScan: any,
) {
  const id = response.data?.id;
  if (!id) {
    throw Error('No id from server');
  }
  const fileFieldsToReplace = {
    intake_photo: intakePhoto,
    intake_form_scan: intakeFormScan,
    exit_home_form_scan: exitFormScan,
  };
  if (!isFileObject(intakePhoto)) {
    delete fileFieldsToReplace.intake_photo;
  }
  if (!isFileObject(intakeFormScan)) {
    delete fileFieldsToReplace.intake_form_scan;
  }
  if (!isFileObject(exitFormScan)) {
    delete fileFieldsToReplace.exit_home_form_scan;
  }
  if (_.isEmpty(fileFieldsToReplace)) {
    return response;
  }
  console.debug('Got what looks like a file in updateFiles, sending to server');
  const formData = jsonWithAttachmentsToFormData(fileFieldsToReplace);
  // NOT Json!! Because this is the FormData with files
  // We don't submit all data this way because FormData doesn't support nested objects
  return axios
    .patch<FormData, AxiosResponse<ChildDetailPatch>>(CHILD_REST_URL + '/' + id + '/', formData)
    .then((response) => {
      console.log('saved some files on child');
      return response;
    });
}

function createChildOptions(childData: undefined | SimpleChildSummary[], showHome?: boolean) {
  if (!childData) {
    return [];
  } else {
    return childData.map((child) => {
      let label = child.name_with_nickname;
      if (showHome) {
        label = child.name_with_nickname + ' (' + child.public_id + ' - ' + child.home_name + ')';
      }
      return {
        label: label,
        value: child.id,
      };
    });
  }
}

function useGetChildrenInHome(selectedHomeId?: number) {
  return useGetChildren(
    {
      home_id: selectedHomeId,
    },
    // https://tanstack.com/query/v4/docs/react/guides/disabling-queries
    !!selectedHomeId,
  );
}

async function getChildrenInHome(selectedHomeId: number): Promise<SimpleChildSummary[]> {
  const requestConfig = {
    params: {
      home_id: selectedHomeId,
    },
  } as AxiosRequestConfig;

  const response = await axios.get<SimpleChildSummary[]>(CHILD_REST_URL, requestConfig);
  return response.data;
}

const useGetChildOverviewPhysical = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildOverviewPhysical', requestConfig, id], async () => {
    const response = await axios.get<ChildOverviewPhysical>(
      CHILD_PHYSICAL_OVERVIEW_REST_URL + '/' + id + '/',
      requestConfig,
    );
    return response.data;
  });
};

const useGetChildOverviewMental = (id: number | undefined) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetChildOverviewMental', requestConfig, id],
    async () => {
      const response = await axios.get<ChildOverviewMental>(
        CHILD_MENTAL_OVERVIEW_REST_URL + '/' + id + '/',
        requestConfig,
      );
      return response.data;
    },
    {
      enabled: !!id,
    },
  );
};

const useGetChildOverviewEducation = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildOverviewEducation', requestConfig, id], async () => {
    const response = await axios.get<ChildOverviewEducation>(
      CHILD_EDUCATION_OVERVIEW_REST_URL + '/' + id + '/',
      requestConfig,
    );
    return response.data;
  });
};

const useGetChildOverviewTransition = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildOverviewTransition', requestConfig, id], async () => {
    const response = await axios.get<ChildOverviewTransition>(
      CHILD_TRANSITION_OVERVIEW_REST_URL + '/' + id + '/',
      requestConfig,
    );
    return response.data;
  });
};

const useGetChildOverviewSponsor = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildOverviewSponsor', requestConfig, id], async () => {
    const response = await axios.get<ChildOverviewSponsor>(
      CHILD_SPONSOR_OVERVIEW_REST_URL + '/' + id + '/',
      requestConfig,
    );
    return response.data;
  });
};

const useGetChildOverviewTabSummary = (id: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildOverviewTabSummary', requestConfig, id], async () => {
    const response = await axios.get<ChildOverviewTabSummary>(
      CHILD_TAB_SUMMARY_OVERVIEW_REST_URL + '/' + id + '/',
      requestConfig,
    );
    return response.data;
  });
};

export {
  useGetChildrenForRoster,
  useGetChildrenInHome,
  useGetChildren,
  useGetChildDetail,
  getChildrenInHome,
  patchChild,
  postChild,
  createChildOptions,
  useGetChildOverviewPhysical,
  useGetChildOverviewMental,
  useGetChildOverviewEducation,
  useGetChildOverviewTransition,
  useGetChildOverviewSponsor,
  useGetChildOverviewTabSummary,
  CHILD_INTAKE_ATTACHMENT_REST_URL,
};
