import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ChildEmotionalCheckIn } from '../typings/api-types';
import {
  ChildEmotionalCheckInPatch,
  ChildEmotionalCheckInPost,
  HomeEmotionalCheckInPost,
} from '../typings/types-to-send';

const CHILD_EMOTIONAL_CHECK_IN_REST_URL = '/child-emotional-check-in';

const useGetChildEmotionalCheckIns = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckIns', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildEmotionalCheckIn[]>(CHILD_EMOTIONAL_CHECK_IN_REST_URL, requestConfig);
      return response.data;
    },
    {
      enabled,
    },
  );
};

const useGetChildEmotionalCheckInDetail = (id: number | undefined, enabled?: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  return useQuery(
    ['getChildEmotionalCheckins', requestConfig, id],
    async () => {
      const response = await axios.get<ChildEmotionalCheckIn>(
        CHILD_EMOTIONAL_CHECK_IN_REST_URL + '/' + id,
        requestConfig,
      );
      return response.data;
    },
    {
      enabled,
    },
  );
};

const patchChildEmotionalCheckIn = (id: number | undefined, data: ChildEmotionalCheckInPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }

  return axios.patch<ChildEmotionalCheckInPatch, AxiosResponse<ChildEmotionalCheckIn>>(
    CHILD_EMOTIONAL_CHECK_IN_REST_URL + '/' + id + '/',
    data,
  );
};

const postChildEmotionalCheckIn = (data: ChildEmotionalCheckInPost) => {
  return axios.post<ChildEmotionalCheckInPost, AxiosResponse<ChildEmotionalCheckIn>>(
    CHILD_EMOTIONAL_CHECK_IN_REST_URL + '/',
    data,
  );
};

// THERE IS NO HOME ENDPOINT!
// Lazy way, just loop through every child and send to the backend
// TODO do this more efficiently with less calls
const postHomeEmotionalCheckIn = (dataWithFiles: HomeEmotionalCheckInPost) => {
  const children = dataWithFiles.children;
  if (children) {
    const promises = children
      .filter((child) => child.is_present)
      .map((childAtCheckIn) => {
        const childCheckIn: ChildEmotionalCheckInPost = {
          date: dataWithFiles.date,
          home: dataWithFiles.home,
          child: childAtCheckIn.child,

          happy_score: childAtCheckIn.happy_score,
          calm_score: childAtCheckIn.calm_score,
          peaceful_score: childAtCheckIn.peaceful_score,
          accepted_score: childAtCheckIn.accepted_score,

          checked_want_to_hurt_someone: childAtCheckIn.checked_want_to_hurt_someone,
          checked_need_help: childAtCheckIn.checked_need_help,
        };
        return postChildEmotionalCheckIn(childCheckIn);
      });
    return Promise.all(promises).then(() => console.log('All emotional check in promises finished'));
  } else {
    return Promise.resolve();
  }
};

const deleteChildEmotionalCheckIn = (id: number) => {
  return axios.delete(CHILD_EMOTIONAL_CHECK_IN_REST_URL + '/' + id + '/');
};

export {
  useGetChildEmotionalCheckIns,
  useGetChildEmotionalCheckInDetail,
  patchChildEmotionalCheckIn,
  postChildEmotionalCheckIn,
  postHomeEmotionalCheckIn,
  deleteChildEmotionalCheckIn,
};
