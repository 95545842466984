import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { GenericOption } from '../shared/formik/FormikSelect/FormikSelect';
import { ChildExtracurricular } from '../typings/api-types';
import { ChildExtracurricularPatch, ChildExtracurricularPost } from '../typings/types-to-send';

const EXTRACURRICULAR_REST_URL = '/child-extracurricular';
const EXTRACURRICULAR_NAME_REST_URL = '/extracurricular-name';

const useGetExtracurriculars = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getExtracurriculars', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildExtracurricular[]>(EXTRACURRICULAR_REST_URL, requestConfig);
      return response?.data;
    },
    {
      enabled,
    },
  );
};

const useGetExtracurricularDetail = (id: number | undefined, enabled: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['getExtracurriculars', requestConfig, id],
    async () => {
      const response = await axios.get<ChildExtracurricular>(EXTRACURRICULAR_REST_URL + '/' + id, requestConfig);
      return response?.data;
    },
    {
      enabled: enabled,
    },
  );
};

const patchExtracurricular = (id: number | undefined, data: ChildExtracurricularPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<ChildExtracurricularPatch>(EXTRACURRICULAR_REST_URL + '/' + id + '/', data);
};

const postExtracurricular = (data: ChildExtracurricularPost) => {
  return axios.post<ChildExtracurricularPost, AxiosResponse<ChildExtracurricular>>(
    EXTRACURRICULAR_REST_URL + '/',
    data,
  );
};

const deleteExtracurricular = (id: number) => {
  return axios.delete(EXTRACURRICULAR_REST_URL + '/' + id + '/');
};

const useGetExistingExtracurricularNames = () => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['getExtracurriculars', requestConfig], async () => {
    // If the grid isn't ready, this won't work,
    //  but ag grid defaults to showing loading overlay initially anyway
    // if (gridApi) {
    //   gridApi.showLoadingOverlay();
    // }
    const response = await axios.get<string[]>(EXTRACURRICULAR_NAME_REST_URL, requestConfig);
    return response?.data;
  });
};

const createExtracurricularNameOptions = (names: string[] | undefined): GenericOption[] => {
  if (!names) {
    return [];
  }
  return names.map((name) => {
    return {
      label: name,
      value: name,
    } as GenericOption;
  });
};

export {
  useGetExtracurriculars,
  useGetExtracurricularDetail,
  patchExtracurricular,
  postExtracurricular,
  deleteExtracurricular,
  useGetExistingExtracurricularNames,
  createExtracurricularNameOptions,
};
