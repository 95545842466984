import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { patchVaccination, postVaccination, useGetVaccinationDetail } from '../../api/vaccination_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { VaccinationPatch, VaccinationPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import { BOOLEAN_OPTIONS, getHomeIdFromChildId, useGetChildIdFromSearchParams } from '../../shared/helpers/formHelper';
import { SimpleChildSummary } from '../../typings/api-types';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { createVaccineOptions, useGetVaccines } from '../../api/vaccine_api';

type VaccinationProps = {};

const DOMAIN_URL = 'vaccination';
const DOMAIN = Domain.VACCINATION;
const CHILD_TAB = 'physical';

const VACCINATION_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date_received: Yup.date().nullable(),

  child: Yup.number().required('Required'),
  vaccine: Yup.number().required('Required'),
  dose_number: Yup.number().required('Required'),
  recommended_date: Yup.date().nullable(),
  is_skipped: Yup.boolean().required('Required'),
});

function getBlankFormData(): VaccinationPost {
  return {
    home: undefined,
    date_received: null,

    child: undefined,
    vaccine: undefined,
    dose_number: undefined,
    recommended_date: null,
    is_skipped: false,
  };
}

const VaccinationPage = (props: VaccinationProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const { data: vaccineData } = useGetVaccines();
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetVaccinationDetail(id, mode !== Mode.CREATE && hasNumberId);

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let formData: VaccinationPost | VaccinationPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    formData = getBlankFormData();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      formData.home = homeId;
      formData.child = childId;
    }
    onSubmit = async (dataToSubmit: VaccinationPost, { setSubmitting }: any) => {
      try {
        await postVaccination(dataToSubmit);
        // const createdId = postResponse.data.id;
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    formData = data as VaccinationPatch;

    onSubmit = async (dataToSubmit: VaccinationPatch, { setSubmitting }: any) => {
      try {
        await patchVaccination(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(formData);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);
  const vaccineOptions = createVaccineOptions(vaccineData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    let home = null;
    if (newChild && childData) {
      const child = childData.find((child: SimpleChildSummary) => {
        return child.id === newChild.value;
      });
      if (child) {
        home = child.home_id;
      }
    }
    formikSetFieldValue('home', home);
  };

  return (
    <Formik initialValues={formData} validationSchema={VACCINATION_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<VaccinationPost | VaccinationPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('vaccination.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'vaccine'}
                    label={t('vaccination.VACCINE')}
                    options={vaccineOptions}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date_received'}
                    label={t('vaccination.DATE_RECEIVED')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'dose_number'}
                    label={t('vaccination.DOSE_NUMBER')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'recommended_date'}
                    label={t('vaccination.RECOMMENDED_DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'is_skipped'}
                    label={t('vaccination.IS_SKIPPED')}
                    options={BOOLEAN_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../' + DOMAIN_URL + '/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default VaccinationPage;
