import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultTooltip, { getCellId } from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { useTranslation } from 'react-i18next';
import { ChildEmotionalCheckIn } from '../../typings/api-types';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';

type EmotionalCheckInScoreCellProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: ChildEmotionalCheckIn;
};
export const EmotionalCheckInScoreCell = (props: EmotionalCheckInScoreCellProps) => {
  const { t } = useTranslation();
  return (
    <span>
      <span className={'pe-2'}>{roundTo2Decimals(props.data.score_average)}</span>
      {props.data.has_any_ones && (
        <DefaultTooltip id={getCellId(props) + '-has-one'} tooltipText={t('emotionalCheckIn.HAS_ONE')}>
          <span className={'text-danger pe-2'}>
            <span className="fa-layers">
              <FontAwesomeIcon transform="shrink-6" icon="1" />
              <FontAwesomeIcon icon={['far', 'square']} />
            </span>
          </span>
        </DefaultTooltip>
      )}
      {props.data.checked_want_to_hurt_someone && (
        <DefaultTooltip
          id={getCellId(props) + '-want-to-hurt-someone'}
          tooltipText={t('emotionalCheckIn.WANT_TO_HURT_SOMEONE')}
        >
          <FontAwesomeIcon className={'text-danger pe-2'} icon="square-check" />
        </DefaultTooltip>
      )}
      {props.data.checked_need_help && (
        <DefaultTooltip id={getCellId(props) + '-need-help'} tooltipText={t('emotionalCheckIn.NEED_HELP')}>
          <FontAwesomeIcon className={'text-danger pe-2'} icon="square-check" />
        </DefaultTooltip>
      )}
    </span>
  );
};

export default EmotionalCheckInScoreCell;
export type { EmotionalCheckInScoreCellProps };
