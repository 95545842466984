import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL,
  postHomeDoctorAppointment,
} from '../../api/doctor_appointment_api';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FieldArray, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildAtHomeDoctorAppointmentPost, HomeDoctorAppointmentPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { GenericOption, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildrenInHome } from '../../api/child_api';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';
import { BOOLEAN_OPTIONS, yupRequiredIfTruthy } from '../../shared/helpers/formHelper';
import FormikMultipleAsyncFileUpload from '../../shared/formik/FormikMultipleAsyncFileUpload/FormikMultipleAsyncFileUpload';
import { createHomeOptions, useGetHomes } from '../../api/home_api';
import { SimpleChildSummary } from '../../typings/api-types';
import i18n from 'i18next';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';

type HomeDoctorAppointmentProps = {};

const DOMAIN = Domain.CHILD_DOCTOR_APPOINTMENT;

const HOME_DOCTOR_APPOINTMENT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  appointment_type: Yup.string().required('Required'),
  summary: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
  doctor_name: Yup.string().required('Required'),
  children: Yup.array()
    .of(
      Yup.object().shape({
        // Auto-populated and can't be modified
        child: Yup.number().required('Required'),
        is_present: Yup.boolean().required('Required'),
        notes: Yup.string().optional(),
        is_chronic_condition: Yup.boolean().when('notes', yupRequiredIfTruthy),
        attachments: Yup.mixed().optional(),
      }),
    )
    .required('Required'),
});

const TYPE_OPTIONS = [
  {
    label: 'Sick/Emergency',
    value: 'Sick/Emergency',
  },
  {
    label: 'Check-up',
    value: 'Checkup',
  },
  {
    label: 'Vision Checkup',
    value: 'Vision Checkup',
  },
  {
    label: 'Physical',
    value: 'Physical',
  },
];

function getBlankHomeAppointmentNoChildren(): HomeDoctorAppointmentPost {
  return {
    appointment_type: '',
    date: '',
    doctor_name: '',
    home: undefined,
    // Null for no children loaded yet, empty array for no children in home
    children: null,
  };
}

function setInitialChildrenAtHomeAppointment(formikProps: any, children: SimpleChildSummary[]): void {
  const childrenAtAppointment = children.map((child) => {
    return {
      child: child.id,
      summary: '',
      notes: '',
      is_chronic_condition: undefined,
      is_present: undefined,
      attachments: [],
    } as ChildAtHomeDoctorAppointmentPost;
  });
  formikProps.setFieldValue('children', childrenAtAppointment);
}

function getChildrenAtAppointmentGrid(formikProps: any, childOptions: GenericOption[]) {
  const t = i18n.t;
  return (
    <FieldArray
      name={'children'}
      render={(arrayHelpers) => {
        const children = formikProps.values.children;
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('common.CHILD')}</th>
                <th>{t('doctorAppointment.IS_PRESENT')}</th>
                <th>{t('doctorAppointment.NOTES')}</th>
                <th>{t('doctorAppointment.IS_CHRONIC_CONDITION')}</th>
                <th>{t('doctorAppointment.ATTACHMENTS')}</th>
              </tr>
            </thead>
            <tbody>
              {children && children.length > 0 ? (
                children.map((childOfHomeAppointment: ChildAtHomeDoctorAppointmentPost, index: number) => (
                  <tr key={index}>
                    <td>
                      <FormikSelect name={`children[${index}].child`} options={childOptions} isReadOnly={true} />
                    </td>
                    <td>
                      <FormikSelect name={`children[${index}].is_present`} options={BOOLEAN_OPTIONS} />
                    </td>
                    <td>
                      <FormikTextAreaInput name={`children[${index}].notes`} formikProps={formikProps} />
                    </td>
                    <td>
                      <FormikSelect name={`children[${index}].is_chronic_condition`} options={BOOLEAN_OPTIONS} />
                    </td>
                    <td>
                      <FormikMultipleAsyncFileUpload
                        baseUrl={CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL}
                        name={`children[${index}].attachments`}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No children in this home</td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      }}
    />
  );
}

/*
  As of 2023-05-12 this is CREATE ONLY, they will be saved as Child Doctor Appointments
 */
const HomeDoctorAppointmentPage = (props: HomeDoctorAppointmentProps) => {
  let { mode: modeString } = useParams();
  let [selectedHome, setSelectedHome] = useState(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: homeData, isLoading: isHomesLoading } = useGetHomes();
  const childrenInHomeResponse = useGetChildrenInHome(selectedHome);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const { data: childData, isIdle: isChildrenIdle, isFetching: isChildrenFetching } = childrenInHomeResponse;
  const mode = parseMode(modeString);
  if (mode !== Mode.CREATE) {
    return <div>Please view or edit individually created Child Doctor Appointments</div>;
  }

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }

  if (isHomesLoading) {
    return <Spinner />;
  }

  const homeAppointment = getBlankHomeAppointmentNoChildren();
  const onSubmit = async (data: HomeDoctorAppointmentPost, { setSubmitting }: any) => {
    await postHomeDoctorAppointment(data);
    // TODO error handling?
    setSubmitting(false);
    navigate('../child-doctor-appointment/search/');
  };

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../child-doctor-appointment/search');
  }

  const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  return (
    <Formik
      initialValues={homeAppointment}
      validationSchema={HOME_DOCTOR_APPOINTMENT_FORM_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomeDoctorAppointmentPost>) => {
        const isEmergencyType = 'Sick/Emergency' === formikProps.values.appointment_type;

        function resetSummaryFromType(genericOptionOfType: any) {
          if (genericOptionOfType && genericOptionOfType.value) {
            const isNewTypeEmergency = 'Sick/Emergency' === genericOptionOfType.value;
            if (isNewTypeEmergency) {
              // Make user type in their own
              formikProps.setFieldValue('summary', '');
            } else {
              // Just use the type as the summary
              formikProps.setFieldValue('summary', genericOptionOfType.value);
            }
          }
        }

        function onHomeChange(newHome: any) {
          setSelectedHome(newHome.value);
          formikProps.setFieldValue('children', null);
        }

        if (!formikProps.values.children && childData) {
          // This seems like a bad place to set this state
          setInitialChildrenAtHomeAppointment(formikProps, childData);
        }
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('doctorAppointment.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={10}>
                  <FormikSelect
                    name={'home'}
                    label={t('common.HOME_NAME')}
                    onChange={onHomeChange}
                    options={homeOptions}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'appointment_type'}
                    label={t('doctorAppointment.TYPE')}
                    onChange={(option: OnChangeValueSingle) => resetSummaryFromType(option)}
                    options={TYPE_OPTIONS}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  {isEmergencyType && (
                    <FormikTextInput
                      name={'summary'}
                      label={t('doctorAppointment.SUMMARY')}
                      formikProps={formikProps}
                      labelWidth={2}
                      showSideBySide={true}
                    />
                  )}
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    name={'doctor_name'}
                    label={t('doctorAppointment.DOCTOR_NAME')}
                    formikProps={formikProps}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  {isChildrenIdle && <div>Please pick a home</div>}
                  {isChildrenFetching && <Spinner />}
                  {formikProps.values.home &&
                    !isChildrenIdle &&
                    !isChildrenFetching &&
                    getChildrenAtAppointmentGrid(formikProps, childOptions)}
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                  <div className="d-flex justify-content-end">
                    <Button
                      className="mx-2 my-2"
                      variant="secondary"
                      onClick={onCancel.bind(null, formikProps.resetForm)}
                    >
                      {t('common.CANCEL')}
                    </Button>
                    <Button className="my-2" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                      {mode === Mode.CREATE ? t('common.CREATE') : t('common.SAVE_CHANGES')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default HomeDoctorAppointmentPage;
