import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../../api/permissions_api';
import { Spinner } from 'react-bootstrap';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import NoPermissionPage from '../../pages/NoPermissionPage/NoPermissionPage';
import React, { PropsWithChildren } from 'react';
import { Mode } from '../../helpers/modeHelper';

type WithDomainPermissionProps = {
  domainToCheck: Domain;
};

const WithDomainPermission = (props: PropsWithChildren<WithDomainPermissionProps>) => {
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = Mode.VIEW;

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, props.domainToCheck))) {
    return <NoPermissionPage />;
  }

  return <>{props.children}</>;
};

export default WithDomainPermission;
