import React from 'react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { deleteHomeSkillTraining, useGetHomeSkillTrainings } from '../../api/skill_training_api';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { HomeSkillTraining } from '../../typings/api-types';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchHomeChildTrainingPageProps = {};

function getColumnDefs(onDelete: (objectToDelete: HomeSkillTraining) => Promise<any>): ColDef<HomeSkillTraining>[] {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    { headerName: t('skillTraining.SKILL_TRAINED'), field: 'skill_trained_name', flex: 2 },
    { headerName: t('common.HOME_NAME'), field: 'home_name', flex: 2 },
    {
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value);
      },
      field: 'date',
      flex: 2,
    },
    { headerName: t('skillTraining.TRAINER'), field: 'trainer_first_name', flex: 2 },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/home-child-training',
        onDelete: onDelete,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

export default function SearchHomeChildTrainingPage(props: SearchHomeChildTrainingPageProps) {
  const useGetHomeSkillTrainingsFilteredByChildTrainings = () => {
    return useGetHomeSkillTrainings({ skill_trained__project: 'CHILD_TRAININGS' });
  };

  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'child-training'}
      getColumns={getColumnDefs}
      permissionDomain={Domain.HOME_SKILL_TRAINING}
      translatePrefix={'childTraining.'}
      useGetListApiHook={useGetHomeSkillTrainingsFilteredByChildTrainings}
      deleteApiFunc={deleteHomeSkillTraining}
      createForHomePageUrl={'/home-child-training/create/new'}
    />
  );
}
