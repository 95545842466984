import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { HomeSkillTraining } from '../typings/api-types';
import { HomeSkillTrainingPatch, HomeSkillTrainingPost } from '../typings/types-to-send';

const HOME_SKILL_TRAINING_REST_URL = '/home-skill-training';

const useGetHomeSkillTrainings = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getHomeSkillTrainings', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<HomeSkillTraining[]>(HOME_SKILL_TRAINING_REST_URL, requestConfig);
      return response?.data;
    },
    {
      enabled,
    },
  );
};

const useGetHomeSkillTrainingDetail = (id: number | undefined) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['getHomeSkillTrainings', requestConfig, id],
    async () => {
      const response = await axios.get<HomeSkillTraining>(HOME_SKILL_TRAINING_REST_URL + '/' + id, requestConfig);
      return response?.data;
    },
    {
      enabled: !!id,
    },
  );
};

const patchHomeSkillTraining = (id: number | undefined, data: HomeSkillTrainingPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<HomeSkillTrainingPatch>(HOME_SKILL_TRAINING_REST_URL + '/' + id + '/', data);
};

const postHomeSkillTraining = (data: HomeSkillTrainingPost) => {
  return axios.post<HomeSkillTrainingPost, AxiosResponse<HomeSkillTraining>>(HOME_SKILL_TRAINING_REST_URL + '/', data);
};

const deleteHomeSkillTraining = (id: number) => {
  return axios.delete(HOME_SKILL_TRAINING_REST_URL + '/' + id + '/');
};

export {
  useGetHomeSkillTrainings,
  useGetHomeSkillTrainingDetail,
  patchHomeSkillTraining,
  postHomeSkillTraining,
  deleteHomeSkillTraining,
};
