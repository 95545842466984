import { createContext } from 'react';
import { FeedbackMessage } from '../components/FeedbackMessages/FeedbackMessages';

type FeedbackMessagesContextType = {
  feedbackMessages: FeedbackMessage[];
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void;
};

const FeedbackMessagesContext = createContext<FeedbackMessagesContextType>(null as never);

export default FeedbackMessagesContext;
