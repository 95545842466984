import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  patchChildEmotionalCheckIn,
  postChildEmotionalCheckIn,
  useGetChildEmotionalCheckInDetail,
} from '../../api/emotional_check_in_api';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildEmotionalCheckInPatch, ChildEmotionalCheckInPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  BOOLEAN_OPTIONS,
  getHomeIdFromChildId,
  setHomeOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';

type ChildEmotionalCheckInProps = {};

const DOMAIN_URL = 'child-mental-check-in';
const DOMAIN = Domain.CHILD_EMOTIONAL_CHECK_IN;
const CHILD_TAB = 'mental';

const ECI_MIN_PER_QUESTION = 1;
const ECI_MAX_PER_QUESTION = 4;

const CHILD_EMOTIONAL_CHECK_IN_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child: Yup.number().required('Required'),

  happy_score: Yup.number().min(ECI_MIN_PER_QUESTION).max(4).required('Required'),
  calm_score: Yup.number().min(ECI_MIN_PER_QUESTION).max(4).required('Required'),
  peaceful_score: Yup.number().min(ECI_MIN_PER_QUESTION).max(4).required('Required'),
  accepted_score: Yup.number().min(ECI_MIN_PER_QUESTION).max(4).required('Required'),

  checked_want_to_hurt_someone: Yup.boolean().required('Required'),
  checked_need_help: Yup.boolean().required('Required'),
});

function getBlankChildCheckIn(): ChildEmotionalCheckInPost {
  return {
    home: undefined,
    date: '',

    child: undefined,

    happy_score: undefined,
    calm_score: undefined,
    peaceful_score: undefined,
    accepted_score: undefined,

    checked_want_to_hurt_someone: undefined,
    checked_need_help: undefined,

    discussion_notes: '',
  };
}

const ChildEmotionalCheckInPage = (props: ChildEmotionalCheckInProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildEmotionalCheckInDetail(
    id,
    mode !== Mode.CREATE && hasNumberId,
  );

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childCheckIn: ChildEmotionalCheckInPost | ChildEmotionalCheckInPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childCheckIn = getBlankChildCheckIn();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      childCheckIn.home = homeId;
      childCheckIn.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildEmotionalCheckInPost, { setSubmitting }: any) => {
      try {
        await postChildEmotionalCheckIn(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    childCheckIn = data as ChildEmotionalCheckInPatch;

    onSubmit = async (dataToSubmit: ChildEmotionalCheckInPatch, { setSubmitting }: any) => {
      try {
        await patchChildEmotionalCheckIn(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childCheckIn);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
  };

  return (
    <Formik initialValues={childCheckIn} validationSchema={CHILD_EMOTIONAL_CHECK_IN_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildEmotionalCheckInPost | ChildEmotionalCheckInPatch>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('emotionalCheckIn.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />

                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'happy_score'}
                    label={t('emotionalCheckIn.HAPPY_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'calm_score'}
                    label={t('emotionalCheckIn.CALM_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'peaceful_score'}
                    label={t('emotionalCheckIn.PEACEFUL_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'accepted_score'}
                    label={t('emotionalCheckIn.ACCEPTED_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />
                  {mode === Mode.VIEW && (
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        {t('emotionalCheckIn.SCORE_AVERAGE')}
                      </Form.Label>
                      <Col className="d-flex align-items-center">{roundTo2Decimals(data?.score_average)}</Col>
                    </Form.Group>
                  )}

                  <br />
                  <FormikSelect
                    label={t('emotionalCheckIn.WANT_TO_HURT_SOMEONE')}
                    name="checked_want_to_hurt_someone"
                    options={BOOLEAN_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    label={t('emotionalCheckIn.NEED_HELP')}
                    name="checked_need_help"
                    options={BOOLEAN_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br/>

                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'discussion_notes'}
                    label={t('emotionalCheckIn.DISCUSSION_NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-mental-check-in/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildEmotionalCheckInPage;

export { ECI_MIN_PER_QUESTION, ECI_MAX_PER_QUESTION };
