import { PermissionsMap } from '../../api/permissions_api';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartColumn,faCalendar
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ReportsDropdownProps = {
  permissionMap: PermissionsMap
};
export const ReportsDropdown = (props: ReportsDropdownProps) => {
  const { t } = useTranslation();

  const canViewMonthlyImpact = true;

  return (
    <Dropdown className='ms-1'>
      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
        <FontAwesomeIcon icon={faChartColumn} /> Reports & Dashboards
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {canViewMonthlyImpact && (
          <Dropdown.Item as={Link} to='/monthly-impact'>
            <FontAwesomeIcon icon={faCalendar} /> {t('monthlyImpact.LINK_LABEL')}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReportsDropdown;