import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { FieldArray, Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  SponsorshipPatch,
  SponsorshipPost,
} from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import {
  patchSponsorship,
  postSponsorship,
  useGetSponsorshipDetail,

} from '../../api/sponsorship_api';
import { ChildSponsorship } from '../../typings/api-types';
import { getTodayIsoDate } from '../../shared/helpers/dateHelper';
import { hasPermission } from '../../shared/helpers/permissionHelper';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';

type SponsorshipPageProps = {};

const DOMAIN_URL = 'sponsorship';
const DOMAIN = Domain.SPONSORSHIP;
const CHILD_TAB = 'sponsorship';

const FREQUENCY_OPTIONS = [{
  label: 'Monthly',
  value: 'Monthly',
}, {
  label: 'Annually',
  value: 'Annually',
}, {
  label: 'Quarterly',
  value: 'Quarterly',
}, {
  label: 'Bi-monthly',
  value: 'Bi-monthly',
},
];

const STATUS_OPTIONS = [{
  label: 'Active',
  value: 'active',
}, {
  label: 'Cancelled',
  value: 'cancelled',
}, {
  label: 'Closed',
  value: 'closed',
}, {
  label: 'Failed',
  value: 'failed',
},
];
const SPONSORSHIP_FORM_SCHEMA = Yup.object().shape({

  children: Yup.array().of(Yup.object().shape({
    child: Yup.number().required('Required'),
  })).required('Required'),
  sponsor_name: Yup.string().required('Required'),
  amount_in_dollars: Yup.number().required('Required'),
});

function getBlankSponsorship(): SponsorshipPost {
  return {
    children: [],
    hubspot_id: '',
    hubspot_link: '',
    fundraise_up_link: '',
    status: 'active',
    start_date_time: getTodayIsoDate(),
    end_date_time: '',
    sponsor_name: '',
    sponsor_email: '',
    notes: '',
    amount_in_dollars: undefined,
    frequency: 'Monthly',
  };
}

const SponsorshipPage = (props: SponsorshipPageProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap, allPermissions } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetSponsorshipDetail(
    id,
    mode !== Mode.CREATE && hasNumberId,
  );

  if (isLoadingPermissions) {
    return <Spinner animation='border' role='status' />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;
  const hasViewAmountPermission = hasPermission(allPermissions, 'view_amount_of_sponsorship');

  let sponsorship: SponsorshipPost | SponsorshipPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    sponsorship = getBlankSponsorship();
    if (isChildrenLoading) {
      return <Spinner animation='border' role='status' />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      sponsorship.children = [{
        child: childId,
      }];
    }
    onSubmit = async (dataToSubmit: SponsorshipPost, { setSubmitting }: any) => {
      try {
        await postSponsorship(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation='border' role='status' />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    sponsorship = data as SponsorshipPatch;

    onSubmit = async (dataToSubmit: SponsorshipPatch, { setSubmitting }: any) => {
      try {
        await patchSponsorship(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(sponsorship);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  return (
    <Formik initialValues={sponsorship} validationSchema={SPONSORSHIP_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<SponsorshipPost | SponsorshipPatch>) => {
        const isViewing = mode === Mode.VIEW;
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className='text-center font-weight-bold'>{t('sponsorship.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Form.Label>
                  {t('sponsorship.CHILDREN')}
                </Form.Label>
                <Col sm={8}>
                  <FieldArray
                    name={'children'}
                    render={(arrayHelpers) => {
                      const childSponsorships = formikProps.values.children;
                      if (!childSponsorships) {
                        return <Alert variant={'danger'}>Undefined Child sponsorships, how did this happen?</Alert>;
                      }
                      return (
                        <div>
                          <Table striped bordered hover>
                            <thead>
                            <tr>
                              <th>{t('common.CHILD_NAME')}</th>
                              {!isViewing && <th>{t('common.ACTIONS')}</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {childSponsorships.length > 0 ? (
                              <>
                                {childSponsorships.map((childSponsorship: ChildSponsorship, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      <FormikSelect name={`children[${index}].child`} options={childOptions}
                                                    isReadOnly={isViewing} />
                                    </td>
                                    {!isViewing && <td><Button variant={'danger'}
                                                               onClick={() => arrayHelpers.remove(index)}>{t('common.DELETE')}</Button>
                                    </td>}
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={4}>No children on this sponsorship</td>
                              </tr>
                            )}
                            </tbody>
                          </Table>
                          <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 20 }}>
                            {!isViewing && <Button
                              onClick={() => arrayHelpers.insert(childSponsorships.length, {
                                child: null,
                              })}>{t('common.CREATE')}</Button>}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <FormikDateInput
                    name={'start_date'}
                    label={t('sponsorship.START_DATE')}
                    isReadOnly={mode === Mode.VIEW}
                    formikProps={formikProps}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  {(!isViewing || hasViewAmountPermission) &&
                    <FormikTextInput name={'amount_in_dollars'} label={t('sponsorship.AMOUNT_IN_DOLLARS')}
                                     isReadOnly={mode === Mode.VIEW}
                                     formikProps={formikProps}
                                     labelWidth={4}
                                     showSideBySide={true}
                    />}
                  <FormikSelect name={'status'} options={STATUS_OPTIONS}  label={t('sponsorship.STATUS')}
                                     isReadOnly={mode === Mode.VIEW}
                                     labelWidth={4}
                                     showSideBySide={true}/>
                  <FormikTextInput name={'sponsor_name'} label={t('sponsorship.SPONSOR_NAME')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true}
                  />
                  <FormikTextInput name={'sponsor_email'} label={t('sponsorship.SPONSOR_EMAIL')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true}
                  />
                  <FormikSelect name={'frequency'} label={t('sponsorship.FREQUENCY')} options={FREQUENCY_OPTIONS}
                                isReadOnly={isViewing} labelWidth={4} showSideBySide={true} />
                  <FormikTextAreaInput name={'notes'} label={t('sponsorship.NOTES')} labelWidth={4}
                                       isReadOnly={mode === Mode.VIEW}
                                       showSideBySide={true} formikProps={formikProps} />
                  <br />
                  <FormikTextInput name={'hubspot_id'} label={t('sponsorship.HUBSPOT_ID')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true}
                  />
                  <FormikTextInput name={'hubspot_link'} label={t('sponsorship.HUBSPOT_LINK')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true} />
                  <FormikDateInput
                    name={'hubspot_created_date'}
                    label={t('sponsorship.HUBSPOT_CREATED_DATE_TIME')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput name={'hubspot_designation'} label={t('sponsorship.HUBSPOT_DESIGNATION')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true} />
                  <FormikTextInput name={'fundraise_up_link'} label={t('sponsorship.FUNDRAISE_UP_LINK')}
                                   isReadOnly={mode === Mode.VIEW}
                                   formikProps={formikProps}
                                   labelWidth={4}
                                   showSideBySide={true} />
                  <br />
                  <FormikDateInput
                    name={'end_date'}
                    label={t('sponsorship.END_DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: `../${DOMAIN_URL}/edit/${idString}`,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default SponsorshipPage;
