import * as React from 'react';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldProps } from 'formik';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FormikSinglePatchFileUpload.module.scss';

type FormikSinglePatchFileUploadProps = {
  name: string;
  // Null needed for translate for some reason
  label?: string | null;
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  isReadOnly?: boolean;
  isInAgGrid?: boolean;
};

/* THIS IS DIFFERENT FROM 'MULTIPLE' COMPONENT IN A KEY WAY:
    Since usually there is just a field on the original object (eg. Child) for the file,
    we expect to PATCH the Child object with id 34 and send the file as a property,
    as opposed to POSTing files without an id and then saving the child after with the ids to link together
 */
export const FormikSinglePatchFileUpload = (props: FormikSinglePatchFileUploadProps) => {
  const fileInputId = 'file_' + props.name;
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          let filesOfInput = event.target.files;
          let newFile;
          if (filesOfInput) {
            newFile = filesOfInput[0];
            fieldProps.form.setFieldValue(fieldProps.field.name, newFile);
          }
        };

        const clearFile = () => {
          fieldProps.form.setFieldValue(fieldProps.field.name, null);
          const htmlFileInputElement = document.getElementById(fileInputId) as any;
          htmlFileInputElement.value = null;
        };

        const existingFile = fieldProps.field.value;

        let fileName;
        if (existingFile && existingFile.name) {
          // This is a local file
          fileName = existingFile.name;
        } else if (existingFile) {
          // This is probably a server file
          fileName = existingFile.split('/').pop();
        }

        let bodyComponent;
        if (props.isReadOnly) {
          if (!existingFile) {
            bodyComponent = <div>No file yet</div>;
          } else {
            bodyComponent = (
              <a className={'pe-2 align-bottom'} href={existingFile}>
                {fileName}
              </a>
            );
          }
        } else {
          const input = (
            <>
              {/* Hidden so that reset button still clears the existing file correctly */}
              <FormControl
                className={props.isInAgGrid ? styles.inputInAgGrid : undefined}
                type="file"
                id={fileInputId}
                onChange={handleChange}
                hidden={existingFile}
              />
              <ErrorMessage name={props.name}>
                {(errorMessage) => {
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </>
          );
          if (existingFile) {
            bodyComponent = (
              <>
                <a className={'pe-2 align-bottom'} href={existingFile}>
                  {fileName}
                </a>
                <Button onClick={() => clearFile()} variant={'outline-danger'} size="sm">
                  <FontAwesomeIcon icon={'trash'} />
                </Button>
                {input}
              </>
            );
          } else {
            bodyComponent = input;
          }
        }

        return (
          <Form.Group as={props.showSideBySide ? Row : undefined}>
            {props.label && (
              <Form.Label column={props.showSideBySide} sm={props.labelWidth}>
                {props.label}
              </Form.Label>
            )}
            <Col>{bodyComponent}</Col>
          </Form.Group>
        );
      }}
    </Field>
  );
};

export default FormikSinglePatchFileUpload;
export type { FormikSinglePatchFileUploadProps };
