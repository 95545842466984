import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const ChildrenSearchButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <Link to='/child/roster'>
        <Button style={{ minWidth: 140 }} variant='secondary' id='childSearchButton' className='m-1'>
          <FontAwesomeIcon icon={faMagnifyingGlass} color='white' /> {t('child.search.SEARCH_BUTTON')}
        </Button>
      </Link>
    </>
  );
};

export default ChildrenSearchButton;
