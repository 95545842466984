import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { Account } from '../typings/api-types';

const ACCOUNT_REST_URL = '/account';

type AccountOption = {
  label: string;
  chipLabel: string;
  value: number | undefined;
};

const useGetAccountsWithChildlightRoles = () => {
  return useGetAccounts({
    groups__name__in:
      'Admin,Basic View and Edit,Education Edit,Education View,' +
      'Mental Edit,Mental View,Physical Edit,Physical View,Sponsorship View',
  });
};

const useGetAccounts = (params?: any) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getAccounts', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<Account[]>(ACCOUNT_REST_URL, requestConfig);
      return response?.data;
    },
    {},
  );
};

function createAccountOptions(accountData: undefined | Account[]): AccountOption[] {
  if (!accountData) {
    return [];
  } else {
    return accountData.map((account) => {
      return {
        label: account.first_name + ' ' + account.last_name + ' - ' + account.email,
        // Custom property used for AccountsMultiSelect component
        chipLabel: account.email,
        value: account.id,
      };
    });
  }
}

export { useGetAccounts, useGetAccountsWithChildlightRoles, createAccountOptions };
export type { AccountOption };
