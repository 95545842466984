import React from 'react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { Domain } from '../../api/permissions_api';
import { useGetHomes } from '../../api/home_api';
import { Home } from '../../typings/api-types';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';
import HomeGridActions from '../HomeGridActions/HomeGridActions';

type SearchHomePageProps = {};

function getColumnDefs(onDelete: (objectToDelete: Home) => Promise<any>): ColDef<Home>[] {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    { headerName: t('home.NAME'), field: 'name', flex: 2 },
    { headerName: t('home.COUNTRY'), field: 'country_name', flex: 1 },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: HomeGridActions,
      cellRendererParams: {
        pageUrl: '/home',
        onDelete: (id: number) => alert('Delete home not allowed at this time ' + id),
        disableEdit: true,
        disableDelete: true,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

export default function SearchHomePage(props: SearchHomePageProps) {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'home'}
      getColumns={getColumnDefs}
      permissionDomain={Domain.HOME}
      translatePrefix={'home.'}
      useGetListApiHook={useGetHomes}
      // deleteApiFunc={deleteHome}
      createByItselfUrl={'/home/create/new'}
      disableCreateByItself={true}
    />
  );
}
