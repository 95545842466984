function roundTo2Decimals(numToRound?: number | null) {
  if (numToRound === undefined || numToRound === null) {
    return undefined;
  }
  // https://stackoverflow.com/a/11832950/13815107
  return Math.round((numToRound + Number.EPSILON) * 100) / 100;
}

function roundTo4Decimals(numToRound?: number | null) {
  if (numToRound === undefined || numToRound === null) {
    return undefined;
  }
  // https://stackoverflow.com/a/11832950/13815107
  return Math.round((numToRound + Number.EPSILON) * 10000) / 10000;
}

export { roundTo2Decimals, roundTo4Decimals };
