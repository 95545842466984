import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';

export const RscaResultIcon = () => {
  return (
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon icon={icon({ name: 'file', style: 'regular' })} />
      <FontAwesomeIcon size={'2xs'} icon={'r'} />
    </span>
  );
};
