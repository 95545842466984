import React from 'react';

import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { Domain } from '../../api/permissions_api';
import { useGetSchools } from '../../api/school_api';
import { School } from '../../typings/api-types';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import i18n from 'i18next';

type SearchSchoolPageProps = {};

function getColumnDefs(onDelete: (objectToDelete: School) => Promise<any>): ColDef<School>[] {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    { headerName: t('school.NAME'), field: 'name', flex: 2 },
    { headerName: t('school.COUNTRY'), field: 'country_name', flex: 1 },
    {
      headerName: t('common.ACTIONS'),
      autoHeight: true,
      flex: 1,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/school',
        onDelete: (id: number) => alert('Deleting school not allowed at this time ' + id),
        disableEdit: true,
        disableDelete: true,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

export default function SearchSchoolPage(props: SearchSchoolPageProps) {
  return (
    <GenericProtectedSearchPage
      savedPreferencesKey={'school'}
      getColumns={getColumnDefs}
      permissionDomain={Domain.SCHOOL}
      translatePrefix={'school.'}
      useGetListApiHook={useGetSchools}
      // deleteApiFunc={deleteSchool}
      createByItselfUrl={'/school/create/new'}
      disableCreateByItself={true}
    />
  );
}
