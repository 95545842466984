import { AgChartsReact } from 'ag-charts-react';
import React, { useState } from 'react';
import { ChildGradeReport } from '../../typings/api-types';
import { AgChartOptions, time } from 'ag-charts-community';
import _ from 'lodash';

type ChildEducationGradeReportChartProps = {
  gradeReports: ChildGradeReport[];
};

type JsDate = {
  js_date: any;
};

const ChildEducationGradeReportChart = (props: ChildEducationGradeReportChartProps) => {
  const checkInsWithJsDates = props.gradeReports.map((gradeReport) => {
    const clonedCheck = _.clone(gradeReport) as ChildGradeReport & JsDate;
    clonedCheck.js_date = new Date(gradeReport.date);
    return clonedCheck;
  });
  const [options] = useState<AgChartOptions>({
    autoSize: true,
    data: checkInsWithJsDates,
    theme: {
      overrides: {
        line: {
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    series: [
      {
        type: 'line',
        xKey: 'js_date',
        yKey: 'gpa',
        stroke: '#000000',
        marker: {
          stroke: '#000000',
          fill: '#000000',
        },
        // tooltip,
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'time',
        tick: {
          interval: time.month.every(3),
        },
        title: {
          text: 'Date',
        },
        label: {
          autoRotate: true,
          // https://www.ag-grid.com/javascript-charts/axes-labels/#time-formatting
          format: '%m %b %Y',
        },
      },
      {
        position: 'left',
        type: 'number',
        min: 0,
        max: 4,
        title: {
          text: 'GPA (max 4.0)',
        },
        label: {
          autoRotate: true,
        },
      },
    ],
  });
  return <AgChartsReact options={options} />;
};

export default ChildEducationGradeReportChart;
