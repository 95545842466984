import { Mode } from '../../helpers/modeHelper';
import { Link, To } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRequestingPageFromNavigateState } from '../DefaultPageActionButtons/DefaultPageActionButtons';

type EditToggleProps = {
  show: boolean;
  mode: Mode;
  formikProps: any;
  toEditLink: string | To;
  onCancel: any;
};

export default function EditToggle(props: EditToggleProps) {
  const requestingPage = useRequestingPageFromNavigateState();
  if (!props.show) {
    return <span />;
  }
  return (
    <>
      {props.mode === Mode.VIEW && (
        // Pass on the requesting page so that saving after editing redirects to original page
        <Link to={props.toEditLink} state={{ requestingPage: requestingPage }}>
          <Button variant={'outline-primary'}>
            <FontAwesomeIcon icon={'pencil'} />
          </Button>
        </Link>
      )}
      {props.mode === Mode.EDIT && (
        <Button variant={'outline-secondary'} onClick={props.onCancel}>
          <FontAwesomeIcon icon={'close'} />
        </Button>
      )}
    </>
  );
}
