import { GenericOption } from '../formik/FormikSelect/FormikSelect';

function findOptionLabelFromValue(value: any, options: GenericOption<any>[]) {
  const foundOption = options.find((option) => option.value === value);
  if (foundOption) {
    return foundOption.label;
  } else {
    return value;
  }
}

export { findOptionLabelFromValue };
