import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import { patchChild, useGetChildOverviewTransition } from '../../api/child_api';
import React, { useState } from 'react';
import { SITUATION_STATUS_OPTIONS, TRACK_STATUS_OPTIONS } from '../ChildOverview/ChildOverview';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ChildDetail, ChildOverviewTransition } from '../../typings/api-types';
import { BOOLEAN_OPTIONS } from '../../shared/helpers/formHelper';
import { ChildDetailPatch } from '../../typings/types-to-send';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Mode } from '../../shared/helpers/modeHelper';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import { findOptionLabelFromValue } from '../../shared/helpers/optionsHelper';
import styles from './ChildTransition.module.scss';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import ChildSkillsList from '../ChildSkillsList/ChildSkillsList';
import DueChildLifeSkillIcon from '../../skill/DueChildLifeSkillIcon/DueChildLifeSkillIcon';
import { LinkContainer } from 'react-router-bootstrap';
import GuidanceSessionList from '../../guidanceSession/GuidanceSessionList/GuidanceSessionList';
import RequiredActivitiesList from '../../guidanceSession/RequiredActivitiesList/RequiredActivitiesList';
import ExtracurricularList from '../../extracurricular/ExtracurricularList/ExtracurricularList';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';
import AsOfDate from '../../shared/components/AsOfDate/AsOfDate';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import TransitionProjectList from '../../transitionProject/TransitionProjectList/TransitionProjectList';

type ChildTransitionProps = {
  childId: number;
  child: ChildDetail;
  refetchChild: () => void;
};

// Strings for now in case we want something more than yes/no
const CITIZENSHIP_STATUS_OPTIONS = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

const CHURCH_STATUS_OPTIONS = [
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Occasionally',
    value: 'OCCASIONALLY',
  },
  {
    label: 'Never',
    value: 'NEVER',
  },
];

const JOB_STATUS_OPTIONS = [
  {
    label: 'Full-time Job',
    value: 'FULL_TIME_JOB',
  },
  {
    label: 'Part-time Job',
    value: 'PART_TIME_JOB',
  },
  {
    label: 'No Job',
    value: 'NO_JOB',
  },
  {
    label: 'Not Applicable',
    value: 'NOT_APPLICABLE',
  },
];

const SCHOLARSHIP_TIER_OPTIONS = [
  {
    label: 'Tier 1',
    value: 'TIER_1',
  },
  {
    label: 'Tier 2',
    value: 'TIER_2',
  },
];

const CHILD_TRANSITION_FORM_SCHEMA = Yup.object().shape({
  higher_ed_school_start_date: Yup.date().nullable(),
});

function createRiasecComponent(data: ChildOverviewTransition | undefined, t: any, childId: number) {
  if (!data) {
    return <span />;
  }
  if (data.last_riasec_result) {
    return (
      <div>
        <span>TAKEN</span>
        <AsOfDate date={data.last_riasec_result.date} label={t('child.education.TAKEN_ON')} />
        <LinkContainer
          className={'ms-2'}
          to={{
            pathname: '/child-riasec-result/view/' + data.last_riasec_result.id,
            search: '?childId=' + childId,
          }}
        >
          <Button variant={'outline-primary'}>{t('common.VIEW')}</Button>
        </LinkContainer>
      </div>
    );
  } else {
    return (
      <div>
        {t('child.transition.RIASEC_NOT_TAKEN_YET')}
        <LinkContainer
          className={'ms-2'}
          to={{
            pathname: '/child-riasec-result/create/new',
            search: '?childId=' + childId,
          }}
        >
          <Button variant={'outline-primary'}>
            {' '}
            + <FontAwesomeIcon icon={'child'} /> {t('riasecResult.ADD_CHILD_BUTTON')}
          </Button>
        </LinkContainer>
      </div>
    );
  }
}

function createImpactComponent(data: ChildOverviewTransition | undefined, t: any, childId: number) {
  if (!data) {
    return <span />;
  }
  if (data.last_impact_result) {
    return (
      <div>
        <span>TAKEN</span>
        <AsOfDate date={data.last_impact_result.date} label={t('child.education.TAKEN_ON')} />
        <LinkContainer
          className={'ms-2'}
          to={{
            pathname: '/child-impact-result/view/' + data.last_impact_result.id,
            search: '?childId=' + childId,
          }}
        >
          <Button variant={'outline-primary'}>{t('common.VIEW')}</Button>
        </LinkContainer>
      </div>
    );
  } else {
    return (
      <div>
        {t('child.transition.IMPACT_NOT_TAKEN_YET')}
        <LinkContainer
          className={'ms-2'}
          to={{
            pathname: '/child-impact-result/create/new',
            search: '?childId=' + childId,
          }}
        >
          <Button variant={'outline-primary'}>
            {' '}
            + <FontAwesomeIcon icon={'child'} /> {t('impactResult.ADD_CHILD_BUTTON')}
          </Button>
        </LinkContainer>
      </div>
    );
  }
}

const ChildTransition = (props: ChildTransitionProps) => {
  const { t } = useTranslation();
  let { mode } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useGetChildOverviewTransition(props.childId);

  const [serverError, setServerError] = useState(false);

  function onCancel(resetForm: () => void) {
    resetForm();
    navigate('../transition/view');
  }

  async function onSubmit(dataWithFiles: ChildDetailPatch, { setSubmitting }: any) {
    if (props.child && props.child.id) {
      try {
        await patchChild(props.child.id, dataWithFiles);
        // refetch transition data because track status might have changed
        // noinspection JSVoidFunctionReturnValueUsed
        await Promise.all([props.refetchChild(), refetch()]);
        setSubmitting(false);
        navigate('../transition/view');
      } catch (error) {
        setServerError(true);
      }
    } else {
      // LOG SOMETHING?
    }
  }

  if (serverError) {
    return <ErrorPage />;
  }

  const riasecResultElement = createRiasecComponent(data, t, props.childId);
  const impactResultElement = createImpactComponent(data, t, props.childId);

  // We imported data and some of it went to bachelors by mistake
  // This helps the CF team spot bad data and cut/paste it to +2 section
  const has_any_bachelors_info = props.child.higher_ed_bachelors_graduated_date
    || props.child.higher_ed_bachelors_school_start_date
    || props.child.higher_ed_bachelors_city
    || props.child.higher_ed_bachelors_country
    || props.child.higher_ed_bachelors_school_name;
  const bachelors_track = props.child.track_status === 'UNIVERSITY'
    || props.child.track_status === 'UNIVERSITY_ABROAD'
    || props.child.track_status === 'GAP_YEAR';
  const show_bachelors = has_any_bachelors_info || bachelors_track;

  const show_masters = props.child.higher_ed_bachelors_graduated_date;
  return (
    <Formik
      // Without enableReinitialize if you save edits and then edit + cancel + edit,
      // your old edits get wiped out
      enableReinitialize
      initialValues={props.child}
      validationSchema={CHILD_TRANSITION_FORM_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<any>) => {
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col sm={{ span: 2, order: 1 }} xs={2}>
                  {mode === 'view' && (
                    <Link to='../transition/edit'>
                      <Button variant={'outline-primary'}>
                        <FontAwesomeIcon icon={'pencil'} />
                      </Button>
                    </Link>
                  )}
                  {mode === 'edit' && (
                    <Button variant={'outline-secondary'} onClick={onCancel.bind(null, formikProps.resetForm)}>
                      <FontAwesomeIcon icon={'close'} />
                    </Button>
                  )}
                </Col>
                <Col sm={{ span: 10, order: 0 }}>
                  <FormikSelect
                    label={t('child.overview.SITUATION_STATUS')}
                    name='situation_status'
                    options={SITUATION_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    readOnlyComponent={
                      <Col className='d-flex align-items-center'>
                        <span>{findOptionLabelFromValue(props.child.situation_status, SITUATION_STATUS_OPTIONS)}</span>
                        <span className={styles.asOfDate + ' ps-2 pt-1'}>
                          {t('child.overview.SITUATION_STATUS_LAST_UPDATED')}{' '}
                          {isoDateToCommonFormat(props.child.situation_status_last_updated)}
                        </span>
                      </Col>
                    }
                  />
                  <FormikSelect
                    label={t('child.overview.TRACK_STATUS')}
                    name='track_status'
                    options={TRACK_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    readOnlyComponent={
                      <Col className='d-flex align-items-center'>
                        <span>{findOptionLabelFromValue(props.child.track_status, TRACK_STATUS_OPTIONS)}</span>
                        <span className={styles.asOfDate + ' ps-2 pt-1'}>
                          {t('child.overview.TRACK_STATUS_LAST_UPDATED')}{' '}
                          {isoDateToCommonFormat(props.child.track_status_last_updated)}
                        </span>
                      </Col>
                    }
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'transition_notes'}
                    label={t('child.transition.TRANSITION_NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                    lastUpdatedDate={formikProps.values.transition_notes_last_updated}
                  />
                  <br />
                  <h3>{t('child.transition.CONTACT_INFO_HEADER')}</h3>
                  <Row>
                    <Col sm={6}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'personal_email'}
                        label={t('child.transition.PERSONAL_EMAIL')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={4}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'personal_phone'}
                        label={t('child.transition.PERSONAL_PHONE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={4}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                      <FormikSelect
                        name='has_sms'
                        label={t('child.transition.HAS_SMS')}
                        isReadOnly={mode === Mode.VIEW}
                        options={BOOLEAN_OPTIONS}
                        labelWidth={4}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                      <FormikSelect
                        name='has_viber'
                        label={t('child.transition.HAS_VIBER')}
                        isReadOnly={mode === Mode.VIEW}
                        options={BOOLEAN_OPTIONS}
                        labelWidth={4}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                      <FormikSelect
                        name='has_whatsapp'
                        label={t('child.transition.HAS_WHATSAPP')}
                        isReadOnly={mode === Mode.VIEW}
                        options={BOOLEAN_OPTIONS}
                        labelWidth={4}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                    </Col>
                    <Col sm={1} />
                    <Col sm={5}>
                      <br /> {/* Guardian email here? */}
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'guardian_phone'}
                        label={t('child.transition.GUARDIAN_PHONE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        smallLabelSize={true}
                        showSideBySide={true}
                      />
                    </Col>
                  </Row>
                  <br />
                  <h3>{t('child.transition.HIGHER_ED_HEADING')}</h3>
                  <FormikSelect
                    label={t('child.transition.CITIZENSHIP_STATUS')}
                    name='citizenship_status'
                    options={CITIZENSHIP_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    defaultValue={''}
                  />
                  <FormikSelect
                    label={t('child.transition.SCHOLARSHIP_TIER')}
                    name='scholarship_tier'
                    options={SCHOLARSHIP_TIER_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    defaultValue={''}
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'higher_ed_notes'}
                    label={t('child.transition.HIGHER_ED_NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <br />
                  <Form.Group>
                    <Form.Label>{t('child.transition.PLUS_TWO_OR_VOCATIONAL')}</Form.Label>
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'plus_two_or_vocational_school_name'}
                      label={t('child.transition.HIGHER_ED_SCHOOL_NAME')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'plus_two_or_vocational_city'}
                      label={t('child.transition.HIGHER_ED_CITY')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                  </Form.Group>
                  <br />
                  {show_bachelors && <Form.Group>
                    <Form.Label>{t('child.transition.BACHELORS')}</Form.Label>
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_bachelors_school_name'}
                      label={t('child.transition.HIGHER_ED_SCHOOL_NAME')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikDateInput
                      formikProps={formikProps}
                      name={'higher_ed_bachelors_school_start_date'}
                      label={t('child.transition.HIGHER_ED_START_DATE')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_bachelors_city'}
                      label={t('child.transition.HIGHER_ED_CITY')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_bachelors_country'}
                      label={t('child.transition.HIGHER_ED_COUNTRY')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikDateInput
                      formikProps={formikProps}
                      name={'higher_ed_bachelors_graduated_date'}
                      label={t('child.transition.HIGHER_ED_GRAD_DATE')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                  </Form.Group>}
                  <br />
                  {show_masters && <Form.Group>
                    <Form.Label>{t('child.transition.MASTERS')}</Form.Label>
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_masters_school_name'}
                      label={t('child.transition.HIGHER_ED_SCHOOL_NAME')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikDateInput
                      formikProps={formikProps}
                      name={'higher_ed_masters_school_start_date'}
                      label={t('child.transition.HIGHER_ED_START_DATE')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_masters_city'}
                      label={t('child.transition.HIGHER_ED_CITY')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikTextInput
                      formikProps={formikProps}
                      name={'higher_ed_masters_country'}
                      label={t('child.transition.HIGHER_ED_COUNTRY')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                    <FormikDateInput
                      formikProps={formikProps}
                      name={'higher_ed_masters_graduated_date'}
                      label={t('child.transition.HIGHER_ED_GRAD_DATE')}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={3}
                      smallLabelSize={true}
                      showSideBySide={true}
                    />
                  </Form.Group>}

                  <br />
                  <h3>{t('child.transition.EMPLOYMENT_HEADING')}</h3>
                  <FormikSelect
                    label={t('child.transition.JOB_STATUS')}
                    name='job_status'
                    options={JOB_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    defaultValue={''}
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'job_notes'}
                    label={t('child.transition.JOB_NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <br />
                  <h3>{t('child.transition.SPIRITUAL_HEADING')}</h3>
                  <FormikSelect
                    label={t('child.transition.CHURCH_STATUS')}
                    name='church_status'
                    options={CHURCH_STATUS_OPTIONS}
                    showSideBySide={true}
                    labelWidth={3}
                    isReadOnly={mode === 'view'}
                    smallLabelSize={true}
                    defaultValue={''}
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'spiritual_notes'}
                    label={t('child.transition.SPIRITUAL_NOTES')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <br />
                  <h3>{t('child.transition.EXIT_HEADING')}</h3>
                  <FormikDateInput
                    formikProps={formikProps}
                    name={'home_departure_date'}
                    label={t('child.transition.HOME_DEPARTURE_DATE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'reason_they_left_home'}
                    label={t('child.transition.REASON_THEY_LEFT_HOME')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <FormikTextAreaInput
                    formikProps={formikProps}
                    name={'home_exit_survey_where_they_are_now'}
                    label={t('child.transition.HOME_EXIT_SURVEY_WHERE_THEY_ARE_NOW')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <FormikSinglePatchFileUpload
                    name={'exit_home_form_scan'}
                    label={t('child.transition.EXIT_HOME_FORM_SCAN')}
                    isReadOnly={mode === Mode.VIEW}
                    showSideBySide={true}
                    labelWidth={3}
                  />
                  <br />
                  <FormikSelect
                    label={t('child.transition.IS_FINANCIAL_SUPPORT_FINISHED')}
                    name="is_financial_support_finished"
                    options={BOOLEAN_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    formikProps={formikProps}
                    name={'program_departure_date'}
                    label={
                      <span>
                        <div>{t('child.transition.PROGRAM_DEPARTURE_DATE')}</div>
                        <div className={styles.asOfDate}>{t('child.transition.PROGRAM_DEPARTURE_DATE_DETAILS')}</div>
                      </span>}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={3}
                    smallLabelSize={true}
                    showSideBySide={true}
                  />
                </Col>
              </Row>
              {mode === 'edit' && (
                <Row>
                  <Col lg={10}>
                    {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
                    <div className='d-flex justify-content-end'>
                      <Button
                        className='mx-2 my-2'
                        variant='secondary'
                        onClick={onCancel.bind(null, formikProps.resetForm)}
                      >
                        {t('common.CANCEL')}
                      </Button>
                      <Button className='my-2' variant='primary' type='submit' disabled={formikProps.isSubmitting}>
                        {t('common.SAVE_CHANGES')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <hr />
              <br />
              <h3>{t('child.transition.ACTIVITIES_HEADING')}</h3>
              {!isLoading && !data && (
                <p style={{ fontStyle: 'italic' }}>{t('child.transition.ERROR_LOADING_CF_DATA')}</p>
              )}
              {isLoading && <p style={{ fontStyle: 'italic' }}>{t('child.transition.LOADING_CF_DATA')}</p>}
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('child.transition.RIASEC')}</Form.Label>
                    {riasecResultElement}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('child.transition.IMPACT')}</Form.Label>
                    {impactResultElement}
                  </Form.Group>
                </Col>
              </Row>
              <br />
              {data && (
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label column>{t('child.transition.INCOMPLETE_LIFE_SKILLS')}</Form.Label>
                      {data.life_skills_due_count > 0 && (
                        <DueChildLifeSkillIcon
                          className={styles.dueLifeSkillIcon}
                          numberDue={data.life_skills_due_count}
                        />
                      )}
                    </Form.Group>
                    <ChildSkillsList
                      emptyListLabel={t('child.transition.NO_REQUIRED_SKILLS')}
                      skills={data?.required_life_skills}
                    />
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label column>{t('child.transition.COMPLETED_LIFE_SKILLS')}</Form.Label>
                    </Form.Group>
                    <ChildSkillsList
                      emptyListLabel={t('child.transition.NO_COMPLETED_SKILLS')}
                      skills={data?.completed_life_skills}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col className='d-flex flex-column align-items-end'>
                  <LinkContainer
                    to={{
                      pathname: '/home-life-skill/create/new',
                      search: '?childId=' + props.childId,
                    }}
                  >
                    <Button variant={'outline-primary'}>
                      {' '}
                      + <FontAwesomeIcon icon={'home'} /> {t('lifeSkill.ADD_HOME_BUTTON')}
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>

              {data && (
                <>
                  <Form.Group>
                    <Form.Label column>{t('child.transition.GUIDANCE_SESSIONS')} </Form.Label>
                    {data.last_guidance_session && (
                      <span className={styles.lastGuidanceSession}>
                        Last Session: {isoDateToCommonFormat(data.last_guidance_session.date)}{' '}
                      </span>
                    )}
                  </Form.Group>
                  {data.guidance_sessions_completed && (
                    <GuidanceSessionList sessions={data.guidance_sessions_completed} />
                  )}
                  {data.guidance_activities_still_required && (
                    <RequiredActivitiesList activities={data.guidance_activities_still_required} />
                  )}
                </>
              )}
              <div className='d-flex flex-column align-items-end'>
                <LinkContainer
                  to={{
                    pathname: '/guidance-session/create/new',
                    search: '?childId=' + props.childId,
                  }}
                >
                  <Button variant={'outline-primary'}>
                    {' '}
                    + <FontAwesomeIcon icon={'compass'} /> {t('guidanceSession.ADD_CHILD_BUTTON')}
                  </Button>
                </LinkContainer>
              </div>

              {data && (
                <>
                  <Form.Group>
                    <Form.Label column>{t('child.transition.EXTRACURRICULARS')}</Form.Label>
                  </Form.Group>
                  {data.extracurriculars && <ExtracurricularList extracurriculars={data.extracurriculars} />}
                </>
              )}
              <div className='d-flex flex-column align-items-end'>
                <LinkContainer
                  to={{
                    pathname: '/extracurricular/create/new',
                    search: '?childId=' + props.childId,
                  }}
                >
                  <Button variant={'outline-primary'}>
                    {' '}
                    + <FontAwesomeIcon icon={'baseball-bat-ball'} /> {t('extracurricular.ADD_CHILD_BUTTON')}
                  </Button>
                </LinkContainer>
              </div>

              {data && (
                <>
                  <Form.Group>
                    <Form.Label column>{t('child.transition.TRANSITION_PROJECTS')}</Form.Label>
                  </Form.Group>
                  {data.transition_projects && <TransitionProjectList transitionProjects={data.transition_projects} />}
                </>
              )}
              <div className='d-flex flex-column align-items-end'>
                <LinkContainer
                  to={{
                    pathname: '/transition-project/create/new',
                    search: '?childId=' + props.childId,
                  }}
                >
                  <Button variant={'outline-primary'}>
                    {' '}
                    + <FontAwesomeIcon icon={'lightbulb'} /> {t('transitionProject.ADD_CHILD_BUTTON')}
                  </Button>
                </LinkContainer>
              </div>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildTransition;
export { CITIZENSHIP_STATUS_OPTIONS, JOB_STATUS_OPTIONS, CHURCH_STATUS_OPTIONS, SCHOLARSHIP_TIER_OPTIONS };
