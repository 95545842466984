import * as React from 'react';
import { useState } from 'react';
import { createCountryOptions, useGetCountries } from '../../api/country_api';
import { Home } from '../../typings/api-types';
import { HomePatch } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { patchHome, postHome, useGetHomeDetail } from '../../api/home_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import FormikSelect from '../../shared/formik/FormikSelect/FormikSelect';
import DefaultPageActionButtons from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type HomeOverviewProps = {
  home: Home;
};

const HOME_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  country: Yup.number().required('Required'),
});

function getBlankHomeNoGradingScaleLevels(): HomePatch {
  return {
    country: undefined,
    name: '',
  };
}

export const HomeOverview = (props: HomeOverviewProps) => {
  let { mode: modeString, id: idString } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mode = parseMode(modeString);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));
  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }

  const [serverError, setServerError] = useState(false);

  const { data, isLoading, error } = useGetHomeDetail(id, hasNumberId);

  const { data: countryData, isLoading: isCountriesLoading } = useGetCountries();

  const countryOptions = createCountryOptions(countryData);
  let home: HomePatch;
  let onSubmit;
  let onCancel: (resetForm: (dataToResetTo?: any) => void) => void;
  if (mode === Mode.CREATE) {
    home = getBlankHomeNoGradingScaleLevels();
    onSubmit = async (data: HomePatch, { setSubmitting }: any) => {
      try {
        await postHome(data);
        // TODO error handling?
        setSubmitting(false);
        navigate('/home/search/');
      } catch (error) {
        setServerError(true);
      }
    };

    onCancel = (resetForm: () => void) => {
      resetForm();
      navigate('/home/search');
    };
  } else if (serverError) {
    return <div> Something went wrong, contact LJI IT </div>;
  } else if (hasNumberId) {
    if (isLoading || isCountriesLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    }

    home = data as HomePatch;

    onSubmit = async (data: HomePatch, { setSubmitting }: any) => {
      try {
        await patchHome(data.id, data);
        // TODO error handling?
        setSubmitting(false);
        navigate('/home/' + idString + '/overview/view/');
      } catch (error) {
        setServerError(true);
      }
    };
    onCancel = (resetForm: () => void) => {
      resetForm();
      navigate('/home/view/' + idString + '/overview/view/');
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function onBack() {
    navigate('/home/search');
  }
  return (
    <Formik
      initialValues={home}
      validationSchema={HOME_SCHEMA}
      onSubmit={onSubmit}
      // Speeds up the form, but not sure what features I lose
      // TODO figure out how to speed up some other way.
      validateOnChange={false}
    >
      {(formikProps: FormikProps<HomePatch>) => {
        return (
          <FormikForm className={'d-flex flex-grow-1'}>
            <Container fluid className="d-flex flex-column flex-grow-1">
              <Row>
                <Col sm={11}>
                  <FormikTextInput
                    name={'name'}
                    label={t('home.NAME')}
                    formikProps={formikProps}
                    // TODO Change when we tackle editing these
                    isReadOnly={true}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'country'}
                    label={t('home.COUNTRY')}
                    options={countryOptions}
                    // TODO Change when we tackle editing these
                    isReadOnly={true}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={1}>
                  {/* TODO Change when we tackle editing these */}
                  {/*<EditToggle*/}
                  {/*  mode={mode}*/}
                  {/*  formikProps={formikProps}*/}
                  {/*  editUrl={'../home/edit/' + idString}*/}
                  {/*  onCancel={onCancel.bind(null, formikProps.resetForm)}*/}
                  {/*/>*/}
                </Col>
              </Row>
              <DefaultPageActionButtons
                mode={mode}
                formikProps={formikProps}
                onCancelEdit={onCancel.bind(null, formikProps.resetForm)}
                onBack={onBack}
              />
              {/*<DisplayFormikState formikProps={formikProps} />*/}
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default HomeOverview;
export type { HomeOverviewProps };
