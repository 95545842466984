import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL,
  patchChildDoctorAppointment,
  postChildDoctorAppointment,
  useGetChildDoctorAppointmentDetail,
} from '../../api/doctor_appointment_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildDoctorAppointmentPatch, ChildDoctorAppointmentPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, {
  FormikSetFunction,
  GenericOption,
  OnChangeValueSingle,
} from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import FormikTextAreaInput from '../../shared/formik/FormikTextAreaInput/FormikTextAreaInput';
import {
  BOOLEAN_OPTIONS,
  getHomeIdFromChildId,
  useGetChildIdFromSearchParams,
  yupRequiredIfTruthy,
} from '../../shared/helpers/formHelper';
import FormikMultipleAsyncFileUpload from '../../shared/formik/FormikMultipleAsyncFileUpload/FormikMultipleAsyncFileUpload';
import { OnChangeValue } from 'react-select';
import { SimpleChildSummary } from '../../typings/api-types';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';

type ChildDoctorAppointmentProps = {};

const DOMAIN_URL = 'child-doctor-appointment';
const DOMAIN = Domain.CHILD_DOCTOR_APPOINTMENT;
const CHILD_TAB = 'physical';

const CHILD_DOCTOR_APPOINTMENT_FORM_SCHEMA = Yup.object().shape({
  // Shared with home
  home: Yup.number().required('Required'),
  appointment_type: Yup.string().required('Required'),
  summary: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
  doctor_name: Yup.string().required('Required'),

  // Child specific
  child: Yup.number().required('Required'),
  notes: Yup.string().optional(),
  is_chronic_condition: Yup.boolean().when('notes', yupRequiredIfTruthy),
  attachments: Yup.mixed().optional(),
});

const TYPE_OPTIONS = [
  {
    label: 'Sick/Emergency',
    value: 'Sick/Emergency',
  },
  {
    label: 'Check-up',
    value: 'Checkup',
  },
  {
    label: 'Vision Checkup',
    value: 'Vision Checkup',
  },
  {
    label: 'Physical',
    value: 'Physical',
  },
];

function getBlankChildAppointment(): ChildDoctorAppointmentPost {
  return {
    // If appointment_type should be '', need to fiddle with typescript definition
    appointment_type: undefined,
    summary: '',
    date: '',
    doctor_name: '',
    home: undefined,
    child: undefined,
    is_chronic_condition: undefined,
    notes: '',
    attachments: [],
  };
}

const ChildDoctorAppointmentPage = (props: ChildDoctorAppointmentProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildDoctorAppointmentDetail(
    id,
    undefined,
    undefined,
    mode !== Mode.CREATE && hasNumberId,
  );

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let childAppointment: ChildDoctorAppointmentPost | ChildDoctorAppointmentPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    childAppointment = getBlankChildAppointment();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      childAppointment.home = homeId;
      childAppointment.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildDoctorAppointmentPost, { setSubmitting }: any) => {
      try {
        await postChildDoctorAppointment(dataToSubmit);
        // const createdId = postResponse.data.id;
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data || !data.data) {
      return <div>Could not find data for doctor appointment {id}</div>;
    } else if (error) {
      return <div>Error loading doctor appointment {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    childAppointment = data.data as ChildDoctorAppointmentPatch;

    onSubmit = async (dataToSubmit: ChildDoctorAppointmentPatch, { setSubmitting }: any) => {
      try {
        await patchChildDoctorAppointment(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad child doctor appointment id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(childAppointment);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    let home = null;
    if (newChild && childData) {
      const child = childData.find((child: SimpleChildSummary) => {
        return child.id === newChild.value;
      });
      if (child) {
        home = child.home_id;
      }
    }
    formikSetFieldValue('home', home);
  };

  return (
    <Formik
      initialValues={childAppointment}
      validationSchema={CHILD_DOCTOR_APPOINTMENT_FORM_SCHEMA}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<ChildDoctorAppointmentPost | ChildDoctorAppointmentPatch>) => {
        const isEmergencyType = 'Sick/Emergency' === formikProps.values.appointment_type;

        function resetSummaryFromType(genericOptionOfType: any) {
          if (genericOptionOfType && genericOptionOfType.value) {
            const isNewTypeEmergency = 'Sick/Emergency' === genericOptionOfType.value;
            if (isNewTypeEmergency) {
              // Make user type in their own
              formikProps.setFieldValue('summary', '');
            } else {
              // Just use the type as the summary
              formikProps.setFieldValue('summary', genericOptionOfType.value);
            }
          }
        }

        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('doctorAppointment.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikSelect
                    name={'appointment_type'}
                    label={t('doctorAppointment.TYPE')}
                    onChange={(option: OnChangeValue<GenericOption | null, boolean>) => resetSummaryFromType(option)}
                    options={TYPE_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  {isEmergencyType && (
                    <FormikTextInput
                      name={'summary'}
                      label={t('doctorAppointment.SUMMARY')}
                      formikProps={formikProps}
                      isReadOnly={mode === Mode.VIEW}
                      labelWidth={2}
                      showSideBySide={true}
                    />
                  )}
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    name={'doctor_name'}
                    label={t('doctorAppointment.DOCTOR_NAME')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <br />
                  <FormikTextAreaInput
                    name={'notes'}
                    label={t('doctorAppointment.NOTES')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikSelect
                    name={'is_chronic_condition'}
                    label={t('doctorAppointment.IS_CHRONIC_CONDITION')}
                    options={BOOLEAN_OPTIONS}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                  <FormikMultipleAsyncFileUpload
                    baseUrl={CHILD_DOCTOR_APPOINTMENT_ATTACHMENT_REST_URL}
                    name={'attachments'}
                    label={t('doctorAppointment.ATTACHMENTS')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={2}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../' + DOMAIN_URL + '/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildDoctorAppointmentPage;
