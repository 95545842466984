import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldProps, FormikProps } from 'formik';
import styles from './FormikDateInput.module.scss';
import React, { ChangeEvent, ReactElement } from 'react';
import { isoDateToCommonFormat } from '../../helpers/dateHelper';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import _ from 'lodash';

interface FormikDateInputProps {
  name: string;
  label: string | ReactElement;
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  isReadOnly?: boolean;
  smallLabelSize?: boolean;

  formikProps: FormikProps<any>;
}

function FormikDateInput(props: FormikDateInputProps) {
  let labelColumnValue: any = undefined;
  if (props.showSideBySide) {
    if (props.smallLabelSize) {
      labelColumnValue = 'sm';
    } else {
      labelColumnValue = true;
    }
  }

  let value = _.get(props.formikProps.values, props.name);

  // const clearDate = () => {
  //   // Don't validate so "Required" doesn't show up
  //   props.formikProps.setFieldValue(props.name, '', false);
  // };
  return (
    <Form.Group as={props.showSideBySide ? Row : undefined}>
      <Form.Label column={labelColumnValue} sm={props.labelWidth}>
        {props.label}
      </Form.Label>
      {props.isReadOnly && <Col className="d-flex align-items-center">{isoDateToCommonFormat(value)}</Col>}
      {!props.isReadOnly && (
        <Col>
          <InputGroup>
            <Field name={props.name}>
              {(fieldProps: FieldProps) => {
                // Replaces '' with null, Django doesn't like dates as ''
                const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                  let newValue: string | null = event.target.value;
                  if (event.target.value === '') {
                    newValue = null;
                  }
                  fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                };
                return (
                  <Form.Control
                    className={value ? '' : styles.inputPlaceholder}
                    onChange={handleChange}
                    type={'date'}
                    value={fieldProps.field.value}
                  />
                );
              }}
            </Field>
            {/* Considering adding this button, but when next to selects, it is easy to miss-click the clear button */}
            {/*<Button variant={'outline-secondary'} className={styles.clearButton} onClick={clearDate}>*/}
            {/*  <FontAwesomeIcon icon={'xmark'} />*/}
            {/*</Button>*/}
          </InputGroup>
          <ErrorMessage name={props.name}>
            {(errorMessage) => {
              return <CustomErrorMessage errorMessage={errorMessage} />;
            }}
          </ErrorMessage>
        </Col>
      )}
    </Form.Group>
  );
}

export default FormikDateInput;
