import * as React from 'react';
import { isMoreThanXMonthsOld, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import styles from './EmotionalCheckInSummary.module.scss';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChildEmotionalCheckIn } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import { roundTo2Decimals } from '../../shared/helpers/roundingHelper';

const NUMBER_OF_MONTHS_OLD_CHECK_IN = 3;

type EmotionalCheckInSummaryProps = {
  emotionalCheckIn?: ChildEmotionalCheckIn;
};
export const EmotionalCheckInSummary = (props: EmotionalCheckInSummaryProps) => {
  const { t } = useTranslation();
  let emotionalCheckIn = props.emotionalCheckIn;

  if (!emotionalCheckIn) {
    return <div>{t('child.mental.LAST_EMOTIONAL_CHECK_IN_UNKNOWN')}</div>;
  }

  let dateColoringClass = '';
  if (isMoreThanXMonthsOld(emotionalCheckIn.date, NUMBER_OF_MONTHS_OLD_CHECK_IN)) {
    dateColoringClass = styles.bad;
  }
  return (
    <span>
      <span>
        <span className={'pe-1'}>
          <span>
            {t('emotionalCheckIn.SCORE_AVERAGE')}:
            <span style={{ fontWeight: 'bold' }} className={'px-2'}>
              {roundTo2Decimals(emotionalCheckIn.score_average)}
            </span>
          </span>
        </span>
        {emotionalCheckIn.has_any_ones && (
          <DefaultTooltip id={'last-mental-check-in-has-one'} tooltipText={t('emotionalCheckIn.HAS_ONE')}>
            <span className={'text-danger pe-2'}>
              <span className="fa-layers">
                <FontAwesomeIcon transform="shrink-6" icon="1" />
                <FontAwesomeIcon icon={['far', 'square']} />
              </span>
            </span>
          </DefaultTooltip>
        )}
        {emotionalCheckIn.checked_want_to_hurt_someone && (
          <DefaultTooltip
            id={'last-mental-check-in-want-to-hurt-someone'}
            tooltipText={t('emotionalCheckIn.WANT_TO_HURT_SOMEONE')}
          >
            <FontAwesomeIcon className={'text-danger pe-2'} icon="square-check" />
          </DefaultTooltip>
        )}
        {emotionalCheckIn.checked_need_help && (
          <DefaultTooltip id={'last-mental-check-in-need-help'} tooltipText={t('emotionalCheckIn.NEED_HELP')}>
            <FontAwesomeIcon className={'text-danger pe-2'} icon="square-check" />
          </DefaultTooltip>
        )}
      </span>
      <span className={styles.asOfDate}>
        {t('common.AS_OF')} <span className={dateColoringClass}>{isoDateToCommonFormat(emotionalCheckIn.date)}</span>
      </span>
    </span>
  );
};

export default EmotionalCheckInSummary;
export type { EmotionalCheckInSummaryProps };
