import { ICellRendererParams } from 'ag-grid-community';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonDeleteModal, { useDeleteModal } from '../../modals/CommonDeleteModal/CommonDeleteModal';
import { ObjectWithAuditFields, ObjectWithId } from '../../../typings/shared-types';

type GridActionProps = ICellRendererParams & {
  // Populated by AG grid because this is one row of data
  data: any;
  pageUrl: string;
  onDelete: (objectToDelete: ObjectWithId) => Promise<any>;
  disableView?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  deleteModalTitle?: string;
  deleteModalBody?: string;
  // When used, passes the child Id onto the linked page
  // When the back button is clicked on the linked page,
  // it will return to childs page on the correct tab
  childId?: number;
  isModifyLocked?: (data: ObjectWithAuditFields) => boolean;
  lockedMessage?: string;
};

const GridActions = (props: GridActionProps) => {
  const { t } = useTranslation();

  const { objectOpenToDelete, closeDeleteModal, openDeleteModal } = useDeleteModal<ObjectWithId>();

  if (!props.data || !props.data.id) {
    return <span />;
  }

  let modifyLocked = false;
  if (props.isModifyLocked) {
    modifyLocked = props.isModifyLocked(props.data);
  }
  const showDisabledMessage = modifyLocked && props.lockedMessage;

  return (
    <span>
      {!props.disableView && (
        <OverlayTrigger
          key={'view' + props.pageUrl + props.data.id}
          placement={'top'}
          overlay={<Tooltip>{t('common.VIEW')}</Tooltip>}
        >
          {/* This span makes tooltip work */}
          <span>
            <LinkContainer
              to={{
                pathname: props.pageUrl + '/view/' + props.data.id,
                search: props.childId ? '?childId=' + props.childId : undefined,
              }}
            >
              <Button variant="outline-primary" size="sm">
                <FontAwesomeIcon icon={'eye'} />
              </Button>
            </LinkContainer>
          </span>
        </OverlayTrigger>
      )}
      {!props.disableEdit && (
        <OverlayTrigger
          key={'edit' + props.pageUrl + props.data.id}
          placement={'top'}
          overlay={<Tooltip>{showDisabledMessage ? props.lockedMessage : t('common.EDIT')}</Tooltip>}
        >
          {/* This span makes tooltip work */}
          <span>
            <LinkContainer
              to={{
                pathname: props.pageUrl + '/edit/' + props.data.id,
                search: props.childId ? '?childId=' + props.childId : undefined,
              }}
            >
              <Button disabled={modifyLocked} variant="outline-primary" size="sm">
                <FontAwesomeIcon icon={'pencil'} />
              </Button>
            </LinkContainer>
          </span>
        </OverlayTrigger>
      )}
      {!props.disableDelete && (
        <OverlayTrigger
          key={'delete' + props.pageUrl + props.data.id}
          placement={'top'}
          overlay={<Tooltip>{showDisabledMessage ? props.lockedMessage : t('common.DELETE')}</Tooltip>}
        >
          <span>
            <Button
              disabled={modifyLocked}
              variant="outline-danger"
              size="sm"
              onClick={() => openDeleteModal(props.data)}
            >
              <FontAwesomeIcon icon={'trash-can'} />
            </Button>
          </span>
        </OverlayTrigger>
      )}
      {props.data.id && (
        <CommonDeleteModal
          objectOpenToDelete={objectOpenToDelete}
          onCancel={() => closeDeleteModal()}
          title={props.deleteModalTitle}
          body={props.deleteModalBody}
          onDelete={(objectOpenToDelete) => {
            return props.onDelete(objectOpenToDelete).then(() => {
              closeDeleteModal();
            });
          }}
        />
      )}
    </span>
  );
};

export default GridActions;
