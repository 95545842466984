import { Mode, parseMode } from '../../helpers/modeHelper';
import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonDeleteModal, { useDeleteModal } from '../../modals/CommonDeleteModal/CommonDeleteModal';
import { ObjectWithId } from '../../../typings/shared-types';

type DefaultPageActionButtonsProps = {
  mode: Mode;
  formikProps: any;
  onCancelEdit: any;
  onBack: any;
  // Not required, adds delete button when put on
  // No security for this yet because I do not use it on many pages
  onDelete?: any;
  afterDelete?: any;
};

function useChildIdFromQueryParams() {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  let childId: string | null = null;
  if (queryParams) {
    childId = queryParams.get('childId');
  }
  return childId;
}

function useRequestingPageFromNavigateState() {
  const { state } = useLocation();
  const requestingPage = React.useMemo(() => {
    return state?.requestingPage ? state.requestingPage : null;
  }, [state]);
  return requestingPage;
}

const useNavigateAfterCancel = (domainUrl: string, childTab: string) => {
  const navigate = useNavigate();
  let { id: idString, mode: modeString } = useParams();
  const mode = parseMode(modeString);
  const childId = useChildIdFromQueryParams();
  const requestingPage = useRequestingPageFromNavigateState();
  const navigateAfterCancel = () => {
    if (requestingPage) {
      navigate(requestingPage);
    } else if (childId) {
      navigate('/child/' + childId + '/' + childTab + '/view/');
    } else {
      if (mode === Mode.CREATE) {
        navigate('../' + domainUrl + '/search');
      } else {
        navigate('../' + domainUrl + '/view/' + idString);
      }
    }
  };
  return navigateAfterCancel;
};

const useOnPageBack = (domainUrl: string, childTab: string) => {
  const navigate = useNavigate();
  const childId = useChildIdFromQueryParams();
  const requestingPage = useRequestingPageFromNavigateState();
  const onBack = () => {
    if (requestingPage) {
      navigate(requestingPage);
    } else if (childId) {
      navigate('/child/' + childId + '/' + childTab + '/view/');
    } else {
      navigate('../' + domainUrl + '/search');
    }
  };
  return onBack;
};

const useNavigateToSuccessPage = (domainUrl: string, childTab: string) => {
  const navigate = useNavigate();
  const childId = useChildIdFromQueryParams();
  const requestingPage = useRequestingPageFromNavigateState();
  const navigateToSuccessPage = () => {
    if (requestingPage) {
      navigate(requestingPage);
    } else {
      if (childId) {
        navigate('/child/' + childId + '/' + childTab + '/view/');
      } else {
        navigate('../' + domainUrl + '/search');
      }
      // Optionally we could have if mode was EDIT reload the view screen.
      // Personally though I think it slows things down
      // TODO delete this code in a bit
      //   if (childId) {
      //     navigate('../' + domainUrl + '/view/' + idString + '?childId=' + childId);
      //   } else {
      //     navigate('../' + domainUrl + '/view/' + idString);
      //   }
      //   // Force refresh page, otherwise opening edit again and cancelling will discard your SAVED changes
      //   // TODO find a way to do this better, it adds load time
      //   // https://stackoverflow.com/a/73181059/13815107
      //   navigate(0);
    }
  };
  return navigateToSuccessPage;
};

const useNavigateToViewPage = (domainUrl: string, childTab: string, createdId?: number) => {
  const navigate = useNavigate();
  const childId = useChildIdFromQueryParams();
  const requestingPage = useRequestingPageFromNavigateState();
  let { id: idString, mode: modeString } = useParams();
  const mode = parseMode(modeString);
  const navigateToSuccessPage = () => {
    if (requestingPage) {
      navigate(requestingPage);
    } else if (mode === Mode.CREATE) {
      if (createdId) {
        if (childId) {
          navigate('../' + domainUrl + '/view/' + createdId + '?childId=' + childId);
        } else {
          navigate('../' + domainUrl + '/view/' + createdId);
        }
      } else {
        throw Error('No createdId');
      }
    } else {
      if (childId) {
        navigate('../' + domainUrl + '/view/' + idString + '?childId=' + childId);
      } else {
        navigate('../' + domainUrl + '/view/' + idString);
      }
    }
  };
  return navigateToSuccessPage;
};

export default function DefaultPageActionButtons(props: DefaultPageActionButtonsProps) {
  const { t } = useTranslation();

  const { objectOpenToDelete, closeDeleteModal, openDeleteModal } = useDeleteModal<ObjectWithId>();
  return (
    <>
      {(props.mode === Mode.EDIT || props.mode === Mode.CREATE) && (
        <Row>
          <Col xs={6} lg={2}>
            <div className="d-flex justify-content-start">
              {props.mode === Mode.EDIT && props.onDelete && (
                <Button
                  className="mx-2 my-2"
                  variant="danger"
                  onClick={() => openDeleteModal(props.formikProps.values)}
                >
                  {t('common.DELETE')}
                </Button>
              )}
            </div>
          </Col>
          <Col xs={6} lg={10}>
            {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
            <div className="d-flex justify-content-end">
              <Button className="mx-2 my-2" variant="secondary" onClick={props.onCancelEdit}>
                {t('common.CANCEL')}
              </Button>
              <Button className="my-2" variant="primary" type="submit" disabled={props.formikProps.isSubmitting}>
                {props.mode === Mode.CREATE ? t('common.CREATE') : t('common.SAVE_CHANGES')}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      {props.mode === Mode.VIEW && (
        <Row>
          <Col lg={10}>
            {/* If the page gets too long, move this to bottom with className="fixed-bottom" */}
            <div className="d-flex justify-content-end">
              <Button className="mx-2 my-2" variant="secondary" onClick={props.onBack}>
                {t('common.BACK')}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      {props.onDelete && (
        <CommonDeleteModal
          objectOpenToDelete={objectOpenToDelete}
          onCancel={() => closeDeleteModal()}
          onDelete={(objectOpenToDelete) => {
            return props.onDelete(objectOpenToDelete).then(() => {
              closeDeleteModal();
              props.afterDelete();
            });
          }}
        />
      )}
    </>
  );
}

export {
  useNavigateAfterCancel,
  useOnPageBack,
  useNavigateToSuccessPage,
  useNavigateToViewPage,
  useRequestingPageFromNavigateState,
};
