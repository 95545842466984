import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Home } from '../../typings/api-types';

type HomeMentalProps = {
  home: Home;
};
export const HomeMental = (props: HomeMentalProps) => {
  return (
    <div>
      <Alert>Nothing here yet!</Alert>
    </div>
  );
};

export default HomeMental;
export type { HomeMentalProps };
