// Based on LoginButton in guide https://auth0.com/docs/quickstart/spa/react/01-login
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoginButton = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      {!isAuthenticated && (
        <Button
          id='logInButton'
          className='m-1'
          onClick={() => {
            loginWithRedirect();
          }}
        >
          {t('LOG_IN')}
        </Button>
      )}
    </>
  );
};

export default LoginButton;
