enum Mode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

function parseMode(modeString: string | undefined): Mode {
  if (modeString === Mode.VIEW) {
    return Mode.VIEW;
  } else if (modeString === Mode.EDIT) {
    return Mode.EDIT;
  } else if (modeString === Mode.CREATE) {
    return Mode.CREATE;
  } else {
    throw Error('Mode not supported: ' + modeString);
  }
}

export { Mode, parseMode };
