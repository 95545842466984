import { Col, Form, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FormikProps } from 'formik';
import CustomErrorMessage from '../CustomErrorMessage/CustomErrorMessage';
import React from 'react';
import _ from 'lodash';

import styles from './FormikTextInput.module.scss';

interface FormikTextInputProps {
  formikProps?: FormikProps<any>;

  name: string;
  label?: string | null;
  // TODO make false actually work
  showSideBySide?: boolean;
  // React bootstrap grid system number out of 12, NOT pixels/ems
  labelWidth?: number;
  isReadOnly?: boolean;
  isInAgGrid?: boolean;
  smallLabelSize?: boolean;
}

function FormikTextInput(props: FormikTextInputProps) {
  let labelColumnValue: any = undefined;
  if (props.showSideBySide) {
    if (props.smallLabelSize) {
      labelColumnValue = 'sm';
    } else {
      labelColumnValue = true;
    }
  }

  return (
    <Form.Group as={Row}>
      {props.label && (
        <Form.Label column={labelColumnValue} sm={props.labelWidth}>
          {props.label}
        </Form.Label>
      )}
      {props.isReadOnly && props.formikProps && (
        <Col className="d-flex align-items-center">{_.get(props.formikProps.values, props.name)}</Col>
      )}
      {props.isReadOnly && !props.formikProps && (
        <Col className="d-flex align-items-center">ERROR pass formikProps or use Field to wrap divs</Col>
      )}
      {!props.isReadOnly && (
        <Col>
          <Field
            className={props.isInAgGrid ? styles.formikTextInputAgGrid : undefined}
            as={Form.Control}
            type="text"
            name={props.name}
            key={props.name}
          />
          <ErrorMessage name={props.name}>
            {(errorMessage) => {
              return <CustomErrorMessage errorMessage={errorMessage} />;
            }}
          </ErrorMessage>
        </Col>
      )}
    </Form.Group>
  );
}

export default FormikTextInput;
