import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { MonthlyImpact } from '../typings/api-types';

const MONTHLY_IMPACT_REST_URL = '/monthly-impact';

const useGetMonthlyImpact = () => {
  const requestConfig = {
    params: {},
  } as AxiosRequestConfig;

  // TODO investigate this rule
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['useGetMonthlyImpact', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<MonthlyImpact>(MONTHLY_IMPACT_REST_URL, requestConfig);
      return response.data;
    }
  );
};

export {useGetMonthlyImpact}