import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Vaccination } from '../../typings/api-types';
import { useTranslation } from 'react-i18next';
import { isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import styles from './VaccinationDateReceivedCell.module.scss';

type VaccinationDateReceivedCellProps =
  //ICellRendererParams &
  {
    // Populated by AG grid because this is one row of data
    data: Vaccination;
  };
export const VaccinationDateReceivedCell = (props: VaccinationDateReceivedCellProps) => {
  const { t } = useTranslation();
  if (props.data.is_skipped) {
    return (
      <span>
        <FontAwesomeIcon className={styles.skippedIcon} icon={'ban'} />{' '}
        <span className={styles.skippedText}>{t('vaccination.SKIPPED')}</span>
      </span>
    );
  } else if (props.data.is_overdue) {
    return (
      <span>
        <FontAwesomeIcon className={styles.overdueIcon} icon={'clock'} />{' '}
        <span className={styles.overdueText}>{t('vaccination.OVERDUE')}</span>
      </span>
    );
  } else if (!props.data.date_received) {
    return (
      <span>
        <span className={styles.upcomingText}>{t('vaccination.UPCOMING')}</span>
      </span>
    );
  } else {
    // Received
    return (
      <span>
        <FontAwesomeIcon className={styles.receivedIcon} icon={'check-square'} />{' '}
        <span className={styles.receivedText}>{isoDateToCommonFormat(props.data.date_received)}</span>
      </span>
    );
  }
};

export default VaccinationDateReceivedCell;
export type { VaccinationDateReceivedCellProps };
