import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { patchChildBeckResult, postChildBeckResult, useGetChildBeckResultDetail } from '../../api/beck_result_api';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChildBeckResultPatch, ChildBeckResultPost } from '../../typings/types-to-send';
import { Mode, parseMode } from '../../shared/helpers/modeHelper';
import { useTranslation } from 'react-i18next';
import FormikDateInput from '../../shared/formik/FormikDateInput/FormikDateInput';
import FormikSelect, { FormikSetFunction, OnChangeValueSingle } from '../../shared/formik/FormikSelect/FormikSelect';
import { createChildOptions, useGetChildren } from '../../api/child_api';
import {
  getHomeIdFromChildId,
  setHomeOnChildChange,
  useGetChildIdFromSearchParams,
} from '../../shared/helpers/formHelper';
import FormikSinglePatchFileUpload from '../../shared/formik/FormikSinglePatchFileUpload/FormikSinglePatchFileUpload';
import EditToggle from '../../shared/components/EditToggle/EditToggle';
import DefaultPageActionButtons, {
  useNavigateAfterCancel,
  useNavigateToSuccessPage,
  useOnPageBack,
} from '../../shared/components/DefaultPageActionButtons/DefaultPageActionButtons';
import FormikTextInput from '../../shared/formik/FormikTextInput/FormikTextInput';
import { Domain, getDomainPermission, hasPagePermission, usePermissions } from '../../api/permissions_api';
import NoPermissionPage from '../../shared/pages/NoPermissionPage/NoPermissionPage';
import ErrorPage from '../../shared/pages/ErrorPage/ErrorPage';
import { RiskLevelDisplay } from './RiskLevelDisplay';

type ChildBeckResultProps = {};

const DOMAIN_URL = 'child-beck-result';
const DOMAIN = Domain.CHILD_BECK_RESULT;
const CHILD_TAB = 'mental';

const CHILD_BECK_RESULT_FORM_SCHEMA = Yup.object().shape({
  home: Yup.number().required('Required'),
  date: Yup.date().required('Required'),

  child: Yup.number().required('Required'),

  baiy_raw_score: Yup.number().min(0).max(100).required('Required'),
  bdiy_raw_score: Yup.number().min(0).max(100).required('Required'),
  baniy_raw_score: Yup.number().min(0).max(100).required('Required'),
  bdbiy_raw_score: Yup.number().min(0).max(100).required('Required'),
  // inverted and scored differently
  bsciy_raw_score: Yup.number().min(0).max(100).required('Required'),

  // Anxiety
  baiy_t_score: Yup.number().min(0).max(100).required('Required'),
  // Depression
  bdiy_t_score: Yup.number().min(0).max(100).required('Required'),
  // Anger
  baniy_t_score: Yup.number().min(0).max(100).required('Required'),
  // Disruptive Behavior
  bdbiy_t_score: Yup.number().min(0).max(100).required('Required'),
  // Self-concept - inverted and scored differently
  bsciy_t_score: Yup.number().min(0).max(100).required('Required'),

  // Files - Sent in separate request
  original_scan: Yup.mixed().required('Required'),
});

function getBlankChildResult(): ChildBeckResultPost {
  return {
    home: undefined,
    date: '',

    child: undefined,

    baiy_raw_score: undefined,
    bdiy_raw_score: undefined,
    baniy_raw_score: undefined,
    bdbiy_raw_score: undefined,
    // inverted and scored differently
    bsciy_raw_score: undefined,

    // Anxiety
    baiy_t_score: undefined,
    // Depression
    bdiy_t_score: undefined,
    // Anger
    baniy_t_score: undefined,
    // Disruptive Behavior
    bdbiy_t_score: undefined,
    // Self-concept - inverted and scored differently
    bsciy_t_score: undefined,

    // Files - Sent in separate request
    original_scan: undefined,
  };
}

const ChildBeckResultPage = (props: ChildBeckResultProps) => {
  let { id: idString, mode: modeString } = useParams();
  const childId = useGetChildIdFromSearchParams();
  const { t } = useTranslation();
  // const { data: homeData } = getHomes();
  const { data: childData, isLoading: isChildrenLoading } = useGetChildren();
  const mode = parseMode(modeString);
  const [serverError, setServerError] = useState(false);
  const { isLoadingPermissions, permissionMap } = usePermissions();
  const onBack = useOnPageBack(DOMAIN_URL, CHILD_TAB);
  const navigateToSuccessPage = useNavigateToSuccessPage(DOMAIN_URL, CHILD_TAB);
  const navigateAfterCancel = useNavigateAfterCancel(DOMAIN_URL, CHILD_TAB);

  const hasNumberId = !!idString && !Number.isNaN(parseInt(idString));

  let id: number | undefined = undefined;
  if (idString) {
    id = parseInt(idString);
  }
  const { data, isLoading, error, refetch } = useGetChildBeckResultDetail(
    id,
    undefined,
    undefined,
    mode !== Mode.CREATE && hasNumberId,
  );

  if (isLoadingPermissions) {
    return <Spinner animation="border" role="status" />;
  } else if (!permissionMap) {
    return <ErrorPage />;
  } else if (!hasPagePermission(mode, getDomainPermission(permissionMap, DOMAIN))) {
    return <NoPermissionPage />;
  }
  const hasChangePermission = getDomainPermission(permissionMap, DOMAIN).canChange;

  let beckResult: ChildBeckResultPost | ChildBeckResultPatch;
  let onSubmit;
  if (mode === Mode.CREATE) {
    beckResult = getBlankChildResult();
    if (isChildrenLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!childData) {
      return <div>Could not load children</div>;
    }
    if (childId) {
      const homeId = getHomeIdFromChildId(childId, childData);
      beckResult.home = homeId;
      beckResult.child = childId;
    }
    onSubmit = async (dataToSubmit: ChildBeckResultPost, { setSubmitting }: any) => {
      try {
        await postChildBeckResult(dataToSubmit);
        // Let the refresh happen when the new page loads (hopefully!)
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else if (hasNumberId) {
    if (isLoading) {
      return <Spinner animation="border" role="status" />;
    } else if (!data) {
      return <div>Could not find data for {id}</div>;
    } else if (error) {
      return <div>Error loading {id}</div>;
    } else if (serverError) {
      return <div> Something went wrong, contact LJI IT </div>;
    }

    // TODO any necessary transformation
    beckResult = data as ChildBeckResultPatch;

    onSubmit = async (dataToSubmit: ChildBeckResultPatch, { setSubmitting }: any) => {
      try {
        await patchChildBeckResult(id, dataToSubmit);
        await refetch();
        setSubmitting(false);
        navigateToSuccessPage();
      } catch (error) {
        setServerError(true);
      }
    };
  } else {
    throw Error('Bad id: ' + idString);
  }

  function handleOnCancel(resetForm: (dataToResetTo: any) => void) {
    resetForm(beckResult);
    navigateAfterCancel();
  }

  // const homeOptions = createHomeOptions(homeData);
  const childOptions = createChildOptions(childData);

  const handleChildChange = (newChild: OnChangeValueSingle<number>, formikSetFieldValue: FormikSetFunction) => {
    setHomeOnChildChange(newChild, childData, formikSetFieldValue);
  };

  return (
    <Formik initialValues={beckResult} validationSchema={CHILD_BECK_RESULT_FORM_SCHEMA} onSubmit={onSubmit}>
      {(formikProps: FormikProps<ChildBeckResultPost | ChildBeckResultPatch>) => {
        const shouldDisplay = mode === Mode.VIEW;
        return (
          <FormikForm>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center font-weight-bold">{t('beckResult.DETAIL_TITLE')}</h1>
                </Col>
              </Row>
              <Row className={'shadow'}>
                <Col sm={10}>
                  <FormikSelect
                    name={'child'}
                    label={t('common.CHILD')}
                    options={childOptions}
                    onChange={handleChildChange}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikDateInput
                    name={'date'}
                    label={t('common.DATE')}
                    formikProps={formikProps}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />

                  <br />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'baiy_raw_score'}
                    label={t('beckResult.BAIY_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'bdiy_raw_score'}
                    label={t('beckResult.BDIY_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'baniy_raw_score'}
                    label={t('beckResult.BANIY_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'bdbiy_raw_score'}
                    label={t('beckResult.BDBIY_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <FormikTextInput
                    formikProps={formikProps}
                    name={'bsciy_raw_score'}
                    label={t('beckResult.BSCIY_RAW_SCORE')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                  <br />

                  <p>
                    {t('beckResult.CHILD_AGE_11_QUESTION')}{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://drive.google.com/file/d/1BDFfCobjRYBAAbY_qPU1fmefs5FCR3WL/view?usp=sharing'}
                    >
                      {t('beckResult.T_SCORE_11_CHART_LINK')}
                    </a>
                  </p>
                  <p>
                    {t('beckResult.CHILD_AGE_15_QUESTION')}{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://drive.google.com/file/d/1x5AOt2jk5ifaontsbUP4j1I1admNc2Zi/view?usp=sharing'}
                    >
                      {t('beckResult.T_SCORE_15_CHART_LINK')}
                    </a>
                  </p>

                  <Row>
                    <Col sm={9}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'baiy_t_score'}
                        label={t('beckResult.BAIY_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.bai_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'bdiy_t_score'}
                        label={t('beckResult.BDIY_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.bdi_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'baniy_t_score'}
                        label={t('beckResult.BANIY_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.bani_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'bdbiy_t_score'}
                        label={t('beckResult.BDBIY_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.bdbi_risk_level} />
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <FormikTextInput
                        formikProps={formikProps}
                        name={'bsciy_t_score'}
                        label={t('beckResult.BSCIY_T_SCORE')}
                        isReadOnly={mode === Mode.VIEW}
                        labelWidth={5}
                        showSideBySide={true}
                      />
                    </Col>
                    <RiskLevelDisplay shouldDisplay={shouldDisplay} riskLevel={data?.bsci_risk_level} />
                  </Row>

                  <br />
                  <FormikSinglePatchFileUpload
                    name={'original_scan'}
                    label={t('beckResult.ORIGINAL_SCAN')}
                    isReadOnly={mode === Mode.VIEW}
                    labelWidth={4}
                    showSideBySide={true}
                  />
                </Col>
                <Col sm={2}>
                  <EditToggle
                    show={hasChangePermission}
                    mode={mode}
                    formikProps={formikProps}
                    toEditLink={{
                      pathname: '../child-beck-result/edit/' + idString,
                      search: childId ? '?childId=' + childId : undefined,
                    }}
                    onCancel={handleOnCancel.bind(null, formikProps.resetForm)}
                  />
                </Col>
                <DefaultPageActionButtons
                  mode={mode}
                  formikProps={formikProps}
                  onCancelEdit={handleOnCancel.bind(null, formikProps.resetForm)}
                  onBack={onBack}
                />
              </Row>
            </Container>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildBeckResultPage;
