import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { GenericOption } from '../shared/formik/FormikSelect/FormikSelect';
import { ChildTransitionProject } from '../typings/api-types';
import { ChildTransitionProjectPatch, ChildTransitionProjectPost } from '../typings/types-to-send';

const TRANSITION_PROJECT_REST_URL = '/child-transition-project';
const TRANSITION_PROJECT_NAME_REST_URL = '/transition-project-name';

const useGetTransitionProjects = (params?: any, enabled?: boolean) => {
  if (!params) {
    params = {};
  }
  const requestConfig = {
    params: params,
  } as AxiosRequestConfig;

  return useQuery(
    ['getTransitionProjects', requestConfig],
    async () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      // if (gridApi) {
      //   gridApi.showLoadingOverlay();
      // }
      const response = await axios.get<ChildTransitionProject[]>(TRANSITION_PROJECT_REST_URL, requestConfig);
      return response?.data;
    },
    {
      enabled,
    },
  );
};

const useGetTransitionProjectDetail = (id: number | undefined, enabled: boolean) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['getTransitionProjects', requestConfig, id],
    async () => {
      const response = await axios.get<ChildTransitionProject>(TRANSITION_PROJECT_REST_URL + '/' + id, requestConfig);
      return response?.data;
    },
    {
      enabled: enabled,
    },
  );
};

const patchTransitionProject = (id: number | undefined, data: ChildTransitionProjectPatch) => {
  if (id === undefined) {
    throw new Error('Id was undefined, please set enabled property on useQuery');
  }
  return axios.patch<ChildTransitionProjectPatch>(TRANSITION_PROJECT_REST_URL + '/' + id + '/', data);
};

const postTransitionProject = (data: ChildTransitionProjectPost) => {
  return axios.post<ChildTransitionProjectPost, AxiosResponse<ChildTransitionProject>>(
    TRANSITION_PROJECT_REST_URL + '/',
    data,
  );
};

const deleteTransitionProject = (id: number) => {
  return axios.delete(TRANSITION_PROJECT_REST_URL + '/' + id + '/');
};

const useGetExistingTransitionProjectNames = () => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['getTransitionProjects', requestConfig], async () => {
    // If the grid isn't ready, this won't work,
    //  but ag grid defaults to showing loading overlay initially anyway
    // if (gridApi) {
    //   gridApi.showLoadingOverlay();
    // }
    const response = await axios.get<string[]>(TRANSITION_PROJECT_NAME_REST_URL, requestConfig);
    return response?.data;
  });
};

const createTransitionProjectNameOptions = (names: string[] | undefined): GenericOption[] => {
  if (!names) {
    return [];
  }
  return names.map((name) => {
    return {
      label: name,
      value: name,
    } as GenericOption;
  });
};

export {
  useGetTransitionProjects,
  useGetTransitionProjectDetail,
  patchTransitionProject,
  postTransitionProject,
  deleteTransitionProject,
  useGetExistingTransitionProjectNames,
  createTransitionProjectNameOptions,
};
