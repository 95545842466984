import { useTranslation } from 'react-i18next';
import { useGetChildOverviewEducation } from '../../api/child_api';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import React, { useContext, useRef } from 'react';
import {
  DEFAULT_DATE_FILTER_PARAMS,
  isMoreThanXMonthsOld,
  isoDateToCommonFormat,
} from '../../shared/helpers/dateHelper';
import styles from './ChildEducation.module.scss';
import { AgGridReact } from 'ag-grid-react';
import GridActions from '../../shared/grid/GridActions/GridActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { ReadOnlyChildGradeCell } from '../../gradeReport/ReadOnlyChildGradeCell/ReadOnlyChildGradeCell';
import ChildEducationGradeReportChart from '../ChildEducationGradeReportChart/ChildEducationGradeReportChart';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import ChildWrat5ScoreCell from '../../wrat5/ChildWrat5ScoreCell/ChildWrat5ScoreCell';
import ChildToniScoreCell from '../../toni/ChildToniScoreCell/ChildToniScoreCell';
import ChildSkillsList from '../ChildSkillsList/ChildSkillsList';
import DueChildTrainingSkillIcon from '../../skill/DueChildTrainingSkillIcon/DueChildTrainingSkillIcon';
import { getClassDisplay } from '../../shared/helpers/classLevelHelper';
import { replaceNewlinesWithBreaks } from '../../shared/helpers/formattingHelper';
import { deleteChildGradeReport } from '../../api/grade_report_api';
import { deleteChildToniResult } from '../../api/toni_result_api';
import { deleteChildWratResult } from '../../api/wrat_result_api';
import { ChildGradeReport, ChildToniResult, ChildWratResult } from '../../typings/api-types';
import FeedbackMessagesContext from '../../shared/contexts/FeedbackMessagesContext';
import { Domain } from '../../api/permissions_api';
import { genericOnDelete } from '../../shared/components/GenericSearchPage/GenericSearchPage';
import i18n from 'i18next';
import AsOfDate from '../../shared/components/AsOfDate/AsOfDate';
import { suppressDataEntryEvents } from '../../shared/grid/gridComponentHelpers';

type ChildEducationProps = {
  childId: number;
};

const NUMBER_OF_MONTHS_OLD_GRADE = 7;

function getGradeReportColumnDefs(onDelete: (objectToDelete: ChildGradeReport) => Promise<any>, childId: number) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 90,
    },
    {
      headerName: t('gradeReport.CHILD_GRADE'),
      cellRenderer: ReadOnlyChildGradeCell,
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-grade-report',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

function getToniResultColumnDefs(onDelete: (objectToDelete: ChildToniResult) => Promise<any>, childId: number) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('toniResult.SCORE'),
      cellRenderer: ChildToniScoreCell,
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-toni-result',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

function getWratResultColumnDefs(onDelete: (objectToDelete: ChildWratResult) => Promise<any>, childId: number) {
  const t = i18n.t;
  const COLUMN_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: t('common.DATE'),
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      width: 190,
    },
    {
      headerName: t('wratResult.SCORE'),
      cellRenderer: ChildWrat5ScoreCell,
      flex: 1,
    },
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: GridActions,
      cellRendererParams: {
        pageUrl: '/child-wrat-result',
        onDelete: onDelete,
        childId: childId,
      },
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ] as ColDef[];
  return COLUMN_DEFS;
}

const ChildEducation = (props: ChildEducationProps) => {
  const { t } = useTranslation();
  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);
  const { data, isLoading, error, refetch } = useGetChildOverviewEducation(props.childId);

  const gradeReportGridRef = useRef<AgGridReact>(null);
  const gradeReportPermissionDomain = Domain.CHILD_GRADE_REPORT;
  const gradeReportTranslationPrefix = 'gradeReport.';
  const onDeleteChildGradeReport = (objectToDelete: ChildGradeReport) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildGradeReport,
      gradeReportPermissionDomain,
      gradeReportTranslationPrefix,
      gradeReportGridRef,

      addFeedbackMessage,
      refetch,
    );
  };

  const toniGridRef = useRef<AgGridReact>(null);
  const toniPermissionDomain = Domain.CHILD_TONI_RESULT;
  const toniTranslationPrefix = 'toniResult.';
  const onDeleteToniResult = (objectToDelete: ChildToniResult) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildToniResult,
      toniPermissionDomain,
      toniTranslationPrefix,
      toniGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  const wratGridRef = useRef<AgGridReact>(null);
  const wratPermissionDomain = Domain.CHILD_WRAT_RESULT;
  const wratTranslationPrefix = 'wratResult.';
  const onDeleteWratResult = (objectToDelete: ChildWratResult) => {
    return genericOnDelete(
      objectToDelete,

      deleteChildWratResult,
      wratPermissionDomain,
      wratTranslationPrefix,
      wratGridRef,

      addFeedbackMessage,

      refetch,
    );
  };

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else if (error) {
    return <div>Error loading {props.childId}</div>;
  } else if (!data) {
    return <div>Could not find data for {props.childId}</div>;
  }

  let lastGradeReportElement = undefined;
  if (data.last_grade_report) {
    let dateColoringClass = '';
    if (isMoreThanXMonthsOld(data.last_grade_report.date, NUMBER_OF_MONTHS_OLD_GRADE)) {
      dateColoringClass = 'bad';
    }
    lastGradeReportElement = (
      <>
        <Col
          style={{
            border: 'solid',
            borderWidth: 1,
            marginTop: 10,
          }}
        >
          <ReadOnlyChildGradeCell data={data.last_grade_report} />
        </Col>
        <Col style={{ marginTop: 5 }}>
          <AsOfDate colorKey={dateColoringClass} date={data.last_grade_report.date} />
          <LinkContainer
            className={'ms-2'}
            to={{
              pathname: '/child-grade-report/view/' + data.last_grade_report.id,
              search: '?childId=' + props.childId,
            }}
          >
            <Button variant={'outline-primary'}>{t('common.VIEW')}</Button>
          </LinkContainer>
        </Col>
      </>
    );
  } else {
    lastGradeReportElement = <div>{t('child.education.LAST_GRADE_REPORT_UNKNOWN')}</div>;
  }

  let finishingExamElement = undefined;

  let addButton = (
    <LinkContainer
      className={'ms-2'}
      to={{
        pathname: '/child-see-result/create/new',
        search: '?childId=' + props.childId,
      }}
    >
      <Button>
        {' '}
        + <FontAwesomeIcon icon={'child'} /> {t('seeResult.ADD_CHILD_BUTTON')}
      </Button>
    </LinkContainer>
  );
  if (data.last_see_result) {
    finishingExamElement = (
      <span>
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>{data.last_see_result.percentage} %</span>
        <AsOfDate date={data.last_see_result.date} label={t('child.education.TAKEN_ON')} />
        <LinkContainer
          className={'ms-2'}
          to={{
            pathname: '/child-see-result/view/' + data.last_see_result.id,
            search: '?childId=' + props.childId,
          }}
        >
          <Button variant={'outline-primary'}>{t('common.VIEW')}</Button>
        </LinkContainer>
      </span>
    );
  } else if (data.apprx_finishing_exam_date) {
    finishingExamElement = (
      <span>
        {t('child.education.FINISHING_EXAM_NOT_TAKEN_YET')}{' '}
        <AsOfDate date={data.apprx_finishing_exam_date} label={t('child.education.FINISHING_EXAM_WILL_TAKE_AROUND')} />
        {addButton}
      </span>
    );
  } else {
    finishingExamElement = (
      <span>
        {t('child.education.FINISHING_EXAM_DATE_UNKNOWN')}
        {addButton}
      </span>
    );
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      {data.current_school && (
        <Form.Group>
          <Form.Label column sm={4}>
            {t('child.education.CURRENT_SCHOOL_NAME')}
          </Form.Label>
          <span>{data.current_school.name}</span>
        </Form.Group>
      )}
      {data.current_class && (
        <Form.Group>
          <Form.Label column sm={4}>
            {t('child.education.CURRENT_CLASS')}
          </Form.Label>
          <span>{getClassDisplay(data.current_class)}</span>
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.education.FINISHING_EXAM')}
        </Form.Label>
        {finishingExamElement}
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          {t('child.education.LAST_GRADE_REPORT')}
        </Form.Label>
        {lastGradeReportElement}
      </Form.Group>
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.education.GRADE_REPORTS_AND_GPA')}
        </Form.Label>
      </Form.Group>
      {data.grade_reports && (
        <Row>
          <Col className="ag-theme-balham" lg={6}>
            <div
              style={{
                height: '300px',
              }}
            >
              <AgGridReact
                ref={gradeReportGridRef}
                rowData={data.grade_reports}
                defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
                columnDefs={getGradeReportColumnDefs(onDeleteChildGradeReport, props.childId)}
                rowSelection={'single'}
                // Cell height doubled (2 cells) + Css grid gap property
                rowHeight={27 + 27 + 5}
                getRowId={(params) => params.data.id}
                animateRows={true}
              />
            </div>
            <div className="d-flex flex-column align-items-end">
              <LinkContainer
                to={{
                  pathname: '/child-grade-report/create/new',
                  search: '?childId=' + props.childId,
                }}
              >
                <Button>
                  {' '}
                  + <FontAwesomeIcon icon={'child'} /> {t('gradeReport.ADD_CHILD_BUTTON')}
                </Button>
              </LinkContainer>
            </div>
          </Col>
          <Col
            style={{
              height: '400px',
            }}
            lg={6}
          >
            <ChildEducationGradeReportChart gradeReports={data.grade_reports} />
          </Col>
        </Row>
      )}
      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.education.TONI_RESULTS')}
        </Form.Label>
      </Form.Group>
      <div
        className="ag-theme-balham"
        style={{
          height: '200px',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={toniGridRef}
          rowData={data.toni_results}
          defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
          columnDefs={getToniResultColumnDefs(onDeleteToniResult, props.childId)}
          getRowId={(params) => params.data.id}
          rowSelection={'single'}
          animateRows={true}
        />
      </div>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/child-toni-result/create/new',
              search: '?childId=' + props.childId,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'child'} /> {t('toniResult.ADD_CHILD_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label column sm={4}>
          {t('child.education.WRAT_RESULTS')}
        </Form.Label>
      </Form.Group>
      <div
        className="ag-theme-balham"
        style={{
          height: '200px',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={wratGridRef}
          rowData={data.wrat_results}
          defaultColDef={{ resizable: true, suppressKeyboardEvent: suppressDataEntryEvents }}
          columnDefs={getWratResultColumnDefs(onDeleteWratResult, props.childId)}
          getRowId={(params) => params.data.id}
          rowSelection={'single'}
          animateRows={true}
        />
      </div>
      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/child-wrat-result/create/new',
              search: '?childId=' + props.childId,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'child'} /> {t('wratResult.ADD_CHILD_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label column>{t('child.education.INCOMPLETE_CHILD_TRAININGS')}</Form.Label>
            {data && data.child_training_skills_due_count > 0 && (
              <DueChildTrainingSkillIcon
                className={styles.dueTrainingIcon}
                numberDue={data.child_training_skills_due_count}
              />
            )}
          </Form.Group>
          <ChildSkillsList
            emptyListLabel={t('child.education.NO_REQUIRED_SKILLS')}
            skills={data.required_child_training_skills}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label column>{t('child.education.COMPLETED_CHILD_TRAININGS')}</Form.Label>
          </Form.Group>
          <ChildSkillsList
            emptyListLabel={t('child.education.NO_COMPLETED_SKILLS')}
            skills={data.completed_child_training_skills}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex flex-column align-items-end">
          <LinkContainer
            to={{
              pathname: '/home-child-training/create/new',
              search: '?childId=' + props.childId,
            }}
          >
            <Button>
              {' '}
              + <FontAwesomeIcon icon={'home'} /> {t('childTraining.ADD_HOME_BUTTON')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label column>{t('child.education.INTAKE_NOTES')}</Form.Label>
          </Form.Group>

          {data.intake_education_narrative ? (
            <p>{replaceNewlinesWithBreaks(data.intake_education_narrative)}</p>
          ) : (
            <p style={{ fontStyle: 'italic' }}>{t('child.education.NO_INTAKE_NOTES')}</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ChildEducation;
export { NUMBER_OF_MONTHS_OLD_GRADE };
